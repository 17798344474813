import stepProgress from "@/components/generals/step-progress"
import {FormWizard, TabContent} from 'vue-form-wizard'
import payment from '@/components/generals/paymentMethods'
import altaTenant from '@/components/tenant/rentModal'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapActions, mapGetters } from "vuex"
import moment from "moment"
import VueHtml2pdf from 'vue-html2pdf'

export default { 
    components:{
      stepProgress,
      FormWizard,
      TabContent,
      payment,
      altaTenant,
      VueHtml2pdf
    },
    props:{
      idBulding:0,
      idTenant:0,
      property:[]
    },
    data () {
      return{
        currentRolComplete:[],
        payMethod:true,
        idmethod:'',
        tenant_identificator:0,
        initDateArray:[],
        investigationInitDate:'',
        investigationInitTime:'',
        statusProcess:'',
        userRolArray:[],
        poliza:[],
        dayToSig:'',
        dateToSig:'',
        timeToSig:'',
        contratUrl: '',
        idStatusProcess:0,
      }
    },
    computed:{
      getClasses(){
        return {isDragging: this.isDragging}
      },
      ...mapGetters({
        user:'getUser',
        getCurrentRol:'getCurrentRol',
        getRol:'getRol',
        getStoreUser:'getStoreUser'
      })
    },
    methods: {
      ...mapActions({
        getUserDataStoreInfo:'getUserDataStoreInfo'
      }),
      closeModal () {
        this.$modal.hide('modal-process')
      },
      onlinePay() {
        this.idmethod = 'online'
        this.payMethod = false
      },
      payCard() {
        this.idmethod = 'creditCartMethod'
        this.payMethod = false
      },
      transfer() {  
        this.idmethod = 'transferMethod'
        this.payMethod = false
      },
      showModalTenant () {
        this.$modal.show('modal-altaInquilino')
      },
      nextTenant (prop) {
        this.$refs.processRentWizzard.nextTab()
        this.idmethod = ' '
        /* if(this.statusProcess === 'investigacion no autorizada' ){
          this.$refs.processRentWizzard.prevTab()
        } */
      },
      backTenant(prop){
        this.idmethod = ''
        this.payMethod = true
        this.$refs.processRentWizzard.prevTab()
      },
      async dateInit (){
        try {
          let condition ={
            tenant_id: this.currentRolComplete.user_rol_id,
            property_id: this.idBulding
          }
          const response = await axiosTrocutServer.post('/getRentalProcess',condition)
            if(response.status === 200){
              //console.log(response.data.response[0])
              this.investigationInitDate = moment(response.data.response[0].init_date).format('YYYY-MM-DD')
              this.investigationInitTime = moment(response.data.response[0].init_date).format('HH:MM:SS')
              this.initDateArray = response.data.response[0]
              this.statusProcess = response.data.response[0].status
              this.idStatusProcess = response.data.response[0].process_status_id
              this.contratUrl = this.initDateArray.urlContrat[0].url
              this.dayToSig = moment(this.initDateArray.last_update).locale('es-mx').format('dddd')
              this.dateToSig =  moment(this.initDateArray.last_update).format('l')
              this.timeToSig = moment(this.initDateArray.last_update).format('LT')
              console.log('proceso de renta',this.contratUrl)
            } 
          } catch (error) {
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; porfavor espere a que nuestros administradores validen sus datos.`,
              type: 'error'
          })
        }
      },
      formatCurrency (amount) {
        return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(amount)
      },
      generatePDF(){
        console.log('por amor a jebus el testamento de codigo que esta escrito creo que indica falta de vida social y novia ');
        this.$refs.html2Pdf.generatePdf()
      }
    },
    mounted (){
      this.getUserDataStoreInfo({
        user_id : this.user.user.user_id
      })
      const store = this.getStoreUser.roles
      this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
      setTimeout(()=> {
        this.dateInit()
      },3000)
     /*  console.log('reach',this.currentRolComplete)
      console.log('tierra',this.user) */
    } 
}


