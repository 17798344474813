import { mapActions } from "vuex"

export default {
    name: "app",
    props:{
        propertyInfo: {
            property_id : String,
            url  : String
        }
    },
    data () {
        return {
            
        }
    },
    watch: {
    },
    computed: {
    }, 
    methods:{
        ...mapActions({
            setGeneralDataSearch: 'setGeneralDataSearch'
          }),
        goToPropertyPropfile (id){
            this.setGeneralDataSearch({
                property_id: id
            })
            this.$router.push({name:'propertyCard'})
        },
    },
    mounted () {
    }
}

