import stepProgress from "@/components/generals/step-progress"
import { required, optional, url} from "vuelidate/lib/validators"
import countries from '@/mixins/countries.json'
import Multiselect from "vue-multiselect"
import { validationMixin } from 'vuelidate'
import moment from "moment"
import { mapActions, mapGetters } from "vuex"
import axiosTrocutServer from '@/axios/axiosTrocutServer'

export default {
  props:{
    idPropertyRent:[],
    validateSteep: null
  },
  mixins: [validationMixin],
  components: {
    stepProgress,
    Multiselect
  },
  data () {
    return{
      collapse1: false,
      collapse2: false,
      submitted: false,
      laboralForm: {
        typeJob: 'Empleado',
        profession: '',
        salary: '',
        company: '',
        date_init: '',
        horary: '',
        turn_of_company: '',
        web_page: '',
        immediate_boss: '',
        email_boss: '',
        job: '',
        phone_company: '',
        position_boss: '',
        amount: 0,
        description: ''
      },
      today: moment().format('YYYY-MM-DD'),
      optionState: [],
      otherIncome: false,
      submittedL : false,
      optionstownship: [],
      optionsCity: [],
      neighboorhood: [],
      horaryArray: [{value:0,name:'Matutino'},{value:1,name:'Vespertino'},{value:3,name:'Nocturno'}],
      laboralInfoTenantAddress: {
        tenantJobStreet: '',
        tenantJobTownship: '',
        tenantJobState: '',
        tenantJobOutdoorNumber: 0,
        tenantJobInteriorNumber: 0,
        tenantJobCP: '',
        tenantPostalCode: null
      },
      submittedAL: false,
    }
  },
  validations: {
    laboralForm: {
      profession: { required },
      company: { required },
      job: { required },
      turn_of_company: { required },
      date_init: { required },
      phone_company: { required },
      salary: { required },
      horary: { required },
      web_page: {
        url,
        $autoDirty: true
      },
      email_boss: { required },
      position_boss: { required },
      immediate_boss: { required },
    },
    laboralInfoTenantAddress: {
      tenantPostalCode: { required },
      tenantState: { required },
      tenantMunicipio: { required },
      tenantNeighboorhood: { required },
      tenantStreet: { required },
      tenantOutDoorNumber: { required },
      tenantInDoorNumber: { optional },
      tenantCity: { required }
    }
  },
  computed: {
    getClasses () {
      return {isDragging: this.isDragging}
    },
    ...mapGetters({
      user: 'getUser',
      getRol: 'getRol',
      getCurrentRol: 'getCurrentRol',
      getStoreUser: 'getStoreUser'
    })
  },
  watch: {
    otherIncome (item) {
      if (!item){
        this.otherIncome = false
      }
    },
    validateSteep () {
      console.log('deberia de realizar la validacion del formulario desde el hijo')
      this.validateForm()
    }
  },
  methods: {
    ...mapActions({
      updateNextStep:'updateNextStep',
      setGeneralDataSearch: 'setGeneralDataSearch',
      countNotifications: 'countNotifications'
    }),
    validateState (name, type) {
      this.submitted = true
      if (type === 'basic') {
        const { $dirty, $error } = this.$v.laboralForm[name]
        return $dirty ? !$error : null
      } else {
        const { $dirty, $error } = this.$v.laboralInfoTenantAddress[name]
        return $dirty ? !$error : null
      }
    },
    async validateForm () {
      try {
        let valid = true
        console.log('Entro a validar el formulario', valid)
        this.$v.laboralForm.$touch()
        this.$v.laboralInfoTenantAddress.$touch()
        this.collapse1 = false
        this.collapse2 = false
        // // this.sleep(2000)
        // if (this.$v.basicData.$anyError) {
        //   this.collapse1 = true
        //   valid = false
        // }
        // if (this.$v.infoTenant.$anyError) {
        //   this.collapse2 = true
        //   valid = false
        // }

        // if (valid) {
        //   this.currentIndex++
        //   const x = await this.savePersonalInfoTenant()
        //   const y = await this.addAdress()
        //   if (x && y) {
        //     await this.updateCompleteBasicInfo(1, 1) // actualizar direcion completa y informacion base
        //   } else {
        //     if (!x) {
        //       await this.updateCompleteBasicInfo(1, 0) // actualizar informacion base completa, direccion incompleta
        //     } else if (!y) {
        //       await this.updateCompleteBasicInfo(0, 1) // actualizar dirección completa, inforamacion base incompleta
        //     }
        //   }
        //   await this.updateCurrentUserStep(0, 1)
        //   await this.getUserDataStore()
        // }
        // return valid
      } catch (error) {
        console.log('Error validar Informacion personal', error)
      }
    },
    async saveLaboralInfo () {
        this.$v.laboralInfoTenant.$touch()
        if (this.$v.laboralInfoTenant.$invalid) {
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; por favor ingrese los datos requridos`,
            type:`error`
          })
        } else {
          try {
            let payload={
              tenant_id: this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : this.user.roles[0].user_rol_id,
              job: this.laboralInfoTenant.job ,
              profession: this.laboralInfoTenant.profession ,
              salary: this.laboralInfoTenant.salary ,
              phone_company: this.laboralInfoTenant.phone_company,
              company:this.laboralInfoTenant.company,
              horary:this.laboralInfoTenant.horary.name ,
              turn_of_company: this.laboralInfoTenant.turn_of_company ,
              web_page:this.laboralInfoTenant.web_page ,
              immediate_boss:this.laboralInfoTenant.immediate_boss ,
              position_boss:this.laboralInfoTenant.position_boss ,
              email_boss:this.laboralInfoTenant.email_boss ,
              date_init: this.laboralInfoTenant.date_init = moment(this.laboralInfoTenant.date_init).format('YYYY-MM-DD'),
              type_job: this.laboralInfoTenant.typeJob,
              amount: this.laboralInfoTenant.amount,
              description: this.laboralInfoTenant.description
            }
            const respuetaLaboral = await axiosTrocutServer.post('/registerUserJobInfo',payload)
            if(respuetaLaboral.status === 200){
              this.$toastedPush({
                message: ` la infomarcion laboral se a agregado de forma correcta`,
                type:`success`
              })
              this.$emit('changeStepIndex')
            }
          } 
          catch (error) {
            this.$toastedPush({
              message: ` &nbsp;&nbsp;&nbsp; No se ha podido registrar correctamente la infomación favor de 
              intentarlo mas tarde`,
              type:`error`
          })
        }
      }
    },
    async addressLaboral () {
      try {
        this.submittedL = true
        this.$v.laboralInfoTenantAddress.$touch()
        if(this.$v.laboralInfoTenantAddress.$invalid){
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp;la direccion laboral no pudo ser guardada porfavor intentelo mas tarde :( `,
            type:`error`
          })
        } else {
          let addressData = {
            street: this.laboralInfoTenantAddress.tenantJobStreet,
            suburb_id: this.laboralInfoTenantAddress.tenantJobNeightboorhood.value,
            city_id:this.laboralInfoTenantAddress.tenantJobCity.value,
            town_id: this.laboralInfoTenantAddress.tenantJobTownship.value,
            state_id: this.laboralInfoTenantAddress.tenantJobState.value,
            interior_number: this.laboralInfoTenantAddress.tenantJobInteriorNumber,
            outdoor_number: this.laboralInfoTenantAddress.tenantJobOutdoorNumber,
            user_rol_id: this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : this.user.roles[0].user_rol_id ,
            address_type: 'Job',
            postal_code: this.laboralInfoTenantAddress.tenantJobCP
          }
          const addresLaboral = await axiosTrocutServer.post('/addAddress',addressData)
          if (addresLaboral.status === 200) {
            //console.log('la direccion fue registrada');
            this.saveLaboralInfo()
          }
        }
      } catch (error) {
        console.error('error en ',error);
      }
    },
    changeStates (arg) {
      console.log(`argumentos de eschcga ${this.arrendadorInfo.arrendadorState}`,this.arrendadorInfo.arrendadorState);
    },
    async getStates () {
      try {
        const response = await axiosTrocutServer.post('/getStates')
        if (response.status === 200) {
          const states = response.data.states
          states.forEach(el => {
            this.optionState.push({
              name: el.state,
              value: el.state_id
            })
          })
        }
      } catch (error) {
        console.error('get States', error)
      }
    },
    async getTownhips (...arg) {
      try {
        this.optionstownship= []
        const params = {
          state_id : arg[0].value
        }
        const response = await axiosTrocutServer.post('/getTownship', params)
          if(response.status === 200){
            response.data.township.forEach( el  =>{
                this.optionstownship.push({
                  name: el.township,
                  value:el.township_id
                })
            })
          }
      } catch (error) {
        console.error('error',error);
      }
    },
    async getCities (...arg) {
      try {
        this.optionsCity = []
        let params = {
          town_id: arg[0].value
        }
        const response = await axiosTrocutServer.post('/getCities',params)
          if(response.status === 200){
            response.data.cities.forEach( el  =>{
                this.optionsCity.push({
                  name: el.city,
                  value:el.city_id
                })
            })
          } else {
            console.error('error');
          }
      } catch (error) {
        console.error('error',error);
      }
    },
    async getNeighboorhood (...arg) {
      try {
        this.neighboorhood = []
        let params = {
          city_id: arg[0].value
        }
        const response = await axiosTrocutServer.post('/getNeighborhoods',params)
          if(response.status === 200){
            response.data.neighborhood.forEach( el  =>{
                this.neighboorhood.push({
                  name: el.neighborhood,
                  value:el.neighborhood_id
                })
            })
          } else {
            console.error('error');
          } 
      } catch (error) {
        console.error('error',error);
      }
    },
  async getLaboralInfo() {
    try {
      let params = {
        tenant_id: this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : this.user.roles[0].user_rol_id
      }
      const responseInfo = await axiosTrocutServer.post('/getInfoTenant',params)
      if(responseInfo.data.infoLaboral.data.job_id !== undefined){
        const data = responseInfo.data.infoLaboral.data
        let type = ''
        switch (data.type_job) {
          case 0:
            type = 'Empleado'
            break;
          case 1:
            type = 'Independiente'
            break;
          case 2:
            type = 'Ambos'
            break;
          default:
            break;
        }
        this.laboralForm.typeJob = type
        this.laboralForm.profession = data.profession
        this.laboralForm.salary = data.salary
        this.laboralForm.company = data.company
        this.laboralForm.date_init = moment(data.date_init).format('YYYY-MM-DD')
        this.laboralForm.horary = data.working_hours
        this.laboralForm.turn_of_company = data.turn_of_company
        this.laboralForm.web_page = data.web_page
        this.laboralForm.immediate_boss = data.immediate_boss
        this.laboralForm.email_boss = data.email_boss
        this.laboralForm.job = data.job
        this.laboralForm.phone_company = data.phone_company
        this.laboralForm.position_boss = data.position_boss
        this.laboralForm.amount = data.amount
        this.laboralForm.description = data.description  
      }
    } catch (error) {
      console.error(error);
    }
  }
},
  mounted (){
    const store = this.getStoreUser.roles
    this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    this.getStates()
    const tempArr =[]
    this.user.roles.forEach(el => {
      tempArr.push(el)
      this.storeStep = tempArr.filter(t => {
        return t.rol === 'Inquilino'
      })
    })
    this.getLaboralInfo()
    this.$refs.collapseLab3.click()
  }
}