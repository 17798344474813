//import timeLine from "../../generals/time-line"
import ownerProcess from "@/components/owner/modalRentOwner";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import stepProgress from "@/components/generals/step-progress"
import {FormWizard, TabContent} from 'vue-form-wizard'
import Multiselect from "vue-multiselect"
import { validationMixin } from 'vuelidate'
import countries from '@/mixins/countries.json'
import { required, minLength } from "vuelidate/lib/validators"
import moment from "moment";
import {  mapActions, mapGetters  } from 'vuex'

export default {
    mixins: [validationMixin],
    components:{
        ownerProcess,
        FormWizard,
        TabContent,
        stepProgress,
        Multiselect
    },
    props:{
        idProperty:[]
    },
    data () {
      return {
        errorExist:false,
        addressGeneral:false,
        foreningNumber:0,
        submitted:false,
        optionState:[],
        optionstownship:[],
        optionsCity:[],
        today:'',
        neighboorhood:[],
        nationalityArray:[],
        currentRolComplete:[],
        imageSpinner: false,
        facturaR:false,
        infoUser:{
          name:'',
          lastname:'',
          lastname2:'',
          rfc:'',
          birthday: '',
          generalinfoNumber:0,
          nationality:'Mexico'
        },
        addressInfoUser:{
          generalinfoPostalCode:'',
          generalinfoInDoorNumber:0,
          generalinfoOutDoorNumber:0,
          generalinfoStreet:'',
          generalinfoNeighboorhood:'',
          generalinfoCity:'',
          generalinfoMunicipio:'',
          generalinfoState:'',
          generalinfoidAddress:0
        }
      }
    },
  validations(){
    return {
      infoUser: {
        name: {required},
        lastname:{required},
        lastname2:{required}
      },
      addressInfoUser:{
        generalinfoPostalCode:{required,minLength:4},
        generalinfoNeighboorhood:{ required },
        generalinfoCity:{ required },
        generalinfoMunicipio:{ required },
        generalinfoState:{ required },
        generalinfoOutDoorNumber:{ required },
        generalinfoStreet:{ required }
      }
    }
      
  },
  computed: {
    ...mapGetters({
      user:'getUser',
      getCurrentRol:'getCurrentRol',
      getStoreUser:'getStoreUser',
      getAuth: 'getAuth',
      getUserNew:'getUserNew'
    })
  },
  methods: {
    ...mapActions({
      updateImage:'updateImage',
      getUserDataStore:'getUserDataStore'
    }),
    regimenWithName ({name}){
      return `${name}`
    },
    citiesSelect () {
      for(var c  of countries) {
        this.countries.push(c)
      }
    },
    nameWithNation (name) {
      return `${name}`
    },
    nameWithState({name}) {
      return `${name}`
    },
    nameWithCity({name}){
      return ` ${name}`
    },
    nameWithTownship({name}){
      return `${name}`
    },
    nameWithNeigthborhood ({name}){
      return `${name}`
    },
    async infoUserUp () {
      try {
        this.submitted = true
        this.$v.infoUser.$touch()
        if(!this.$v.infoUser.$invalid) {
          let payload = {}
          var nacimientoMoment = moment (this.infoUser.birthday).format('YYYY-MM-DD')
          var actualidadMoment = moment().format('YYYY-MM-DD')
          var nacimiento = new Date(nacimientoMoment)
          var actualidad = new Date(actualidadMoment)
          var edad = Math.abs(nacimiento.getFullYear() -actualidad.getFullYear())
          if (edad >= 18) {
            payload = {
              user_id: this.user.user.user_id,
              name: this.infoUser.name ,
              lastname: this.infoUser.lastname,
              lastname2: this.infoUser.lastname2,
              birthday: this.infoUser.birthday = moment (this.infoUser.birthday).format('YYYY-MM-DD'),
              is_foreign: 0,
              rfc:' ',
              nationality:'Mexico'
            }
            const response = await axiosTrocutServer.post('/registerUserData',payload)
            if (response.status === 200) {
              this.getUserDataStore({
                user_id: this.user.user.user_id
              })
              return response
            }
          } else {
            payload = {
              user_id: this.user.user.user_id,
              name: this.infoUser.name ,
              lastname:this.infoUser.lastname,
              lastname2:this.infoUser.lastname2,
              birthday:this.infoUser.birthday = moment (this.infoUser.birthday).format('YYYY-MM-DD'),
              is_foreign: 0,
              rfc:' ',
              nationality:'Mexico'
            }
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;debes de tener la mayoria de edad para poder seguir con el proceso`,
              type: 'error'
            })
          }
        }else {
          console.error('error actualizar datos');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addressGeneralInfo () {
      try {
        this.submitted = true
        this.$v.addressInfoUser.$touch()
        if (!this.$v.addressInfoUser.$invalid) {
         let  payload= {
            street: this.addressInfoUser.generalinfoStreet ,
            suburb_id: this.addressInfoUser.generalinfoNeighboorhood.value,
            city_id: this.addressInfoUser.generalinfoCity.value,
            town_id: this.addressInfoUser.generalinfoMunicipio.value ,
            state_id: this.addressInfoUser.generalinfoState.value ,
            interior_number: this.addressInfoUser.generalinfoInDoorNumber ,
            outdoor_number: this.addressInfoUser.generalinfoOutDoorNumber ,
            user_rol_id: this.currentRolComplete.user_rol_id,
            address_type: 'User' ,
            postal_code: this.addressInfoUser.generalinfoPostalCode,
            user_id: this.currentRolComplete.user_id
          }
          console.log('payload',payload);
          const response = await axiosTrocutServer.post('/addAddress',payload)
            if( response.status === 200){
              console.log('response',response);
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;&nbsp; Información guardada correctamente.`,
                type: 'success'
              })
              return response
            }
          }
        } catch (error) {
        console.error( 'error' )
      }
    },
    async compareData(){
      try {
        let valid = true
        this.$v.addressInfoUser.$touch()
        this.$v.infoUser.$touch()
        if (this.$v.infoUser.$anyError) {
          this.errorExist = true
          valid = false
        }
        if(this.$v.addressInfoUser.$anyError){
          this.addressGeneral = true
          valid = false
          this.getStates()
          this.getTownhips()
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; favor de llenar los campos faltantes`,
            type: 'error'
          })
        }
        if(valid){
          const x = await this.infoUserUp()
          const y = await this.addressGeneralInfo()
          if(x && y){
            this.$refs.refInfoGeneral.nextTab()
          }else{
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; Favor de llenar los datos marcados con *`,
              type: 'error'
            })
          }
          return valid
        }
      } catch (error) {
        console.error(error);
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp; Favor de llenar los datos marcados con *`,
          type: 'error'
        })
      }
    },
    validateState (name, type) {
      let data = []
      if (type === 'infoUser') {
        data = this.$v.infoUser[name]
      }if(type === 'addressInfoUser'){
        data = this.$v.addressInfoUser[name]
      }
      const { $dirty, $error } = data
      return $dirty ? !$error : null
    },
    async getStates () {
            try {
              const response = await axiosTrocutServer.post('/getStates')
              if (response.status === 200){
                response.data.states.forEach(el => {
                  this.optionState.push({
                    name: el.state,
                    value: el.state_id
                  })
                })
              }
            } catch (error) {
              console.error('get States',error);
            }
    },
    async getTownhips () {
            try {
              this.optionstownship = []
              const response = await axiosTrocutServer.post('/getTownship')
                if (response.status === 200) {
                  response.data.township.forEach(el => {
                    this.optionstownship.push({
                      value:el.township_id,
                      name:el.township
                    })
                  });
                }
              } catch (error) {
                console.error('getTownship Error', error)
              }
    },
    async getCities (...arg) {
        try {
          this.optionsCity = []
          let params = {
            town_id:  arg[0].value
          }
          const response = await axiosTrocutServer.post('/getCities',params)
          if(response.status === 200){
            response.data.cities.forEach( el  =>{
              this.optionsCity.push({
                value:el.city_id,
                name: el.city
              })
            })
          } else {
            console.error('error');
          }
        } catch (error) {
          console.error('error',error);
        }
    },
    async getNeighboorhood (...arg) {
          try {
            this.neighboorhood = []
            let params = {
              city_id: 1
            }
            const response = await axiosTrocutServer.post('/getNeighborhoods',params)

              if(response.status === 200){
                response.data.neighborhood.forEach( el  =>{
                    this.neighboorhood.push({
                      name: el.neighborhood,
                      value:el.neighborhood_id
                    })
                })
              } else {
                console.error('error');
              } 
          } catch (error) {
            console.error('error',error);
          }
    },
    async getInfo(){
      try {
        let params = {
          user_id: this.currentRolComplete.user_id  !== undefined ? this.currentRolComplete.user_id : this.currentRolComplete.user_id,
          address_type:"User" 
        }
        this.infoUser.name= this.user.user_data.name
        this.infoUser.lastname= this.user.user_data.lastname
        this.infoUser.lastname2= this.user.user_data.lastname2
        this.infoUser.birthday = moment(this.user.user_data.birthday).format('YYYY-MM-DD')
        this.infoUser.generalinfoNumber = this.user.user.phone
        const response = await axiosTrocutServer.post('/getAddress',params)
        if(response.status === 200){
          response.data.address.forEach(el => {
            this.addressInfoUser.generalinfoPostalCode=el.postal_code,
            this.addressInfoUser.generalinfoInDoorNumber=el.interior_number,
            this.addressInfoUser.generalinfoOutDoorNumber=el.outdoor_number,
            this.addressInfoUser.generalinfoStreet=el.street,
            this.addressInfoUser.generalinfoidAddress = el.address_id,
            this.addressInfoUser.generalinfoNeighboorhood={value:el.suburb_id,name:el.suburb},
            this.addressInfoUser.generalinfoCity={value:el.city_id,name:el.city},
            this.addressInfoUser.generalinfoMunicipio={ value:el.town_id, name:el.township} ,
            this.addressInfoUser.generalinfoState={value:el.state_id,name:el.state}
          })
        }
        
      } catch (error) {
        console.error(error);
        }
    },
    async imageProfile (e){
      try {
        this.imageSpinner = true
        const images = e.target.files
        var form = new FormData()
        form.append("type","User")
        form.append("subtype","images")
        form.append("user_id",this.user.user.user_id)
        for (const key in images) {
          form.append('Images', images[key])
        }
        const response = await axiosTrocutServer.post('/addProfileImage', form)
        if( response.status === 200){
          this.imageSpinner = false
          this.updateImage({
            user_id: this.user.user.user_id
          })
          await this.completeInfoBase()
        }
      } catch (error) {
        console.error(error)
      }
    },
    nextGeneralInfo(prop){
      let indexInfoGeneral = prop.activeTabIndex
      switch (indexInfoGeneral) {
        case 0:
          this.compareData()
        break
      }
    },
    backGeneralInfo(prop){
      this.$refs.refInfoGeneral.prevTab()
    },
    async completeInfoBase (){
      try {
          let params = {
            complete_info_base: 1,
            complete_address: 1,
            user_id:this.user.user.user_id
          }
        const response = await axiosTrocutServer.post('/updateInfoBase',params)
        if(response.status === 200){
          this.$emit('loadImage')
          this.getUserDataStore({
            user_id: this.user.user.user_id
          })
         this.$modal.hide('modal-GeneralInfo')
        }
      } catch (error) {
        console.error(error);
      }
    },
    modalClose (){
      //this.$modal.hide('modal-GeneralInfo')
      this.$modal.hide('modal-GeneralInfo')
    }
  },
  async  mounted () {
      this.today = moment().format('YYYY-MM-DD')
      this.getStates()
      this.getTownhips()
      const store = this.getStoreUser.roles
      if(store !== undefined){  
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        await this.getInfo()
      }
    }
}