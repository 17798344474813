
import { Carousel, Slide } from 'vue-carousel';

export default {
    components: {
      Carousel,
      Slide
    },
    name: "app",
    data () {
        return {
          trocut: false,
          broker: false,
          inquilino: false,
          propietario: false,
          resolutionScreen: 0,
          settings: {
            itemsToShow: 1,
            snapAlign: 'start',
          },
          breakpoints:{
            320:{
              itemsToShow: 1,
              snapAlign: 'start',
            },
            768:{
            itemsToShow: 1,
            snapAlign: 'center',
          }
        },
        windowWidth: window.innerWidth,
      }
    },
    watch: {
      
    },
    computed: {
      widthSacreen () {
        return window.innerWidth()
     }
    },
    methods: {
      async showModalVideo (type) {
        console.log('type', type)
          this.$modal.show('modal-tutorial')
          this.trocut = false
          this.broker = false
          this.inquilino = false
          this.propietario = false
          await this.sleep(1000)
          switch (type) {
            case 'trocut':
              this.trocut = true
              break
            case 'broker':
              this.broker = true
              break
            case 'inquilino': 
              this.inquilino = true
              break
            case 'propietario':
              this.propietario = true
              break
            default:
              break
          }
          await this.sleep(1000)
          this.$refs.videoRef.play();
      },
      sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      },
      onResize() {
        this.windowWidth = window.innerWidth
      }
    },
    mounted ()  {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    }
    
}
    