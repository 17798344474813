//import brokerPropiedades from '@/components/broker/brokerPropertyProfile'

export default {
  components: {
    //brokerPropiedades   
  },
  data(){
    return{
     
    }
  }
};
