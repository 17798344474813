<template>
  <div>
    <stripe-element-payment
      ref="paymentRef"
      pk="pk_test_51K738XKwC7CF7kIrnqxg2xdts0Q59bYRtkFLcV28fXUJAMkhPKPL4mxxQ3yA562aSH5HR2FEUipiyfXJICOzq1PN00BK7jA2eE"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
    />
    <button @click="pay">Pay Now</button>
  </div>
</template>

<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
export default {
  components: {
    StripeElementPayment,
  },
  data () {
    return {
      pk: 'your-publishable-key',
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: 'http://localhost:8080/success', // success url
      },
    };
  },
  mounted () {
    this.generatePaymentIntent();
  },
  methods: {
    async generatePaymentIntent () {
      // const paymentIntent = await apiCallToGeneratePaymentIntent(); // this is just a dummy, create your own API call
      // this.elementsOptions.clientSecret = paymentIntent.client_secret;
      this.elementsOptions.clientSecret = 'pi_3LeklHKwC7CF7kIr2EaKY0ef_secret_XPOUWW8O61P80QkF8PiHaRNxm'
    },
    pay () {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
For a more comprehensive tutorial, go here https://stripe.com/docs/payments/quickstart