//import timeLine from "../../generals/time-line"
import ownerProcess from "@/components/owner/modalRentOwner";
import axiosTrocutServer from '@/axios/axiosTrocutServer'


export default {
    components:{
        ownerProcess
    },
    props:{
        idProperty:[]
    },
    data () {
        return{
            book:'https://aprendepython.es/_downloads/907b5202c1466977a8d6bd3a2641453f/aprendepython.pdf',
            
        }
    },
    methods: {
        goToDownloadPDF () {
            this.$router.push({path:'aprendepython.es/_downloads/907b5202c1466977a8d6bd3a2641453f/aprendepython.pdf'})
        },
        closeModal (){
            this.$modal.hide('modal-confirmFinale')
            this.$modal.hide('modal-cancelBroker')
        },
        async desactivePropertyBroker(){
            try {
                let params = {
                    status: 4,//solicitud de baja
                    reason_down: this.idProperty[0].cause,
                    property_id: this.idProperty[0].property_id,
                    typeDisabled: 'Broker'
                }
                const response = await axiosTrocutServer.post('/disabledProperty',params)
                if( response.status === 200 ){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;&nbsp;se dio deshabilito la propiedad .`,
                        type: 'success'
                    })
                    this.$router.go(0)
                    this.closeModal() 
                }
            } catch (error) {
                console.error(error);
            }
        } 
    }
}