import Datepicker from 'vuejs-datepicker'
import es from 'vuejs-datepicker/dist/locale'
import Multiselect from 'vue-multiselect'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { minValue,required } from 'vuelidate/lib/validators'

export default {

  mixins:[validationMixin],
    components:{
        Datepicker,
        Multiselect
    },
    props:{
        idProperty:0,
        tourID:0
    },
    data () {
        return{
            es:es,
            state:Date,
            hora:0,
            petActive: true,
            mediumPet: false,
            largePet : false,
            condition :'',
            acepetUpdate: false,
            model_minimal_price: false,
            model_init_contrat: false,
            model_months_of_deposit: false,
            model_contract_term: false,
            editable: 0,
            submitted:false,
            pricePropertyVar:0,
            model_pets: false,
            arraCondition:[{
                minimal_price:0,
                init_contrat:'',
                months_of_deposit:0,
                contract_term:0,
                pets:'',
                others:[],
                priceProperty:0

            }],
            dateInit:'',
        }
    },
    validations () {
        return {
            arraCondition:{
                //minimal_price: { minValue: minValue(7500) }
                minimal_price: { required, minValue: minValue(`${this.arraCondition.priceProperty}`) }
            }
        }
    },
    computed:{
       
    },
    watch:{
        diasablePets (item){
            if(!item){
                this.petActive= false
                this.mediumPet= false
                this.largePet = false
            }
        }
    },
    methods: {
        nameResponse ({ name }) {
            return `${name}`
          },
        closeModal (){
            this.$modal.hide('modal-condiciones')
            this.$modal.hide('modal-NewCondiciones')
            this.$modal.hide('modal-Congratulactions')
        },
        citaDate (){
            console.log('fecha de inicio',this.state.date,'hora',this.hora);
        },
        disabledCondition(...arg){
            console.log('arg',arg)
        },
        selectedPet (type) {
            if( type === 'little'){
                this.petActive= true
                this.mediumPet= false
                this.largePet = false
                this.arraCondition.pets = 'little' 
            }
            if( type === 'medium'){
                this.petActive= false
                this.mediumPet= true
                this.largePet = false
                this.arraCondition.pets = 'medium' 
            } if( type  === 'large') {
                this.petActive= false
                this.mediumPet= false
                this.largePet = true
                this.arraCondition.pets = 'large' 
            }
        },
        showUpdateConditions() {
            this.acepetUpdate = true
        },
        showCongratulations(){
            this.$modal.show('modal-Congratulactions')
        },

        async getConditions(){
            this.arraCondition =[]
            try {
                let params = {
                    property_id: this.idProperty,
                    tour_id: this.tourID
                }
                console.log(params);
                const response = await axiosTrocutServer.post('/getConditionalRent',params)
                if(response.status === 200){
                    let tempArr = []
                    tempArr.push(response.data.consdicionesRendicion[0])
                    tempArr.forEach(el => {
                        if(el.edit_contrat || el.edit_minimal_price || el.edit_month || el.edit_contrat_term || el.edit_others ||el.edit_pets){
                            this.editable++
                        }
                        console.log(this.editable);
                        this.arraCondition=el
                        console.log(this.arraCondition);
                        this.pricePropertyVar = el.priceProperty
                        this.arraCondition.init_contrat = moment(el.init_contrat).format('YYYY-MM-DD')
                        this.selectedPet(this.arraCondition.pets)
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        async otherConditionsSend (textDescription){
            try {
                let payload ={
                    textCondition: this.arraCondition.others[0].textCondition ,
                    value: textDescription,
                    type:'Broker' ,
                    property_id: this.idProperty,
                    tour_id: this.tourID
                }
                const response = await axiosTrocutServer.post('/addOtherConditions',payload)
                if(response.status === 200){
                    this.closeModal()
                }
                this.$modal.show('modal-Congratulactions')
            } catch (error) {
               console.error('error',error); 
            }
        },
        showAlers(){
            alert('message to the bears')
        },
       async modalConfirm (){
            try {
                this.submitted = true
                this.$v.arraCondition.$touch()
                if(!this.$v.arraCondition.$invalid){
                    let payload = {
                        property_id: this.idProperty,
                        minimal_price: this.arraCondition.minimal_price ,
                        init_contrat: moment(this.arraCondition.init_contrat).format('YYYY-MM-DD')  ,
                        months_of_deposit: this.arraCondition.months_of_deposit ,
                        contract_term: this.arraCondition.contract_term ,
                        pets: this.arraCondition.pets !== null ?  this.arraCondition.pets : ' ',
                        edit_pets: this.arraCondition.edit_pets !== true ? 0 : 1,
                        tour_id:this.tourID
                    }
                    //console.log('payload',payload)
                    const response = await axiosTrocutServer.post('/updatePropertyConditions',payload)
                    if(response.status === 200){
                        if(this.arraCondition.others[0] !== undefined ){
                            this.otherConditionsSend(this.arraCondition.others[0].value)
                        }
                        this.closeModal()
                    } 
                }else {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; el precio de renta no debe de ser menor a ${this.arraCondition.priceProperty}`,
                        type: 'error'
                      })
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    async mounted () {
        await this.getConditions()
        this.dateInit = moment().format('YYYY-MM-DD')
    }
}


