import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators"
import Multiselect from "vue-multiselect"
import {  validationMixin } from 'vuelidate' 
import axiosTrocutServer from '@/axios/axiosTrocutServer'

export default {
    mixins: [ validationMixin ],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Multiselect
    }, 
    name: "app",
    data () {
        return {
          currentRolComplete:[],
            alertB: false,
            types:[{value:0,name:'Deparatamento'},{value:1,name:'Casa'}],
            roomsC:[{value:1,name:'1 habitacion'},{value:2,name:'2 habitaciones'},{value:3,name:'3 habitaciones'},{value:4,name:'4 habitaciones'},{value:5,name:'5 habitaciones'}],
            alertModel:{
                email:'',
                inmuebleType:'',
                city:'',
                room: '',
                pets:false,
                minRange:7500,
                maxRange:80000
            },
            submitted:false,
            cities:[]
        }
    },
    validations () {
        return {
            alertModel:{
                inmuebleType: { required },
                city: { required },
                room: { required },
                pets: { required },
                minRange:{ required },
                maxRange:{ required }
            }
        }
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            auth:'getAuth',
            user:'getUser',
            getCurrentRol:'getCurrentRol',
            getStoreUser:'getStoreUser',
        }),
        isAuth() {
            this.alertB = false
        }
    },
    methods: {
        alertButton (){
            this.alertB =! this.alertB
        },
        nameBulding ({ name }) {
            return `${name}`
        },
        nameCity ({ city }) {
            return `${city}`
        },
        valueRooms ({ name }) {
            return `${name}`
        },
        clickClose (){
            this.alertB = false
        },
        async AlertProperty (usuario){
            try {
                let params = {
                    notification: `Hola ${usuario}, hemos encontrado propiedades relacionadas con tu alerta de
                    acuerdo a los criterios específicos que nos proporcionaste. Esperamos que se
                    adapten a tu necesidad para que muy pronto puedas rentar con beneficios a
                    través de la plataforma  trocut`,
                    importantId: 1,
                    user_rol_id: this.currentRolComplete.user_rol_id,
                    notification_type: "Tenant",
                    rental_process_id: 0,
                    idTemplate: 7,
                    userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : this.user.user.email
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    console.log('se lanzo la notificacion');
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;Se ha creado la alerta. Te mantendremos informado(a) respecto a tu solicitud.`,
                        type: 'success'
                    })
                }
            } catch (error) {
                console.error(error);
            }  
              
        },
        async sendAlert (){
            this.submitted = true
            this.$v.alertModel.$touch()
            if (this.$v.alertModel.$invalid) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;&nbsp;Por favor verifique que ingreso los datos para crear la alerta.`,
                    type: 'error'
                }) 
            } else {
                const params = {
                    email: this.auth !== true ?  this.alertModel.email : this.user.user.email,
                    type: this.alertModel.inmuebleType.name,
                    city: this.alertModel.city.city,
                    minPrice: this.alertModel.minRange < 7500 ? 7500 : this.alertModel.minRange,
                    maxPrice: this.alertModel.maxRange,
                    rooms: this.alertModel.room.value,
                    pets: this.alertModel.pets ? 1 : 0,
                    is_auth: this.auth !== true ? 0 : 1
                }
                const response = await axiosTrocutServer.post('/addNotificationSchedule', params)
               if(response.status === 200){
                    this.alertModel.email = '',
                    this.alertModel.inmuebleType.name = '',
                    this.alertModel.city.city = '',
                    this.alertModel.minRange = 7500,
                    this.alertModel.maxRange = 80000,
                    this.alertModel.room = '',
                    this.alertModel.pets =  0
                    
                    this.AlertProperty(this.auth !== true ? this.alertModel.email : this.user.user.email)
                    this.clickClose()
                }
            }
        },
        async getCities(){
            try {
                let params = {
                    town_id: 14
                }
                const response = await axiosTrocutServer.post('/getCities',params)
                if(response.status === 200){
                    response.data.cities.forEach(el => {
                        this.cities.push({value:el.city_id,city:el.city})
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {
        setTimeout(() => {
            const store = this.getStoreUser.roles
            if(store !== undefined){
                this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
            }
        }, 3000)
        this.getCities()
    }
}
////documentArray: [3,13,2,11,12,4,14,3,13,15,7,4,7], tenant Modal
