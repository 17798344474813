export default {
    components: {
    },
    name: "highlighted-areas",
    data () {
        return {
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
    }
}
