//import timeLine from "../../generals/time-line"
import ownerProcess from "@/components/owner/modalRentOwner";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
    components:{
        ownerProcess,
        Multiselect
    },
    props:{
        propertyInfo: []
    },
    data () {
        return{
            currentRolComplete:[],
            reasonDelete:[{value:'La rente por otra vía'},{value:'motivos personales'}],
            reasonValue:''
        }
    },
    computed:{
        ...mapGetters({
            user:'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        }),
    },
    methods: {
        goToDownloadPDF () {
            this.$router.push({path:'aprendepython.es/_downloads/907b5202c1466977a8d6bd3a2641453f/aprendepython.pdf'})
        },
        nameWithValue({ value }) {
            return `${value}`},
        closeModal (){
            this.$modal.hide('modal-cancel')
        },
        async desactiveProperty(){
            try {
                let params = {
                    status:2,
                    reason_down: this.reasonValue.value,
                    property_id: this.propertyInfo.idproperty,
                    typeDisabled: 'Owner'
                }
                const response = await axiosTrocutServer.post('/disabledProperty',params)
                if(response.status === 200 ){
                    this.closeModal()
                    this.$emit('updateProperty')
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;&nbsp;se dio de baja la propiedad .`,
                        type: 'success'
                    })
                } 
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
    }
}