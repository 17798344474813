import register from '@/components/home/register'
import { mapGetters  } from 'vuex'


export default {
  components: {
    register
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
        getAuth: 'getAuth'
    })
  },
  methods: {
    register () {
      this.$modal.show('modal-register')
    }
  }
}
