import axiosTrocutServer from '@/axios/axiosTrocutServer'

const property = {
    properties: {
        address_id: 0,
        property_id: 0,
        keyProperty:''
    },
    mutations: {
        setInfo (properties,params){
            console.log('parametros',params,'propiedades',properties);
            properties.address_id = params.address_id
            properties.property_id = params.property_id,
            properties.keyProperty = params.key_property
        },
        clearInfo (properties) {
            properties.address_id = 0
            properties.property_id = 0,
            properties.keyProperty = ''
            console.log('se limpio',properties)
        }
    },
    actions:{
        async getPropertieInfo({commit},params){
            try {
                let payload = {
                    property_id: params,
                    status: 6
                }
                const response = await axiosTrocutServer.post('/getProperties',payload)
                if(response.status === 200){
                    const data = response.data.properties[0]
                    commit('setInfo',data)
                }
            } catch (error) {
                console.error('error en :',error)
            }
        },
        setIdAddress({commit},params){
            commit('setInfo',params)
        },
        clearAddress({commit}){
            commit('clearInfo')
        }
    },
    getters:{
        getIdAddress(properties){
            return properties
        }
    }
}
export default property