import cardCarousel from '@/components/generals/cards-properties'
import GoogleMapLoader from '@/components/generals/maps/GoogleMapLoader.vue'
import Marcadores from '@/components/generals/maps/markets.js'
import InfiniteLoading from 'vue-infinite-loading'
import  searchHeader  from "@/components/generals/search";
import  alertaBusqueda from "@/components/generals/alertGeneral";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from "vuex"
import  bestProperties  from "@/components/home/best-properties"

export default {
  components: {
    cardCarousel,
    GoogleMapLoader,
    Marcadores,
    searchHeader,
    InfiniteLoading,
    alertaBusqueda,
    bestProperties
  },
  name: "app",
  data () {
    return {
      infoData: null,
      loading: false,
      mapZoom: 11,
      pet:'',
      windowWidth: window.innerWidth, 
      // optionsPolygonNeight: {
      //   strokeColor: "#FF0000",
      //   strokeOpacity: 0.5,
      //   strokeWeight: 3,
      //   fillColor: "#fdcb67",
      //   fillOpacity: 0.35,
      // },
      // optionsPostalCode: {
      //   strokeColor: "#F60",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 3,
      //   fillColor: "#FF0000",
      //   fillOpacity: 0.35,
      // },
      busqueda: false,
      // paths: [],
      // list: [],
      markerArr: [],
      // pathColony: [],
      paginationArray:[],
      // hover: false,
      page: 0,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },
      center: {
        lat: 20.60101842434338,
        lng: -100.39535572445847
      },
      propertiesLoad:[],
      pamar:[],
      amenidad:[],
      renderComponent: true,
      currentRolComplete: []
    }
  },
  computed: {
    ...mapGetters({
      user:'getUser',
      storeSearch: 'getStoreSearch',
      getStoreUser:'getStoreUser',
      actionSearch: 'getSearchClick',
      getCurrentRol:'getCurrentRol'
    }),
    advanced () {
      this.pamar = this.storeSearch
      this.amenidad = this.pamar.amenities
      return  this.amenidad
    }
  },
  watch: {
    async storeSearch () {
      await this.loadFromDB()
    },
    async actionSearch () {
      console.log('algo cambio', this.actionSearch)
      await this.loadFromDB()
    },
    loadResolution(){
      this.onResize
    }
  }, 
  methods: { 
    onResize() {
      this.windowWidth = window.innerWidth
    },
    async loadFromDB() {
      try {
        this.propertiesLoad = []
        let params2 = this.storeSearch 
        const paramsSearch = {
          params:{
            minimumPrice: 7500,
            maximumPrice: 80000
          }
        }
        const response = await axiosTrocutServer.get('/getPropertyShowCase', paramsSearch)
        response.data.propertyShowCase.forEach(property => {
          console.log(property.location.geographical);
          this.propertiesLoad.push({
            id: String(property.id) ,
            room: property.details[0].room,
            bath: property.details[0].bathroom,
            pets: property.details[0].pets.allowed,
            parkinlots: property.details[0].garage,
            rentCost:Intl.NumberFormat('en-EN', { minimumFractionDigits: 0 }).format(property.price.rental),
            manteniment:Intl.NumberFormat('en-EN', { minimumFractionDigits: 0 }).format(property.price.maintenance), 
            ubication: property.title,
            description: property.location.address,
            cp: 75000,
            media: property.gallery,
            position: {
              lat: parseFloat(property.location.geographical.latitude),
              lng: parseFloat(property.location.geographical.longitude),
            },
            favorite:false
          })
        })
        this.loadMore()
      }  catch (error) {
        console.error(error);
      }
      //console.log('arrglo de datos',this.propertiesLoad);
    },
    loadMore () {
      this.paginationArray = []
      this.loading = true;
      setTimeout(() => {
        for (var i = 0; i < this.propertiesLoad.length; i++) {
          this.paginationArray.push(this.propertiesLoad[i])
        }
        this.loading = false;
      }, 200);
    },
    async updateCenterMap (args) {
      //console.log('argumentos',args);
      if (args !== undefined) {
        var metodo = args
        var temA = []
        temA.push(metodo)
        const definicionCUadro  =   Object.values(temA[0]);
        const esquinasSuperiores  =   Object.values(definicionCUadro[0]);
        const esquinasInferiores =   Object.values(definicionCUadro[1]);
        //las coordenadas 20.00000 son norte
        //por ende las coordenadas -100 son sur
        var northWest = esquinasSuperiores[0]
        var northEst = esquinasSuperiores[1]
        var  southWest = esquinasInferiores[0]
        var southEst = esquinasInferiores[1]
        this.paginationArray = []
        for(var y = 0; y< this.propertiesLoad.length; y++) {
          if(this.propertiesLoad[y].position.lat >= northWest) {
            if(this.propertiesLoad[y].position.lng >= southWest) {
              if(this.propertiesLoad[y].position.lat <= northEst) {
                if(this.propertiesLoad[y].position.lng <= southEst) {
                  this.paginationArray.push(this.propertiesLoad[y])
                  this.page = this.paginationArray.length
                }
              }
            }
          }
        }
      }
    },
    async clusterMarkers (event) {
      const markersIdList = []
      this.paginationArray = []
      const gmapMarkers = event.getMarkers()
      gmapMarkers.forEach((m) => markersIdList.push(m.getTitle()))
      for(var i = 0; i <= markersIdList.length; i++) {
        for(var k = 0; k < this.propertiesLoad.length; k++) {
          if (this.propertiesLoad[k].id === markersIdList[i]) {
            this.paginationArray.push(this.propertiesLoad[k])
            this.zoom=30
          }
        }
      }
      this.busqueda = true
    },
    // openMarker (args) {
    //   for (let i = 0; i < this.properties.length; i++) {
    //     const idMarker = this.properties[i].id
    //     if(args.id === idMarker){
    //       this.properties = [];
    //       this.properties.push(args)
    //       for(let x=0; x< this.properties.length;x++) {
    //         if(args.id === this.properties[x].id){
    //           this.paginationArray=[]
    //           this.paginationArray.push(this.properties[x])
    //         }else{
    //           continue
    //         }
    //       }
    //     }else{
    //       continue
    //     }
    //   }
    //   this.busqueda = true
    // },
    // getPagination () {
    //   let count=0;
    //   if ( (this.properties.length % 9) === 0 && this.properties.length >= 6) {
    //     count = this.properties.length / 6;
    //   } else {
    //     if((this.properties.length % 9) <= 5)
    //       count=(this.properties.length / 9) +1;
    //       count=Math.trunc(count);
    //   }
    //   return count;
    // },
    // async arrayTemp () {
    //     let internalcount=0;
    //     let temA = [] 
    //     this.properties.forEach(el => {
    //       if(internalcount <= 6) {  // 4
    //         temA.push(el)
    //         internalcount++;
    //         if (internalcount === 6) {
    //           this.paginationArray.push(temA);
    //           internalcount = 0;
    //           temA = []
    //         }
    //       }
    //    });
    //    if (temA.length > 0) {
    //     this.paginationArray.push(temA);
    //    }
    // },
    functionHover (arg) {
     for(var x = 0; x < this.propertiesLoad.length; x++){
        var idN = this.propertiesLoad[x].id
        var pos = this.propertiesLoad[x].position
        if(arg.id === idN && this.markerArr.length < 1){
          this.markerArr.push({
            id: idN,
            position:pos,
            animation:1
          })
        }
      }
      /*
       let coords=[]
      let coordsC=[]
       for(var x of mapLocalization){
        if(x.cp === arg.cp ){
          coords.push(x);
        }
      }
      for(var y of neighbor ){
        if( y.cp === arg.cp){
          coordsC.push(y);
        }
      } 
      for (var u of this.properties){
        console.log(this.properties.position[u].id);
      }
      if(arg.id === this.properties.position.id)
      
      this.pathColony=coordsC;
      this.paths=coords;
      console.log(arg); */
    },
    functionLeave () {
        this.temCoords=[];
        // this.paths=[];
        // this.pathColony=[];
        this.markerArr=[];
        // this.hover=false;
        // console.log('clear',this.hover);
    }
  },
  async mounted () {
    // console.log('Styore', store)
    // store.watch(() => {
    //   console.log('Cambio algo por aqui')
    // })
    this.loadFromDB()
    const listElm = document.querySelector('#infinite-list');
    listElm.addEventListener('scroll', () => {
      if(listElm.clientHeight === listElm.scrollHeight) {
        this.loadMore()
      }
    })
    setTimeout(() => {
      const store = this.getStoreUser.roles
      if(store !== undefined){
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
      }
    }, 3000)
  }
}