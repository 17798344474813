import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import moment from 'moment';
import { mapGetters } from 'vuex';
import shareM from '@/components/generals/share-modal'

import modalRent from '@/components/tenant/rentModal'
export default {
    components:{
        modalRent,
        Carousel,
        Slide,
        Pagination, 
        Navigation,
        moment,
        shareM
    },
    data () {
        return {
            agendados:[],
            idP:0,
            currentRolComplete:[]
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getRol:'getRol',
            getCurrentRol: 'getCurrentRol',
            getStoreUser:'getStoreUser'
        }),
    },
    methods: {
        closeModalS(){
            this.$modal.hide('modal-eliminacion')
        },
        showModal (id){
            this.idP = id
            this.$modal.show('modal-eliminacion')
        },
        async cancelTours () {
            try {
                let payload = {
                    tour_id: this.idP.tour_id
                }
                const response = await axiosTrocutServer.post('/cancelTour',payload)
                if(response.status === 200){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;se cancelo el tour de manera correcta.`,
                        type: 'success'
                    })
                    this.getTourSheduled()
                }
            } catch (error) {
                console.error('error',error)
            }
            this.closeModalS()
        },
        async getTourSheduled(){
            this.agendados = []
            try {
                let payload = {
                    tenant_id: 1,
                    status_id:2
                }
                const response = await axiosTrocutServer.post('/getTours',payload)
                console.log(response);
                if (response.status === 200) {
                    response.data.tours.forEach(el => {
                        this.agendados.push({
                            active: el.active,
                            broker_id: el.broker_id,
                            date:moment(el.date).format('YYYY-MM-DD'),
                            property: el.property,
                            property_id: el.property_id,
                            score: el.score,
                            status: el.status,
                            status_id: el.status_id,
                            tenant_id: el.tenant_id,
                            time:moment(el.date).format('LT'),
                            tour_id: el.tour_id,
                            virtual_tour: el.virtual_tour
                        })
                        this.idP = el.property_id
                    });
                }
             } catch (error) {
                console.error(error);
            }
            
        },
        shareProperty (){
            this.$modal.show('share-modal')
        }
    },
    mounted(){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.getTourSheduled()
    }
}