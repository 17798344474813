import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapActions, mapGetters } from "vuex"
import moment from "moment";
export default {
    components:{
    },
    data () {
        return{
            currentRolComplete:[],
            notificationA:[],
            propertyInfo:[],
            Finalizados:[],
            prospectos:[],
            investigacion:[],
            cierre:[]
        }
    },
    computed:{
        ...mapGetters({
            user:'getUser',
            getCurrentRol:'getCurrentRol',
            getStoreUser:'getStoreUser'
          }),
          nameFull () {
            let name = this.user.user_data === undefined ? this.user.user.email : this.user.user_data.fullName
            return name
        }
    },
    methods: {
        gotToCards () {
            this.$router.push({path:'owner/propertyes'})
        },
        gotToProspects () {
            this.$router.push({path:'/owner/clients'})
        },
        goToAcount () {
            this.$router.push({path:'/owner/profile'})
        },
        async getOwnerProperty () {
            try {
                let params = {
                    user_rol_id : this.currentRolComplete.user_rol_id,
                    type:'Propiedad'
                }
                const response = await axiosTrocutServer.post('/getProperties',params) 
                if(response.status === 200 ){
                    response.data.properties.forEach(el => {
                        this.propertyInfo.push({
                            id:el.property_id ,
                            idBuilding:el.property_id,
                            nameOwner:el.propietario,
                            brokersName:el.Broker,
                            contactP:el.phone,
                            key:el.key_property
                        })
                    });
                    this.lengthArr = response.data.length
                } else{
                    console.error('error');
                }
            } catch (error) {
                console.error(error);
            }
        },
        goToPropertyProfile (id) {
            this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})
        },
        async getProspectos(){
            try {
                let params = {
                    user_rol_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getAdditionalOwnerInfo',params)
                if(response.status === 200){
                    response.data.message.toursfinalizados.forEach(el => {
                        this.Finalizados.push(el)
                    }); 
                    response.data.message.prospectos.forEach(el => {
                        this.prospectos.push(el)
                    });
                    response.data.message.cierre.forEach(el => {
                        this.cierre.push(el)
                    });
                    response.data.message.investigacion.forEach(el => {
                        this.investigacion.push(el)
                        console.log(el);
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getNotifations (){
            this.notificationA= []
            try {
              let payload = {
                user_rol_id: this.currentRolComplete.user_rol_id
              }
              const response = await axiosTrocutServer.post('/getNotifications',payload)
              if( response.status === 200 ){
                response.data.response.forEach(el => {
                    if(el.notification_type !== 'Sistema' ){
                        this.notificationA.push({
                          Date :moment(el.create_at).format('YYYY-MM-DD'),
                          origin:el.notification_type,
                          message:el.notification
                        })
                        return
                    }
                })
                console.log('notificaciones',this.notificationA);
              }
            } catch (error) {
              console.error(error);
            }
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
        this.getOwnerProperty()
        this.getProspectos()
        this.getNotifations()
    }
}


