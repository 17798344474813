import { mapGetters } from 'vuex'

export default {
    components:{

    },
    computed: {
        ...mapGetters({
          user: 'getUser'
        })
      },
    data () {
        return {

        }
    }
}