import Advice from "./../../../components/home/advice";
import BestProperties from "./../../../components/home/best-properties";
import Testimonials from "./../../../components/home/testimonials";
import Tutorial from "./../../../components/home/tutorial";
import HomeHead from "../../../components/home/home-header";
import HighlightedAreas from "../../../components/home/highlighted-areas";
import Broker from "../../../components/home/broker";
import Owner from "../../../components/home/owner";

export default {
  components: {
    Advice,
    BestProperties,
    Testimonials,
    Tutorial,
    HomeHead,
    HighlightedAreas,
    Broker,
    Owner
  },
  data () {
    return{
      testimonialInfo: [
        {
          testimonialName:"Lucia Olvera",
          testimonialType:'Inquilino',
          testimonialDescription:'Todas las plataformas inmobiliarias ofrecen prácticamente lo mismo. La diferencia con Trócut es que a través de ellos obtuve gratis un beneficio por el que en otros lados habría tenido que pagar',
          testimonialUrl:"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
        },
        {
          testimonialName:"Lucia Olvera",
          testimonialType:'Broker',
          testimonialDescription:'Me registré, me eligieron como broker en la misma zona donde vivo, y a los pocos días ya estaba mostrando la casa de enfrente. Se cerró la operación de renta y me gané una comisión que me vino de maravilla',
          testimonialUrl:"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
        },
        {
          testimonialName:"Salinas de Gortari",
          testimonialType:'Propietario',
          testimonialDescription:'Además de asegurarme mi inmueble, lo mejor de todo es que no tuve que negociar quien pagara la póliza. Trócut me la dio sin costo, a diferencia de años anteriores en los que tenía que pagar una parte de ella con mi bolsillo',
          testimonialUrl:"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
        },
        {
          testimonialName:"Donaldo Colocio",
          testimonialType:'Inquilino / broker',
          testimonialDescription:'Renté el departamento donde vivo a través de Trócut, y buscando obtener un ingreso adicional me registré también como broker. Me asignaron uno en el mismo edificio, lo mostré, se rentó y yo me gané una buena comisión, prácticamente sin salir de casa',
          testimonialUrl:"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
        }
      ]
      
    }
  }

}
