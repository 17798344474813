import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import { mapActions, mapGetters } from "vuex"
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import shareM from '@/components/generals/share-modal'

export default {
    props:{
        properties: Object
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        shareM
    },
    name: "app",
    data () {
        return {
            currentRolComplete:[],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                700: {
                  itemsToShow: 1,
                  snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                }
            },
            numberP: 0,
            color:0,
            idCator:0
        }
    },
    watch: {
    },
    computed: {
        prices(){
            this.properties.rentCost,
            this.properties.manteniment
        },
        ...mapGetters({
            auth:'getAuth',
            user:'getUser',
            getRol:'getRol',
            getStoreUser:'getStoreUser',
            getCurrentRol: 'getCurrentRol'
        })
    },
    methods: {
        ...mapActions({
            setGeneralDataSearch: 'setGeneralDataSearch'
        }),
        goToProperty (id) {
            this.setGeneralDataSearch({
                property_id: id
            })
            /* this.$router.push({name:'propertyProfile', params: {property_id: id}}).catch((error) => {
                console.error(error);
            }) */
            //alert('enviar')
        },
        cambioDIvisas(precio){
            return new Intl.NumberFormat('en-MX',{style:'currency',currency:'INR',minimumFractionDigits:0}).format(precio)
        },
        async addFavorites (row){
            console.log(row);
            this.$refs['heart' + row].classList.add('favoriteProperty')
            let payload = {
                tenant_id: this.currentRolComplete.user_rol_id,
                property_id: parseInt(row)
            }
            const response = await axiosTrocutServer.post('/addFavoriteProperty', payload)
            if (response.status === 200) {
                this.$emit('removeEvent')
                if (response.data.insertId > 0) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Se registro a favoritos la propiedad.`,
                        type: 'success'
                    })
                } 
                if (response.data.error) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; ${response.data.msg}.`,
                        type: 'error'
                    })
                }
            }
        },
        shareProperty(id){
            this.$modal.show('share-modal')
        },
        closeModalS(){
            this.$modal.hide('share-modal')
            alert('tarjetas de propiedad')
        },
        async removeFavorites (row){
            this.$refs['heart' + row].classList.remove('favoriteImageColor')
            let payload = {
                tenant_id:  this.currentRolComplete.user_rol_id,
                property_id: parseInt(row)
            } 
            const response = await axiosTrocutServer.post('/removeFavoriteProperty', payload)
            if (response.status === 200) {
                this.$emit('removeEvent')
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; Se elimino la propiedad de tus favoritos.`,
                    type: 'success'
                }) 
            }
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        if(store !== undefined){
            this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        }
    }
}
