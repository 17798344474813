export default {
    components: {
    },
    name: "app",
    props:['length'],
    data () {
        return {
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
    }
}
