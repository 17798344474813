import axiosTrocutServer from '@/axios/axiosTrocutServer'
import {FormWizard, TabContent} from 'vue-form-wizard'
import { validationMixin } from 'vuelidate'
import {
    required
    // ,
    // email,
    // url,
    // minLength,
    // helpers,
    // between
} from "vuelidate/lib/validators"
import moment from "moment"
import Multiselect from "vue-multiselect"
import countries from '@/mixins/countries.json'
import laboralSep from "@/components/tenant/laboralStep"
import { mapActions, mapGetters } from "vuex"

export default {
  props: {
    idPropertyRent: []
  },
  mixins: [validationMixin],
  components: {
    FormWizard,
    TabContent,
    Multiselect,
    laboralSep
  },
  computed: {
    titleActive () {
      const val = this.currentIndex
      console.log('******###¨#¨#¨#¨¨¨****', val)
      if (val === 0) {
        return 'Información personal base'
      }
      if (val === 1) {
        return 'Información laboral'
      }
    },
    ...mapGetters({
      user:'getUser',
      getStore: 'getStore'
      // getRol:'getRol',
      // getCurrentRol: 'getCurrentRol',
      // getStoreUser:'getStoreUser',
      // getStore:'getStore'
    })
  },
  data () {
    return {
      validateSteep: 0,
      currentIndex: 0,
      collapse1: false,
      collapse2: false,
      nationalityArray: countries,
      statusMigartory: ['Temporal', 'Permanente'],
      regimenArray: [
        {
          value: null,
          name: 'Seleccione Una Opcion'
        },
        {
          value: 1,
          name:'Bienes separados'
        },
        {
          value: 0,
          name: 'Bienes mancomunados'
        },
      ],
      infoTenant: {
        tenantPostalCode: '',
        tenantInDoorNumber: 0,
        tenantOutDoorNumber: 0,
        tenantStreet: '',
        tenantNeighboorhood: '',
        tenantCity: '',
        tenantMunicipio: '',
        tenantState: ''
      },
      basicData: {
        name: '',
        lastname: '',
        lastname2: '',
        rfc: '',
        birthday: '',
        is_foreign: true,
        nationality: 'México',
        immigration_status: 'Residente',
        marital_status: '',
        community_property: null
      },
      optionsCity: [],
      optionState: [],
      neighboorhood: [],
      townshipOptions: [],
      maritalStateOptions: [
        'Soltero(a)',
        'Casado',
        'Union Libre' ,
        'Divorciado(a)',
        'Viudo(a)'
      ],
      submitted: false,
      swtfactura: false,
      today: moment().format('YYYY-MM-DD')
    }
  },
  validations: {
    basicData: {
      name: {
        required
      },
      lastname: {
        required
      },
      lastname2: {},
      birthday: {
        required
      },
      marital_status: {
        required
      },
      community_property: {
        required
      }
    },
    infoTenant: {
      tenantPostalCode: {
        required
      },
      tenantState: {
        required
      },
      tenantMunicipio: {
        required
      },
      tenantCity: {
        required
      },
      tenantNeighboorhood: {
        required
      },
      tenantStreet: {
        required
      },
      tenantOutDoorNumber: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      getUserDataStore: 'getUserDataStore'
    }),
    validateState (name, type) {
      this.submitted = true
      if (type === 'basic') {
        const { $dirty, $error } = this.$v.basicData[name]
        return $dirty ? !$error : null
      } else {
        const { $dirty, $error } = this.$v.infoTenant[name]
        return $dirty ? !$error : null
      }
    },
    validateStep2 () {
      console.log('Deberian de validar el steep 2')
      this.validateSteep++
      return false
    },
    closeModal () {
      this.$modal.hide('directRent')
    },
    async getStates () {
      try {
        const response = await axiosTrocutServer.post('/getStates')
        if (response.status === 200) {
          response.data.states.forEach(el => {
            this.optionState.push({
              name: el.state,
              value: el.state_id
            })
          })
        }
      } catch (error) {
        console.error('/getStates', error)
      }
    },
    async getTownhips (...arg) {
      try {
        this.optionstownship= []
        const params = {
          state_id : arg[0].value
        }
        const response = await axiosTrocutServer.post('/getTownship', params)
          if(response.status === 200) {
            response.data.township.forEach( el  =>{
              this.townshipOptions.push({
                name: el.township,
                value: el.township_id
              })
            })
          }
      } catch (error) {
        console.error('/getTownship', error)
      }
    },
    async getCities (...arg) {
      try {
        this.optionsCity = []
        let params = {
          town_id: arg[0].value
        }
        const response = await axiosTrocutServer.post('/getCities',params)
        if(response.status === 200) {
          response.data.cities.forEach( el  =>{
            this.optionsCity.push({
              name: el.city,
              value: el.city_id
            })
          })
        } else {
          console.error('error')
        }
      } catch (error) {
        console.error('/getCities', error)
      }
    },
    async getNeighboorhood (...arg) {
      try {
        this.neighboorhood = []
        let params = {
          city_id: arg[0].value
        }
        const response = await axiosTrocutServer.post('/getNeighborhoods',params)
        if (response.status === 200) {
          response.data.neighborhood.forEach( el  =>{
            this.neighboorhood.push({
              name: el.neighborhood,
              value:el.neighborhood_id
            })
          })
        }
      } catch (error) {
        console.error('/getNeighborhoods', error)
      }
    },
    sleep(milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
       if ((new Date().getTime() - start) > milliseconds) {
        break;
       }
      }
    },
    async validatePersonalInfo () {
      try {
        let valid = true
        if (this.basicData.community_property === null) {
          if (this.basicData.marital_status !== 'Casado') {
            this.basicData.community_property = {
              value: 1,
              name:'Bienes separados'
            }
          }
        }
        this.$v.basicData.$touch()
        this.$v.infoTenant.$touch()
        this.collapse1 = false
        this.collapse2 = false
        // this.sleep(2000)
        if (this.$v.basicData.$anyError) {
          this.collapse1 = true
          valid = false
        }
        if (this.$v.infoTenant.$anyError) {
          this.collapse2 = true
          valid = false
        }

        if (valid) {
          this.currentIndex++
          const x = await this.savePersonalInfoTenant()
          const y = await this.addAdress()
          if (x && y) {
            await this.updateCompleteBasicInfo(1, 1) // actualizar direcion completa y informacion base
          } else {
            if (!x) {
              await this.updateCompleteBasicInfo(1, 0) // actualizar informacion base completa, direccion incompleta
            } else if (!y) {
              await this.updateCompleteBasicInfo(0, 1) // actualizar dirección completa, inforamacion base incompleta
            }
          }
          await this.updateCurrentUserStep(0, 1)
          await this.getUserDataStore()
        }
        return valid
      } catch (error) {
        console.log('Error validar Informacion personal', error)
      }
    },
    async updateCurrentUserStep (additionalInfo, step) {
      try {
        let payload = {
          user_id: this.user.user.user_id,
          user_rol_id: this.user.roles[0].user_rol_id,
          complete_additional_info: additionalInfo,
          next_step: step,
          status_id: 5
        }
        const response = await axiosTrocutServer.post('/updateStepUserDataTenant',payload)
      } catch (error) {
        console.error(error);
      }
    },
    async savePersonalInfoTenant() {
      try {
        let payload = {
          user_id: this.user.user.user_id,
          name: this.basicData.name,
          lastname: this.basicData.lastname,
          lastname2: this.basicData.lastname2,
          marital_status: this.basicData.marital_status,
          birthday: moment (this.basicData.birthday).format('YYYY-MM-DD'),
          community_property: this.basicData.community_property.value,
          is_foreign: this.basicData.is_foreign ? 1 : 0,
          nationality: this.basicData.nationality !== undefined ? this.basicData.nationality : 'Mexico',
          immigration_status: this.basicData.immigration_status,
          rfc: this.basicData.rfc  !== null ? this.basicData.rfc : ''
        }
        const response = await axiosTrocutServer.post('/registerUserData',payload)
        if(response.status === 200) {
          this.$toastedPush({
            message: ' ' + response.data.msg,
            type: 'success'
          })
          return response
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addAdress () {
      try {
        let payload = {
          street: this.infoTenant.tenantStreet,
          suburb_id: this.infoTenant.tenantNeighboorhood.value,
          city_id: this.infoTenant.tenantCity.value,
          town_id: this.infoTenant.tenantMunicipio.value,
          state_id: this.infoTenant.tenantState.value,
          interior_number: parseInt(this.infoTenant.tenantInDoorNumber),
          outdoor_number: parseInt( this.infoTenant.tenantOutDoorNumber),
          user_rol_id: this.user.roles[0].user_rol_id,
          address_type: "User" ,
          postal_code: this.infoTenant.tenantPostalCode,
          new: true,
          user_id: this.user.user.user_id
        }
        let response = await axiosTrocutServer.post('/addAddress', payload)
        if (response.status === 200) {
          this.$toastedPush({
            message: ' ' + response.data.response,
            type: 'success'
          })
          return response
        } 
      } catch (error) {
          console.log('Error', error);
      }
    },
    async updateCompleteBasicInfo (infoBase, address) {
      try {
        let params = {
          complete_info_base: infoBase,
          complete_address: address,
          user_id: this.user.user.user_id
        }
        const response = await axiosTrocutServer.post('/updateInfoBase',params)
        if(response.status === 200){
          console.log('success');
        }
      } catch (error) {
        console.error('updateCompleteBasicInfo', error);
      }
    },
    async loadInfoBase () {
      // Informacion base
      this.basicData.name = this.user.user_data.name
      this.basicData.lastname = this.user.user_data.lastname
      this.basicData.lastname2 = this.user.user_data.lastname2
      this.basicData.birthday = moment(this.user.user_data.birthday).format('YYYY-MM-DD')
      this.basicData.marital_status = this.user.user_data.marital_status
      this.basicData.community_property = this.user.user_data.community_property
      this.swtfactura = this.user.user_data.rfc !== null ? true : false
      this.basicData.rfc = this.user.user_data.rfc 
      this.basicData.is_foreign = this.user.user_data.is_foreign,
      this.basicData.nationality = this.user.user_data.nationality,
      this.basicData.immigration_status = this.user.user_data.immigration_status
      // Carga de dirección 
      console.log('*****%*%*%*%*%*5*', this.user.user_data.address)
      this.infoTenant.tenantPostalCode = this.user.user_data.address.postal_code
      this.infoTenant.tenantInDoorNumber = this.user.user_data.address.interior_number
      this.infoTenant.tenantOutDoorNumber = this.user.user_data.address.outdoor_number
      this.infoTenant.tenantStreet = this.user.user_data.address.street
      const stateVal = this.optionState.find(el => el.value === this.user.user_data.address.state_id)
      this.infoTenant.tenantState = stateVal
      await this.getTownhips(stateVal)
      const townVal = this.townshipOptions.find(el => el.value === this.user.user_data.address.town_id)
      this.infoTenant.tenantMunicipio = townVal
      await this.getCities(townVal)
      const cityVal = this.optionsCity.find(el => el.value === this.user.user_data.address.city_id)
      this.infoTenant.tenantCity = cityVal
      await this.getNeighboorhood(cityVal)
      const suburbVal = this.neighboorhood.find(el => el.value === this.user.user_data.address.suburb_id)
      this.infoTenant.tenantNeighboorhood = suburbVal
    }
  },
  async mounted () {
    console.log('Esto es el userData', this.user)
    await this.getUserDataStore()
    await this.getStates()
    await this.loadInfoBase()
    for (const key in countries) {
      this.nationalityArray.push(countries[key].name)
    }
    this.$refs.collapse1.click()
  },
  created () {
    this.currentIndex = this.getStore.rol.next_step
  }
}