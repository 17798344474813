import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import Multiselect from 'vue-multiselect';
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from "vuex";
 

export default {
   
    components: {
        Carousel,
        Slide,
        Multiselect,
        Pagination,
        Navigation
    }, 
    name: "app",
    data () {
        return {
            
            pov: null,
            idUser: '',
            streetviewButton:false,
            alertB: false,
            loricum:5,
            center:{lat: 20.588986995667902,lng:-100.3918860309639},
            causaReporte:'',
            markers:[
                {
                    id:1,
                    pov:{heading: 34 ,pitch: 10 ,zoom: 1},
                    position:{
                        lat: 20.588986995667902, 
                        lng: -100.3918860309639 
                    },
                },
            ],
            options:{
                zoom:18,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: true,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                draggable: false
            },
            reporteBroker:[{name:'Me robo'},{name:'No pude contactarlo'},{name:'Me trato mal'},{name:'No gestiona mi propiedad'}],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                700: {
                  itemsToShow: 1,
                  snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                }
            },
            propertyArr:[]
        }
    },
    watch: {
    },
    computed: {
        isAuth() {
            this.alertB = false
        },
        
    },

    methods: {
        nameReporte({name}){
            return `${name}`
        },
        updatePov(pov) {
            this.pov = pov;
        },
        buttonStreetview (){
            this.streetviewButton = !this.streetviewButton;
        },
        alertButton (){
            this.alertB =! this.alertB
        },
        reportarBroker  (){
            this.$modal.show('modal-reportar')
        },
        closeModal(){
            this.$modal.hide('modal-reportar')
        },
        mensaje  (){
            this.$modal.show('modal-mensaje')
        },
        closeModalMensaje(){
            this.$modal.hide('modal-mensaje')
        },
        async getPorperties(){
            try {
                let params = {
                    user_ro_id : this.idUser
                }
                const response = await axiosTrocutServer.post('/getProperties',params) 
                if(response.status === 200 ){
                    this.propertyArr.push(response.data.properties)
                } else{
                    console.error('error');
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted(){
        this.idUser  = this.user
        this.getPorperties()
    }
}
