import axiosTrocutServer from '@/axios/axiosTrocutServer'
import {  validationMixin } from 'vuelidate' 
import { required,sameAs } from "vuelidate/lib/validators";
import passwordMeter from "vue-simple-password-meter"

export default {
  name: "app",
  mixins: [ validationMixin ],
  components:{
    passwordMeter
  },
  props:{
      email:''
    },
    data () {
      return {
        submitted:false, 
        typeVerification:'',
        security:'',
        credentials:{
          email:'',
          newPassword:'',
          confirmPassword:''
        }
      }
    },
    validations: {
      credentials: {
        newPassword: {required} ,
        confirmPassword: {required,sameAsPassword: sameAs('newPassword')} 
      }
    },
    watch: {
      
    },
    computed: {
      classObject() {
        return{
          riskPassword: this.security === 'risky' || this.security === 'guessable',
          mediumPassword: this.security === 'weak',
          safePassowrd: this.security === 'secure' || this.security === 'safe'
        }
      },
      isDisabled () {
        if(this.credentials.newPassword  !== ''){
          return this.$v.$invalid
          
        } else {
          this.typeVerification = ''
          return true
        }
      }
    },
    methods: {
      async resetPassword(){
        try {
          this.submitted=true
          if(this.newPassword === this.confirmPassword){
              this.$v.credentials.$touch()
            if(!this.$v.credentials.$invalid){
              let params = {
                email: this.credentials.email,
                password: this.credentials.newPassword
              }
              const response = await axiosTrocutServer.post('/resetPassword',params)
              if (response.status  === 200) {
                console.log('se reseteo el password')
                this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp;&nbsp; Estimado usuario la contraseña fue cambiada con exito`,
                  type: 'success'
                })
                this.$router.push({path:'/'})
              }
            }else {
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;&nbsp; No se ha podido cambiar la contraseña`,
                type: 'error'
              })
            }
          } else {
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp; Las constraseñas no coinciden`,
              type: 'error'
            })
          }
        } catch (error) {
          console.error(error);
        }
      },
      onScore({strength}){
        if(strength === 'risky'){
          this.typeVerification = 'Muy baja seguridad'
        }if(strength === 'guessable'){
          this.typeVerification = 'Baja seguridad'
        }if(strength === 'weak'){
          this.typeVerification = 'Media seguridad'
        }if(strength === 'safe'){
          this.typeVerification = 'Aceptable'
        }if(strength === 'secure'){
          this.typeVerification = 'Segura'
        }
        this.security = strength
      }
    },
    mounted ()  {
      this.credentials.email=this.$route.params.emailHash
      console.log('credenciales',this.credentials.email);
    }
}
    