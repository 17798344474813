import { FormWizard,TabContent } from 'vue-form-wizard'
import steProgress from '@/components/generals/step-progress'
import closeRent from '@/components/owner/modalCloseProceso'
import Datepicker from 'vuejs-datepicker'
import {es} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import { mapGetters,mapActions } from 'vuex'
import axiosTrocutServer from '@/axios/axiosTrocutServer'

export default {
    props:{
        propertyInfoRent:[],
        esto:0
    },
    components:{
        steProgress,
        FormWizard,
        TabContent,
        closeRent,
        Datepicker,
        moment
    },
    data () {
        return{
          currentRolComplete:[],
            es:es,
            agenda:false,
            keyG:false,
            Solicitados:[],
            Finalizados:[],
            prospectos:[],
            investigacion:[],
            cierre:[],
            seletedTour:[],
            dia:'',
            horario:'',
            fechas:'',
            plasma:[],
            idBroker:0,
            state:{
                date: new Date
            },
            hora:0,
            postProperty:[{fecha:'15-02-2022',hora:'14:54:11'}],
            candidatos:[
            ],
            selectionPeople:[],
            successInfo:[
                {nameTourist:'pedro alvarado',dateTour:'20-15-2021',statusInvestigation:'Aprobado'},
                {nameTourist:'Alvaro Torres',dateTour:'20-15-2021',statusInvestigation:'Rechazado'}
            ], 
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        })
    },
    methods: {
        async getProspectos(){
            try {
                this.Solicitados = []
                this.Finalizados =[]
                this.plasma=[]
                this.prospectos = []
                this.investigacion = []
                this.cierre = []
                let params = {
                    broker_id : this.currentRolComplete.user_rol_id,
                    property_id : this.propertyInfoRent.id_property
                }
                const response = await axiosTrocutServer.post('/getAddititonalBrokerInfo',params)
                if(response.status === 200){
                    response.data.solicitados.forEach(el => {
                        this.Solicitados.push({
                            tenant:el.tenant,
                            date: moment(el.date).format('YYYY-MM-DD')
                        })
                    })
                    response.data.finalizados.forEach(el => {
                        this.Finalizados.push({
                            tenant:el.tenant,
                            date: moment(el.date).format('YYYY-MM-DD')
                        })
                    }); 
                    response.data.prospectos.forEach(el => {
                        this.prospectos.push({
                            tenant:el.Tenant,
                            date: moment(el.init_date).format('YYYY-MM-DD'),
                            time: moment(el.init_date).format('LT')
                        })
                    })
                    response.data.cierre.forEach(el => {
                        this.cierre.push({
                            tenant:el.Tenant,
                            status:el.status,
                            contratSchedule: el.shedule_contrat,
                            time: el.time
                        })
                    });
                    response.data.investigacion.forEach(el => {
                        this.investigacion.push({
                            tenant:el.Tenant,
                            status:el.status
                        })
                    })
                    console.log('prospectos',this.prospectos)
                    if( this.cierre[0].shedule_contrat !== null ||this.cierre[0].shedule_contrat !== ''  ){
                        this.fechas =moment(this.cierre[0].contratSchedule).format('YYYY-MM-DD')
                        this.dia = moment(this.cierre[0].shedule_contrat).locale('es').format('dddd')    
                        this.hora = this.cierre[0].time
                        this.agenda = true
                    }
                }

            } catch (error) {
                console.error(error);
            }
        },
        recibirllave () {
            this.keyG = true
        },
        gotToCards () {
            this.$router.push({path:'owner/propertyes'})
        },
        secondModal(){
            this.$modal.show('modal-closeProcess')
        },closeModal (){
            this.$modal.hide('modal-processRent')
        },
       async citaDate (arg){
            var fecha = moment(this.state.date).format('YYYY-MM-DD')
            try {
                let params = {
                    date: fecha,
                    time: this.hora,
                    rentProcess: arg.procesRent[0].process_id
                }
                this.fechas = fecha
                this.dia = moment(fecha).locale('es').format('dddd')
                const response = await axiosTrocutServer.post('/sigcontrat',params)
                if(response.status === 200){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Se agendo de manera correcta la firma del contrato`,
                        type: 'success'
                    })
                    this.agenda = true
                }
            } catch (error) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; favor de agregar una hora para la firma del contrato`,
                    type: 'error'
                })
            }
        },
        Reagendar () {
            this.agenda =false
        },
        async createNotification (){
            try {
                let params = {
                    notification: `Hola  ${this.user.user_data.fullName} has agendado exitosamente la firma de contrato y entrega de la propiedad  ${this.propertyInfoRent.key}.A continuación te indicamos los datos para la cita:
                    
                    Te recordamos que es obligatoria tu asistencia el día de la firma y entrega. En caso de no poder asistir, no podrás enviar a nadie a nombre tuyo, y deberás comunicarlo al prospecto y a Trócut con suficiente tiempo de anticipación, asegurándote de reagendar lo antes posible.
                    El equipo Trócut`,
                    importantId: 1,
                    user_rol_id: this.currentRolComplete.user_rol_id,
                    notification_type: "Broker",
                    rental_process_id: 0,
                    idTemplate: 7,
                    userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : this.user.user.email,
                    roleId: 4
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    console.log('war thunder');
                }
            } catch (error) {
                console.error(error);
            }  
          },
        nextSave(prop) {
            let indexAddRegister = prop.activeTabIndex
            switch (indexAddRegister) {
                case 0:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 1:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 2:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 3:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 4:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 5:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 6:
                    this.$refs.wizzardProcesoCierreRenta.nextTab()
                break;
                case 7:
                    this.createNotification()
                    this.$modal.hide('modal-processRent')
                break;
            }
        }

    },
    mounted () {
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.getProspectos()
    }
}


