const searchData = {
  search: {
    rooms: undefined,
    garage: 0,
    prices: [],
    city: 0,
    suburb: 0,
    township: 0,
    amenities: [],
    bathrooms: undefined,    
    area: [0,0],
    direccionCompleta:'',
    property_id:0,
    pets:0,
    btnclick: 1,
    type : null,
    tenant_id: undefined,
    key_delivery:1
  },
  mutations: {
    storeSearchGenral (search, params) {
      search.rooms = params.rooms
      search.prices = params.prices
      search.township = params.township
      search.amenities = params.amenities
      search.area = params.area
      search.bathrooms = params.bathrooms
      search.prices = params.prices
      search.property_id = params.property_id
      search.direccionCompleta = params.direccionCompleta
      search.pets = params.pets
      search.btnclick = search.btnclick + 1
      search.type = params.type
      search.tenant_id = params.tenant_id
      search.key_delivery= 1
    }
  },
  actions: {
    setGeneralDataSearch ({ commit }, params) {
      commit('storeSearchGenral', params)
    }
  },
  getters: {
    getStoreSearch (search) {
      return search
    },
    getSearchClick (search) {
      return search.btnclick
    }
  }
}

export default searchData
