import Advice from "./../../../components/home/advice";
import BestProperties from "./../../../components/home/best-properties";
import Testimonials from "./../../../components/home/testimonials";
import Tutorial from "./../../../components/home/tutorial";
import HomeHead from "../../../components/home/home-header";
import HighlightedAreas from "../../../components/home/highlighted-areas";
import Broker from "../../../components/home/broker";
import Owner from "../../../components/home/owner";

export default {
  components: {
    Advice,
    BestProperties,
    Testimonials,
    Tutorial,
    HomeHead,
    HighlightedAreas,
    Broker,
    Owner
  },
  data () {
    return{
      testimonialInfo: {
        testimonialName:"Lucia Olvera",
        testimonialDescription:"Es la primeravez que uso la aplicacion y me lleve una muy buena experiencia y sobre todo bastante confiable.",
        testimoniaImg:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBwgu1A5zgPSvfE83nurkuzNEoXs9DMNr8Ww&usqp=CAU",
        testimonialType: "Propietario"
      }
      
    }
  }

}
