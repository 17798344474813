import ownerClient from '@/components/owner/ownerClients'

export default {
  components: {
    ownerClient   
  },
  data(){
    return{
     
    }
  }
};
