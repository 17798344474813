import stepProgress from "@/components/generals/step-progress"
import {FormWizard, TabContent} from 'vue-form-wizard'
import { mapGetters,mapActions } from "vuex"
import axiosTrocutServer from '@/axios/axiosTrocutServer'

export default{
    components:{
        stepProgress,
        FormWizard,
        TabContent
    },
    props:{
        notificationsData:[]
    },
    data () {
        return{
            currentRolComplete:[]
        }
    },
    computed:{
        ...mapGetters({
            getRol:'getRol',
            getStoreUser:'getStoreUser',
            getCurrentRol:'getCurrentRol'
        })
    },
    methods: {
        ...mapActions({
            countNotifications: 'countNotifications'
        }),
       async notificationInfo(...arg){
            try {
                let payload = {
                    notification_id: arg[0].notification_id,
                    read_notification: 1
                }
                const result = await axiosTrocutServer.post('/readNotification',payload)
                if(result.status === 200){
                    setInterval(() => {
                     this.$emit('readNotification')
                    }, 15000)
                    this.countNotifications(this.currentRolComplete.user_rol_id) 
                } else {
                    console.error('exploto esto');
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
      this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    }
}


