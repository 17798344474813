 import path from 'path'
 import dotenv from 'dotenv'
import { env } from 'process'

 dotenv.config({path: path.join(__dirname, '.env.development')})
export default {
    trocut: {
        apiUrl: process.env.VUE_APP_AUTH_TROCUT
    },
    firebaseAuth: {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
    },
    googleMaps: {
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    },
    reCAPTCHA: {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
    auth: {
        apiUrl: process.env.VUE_APP_AUTH_TROCUT
    },
    stripe: {
        publicKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        acount: process.env.VUE_APP_STRIPE_ACCOUNT,
        version: process.env.VUE_APP_API_VERSION
    }
}
  
 
  
  
   
  
 