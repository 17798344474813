import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/modules/auth'
import searchData from '@/store/modules/search'
/* import filesArray from '@/store/modules/files' */
import alertsArray from '@/store/modules/alerts'/* 
import brokers from '@/store/modules/brokersOwner'*/
import infoProperties from '@/store/modules/properties' 

// import permissions from '@/store/modules/permissions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    searchData,
    alertsArray,
    infoProperties
    // permissions,
  },
  plugins: [
    createPersistedState({
      //storage: window.sessionStorage,
      storage: window.localStorage,
      paths: [
        'auth',
        'searchData.rooms',
        'searchData.garage',
        'searchData.prices', 
        'searchData.city',
        'searchData.suburb',
        'searchData.township',
        'searchData.amenities', 
        'searchData.bathrooms', 
        'searchData.area',
        'searchData.direccionCompleta',
        'searchData.btnclick',
        'alertsArray',
        'infoProperties'
      ]
    })
  ]
})
