

export default {
    props:{
        idProperty: 0
    },
    components: {

    },
    name: "app",
    data () {
        return {
            idCator:0
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
        shareProperty(id){
            this.idCator =  id
            setTimeout(()=>{
                this.idCator = 0
            },9000)
        },
        closeModalS(){
            this.$modal.hide('share-modal')
        }
        
    }
}
