/* import ownerP from '@/components/generals/userProfile'
import { mapGetters,mapActions } from 'vuex';

export default {
  components: {
    ownerP   
  },
  data(){
    return{
      userInfo:[{Nombre:'Elon Musk',email:'exaple@gmail.com',Tel:4432364657,Direccion:'Centro Historico de morelia', tipoU:'propietario'}]
    }
  },
  computed: {  
    ...mapGetters({
      user:'getUser'
    })
  },methods :{
    ...mapActions({
      alert: 'setAlerts'
    }),
    createAlert () {
      this.alert({
        type: 'alert alert-success ',  success, error, warning, info
          msg: 'estimado Usuario le pedimos que termine de cargar su información para poder disfrutar de los beneficios de la plataforma',
          icon: 'check',
          title: 'Bienvenido  a trócut Usuario:'
      })
    } ,
    removeAlert(arg) {
      delete this.alerts[arg] 
      const tempArr = []
      this.alerts.forEach(el => {
        tempArr.push(el)
      });
      this.setAlerts(tempArr)
    }
  },
  mounted () {
    this.createAlert()
    setTimeout(() => {
      this.removeAlert(0) 
    }, 3000)
  }
};
 */
import ownerP from '@/components/generals/userProfile'
import { mapGetters } from 'vuex'

export default {
  components: {
    ownerP
  },
  data (){
    return{
      currentRolComplete: [],
      userInfo:[{Nombre:'Elon Musk',email:'exaple@gmail.com',Tel:4432364657,Direccion:'Centro Historico de morelia', tipoU:'broker'}]
    }
  },computed: {  
    ...mapGetters({
      user:'getUser',
      getStoreUser:'getStoreUser',
      getCurrentRol:'getCurrentRol'
    })
  }, 
  methods :{
    currentRolCompleteStatus(){
      const store = this.getStoreUser.roles
      if(store !== undefined ){
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        return this.currentRolComplete.status
      }
    }
  },
 async mounted () {
    this.currentRolCompleteStatus()
  }
}
