//import timeLine from "../../generals/time-line"
import ownerProcess from "@/components/owner/modalRentOwner";
import final from '@/components/generals/alertFinal'
import Multiselect from "vue-multiselect";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from "vuex";

export default {
    components:{
        ownerProcess,
        final,
        Multiselect
    },
    props:{
        propertyInfo: [],
    },
    data () {
        return{
            report: false,
            causesCancel: [{cause:'Propietario no responde'}, {cause:'Propiedad rentada'}, {cause:'Otro'}],
            energy:[],
            causeModel:'',
          currentRolComplete:[]
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        })
    },
    methods: {
        causeReportLang ({ cause }) {
        return `${cause}`
    }, closeModal (){
            this.$modal.hide('modal-cancelBroker')
        },
        async showComfirmModal () {
            this.energy.push({
                property_id: this.propertyInfo[0].id_property,
                cause:this.causeModel.cause
            })
            this.$emit('property')
            this.$modal.show('modal-confirmFinale')
        },
        sleep(ms) {
            return new Promise((resolve) => {
              setTimeout(resolve(), ms);
            })
        }
    },mounted () {
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    }
}