import stepProgress from "@/components/generals/step-progress"
import {FormWizard, TabContent} from 'vue-form-wizard'
export default{
    components:{
        stepProgress,
        FormWizard,
        TabContent
    },
    data () {
        return{
          rmotiveRetunMoney:['Seleccione un motivo ','Encontré una mejor oferta','Problemas con el broker','Problemas con el propietario','otros motivos personales']
        }
    },
    computed:{
    },
    methods: {
        closeModal () {
            this.$modal.hide('cancel-modal')
        },
        showModalTabla (){
            this.$modal.show('modal-tabla')
        }
    }
}


