import addBulding from '@/components/owner/modalAddBulding'
import ownerProcess from "@/components/owner/modalRentOwner";
import cancel from "@/components/owner/cancelModal";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters,mapActions } from 'vuex'
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf'
import _ from 'lodash'


export default {
    components:{
        ownerProcess,
        addBulding,
        cancel,
        VueHtml2pdf
    },
    data () {
        return{
            propertyInfo:[],
            currentRolComplete:[],
           idUser: 0,
           idproperty:0,
           continueUp: undefined,
           sendArr:[],
           prospectos:[],
           propertyInfoNew:[]
        }
    },
    computed:{
        ...mapGetters({
            user:'getUser',
            getRol:'getRol', 
            storeSearch: 'getStoreSearch',
            getCurrentRol:'getCurrentRol',
            getStoreUser:'getStoreUser',
            actionSearch: 'getSearchClick'
        }),
    },
    methods: {
        goToDownloadPDF () {
            this.$router.push({path:'aprendepython.es/_downloads/907b5202c1466977a8d6bd3a2641453f/aprendepython.pdf'})
        },
        showModal (idP) {
            this.idproperty = idP
            this.$modal.show('modal-Owner')
        },
        showJediOrder (type){
            if(type === 'new'){
                this.continueUp = undefined
                this.$modal.show('modal-addBulding')
            } else {
                this.continueUp = undefined
                this.$modal.show('modal-addBulding')
            }
            
        },
        showModalCancel (arg) {
            this.idproperty = arg.idproperty
            this.sendArr = arg 
            this.$modal.show('modal-cancel')
        },
        goToPropertyProfile (id) {
           this.$router.resolve({name:'propertyProfile',params:{idProperty:id}}).catch(()=>{})
        },
        async getOwnerProperty () {
            try {
                this.propertyInfo = []
                let params = {
                    user_rol_id : this.currentRolComplete.user_rol_id,
                    auth: true
                }
                const response = await axiosTrocutServer.post('/getProperties',params)
                if(response.status === 200 ){
                    response.data.properties.forEach(el => {
                        this.propertyInfo.push({
                            id:el.user_rol_id,
                            idproperty:el.property_id,
                            idBuilding:el.key_property,
                            nameOwner:el.propietario,
                            brokersName: !_.isEmpty(el.broker_info) ? el.broker_info.fullName :   'sin broker asignado',
                            contactP:  !_.isEmpty(el.broker_info) ?  el.broker_info.phone : '' ,
                            completeProperty:el.complete,
                            statusID:el.status,
                            media:el.property_media,
                            procesRent:el.procesRent
                        })
                    })
                    //console.log(this.propertyInfo[0].procesRent !== undefined ? this.propertyInfo[0].procesRent : this.propertyInfo[0] )
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getProspectos(){
            try {
                let params = {
                    user_rol_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getAdditionalOwnerInfo',params)
                if(response.status === 200){
                    response.data.message.prospectos.forEach(el => {
                       this.prospectos.push({ 
                        key_property:el.key_property,
                        Broker: el.Broker,
                        Tenant: el.Tenant,
                        broker_id: el.broker_id,
                        comments_id: el.comments,
                        init_date: moment(el.init_date).format('YYYY-MM-DD'),
                        last_update: moment(el.last_update).format('YYYY-MM-DD'),
                        phone: el.phone,
                        process_status_id: el.status_process_id,
                        property_id: el.property_id,
                        status: el.status,
                        status_id: el.status_id,
                        status_process_id: el.status_process_id,
                        tenant_id: el.tenant_id})
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        generatePDF(...arg){
            this.propertyInfoNew = arg[0]
            console.log('por amor a jebus el testamento de codigo que esta escrito creo que indica falta de vida social');
            this.$refs.html2Pdf.generatePdf()
        },
        contac (arg) {
            window.open(`https://api.whatsapp.com/send?phone=52${arg}&text=!Hola%20Tengo%20Dudas%20acerca%20de%20TROKUT`)
        }
    },
    mounted () {
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.propertyInfo = []
        this.getOwnerProperty()
        this.getProspectos()

    }
}


