import { render, staticRenderFns } from "./template2.html?vue&type=template&id=68b34633&"
import script from "./controller2.js?vue&type=script&lang=js&"
export * from "./controller2.js?vue&type=script&lang=js&"
import style0 from "./styles.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports