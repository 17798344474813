import Vue from 'vue'
import App from './app'
import router from './router'

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import fontAwesomeIcons from '@/fontAwesomeIcons'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueCompositionAPI from '@vue/composition-api'
import * as VueGoogleMaps from 'vue2-google-maps'
import vmodal from 'vue-js-modal'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-js-modal/dist/styles.css'
import InfiniteLoading from 'vue-infinite-loading'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import Toasted from 'vue-toasted'
// configs
import config from '@/config'
import {fire} from '@/firebase'
import store from '@/store'
// Custom Plugins
import lodashPlugin from '@/plugins/LodashPlugin'
import toastedPlugin from '@/plugins/ToastedPlugin'
import fontAwesomePlugin from '@/plugins/FontAwesomePlugin'
import VueExpandableImage from 'vue-expandable-image'

/* import { StripePlugin } from '@vue-stripe/vue-stripe';
console.log(config.stripe)
const options = {
  pk: config.stripe.publicKey,
  stripeAccount: config.stripe.acount,
  apiVersion: config.stripe.version
}; 

Vue.use(StripePlugin, options);*/
library.add(
  fontAwesomeIcons
)

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(InfiniteLoading)
Vue.use(VueGoogleMaps,{
  load: {
  key: config.googleMaps.apiKey,
  libraries: 'places',
}
})
Vue.use(VueExpandableImage)
Vue.use(Toasted)
Vue.use(lodashPlugin)
Vue.use(toastedPlugin)
Vue.use(fontAwesomePlugin)
Vue.use(fire)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('GmapCluster', GmapCluster)
// Vue.use(router)
Vue.use(vmodal)
new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    let title = [
      'font-weight: bold',
      'font-size: 50px',
      'color: red',
      'text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px, 1px, 0px black, -1px -1px 0px black'
    ].join(';')
    let text = [
      'font-size: 30px',
      'color: gray'
    ].join(';')
    let trocut = [
      'font-size: 30px',
      'color: #e8455d'
    ].join(';')
    console.log('%c ¡Detente!', title)
    console.log('%c Esta función del navegador está pensada para desarrolladores. Si alguien te indicó que copiaras y pegaras algo aquí para habilitar una función de %c Trócut %c o para "hackear" la cuenta de alguien, se trata de un fraude. Si lo haces, esta persona podrá acceder a tu cuenta.', text, trocut, text)
  }
}).$mount('#app')
