import propertye from '@/components/owner/dashboard'

export default {
  components: {
    propertye
  },
  data () {
    return{
      
    }
  }
};
