import Nombreinquilino from "./../../../../components/tenant/tenantName";
import Tours from "./../../../../components/tenant/toursCards";
import Favorites from "./../../../../components/tenant/favorites";
import Rents from "./../../../../components/tenant/rents";
import searchBar from './../../../../components/generals/search'
import carusel from './../../../../components/tenant/carucelTenant' 
import agendados from '@/components/tenant/tourAgendados'
 import {mapGetters, mapActions}  from 'vuex' 

export default {
  components: {
    searchBar,
    Nombreinquilino,
    Tours,
    Favorites,
    Rents,
    carusel,
    agendados
  },
  data () {
    return{
     token : '',
     change:1000
    }
  },
  computed: {
    ...mapGetters({
    user:'getUser'
    })
},
methods :{
    ...mapActions({
      signIn: 'signIn',
      setCurrentRol:'setCurrentRol'
    }),
    loadRentalProcess (){
     this.change++
    }
  },
  mounted (){
    this.loadRentalProcess()
  }
}
