import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters,mapActions } from 'vuex'
import moment from 'moment'
import seeModal from '@/components/broker/modalCondiciones'
import VueHtml2pdf from 'vue-html2pdf'

export default {

    components:{
        seeModal,
        VueHtml2pdf
    },
    data () {
        return{
            Tours: [],
            Finalizados: [],
            prospectos: [],
            investigacion: [],
            cierre: [],
            sendProp: 0,
            sendTour: 0,
            arraCondition:  [{
                minimal_price: 0,
                init_contrat: '',
                months_of_deposit: 0,
                contract_term: 0,
                pets: '',
                edit_pets: 0,
                others: ''
            }],
            dateInit: '',
           propertyInfoNew: []

        }
    },computed:{
        ...mapGetters({
            user:'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        }),
    },
    methods: {
        ...mapActions({
            setGeneralDataSearch: 'setGeneralDataSearch'
        }),
        showModal(id,tour) {
            this.sendProp = id
            this.sendTour = tour
            this.getConditions()
            this.$modal.show('modal-Conditions')
        },
       async modalConfirm (){
            try {
                let payload = {
                    property_id: this.sendProp,
                    minimal_price: this.arraCondition.minimal_price ,
                    init_contrat: moment(this.arraCondition.init_contrat).format('YYYY-MM-DD')  ,
                    months_of_deposit: this.arraCondition.months_of_deposit ,
                    contract_term: this.arraCondition.contract_term ,
                    pets: this.arraCondition.pets !== null || this.arraCondition.pets !== ' ' ?  this.arraCondition.pets : ' ',
                    edit_pets:this.arraCondition.edit_pets !== true ?  0 : 1,
                    others:this.arraCondition.others.length > 0 ? this.arraCondition.others: ' ' ,
                    tour_id: this.sendTour
                }
                const response = await axiosTrocutServer.post('/updatePropertyConditions',payload)
                 if(response.status === 200){
                    console.log('estado',response);
                }
            } catch (error) {
                console.error(error);
            }
            this.$modal.show('modal-Congratulactions')
        },
        closeModal () {
            this.$modal.hide('modal-Conditions')
            this.$modal.hide('modal-Congratulactions')
        },
        async getProspectos(){
            try {
                let params = {
                    user_rol_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getAdditionalOwnerInfo',params)
                if(response.status === 200){
                    response.data.message.toursfinalizados.forEach(el => {
                        this.Finalizados.push(el)
                        this.Tours.push(el.comment)
                    })
                    response.data.message.prospectos.forEach(el => {
                       this.prospectos.push({ 
                        key_property:el.key_property,
                        Broker: el.Broker,
                        Tenant: el.Tenant,
                        broker_id: el.broker_id,
                        comments_id: el.comments,
                        init_date: moment(el.init_date).format('YYYY-MM-DD'),
                        last_update: moment(el.last_update).format('YYYY-MM-DD'),
                        phone: el.phone,
                        process_status_id: el.status_process_id,
                        property_id: el.property_id,
                        status: el.status,
                        status_id: el.status_id,
                        status_process_id: el.status_process_id,
                        tenant_id: el.tenant_id})
                    });
                    response.data.message.cierre.forEach(el => {
                        this.cierre.push({ 
                            key_property:el.key_property,
                            Broker: el.Broker,
                            Tenant: el.Tenant,
                            broker_id: el.broker_id,
                            comments_id: el.comments,
                            init_date: moment(el.init_date).format('YYYY-MM-DD'),
                            last_update: moment(el.last_update).format('YYYY-MM-DD'),
                            phone: el.phone,
                            process_status_id: el.status_process_id,
                            property_id: el.property_id,
                            status: el.status,
                            status_id: el.status_id,
                            status_process_id: el.status_process_id,
                            tenant_id: el.tenant_id})
                    });
                    response.data.message.investigacion.forEach(el => {
                        this.investigacion.push({
                            key_property:el.key_property, 
                            Broker: el.Broker,
                            Tenant: el.Tenant,
                            broker_id: el.broker_id,
                            comments_id: el.comments,
                            init_date: moment(el.init_date).format('YYYY-MM-DD'),
                            last_update: moment(el.last_update).format('YYYY-MM-DD'),
                            phone: el.phone,
                            process_status_id: el.status_process_id,
                            property_id: el.property_id,
                            status: el.status,
                            status_id: el.status_id,
                            status_process_id: el.status_process_id,
                            tenant_id: el.tenant_id})
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        goToProperty (id) {
            this.setGeneralDataSearch({
                property_id: id
            })
            this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})
        },
        async getConditions(){
            try {
                let params = {
                    property_id : this.sendProp,
                    tour_id: this.sendTour
                }
                const response = await axiosTrocutServer.post('/getConditionalRent',params)
                if(response.status === 200){
                    response.data.consdicionesRendicion.forEach(el => {
                            this.arraCondition = el
                            this.arraCondition.init_contrat = moment(el.init_contrat).format('YYYY-MM-DD')
                        console.log('condiciones de renta ',el)
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        generatePDF(...arg){
            this.propertyInfoNew = arg[0]
            console.log('por amor a jebus el testamento de codigo que esta escrito creo que indica falta de vida social');
            this.$refs.html2Pdf.generatePdf()
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
        this.getProspectos()
        this.dateInit = moment().format('YYYY-MM-DD')
    }
}


