export default {
    components:{
    },
    data () {
        return{
        }
    },
    computed:{

    },
    methods: {
        know () {
            this.$router.push({
                name: 'index.info.knowUs',
                params: {
                    type: 'Broker'
                }
            })
        }
    },
    mounted () {
    }
}