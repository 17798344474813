import dashboard from "@/components/broker/dashboard";

export default {
  components: {
    dashboard
  },
  data () {
    return{
      
    }
  }
};
