import notification from "@/components/generals/notifications";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    notification
  },
  data () {
    return {
      notificationsInfo:[],
      tempArr:[],
      currentRolComplete:[]
    }
  },
  computed :{
    ...mapGetters({
      user: 'getUser',
      getCurrentRol: 'getCurrentRol',
      getRol:'getRol',
      getStoreUser:'getStoreUser'
    })
  },
  methods:{
    ...mapActions({
      getUserDataStore:'getUserDataStore',
      countNotifications: 'countNotifications'
    }),
    async readSistemNotification(arg){
      try {
        let payload = {
          notification_id: arg[0].notification_id,
          read_notification: 1
        }
        const result = await axiosTrocutServer.post('/readNotification',payload)
        if(result.status === 200){
          this.updateInfoBase()
          this.getNotifations()
          this.countNotifications(this.currentRolComplete.user_rol_id)
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateInfoBase (){
      try {
        let params = {
          complete_info_base: 1,
          complete_address: 1,
          user_id: this.user.user.user_id
        }
        console.log('para actualizar',params);
        const response = await axiosTrocutServer.post('/updateInfoBase',params)
        if( response.status ===  200){
          this.getUserDataStore({
            user_id: this.user.user.user_id
          }) 
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNotifations (){
      this.notificationsInfo = []
      try {
        let payload = {
          user_rol_id: this.currentRolComplete.user_rol_id
        }
        const response = await axiosTrocutServer.post('/getNotifications',payload)
        if( response.status === 200 ){
          response.data.response.forEach(el => {
            if(el.notification_type !== 'Sistema' ){
              this.notificationsInfo.push({
                notification_id:el.notification_id,
                dateN :moment(el.create_at).format('YYYY-MM-DD'),
                origin:el.notification_type,
                message:el.notification,
                read_notification:el.read_notification
              })
            } else {
              this.tempArr = []
              this.tempArr.push({
                active: el.active,
                create_at: el.create_at,
                important: el.important,
                notification: el.notification,
                notification_id: el.notification_id,
                notification_type: el.notification_type,
                read_notification: el.read_notification,
                rental_process_id: el.rental_process_id,
                user_rol_id: el.user_rol_id
              })
              if(this.tempArr[0].read_notification === 0 ){  
                setTimeout(() => {
                  this.readSistemNotification(this.tempArr)
                }, 60000)
                return
              }
              //180000
            }
          })
        }
      } catch (error) {
        console.error(error);
      }
    }
  },

  mounted (){
    const store = this.getStoreUser.roles
    this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    this.getNotifations()
  }
};
