import { FormWizard,TabContent } from 'vue-form-wizard'
import steProgress from '@/components/generals/step-progress'
import { validationMixin } from 'vuelidate'
import { required ,alpha,helpers} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    mixins: [validationMixin],
    components:{
        steProgress,
        FormWizard,
        TabContent,
        Multiselect
    },
    props: {
        idProperty: 0
    },
    data () {
        return {
            viaC: [],
            dateInit: '',
            interestingPeople: [],
            yesNo: [{value:0,name:"Si"},{value:1,name:"No"}],
            selectionPeople: [],
            successInfo: [],
            property: [],
            maritalStatus: [{value:0,name:'Propietario Unico'},{value:1,name:'Sociedad Conyugal'},{value:2,name:'co-propietarios'}],
            banks: [{bank_id:0,bank:''}],
            interCB: [1234567890],
            partOwners: [{name:'',kin:''}],
            partowner: 0,
            signers: [{name:'Yo'},{name:'Tío'},{name:'Conyuge'},{name:'Amigo'},{name:'Fiador'},{name:'Concubina'}],
            timeD: [{value:1,name:"1-5 Dias"},{value:2,name:"6-10 Dias"}],
            mounths: [{value:1,name:"1 mes"},{value:2,name:"2 meses"},{value:3,name:"3 meses"},{value:4,name:"4 meses"},{value:5,name:"5 meses"},{value:6,name:"Resto del contrato"}],
            anticipOption: [{value:0,name:'Inmediato'},{value:1,name:'15 Días'},{value:2,name:'30 Días'},{value:3,name:'45 Días'},{value:4,name:'60 Días'}],
            polizaJuricaData: {
                penalizacion: '',
                vencimientoRenta: '',
                anticipadoVencimiento: '',
                depositoMes: 0,
                tiempoGracia: '',
                tiempoGraciaMes: '',
                rulesBuilding: '',
                formaPago: 0,
                interes: '',
                porcentaje: 0,
                nearWater: ''
            },
            Deposito: {
                intBancaria: '',
                //cuenta:/^[a-zA-Z0-9_ ]*$/,
                cuenta: '',
                clabe: '',
                importeR: false,
                otros: []
                },
                contrat: {
                    propietario: '',
                    domicilio: '',
                    coprop: '',
                    marital: ''
                },
                infoClose: {
                    interesado: '',
                    monto: 0,
                    representante: '',
                    obligado: 0,
                    fecha: '',
                    idIdentifiator: ''
                },
            submitted: false

        }
    },
    validations() {
        return {
            polizaJuricaData: {
                penalizacion: { required }, 
                vencimientoRenta: { required },
                anticipadoVencimiento: { required },
                tiempoGracia: { required  },
                rulesBuilding: { required  },
                nearWater: { required }
            },
            Deposito:{
                intBancaria: { required },
                cuenta: { required, isNameValid: helpers.regex('isNameValid',/^[a-zA-Z\s]*$/)},
                clabe: { required  },
                importeR:{ required }
              },
              contrat:{
                propietario: {required, validName: helpers.regex('validName',/^[a-zA-Z\s]*$/)},
                domicilio: { required },
                coprop: {required},
                marital: {required}
            },
            infoClose:{
                interesado: {required},
                monto: {required},
                representante: {required},
                obligado: {required},
                fecha: {required},
                idIdentifiator: {required}
            }
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser'
        }),
        vencimiento(item){
            return this.polizaJuricaData.vencimiento
        },
        nameAccountHolder() {
           return this.$v.Deposito.cuenta = /^[a-zA-Z\s]*$/g
        }
    },
    methods: {
        nameSigner({name}){
               // name = this.user.user_data.fullName
            return `${name}`
        },
        nameQuestion ({name}) {
            return `${name}`
          },
          nameMounth ({name}) {
            return `${name}`
          },
          nameBank ({bank}) {
            return `${bank}`
          },
          nameDevuelta ({name}) {
            return `${name}`
          },
          nameAnticipo ({name}) {
            return `${name}`
          },
        gotToCards () {
            this.$router.push({path:'owner/propertyes'})
        },
        closeModal () {
            this.$modal.hide('modal-closeProcess')
        },
        async polizaJuricaModel () {
            this.submitted = true
            this.$v.polizaJuricaData.$touch()
            if(!this.$v.polizaJuricaData.$invalid){
                if(this.polizaJuricaData.interes === 'false' && this.polizaJuricaData.penalizacion === 'false'){
                    this.polizaJuricaData.interes = false
                    this.polizaJuricaData.penalizacion = false
                }   else {
                    this.polizaJuricaData.interes = true
                    this.polizaJuricaData.interes = true
                }
                this.accountHolder()
                this.updateWater()
                this.$refs.wizzardPoliza.nextTab()
            }else {
                this.$toastedPush({
                    message:`&nbsp;&nbsp;&nbsp; favor de llenar los campo en blanco o con *`,
                    type:'error'
                })
            }
        },
        async deposito () {
            this.submitted = true
            if(this.$v.Deposito.$invalid){
                console.log(this.Deposito)
            }else{
                console.log(this.Deposito)
            }
        },
        async contrato () {
            this.submitted = true
            if(this.$v.contrat.$invalid){
                console.log(this.Deposito)
            }else{
                console.log(this.contrat)
            }
        },
        async close () {
            this.submitted = true
            if(this.$v.infoClose.$invalid){
                console.log(this.Deposito)
            }else{
                console.log(this.infoClose)
            }
        },
        async getBanks () {
            try {
                const response = await axiosTrocutServer.post('/getBanks')
                response.data.banks.forEach(el => {
                    this.banks.push({ 
                        bank_id : el.bank_id,
                        bank : el.bank
                    })
                   
                })
            } catch (error) {
                console.error(error)
            }
        },
        async postClosingPropessRent(){
            try {
                if(this.polizaJuricaData.tiempoGracia === false){
                    this.polizaJuricaData.tiempoGracia = 0
                } if(this.polizaJuricaData.tiempoGracia === true) {
                    this.polizaJuricaData.tiempoGracia = 1
                }
                if(this.polizaJuricaData.rulesBuilding === false){
                    this.polizaJuricaData.rulesBuilding = 0
                } if(this.polizaJuricaData.rulesBuilding === true) {
                    this.polizaJuricaData.rulesBuilding = 1
                }
                if(this.infoClose.obligado === false){
                    this.infoClose.obligado = 0
                } if(this.infoClose.obligado === true) {
                    this.infoClose.obligado = 1
                }
                let params = {
                    penalty: this.polizaJuricaData.penalizacion.value,
                    deposit: this.polizaJuricaData.depositoMes,
                    return_of_deposit: this.polizaJuricaData.vencimientoRenta.value,
                    grace_time: this.polizaJuricaData.tiempoGracia.value,
                    days_grace: this.polizaJuricaData.tiempoGraciaMes.name,
                    rules: this.polizaJuricaData.rulesBuilding.value,
                    payment_type: this.polizaJuricaData.formaPago,
                    payment_rent: this.property[0].amount_rent ,
                    interestAcept: this.polizaJuricaData.interes = this.polizaJuricaData.interes !== false ? 0 : 1 ,
                    interest: this.polizaJuricaData.porcentaje,
                    account_id: this.idAcount,
                    part_owner: this.partowner,
                    jointlyBound: this.infoClose.obligado,
                    init_contrat: moment(this.infoClose.fecha).format('YYYY-MM-DD') ,
                    id_media_deed: 1,
                    id_media_contrat: 1,
                    id_media_partowner: 1,
                    property_id: this.idProperty,
                    id_media_IdCard: 1,
                    anticipaded:this.polizaJuricaData.anticipadoVencimiento.name
                }
                console.log('proceso de cierre de renta',params)
                const response = await axiosTrocutServer.post('/addInfoProcess', params)
                if(response.status === 200) {
                    this.$modal.hide('modal-closeProcess')
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Registro Completo, por favor continue agregando su información.`,
                        type: 'success'
                    })
                    this.$emit('updateProcess')
                }
            } catch (error) {
                console.error(error)
            }
        },
        async updateWater () {
            try {
                let params = {
                    amount_rent: 7500,
                    nearWater: this.polizaJuricaData.nearWater.value,
                    property_id: this.idProperty
                }
                const response =await axiosTrocutServer.post('/updateAddProperty', params)
                if(response.status === 200) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;Los datos fueron registragos correctamente`,
                        type: 'success'
                    })
                }
            } catch (error) {
               console.error(error);
            }
        },
        async accountHolder() {
            try {
                let params = {
                    id_bank: this.Deposito.intBancaria.bank_id,
                    clabe: parseInt(this.Deposito.clabe),
                    fullNameAcountHolder: this.Deposito.cuenta,
                    type: 'Owner',
                    id_property: this.idProperty
                }
                const response = await axiosTrocutServer.post('/addAccountHolder', params)
                if(response.status === 200) {
                    this.idAcount = response.data.metadata.insertId
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;Se registraron correctamente los datos`,
                        type: 'success'
                    })
                }
            } catch (error) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Error favor de intentarlo mas tarde`,
                    type: 'error'
                })  
            }
        },
        async addPartOwner(){
            try {
                let params = {
                    fullName: this.contrat.propietario, 
                    address: this.contrat.domicilio, 
                    ineChar: this.infoClose.idIdentifiator,
                    kin: this.infoClose.interesado.name,
                    community: this.contrat.coprop.name,
                    property_id: this.idProperty
                }
                const response = await axiosTrocutServer.post('/registerPartOwner', params)
                if (response.status === 200) {
                    this.partowner = response.data.metadata.insertId
                    this.$refs.wizzardPoliza.nextTab()
                }
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Copropietario fue registrado de manera exitosa`,
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                /* this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Error favor de intentarlo mas tarde`,
                    type: 'error'
                }) */
            }
        },
        async getInfo() {
            try {
                let params = {
                    property_id: this.idProperty
                }
                const response = await axiosTrocutServer.post('/getPolicy',params)
                if(response.status === 200){
                    response.data.property.forEach(el => {
                     this.property.push(el)
                    })
                    this.contrat.domicilio = this.property[0].direccionCompleta
                    response.data.poliza.forEach(el => {
                    if(el.penalty === 0){
                        this.polizaJuricaData.penalizacion = {value:el.penalty,name:'Si'}
                    }if(el.penalty === 1){
                        this.polizaJuricaData.penalizacion = {value:el.penalty,name:'No'}
                    }
                    if(this.polizaJuricaData.vencimiento === 1){
                        this.polizaJuricaData.vencimiento = {value:el.return_of_deposit,name:'1 mes'}
                    }
                    if(this.polizaJuricaData.vencimiento === 2){
                        this.polizaJuricaData.vencimiento = {value:el.return_of_deposit,name:'2 mes'}
                    }if(this.polizaJuricaData.vencimiento === 3){
                        this.polizaJuricaData.vencimiento = {value:el.return_of_deposit,name:'3 mes'}
                    }if(this.polizaJuricaData.vencimiento === 4){
                        this.polizaJuricaData.vencimiento = {value:el.return_of_deposit,name:'4 mes'}
                    }if(this.polizaJuricaData.vencimiento === 5){
                        this.polizaJuricaData.vencimiento = {value:el.return_of_deposit,name:'5 mes'}
                    }if(this.polizaJuricaData.vencimiento === 6){
                        this.polizaJuricaData.vencimiento = {value:el.return_of_deposit,name:'El resto del contrato'}
                    }
                    this.Deposito.cuenta = el.cable,
                    this.infoClose.fecha = moment(el.init_contrat).format('YYYY-MM-DD')
                    this.infoClose.representante = el.fullName
                    this.infoClose.interesado = el.kin
                    this.polizaJuricaData.deposito = el.deposit
                    this.infoClose.obligado =el.jointlyBound
                    this.infoClose.representante = el.fullName
                    this.Deposito.cuenta = el.fullNameAcountHolder
                    this.Deposito.clabe = el.clabe
                    this.Deposito.intBancaria= {value:el.id_bank,bank:el.bank}
                    this.contrat.propietario = el.fullName
                    this.contrat.domicilio = el.address
                    this.infoClose.idIdentifiator = el.ineChar
                    this.polizaJuricaData.vencimiento = {value:0,name:''}
                    this.polizaJuricaData.anticipadoVencimiento= {value:1,name:''}
                    this.polizaJuricaData.rules = el.rules
                    })
                }
            } catch (error) {
                console.error(error)
            }
        },
        saveRent (prop) {
            let indexAddPoliza = prop.activeTabIndex
            switch (indexAddPoliza) {
                case 0:
                     this.polizaJuricaModel()
                    /*this.deposito() 
                    this.$refs.wizzardPoliza.nextTab()*/
                    break;
                case 1:
                    this.$refs.wizzardPoliza.nextTab()
                    break;
                case 2:
                    this.addPartOwner()
                    this.postClosingPropessRent() 
                    this.$modal.hide('modal-closeProcess')
                    /* this.$refs.wizzardPoliza.nextTab() */
                break;
                default:
                    break;
            }
        },
        masAmigos() {
            
            if( this.partOwners.length >=2) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Lo sentimos pero no se pueden agregar mas copropietarios`,
                    type: 'error'
                }) 
            } else {
                this.partOwners.push({name:'',kin:''})
            }
        }
    },
    mounted () {
        this.dateInit = moment().format('YYYY-MM-DD')
        this.getBanks()
        this.getInfo()
        this.polizaJuricaData.vencimiento = {value:1,name:''}
        this.contrat.coprop = {value:1,name:''}
        this.contrat.propietario = this.user.user_data.fullName
        this.Deposito.cuenta = this.user.user_data.fullName
    }
}