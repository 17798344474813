import {  mapActions,mapGetters  } from 'vuex'
import {FormWizard, TabContent} from 'vue-form-wizard'
import { helpers } from 'vuelidate/lib/validators'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    firebase,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    OAuthCredential,
    signInWithPhoneNumber,
    EmailAuthProvider,
    createUserWithEmailAndPassword
} from '@/firebase' 
import { getAuth, linkWithCredential, signInWithCredential } from "firebase/auth";
import InvisibleRecaptcha from '@/components/generals/InvisibleRecaptcha.vue'
import axiosAuth from '@/axios/axiosAuthService'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import config from "@/config"
import {  validationMixin } from 'vuelidate' 
import { required,email } from "vuelidate/lib/validators";
import countries from '@/mixins/countries.json';
import moment from 'moment';

export default {
    mixins: [ validationMixin ],
    components: {
        FormWizard,
        TabContent,
        InvisibleRecaptcha,
        helpers
    },
    name: "register",
    data () {
        return {
            termsAndConditions: true,
            noticePrivacy: true,
            googleCredential: '',
            facebookCredential: '',
            phoneProvider:'',
            proovedor:{},
            appleCredential:'',
            userFirebase: '',
            appVerifier: '',
            showInputPhone:false,
            phoneNumber: null,
            email: '',
            password: '' ,
            newPassword:'',
            confirmationResult: null,
            verifyNumberCode: null,
            recaptchaVerifier: '',
            recaptchaWidgetId: null,
            confirmResult: null,
            smsSent: false,
            firebaseAuth: null,
            activeIndex: 0,
            isLoading: false,
            validateNextBtn: false,
            invisibleRecaptchaReadOnly: true,
            rolesN:[],
            new_user_id: 0,
            validators:false,
            newUserRolId:0,
            submitted:false,
            dial_code_Array: [],
            countries:[],
            dial_code:'+52',
            arrayNation:[]
        }
    },
    watch: {
        password (p) {
            if (p.length >= 6) {
                this.validateNextBtn = true
            } else {
                this.validateNextBtn = false
            }
        },
        phoneNumber (p) {
            if (p.length === 10) {
                this.invisibleRecaptchaReadOnly = false
            } else {
                this.invisibleRecaptchaReadOnly = true
            }
        }
    },
    validations(){
        return {
            email: {email}
            /* ,phoneNumber: { isNameValid:helpers.regex('isNameValid',/^[a-zA-Z\s]*$/) } */
        }
      },
    computed: {
        indexSteptWizzard(){
            return this.activeIndex
        },
        sitekey () {
            return this.$_.get(config, 'reCAPTCHA.siteKey', '')
        },
        
        ...mapGetters({
            user:'getUser',
            getCurrentRol:'getCurrentRol',
        })
    },
    methods: {
        ...mapActions({
            signIn: 'signIn',
            setCurrentRol: 'setCurrentRol',
            signOut: 'signOut',
            updateImage:'updateImage',
          }),
          //updateUserRol: 'updateUserRol'
        ladaPhone  () {
            for(var c  of countries){
              this.countries.push(c)
            }
          },
        async registerFirebase() {
            const credential = EmailAuthProvider.credential(this.email, this.password)
            const auth = getAuth()
            createUserWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {
                this.userFirebase = userCredential.user
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
               
            });
        },
        closeModal () {
          this.$modal.hide('modal-register')
          if(this.user.user !== undefined ){
                this.IncompleteRegister(this.user.user.email)
                return
            }if(this.email !== ''){
                this.IncompleteRegister(this.email)
                return
            }
            //console.log('revestimiento el usuario',this.user.user)
        },
        async registerTrocutServer (passwordInput,typeSign) {
          try {
            let payload = {
              email: this.email,
              password: passwordInput,
              method: typeSign
            }
            this.password = passwordInput
            let response = await axiosAuth.post('/register', payload)
            //console.log('Registro *** * * * * *', response)
            if (response.status === 200) {
                if(!response.data.error) {
                this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp; Registro exitoso`,
                  type: 'success'
                }) 
                await this.signIn(payload)
                this.new_user_id = response.data.response.insertId
                this.$refs.wizzardRegister.nextTab()
                this.insertPictures()
              } else {
                console.error('errores',response.data.error);
                this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp; ${this.email} ya se encuentra registrado, por favor verifique su información.`,
                  type: 'error'
                })
                this.$router.go(0)
                this.deleteBefore()
                this.isLoading = false
                return
              }
            }
          } catch (error) {
            console.error('Error registro Trocutserver', error)
          }
        },
        async linkMethod(auth,provider,type){
            try {
                    if(type === 'google') {
                if (!this.termsAndConditions || !this.noticePrivacy) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Para continuar deberá aceptar Términos y Condiciones y Aviso de Privacidad`,
                        type: 'error'
                    })
                    return
                    }
                    signInWithPopup(auth, provider)
                    .then(async (result) => {
                        this.googleCredential = GoogleAuthProvider.credentialFromResult(result)
                        const user = result.user
                        this.email = result.user.email
                        this.password = user.uid
                        this.deleteBefore()
                        await this.$refs.wizzardRegister.nextTab()
                    }).catch((error)=>{
                        console.error(error);
                    })
                    //await firebase.auth().signInWithCredential(this.googleCredential)
                } 
                if(type === 'facebook'){
                    if (!this.termsAndConditions || !this.noticePrivacy) {
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp; Por favor acepte los terminos y condiciones ademas de el aviso de privacidad.`,
                            type: 'error'
                        })
                        return
                    }
                    signInWithPopup(auth, provider)
                    .then(async (result) => {
                        this.facebookCredential = FacebookAuthProvider.credentialFromResult(result)
                        const user = result.user
                        this.email = user.email
                        this.password = user.uid
                        this.deleteBefore()
                        await this.$refs.wizzardRegister.nextTab()
                    }).catch((error)=>{
                        console.error(error);
                    })
                    //firebase.auth().signInWithCredential(this.facebookCredential)
                } 
                if(type === 'apple'){
                    if (!this.termsAndConditions || !this.noticePrivacy) {
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp; Por favor acepte los terminos y condiciones ademas de el aviso de privacidad.`,
                            type: 'error'
                        })
                        return
                    }
                    signInWithPopup(auth,provider)
                    .then(async (result) => {
                        this.appleCredential =  OAuthProvider.credentialFromResult(result)
                        const user = result.user
                        this.email  = result.user.email
                        this.password = user.uid
                        this.deleteBefore()
                        await this.$refs.wizzardRegister.nextTab()
                    })
                    .catch((error)=>{
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        const email = error.email;
                        const credential = OAuthProvider.credentialFromError(error);
                    })
                    //firebase.auth().signInWithCredential(this.appleCredential)
                }
            } catch (error) {
                console.error(error);
            }
            
         }, 
        async registerWithApple () {
            const provider = new OAuthProvider('apple.com')
            const auth = getAuth()
            provider.addScope('email')
            provider.addScope('name')
            provider.setCustomParameters({
                locale :'es_MX'
              });
           await this.linkMethod(auth,provider,'apple')
        },
        async registerWithFacebook () {
            const provider = new firebase.auth.FacebookAuthProvider()
            provider.addScope('user_age_range')
            provider.addScope('user_gender')
            provider.addScope('email') 
            const auth = getAuth()
           await this.linkMethod(auth,provider,'facebook')
        },
        async registerWithGoogle () {
            const providerG  = new firebase.auth.GoogleAuthProvider
            /* providerG.addScope('https://www.googleapis.com/auth/user.emails.read');
            providerG.addScope('https://www.googleapis.com/auth/user.gender.read');
            providerG.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
            providerG.addScope('https://www.googleapis.com/auth/userinfo.profile');
            providerG.addScope('https://www.googleapis.com/auth/analytics')
            providerG.addScope('https://www.googleapis.com/auth/analytics.readonly') */
            const authG = getAuth()
           await this.linkMethod(authG,providerG,'google')
        },
        async registerWithPhone (arg) {
            this.isLoading = true
            if (this.phoneNumber === null) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Por favor ingrese un número de teléfono a 10 digitos`,
                    type: 'error'
                })
                this.isLoading = false
                return
            }
            if (this.phoneNumber.length < 10 || this.phoneNumber.length >10 ) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;El número de teléfono debe de ser de 10 digitos`,
                    type: 'error'
                })
                this.isLoading = false
                return
            }
            const verification = await this.validatePhone(this.phoneNumber)
            if (verification) {
                this.isLoading = false
                return
            }
            let numberP = arg
            numberP = numberP.concat(this.phoneNumber)
            numberP = numberP.trim()
            this.phoneNumber = numberP
            signInWithPhoneNumber(getAuth(), numberP, this.appVerifier)
            .then((confirmationResult)=>{                
                this.confirmResult = confirmationResult
                this.smsSent=true
                this.showInputPhone = true
                this.validateNextBtn = true
            })
            .catch((error) => {
                this.showInputPhone = false
                console.error('Error', error)
                window.recaptchaVerifier.render().then((widgetId) => {
                    this.recaptchaWidgetId = widgetId
                })
            })
        },
        deleteBefore(){
            var userD = firebase.auth().currentUser
                userD.delete().then(async()=>{
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; favor de ingresar el numero telefónico para dar de alta al usuario`,
                        type: 'success'
                    })
                }).catch((error)=>{
                    console.error(error);
                })
        },
       async createNotificationByRol (rol,usuario,adittionalText,template){
            try {
                let params = {
                    notification: `Hola ${usuario}, ¡Bienvenid@ a Trócut!. Estamos muy emocionados que seas parte de nuestra familia, ${adittionalText},¿Quieres seguir recibiendo nuestros correos? Haz click aquí para suscribirte de forma gratuita a nuestro newsletter mensual y mantente informado de temas de interés relacionados al mundo inmobiliario.`,
                    importantId: 1,
                    user_rol_id: 0,
                    notification_type: "Registro",
                    rental_process_id: 0,
                    idTemplate: template,
                    roleId: 0,
                    tour_id: 0,
                    userId: this.user.user !== undefined ? this.user.user.user_id  : rol,
                    email: this.user.user !== undefined ? this.user.user.email : this.email
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    console.log('send')
                }
                if( response.status === 500){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; por el momento no cuenta con notificaciones disponibles`,
                        type: 'error'
                    })
                }
            } catch (error) {
                console.error(error)
            }  
              
        },
        async IncompleteRegister (usuario){
            try {
                let params = {
                    notification: `Hola ${usuario}, ¡Ups!, hemos detectado que aún tienes información por
                    completar.
                    Ingresa a trocut.com y concluye el proceso pendiente.
                    El equipo Trócut`,
                    importantId: 1,
                    user_rol_id: 0,
                    notification_type: "IncompleteRegister",
                    rental_process_id: 0,
                    idTemplate: 9,
                    userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : this.email
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                
            } catch (error) {
                console.error(error);
            }  
              
        },
        linkCredentialsEmail() {
            this.newPassword = this.password
            const credential = EmailAuthProvider.credential(this.email, this.newPassword)
            // const credential = firebase.auth.PhoneAuthProvider.credential(this.confirmResult.verificationId, this.verifyNumberCode);
            const auth = getAuth()
            /* firebase.auth().signInWithCredential(credential) */
            linkWithCredential(auth.currentUser, credential)
              .then( async (usercred) => {
                const user = usercred.user;
                await this.registerTrocutServer(this.newPassword,'Email')
                await this.registerPhone()
              }).catch((error) => {
                  this.showInputPhone = false
                  this.isLoading = false
                this.verifyNumberCode = ''
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; El número ${this.phoneNumber} ya se encuentra registrado`,
                    type: 'error'
                })
            })            
        },
       async linkCredentialsGoogle() {
           this.newPassword = ''
            const auth = getAuth();
            linkWithCredential(auth.currentUser,this.googleCredential)
              .then(async(usercred) => {
                const user = usercred.user;
                this.newPassword = user.uid
                await this.registerTrocutServer(this.newPassword,'Google')
                await this.registerPhone()
               
              }).catch((error) => {
                console.error("Account linking error firebase", error)
                console.error(error);
            })
        },
        async linkCredentialsFacebook() {
            this.newPassword = ''
            const auth = getAuth();
            linkWithCredential(auth.currentUser,this.facebookCredential)
              .then(async(usercred) => {
                const user = usercred.user;
                this.newPassword = user.uid
                await this.registerTrocutServer(this.newPassword,'Facebook')
                await this.registerPhone()
              }).catch((error) => {
                  console.error(error);
              })          
        },
        linkCredentialsApple() {
            this.newPassword = ''
            const auth = getAuth();
            linkWithCredential(auth.currentUser,this.appleCredential )
              .then(async(usercred) => {
                const user = usercred.user;
                this.newPassword = user.uid
                await this.registerTrocutServer(this.newPassword,'Apple')
                await this.registerPhone()
              }).catch((error) => {
                console.error(error);
              })            
        },
        testVerifyAccounts (newCredential) {
            // Get reference to the currently signed-in user
          const auth = getAuth()
          const prevUser = auth.currentUser
          
          // Get the data which you will want to merge. This should be done now
          // while the app is still signed in as this user.
          //const prevUserData = this.googleCredential.user
          
          // Sign in user with the account you want to link to
          signInWithCredential(auth, newCredential).then((result) => {
              const credential = OAuthProvider.credentialFromResult(result)
              return linkWithCredential(prevUser, credential)
          .then((linkResult) => {
              // Sign in with the newly linked credential
              const linkCredential = OAuthProvider.credentialFromResult(linkResult)
              return signInWithCredential(auth, linkCredential)
          }).then((signInResult) => {
      // Save the merged data to the new user
    //   repo.set(signInResult.user, mergedData);
    })
        }).catch((error) => {
        // If there are errors we want to undo the data merge/deletion
        console.error("Sign In Error", error)
        })
        }, 
        registerOtherPhone(){
            this.showInputPhone = false
            this.isLoading = false
            this.verifyNumberCode = ''
        },
        async verifyCode(arg) {
            try {
                this.confirmResult.confirm(this.verifyNumberCode)
                .then(async (result) => {
                    if(arg === 'registro normal'){
                        await this.linkCredentialsEmail()
                    }
                    if(arg === 'apple'){
                        await this.linkCredentialsApple()
                    }
                    if(arg === 'facebook'){
                        await this.linkCredentialsFacebook()
                    
                    }
                    if(arg === 'google'){
                        await this.linkCredentialsGoogle()
                    }   
                })
                .catch((error)=>{
                    this.showInputPhone = true
                    console.error('Error comfirm', error)
                    if(this.verifyNumberCode.length < 6){
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp; El código de validación debe ser de 6 dígitos`,
                            type: 'error'
                        })
                    }else {
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp; el numero ${this.phoneNumber} ya se encuentra registrado en la plataforma Trocut`,
                            type: 'error'
                        })
                    }
                    
                    this.verifyNumberCode = ''
                })
            } catch (error) {
                console.error('error try catch',error);
            }
        },
        forgotPassword () {},
         async getRolesRegister (){
            let response  = await axiosTrocutServer.post('/getRoles')
            this.rolesN = response.data.roles
         },
        async registerPhone () {
            try {
                let payload = {
                    user_id: this.new_user_id,
                    phone: this.phoneNumber
                }
                let response = await axiosTrocutServer.post('/updatePhoneRegister', payload)
                if (response.status === 200) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Verificacion exitosa, por favor continue agregando su información.`,
                        type: 'success'
                    })
                } else {
                  this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Algo ocurrio, por favor, intentelo de nuevo.`,
                    type: 'error'
                  })
                }
                
            } catch (error) {
                console.error('Error registerPhone', error)
                this.$toastedPush({
                    message: ` &nbsp;&nbsp;&nbsp; Error registerTrocutServer ,${error}`,
                    type:`error`
                })

            }        
        },
        async registerRol (arg) {
            try {
                let payload = {
                    user_id: this.new_user_id,
                    role_id: arg ,
                    os: 'web'
                }
                const response = await axiosTrocutServer.post('/registerRoleForUser', payload)
                if (response.status === 200) {
                    this.newUserRolId = response.data.response.insertId
                    //this.updateUserRol(this.newUserRolId)
                    if(payload.role_id === 1){
                        var inquilino = 'Como inquilino podrás buscar y elegir una propiedad que se adapte a tus necesidades y gustos.'
                        this.createNotificationByRol(this.newUserRolId,this.email,inquilino,1)
                    }
                    if(payload.role_id === 2){
                        var broker = ' Como broker podrás gestionar inmuebles que te asignamos cerca de tu ubicación y ganar atractivas comisiones mediante un acompañamiento en los procesos de renta.'
                        this.createNotificationByRol(this.newUserRolId,this.email,broker,16)
                    }
                    if(payload.role_id ===3){
                        var owner = 'Como propietario podrás publicar tu inmueble de manera gratuita, contar con un broker, seguir el proceso de renta en tiempo real y tener la certeza de que evaluaremos a profundidad al prospecto para tu tranquilidad Visita trocut.com y vive la experiencia de rentar de manera segura y con beneficios.'
                        this.createNotificationByRol(this.newUserRolId,this.email,owner,53)
                    }
                }
            } catch (error) {
                console.error(error)
                this.$toastedPush({
                    message: ` &nbsp;&nbsp;&nbsp; Error registerTrocutServer ,${error}`,
                    type:`error`
                })
            }
        },
        async validatePhone (phone) {
            try {
                let payload = {
                    phone: phone
                }
                let response = await axiosTrocutServer.post('/validatePhone', payload)
                if (response.status === 200) {
                    if (response.data.exist) {
                        this.$toastedPush({
                            message: ` &nbsp;&nbsp;&nbsp; El número ${phone}, ya se encuentra registrado, por favor verifique su información.`,
                            type:`error`
                        })
                    }
                    return response.data.exist
                }
            } catch (error) {
                console.error('Error validatePhone', error)
            }
        },
        async goToP (arg){
            let payload = {
                email: this.email,
                password: this.newPassword
            }
            switch (arg) {
                case 0:
                await this.registerRol(1)
                    /* this.signOut() */
                    this.setCurrentRol(this.rolesN[arg])
                    await this.signIn(payload)
                    this.$router.push({name:'index.tenant.profile'}).catch(()=>{}) 
                break;
                case 1:
                    await this.registerRol(2)
                    /* this.signOut() */
                    this.setCurrentRol(this.rolesN[arg])  
                    await this.signIn(payload)
                    this.$router.push({name:'index.broker.account'}).catch(()=>{})
                    break;
                case 2:
                    await this.registerRol(3)
                    /* this.signOut() */
                    this.setCurrentRol(this.rolesN[arg])
                    await this.signIn(payload)
                    this.$router.push({name:'index.owner.profile'}).catch(()=>{})
                  break;
                }
               //await this.updateImage(this.new_user_id)
            this.$modal.hide('modal-register')
        },
       async next (prop) {
            if (!this.termsAndConditions || !this.noticePrivacy) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; Para continuar deberá aceptar Términos y Condiciones y Aviso de Privacidad`,
                    type: 'error'
                })
                return
            }
            //this.validateNextBtn = false
            let activeIndex = prop.activeTabIndex
            switch (activeIndex) {
                case 0:
                    this.submitted =true
                    this.$v.email.$touch()
                    if(!this.$v.email.$invalid){
                        if (this.email === '') {
                            this.$toastedPush({
                                message: `&nbsp;&nbsp;&nbsp; El correo electrónico es requerido para el registro.`,
                                type: 'error'
                            })
                            return
                        }
                        if (this.password === '' || this.validateNextBtn === false ) {
                            this.$toastedPush({
                                message: `&nbsp;&nbsp;&nbsp; Favor de ingresar una contraseña de mínimo 6 caracteres`,
                                type: 'error'
                            })
                            return
                        }
                        this.$refs.wizzardRegister.nextTab()
                    } else {
                        this.submitStatus = 'ERROR'
                    }
                    //this.registerTrocutServer()
                break;
                case 1:
                    if(this.googleCredential !== '' ){
                        await this.verifyCode('google')
                    } else if(this.appleCredential !== '' ){
                       await this.verifyCode('apple')
                    }else if(this.facebookCredential !== '' ){
                       await this.verifyCode('facebook')
                    }else {
                      await  this.verifyCode('registro normal')
                    }
                break;
                
            }
        },
        initReCaptcha(){
            setTimeout(()=>{
              let vm = this
              window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function(response) {
                  // reCAPTCHA solved, allow signInWithPhoneNumber.
                  // ...
                
                  //this.signInWithPhoneNumber()
                },
                'expired-callback': function() {
                  // Response expired. Ask user to solve reCAPTCHA again.
                  // ...
                }
              });
              //
              this.appVerifier =  window.recaptchaVerifier
            },1000)
        },
        async insertPictures(){
            try {
                console.log('esto es user',this.user);
              let params = {
                user_id: this.new_user_id,
                name: this.user.user.email,
                lastname: ' ',
                lastname2: ' ',
                marital_status: ' ',
                birthday: moment('1987-03-09').format('YYYY-MM-DD'),
                community_property:  1,
                is_foreign: 0,
                nationality:  'Mexico',
                immigration_status: 'Permanente',
                rfc:  ''
            }
            const response = await axiosTrocutServer.post('/registerUserData',params)
              if(response.status === 200){
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;  Favor de agregar su información personal a continuación`,
                    type: 'success'
                })
            }
            } catch (error) {
              console.error(error);
            }
        }
    },
    mounted() {
        
        this.showInputPhone = false
        this.getRolesRegister()
        this.ladaPhone()
        this.dial_code_Array 
         this.arrayNation = countries
        for (var i = 0; i<this.arrayNation.length;i++){
            this.dial_code_Array.push(this.arrayNation[i])
        }
        //México +52 4421234568 4420876545
        //Argentina +54 5694569631
    },
    created() {
        this.initReCaptcha()
    }
}
