import get from 'lodash/get'
import axios from 'axios'
import config from '@/config'
import store from '@/store'
import router from '@/router'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: config.trocut.apiUrl
})

instance.interceptors.request.use((config) => {
  const token = get(store, 'state.auth.token', false)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const status = get(error, 'response.status', 500)
  if (status === 401) {
    store.dispatch('signOut')
    router.push({ name: 'index.home', params: { session_expired: true } })
  } else {
    return Promise.reject(error)
  }
})

export default instance