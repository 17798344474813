import axiosTrocutServer from '@/axios/axiosTrocutServer'
export default {
  props:{
    blogId:0
    },
    name: "app",
    data () {
        return {
          typeProp:[],
          blogArr:[]
      }
    },
    watch: {
      
    },
    computed: {
    },
    methods: {
      async getBolg(arg){
        try {
          let params = {
            id_blog:arg
          }
          const response = await axiosTrocutServer.post('/getBlog',params)
          this.blogArr.push(response.data.contenidoBlog[0])
          //console.log('arrglo de blog',this.blogArr);
        } catch (error) {
          console.error(error);
        }
      }
    },
    mounted ()  {
      this.typeProp = this.$route.params.type
      this.getBolg(this.typeProp)
    }
}
    