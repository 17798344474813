import cardProperty from '@/components/owner/cardPropertyOwner'

export default {
  components: {
    cardProperty
  },
  data () {
    return{
     
      
    }
  }
};
