import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel'
import login from '@/components/home/login'
import alertaGeneral from '@/components/generals/alertGeneral'
import  directModal from "@/components/tenant/rentModal"
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import moment from 'moment';
import stripe from '@/components/generals/stripe'


export default {
    name: "property-Profile",
    components: {
        login,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Multiselect,
        alertaGeneral,
        directModal,
        stripe
    },
    data () {
        return {
            isLoad:false,
            causaReporte: '',
            stars: 5,
            question: '',
            questionsProperty: [],
            hasPet: 0,
            hasDeposit:0,
            hasOthers:0,
            hasVigenci:0,
            dateQuestion:'',
            dateResponse:'',
            isEnable:'',
            propertyAmenities: [],
            propertyServices: [],
            pov: null,
            pano: null,
            streetviewButton:false,
            alertB: false,
            center:{
                lat: 0.0, 
                lng: 0.0
            },
            reporte:false,
            currentRolComplete:[],
            property:[],
            mediaProperty:[],
            hour:'',
            date_init:'',
            idProperty:[],
            processRent:[],
            payment:[],
            textAreaTenant:'',
            markers:[
                {
                    id:1,
                    pov:{heading: 34 ,pitch: 10 ,zoom: 1},
                    position:{
                        lat: 0, 
                        lng: 0
                    },
                },
            ],
            options:{
                zoom:18,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: true,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                draggable: false
              },
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                700: {
                  itemsToShow: 1,
                  snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                }
            },
            reporteBroker:['Me robo', 'No pude contactarlo', 'Me trato mal', 'No gestiona mi propiedad'],
            plat:null,
            plng:null,
            auth:false,
            brokerArra: {
                brokerName: 'Sin broker',
                url: '',
                rentas: 0
            },
            descripcion:'',
            responses:{
                responseBroker:''
            }
            ,
            reportProperty:''
        }
    },
    watch: {
    },
    computed: {
        isAuth() {
            this.alertB = false
        },
        ...mapGetters({
            getStoreSearch:'getStoreSearch',
            Auth:'getAuth',
            user:'getUser',
            getRol:'getRol',
            getCurrentRol:'getCurrentRol',
            getStoreUser:'getStoreUser',
            storeSearch: 'getStoreSearch',
            actionSearch: 'getSearchClick'
        })
    },
    methods: {
        updatePov(pov) {
            this.pov = pov;
          },
          updatePano(pano) {
            this.pano = pano;
          },
        buttonStreetview (){
            this.streetviewButton = !this.streetviewButton;
        },
        alertButton (){
            this.alertB =! this.alertB
        },
        reporProperti () {
            this.reporte =! this.reporte
            
        },
        quickRent(arg){
            this.idProperty = arg
            this.$modal.show('renta-modal')
            this.createNotificationSelectedProperty()
        },
        closeModal(type){
            this.$modal.hide(type)
            this.$modal.hide("renta-modal")
        },
        quickRoute (){
            this.$modal.show('directRent')
        },
        loginquick(){
          this.$modal.show('modal-login')  
        },
        reportarBroker  (){
            this.$modal.show('modal-reportar')
        },
        async createNotificationSelectedProperty (){
            try {
                let params = {
                    notification: `Hola ${this.user.user.email},¡Genial!, has seleccionado exitosamente la propiedad
                    para rentar. Una vez que completes tu información en la Solicitud de
                    arrendamiento, llevaremos a cabo el proceso de investigación correspondiente y
                    te avisaremos del resultado`,
                    importantId: 1,
                    user_rol_id: this.currentRolComplete.user_rol_id,
                    notification_type: "Inquilino",
                    rental_process_id: 0,
                    idTemplate: 47,
                    userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : this.user.user.email
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    console.log('war thunder')
                }
            } catch (error) {
                console.error(error);
            }   
        },
        async mensaje(){
            try {
                let params = {} 
                if(this.currentRolComplete.rol === 'Propietario'){
                        params = {
                            typeReport: 'Broker',
                            motivo: this.causaReporte,
                            relation: this.idProperty,
                            user_rol_id_report:this.currentRolComplete.rol
                        }
                } 
                if (this.currentRolComplete.rol === 'Inquilino'){
                    params = {
                        typeReport: 'Propiedad',
                        motivo: this.reportProperty,
                        relation: this.idProperty,
                        user_rol_id_report: this.currentRolComplete.user_rol_id
                    }
                }
               const response = await axiosTrocutServer.post('/createReport',params)
                 if(response.status === 200){
                    this.$modal.hide('modal-reportar')
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Tu reporte ha sido enviado.`,
                        type: 'success'
                    })
                    //this.$router.go(0)
                    this.$router.replace({path:'/'})
                }
            } catch (error) {
                console.error(error);
            }
        },
        async createNotification (){
            try {
                let params = {
                  notification: `Hola ${this.user.user_data.fullName},hemos encontrado propiedades relacionadas con tu alerta de
                  acuerdo a los criterios específicos que nos proporcionaste. Esperamos que se
                  adapten a tu necesidad para que muy pronto puedas rentar con beneficios a
                  través de
                  `,
                  important: 1,
                  user_rol_id: this.currentRolComplete.user_rol_id,
                  notification_type: 'Broker',
                  rental_process_id: 0,
                  idTemplate:0
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    console.log('war thunder')
                }
            } catch (error) {
                console.error(error);
            }  
        },
        async getPorperty () {
            try {
                this.isLoad = true
                const params = {
                    property_id: Number(this.idProperty),
                    type: 'Propiedad'
                }
                const response = await axiosTrocutServer.post('/getProperties', params)
                if (response.status === 200) {
                    const {generalPropertyData,broker_info,amenities,property_address,property_media,rental_conditions} = response.data.properties[0]
                    this.isLoad =false
                    this.property = response.data.properties[0]
                    this.hasPet = generalPropertyData.pets
                    this.hasVigenci = rental_conditions.contract_term
                    this.isEnable = moment(rental_conditions.init_contrat).format('YYYY-MM-DD')
                    this.hasDeposit = rental_conditions.months_of_deposit
                    this.hasOthers = rental_conditions.other
                    this.mediaProperty = property_media
                    this.plat = Number(property_address.latitude)
                    this.plng =  Number(property_address.longitud)
                    this.center = {
                        lat: this.plat,
                        lng: this.plng
                    }
                    this.markers.push({
                        pov: {
                            heading: 34,
                            pitch: 10,
                            zoom: 1
                        },
                        position: {
                            lat: Number(property_address.latitude), 
                            lng: Number(property_address.longitud)
                        },
                    })                  
                    this.propertyServices = amenities.filter(el => {
                        return el.type === 'Caracteristica'
                    })
                    this.propertyAmenities = amenities.filter(el => {
                        return el.type === 'Amenidad'
                    })
                    /* for (let  i = 0;  i <  3;  i++) {
                        this.mediaProperty.push(response.data.properties[0].property_media)
                    } */
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getDataPayment(arg){
            try {
               let params = {
                    tenant_id: this.currentRolComplete.user_rol_id,
                    id_property: arg
                }
                const response = await axiosTrocutServer.post('/getPayment',params)
                if(response.status === 200){
                    response.data.metadata.forEach(el => {
                        this.payment.push(el)
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getBroker (id){
            try {
                const params = {
                   user_rol_id: id
                }
                const response = await axiosTrocutServer.post('/getBroker', params)
                if(response.status === 200){
                    this.brokerArra.brokerName = response.data.user_data.fullName,
                    this.brokerArra.url = response.data.user_data.media.url,
                    this.brokerArra.rentas=response.data.rental_success.total
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getQuestions () {
            try {
                const params = {
                    property_id: this.idProperty
                }
                const response = await axiosTrocutServer.post('/getQuestionsProperty', params)
                response.data.forEach(el => {
                    if (el.date_question !== undefined) {
                        el.date_question = moment(el.date_question).format('YYYY-MM-DD')
                    }
                    if (el.date_response !== undefined) {
                        el.date_response = moment(el.date_response).format('YYYY-MM-DD')
                    }
                })
                this.questionsProperty = response.data
            } catch (error) {
                console.error(error);
            }
        },
        async sendQuestion () {
            try {
                const params = {
                    property_id: this.idProperty,
                    question: this.question
                }
                const response = await axiosTrocutServer.post('/registerQuestion', params)
                if(response.status === 200) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Tu pregunta ha sido enviada. En breve recibirás una respuesta en tu correo.`,
                        type: 'success'
                    })
                    this.question = ''
                    this.getQuestions()
                }
            } catch (error) {
                console.error(error);
            }
        },
        async responseQuestion (...arg) {
            try {
                const params = {
                    response:this.responses.responseBroker,
                    question_id:arg[0]
                }
                const response = await axiosTrocutServer.post('/registerQuestion', params)
                if(response.status === 200) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Haz respondido una pregunta satisfactoriamente`,
                        type: 'success'
                    })
                    this.getQuestions()
                }
            } catch (error) {
                console.error(error);
            }
        },
        formatCurrency (amount) {
            return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(amount)
        },
        async addTour(arg){
            try {
                let params = {
                    tenant_id: this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : this.currentRolComplete.user_rol_id,
                    broker_id: arg.broker_id,
                    property_id: arg.property_id
                }
                const response = await axiosTrocutServer.post('/addTour',params)
                if(response.status === 200){
                   console.log('De todos los infortunios que afligen a la humanidad el más amargo es que hemos de tener cociencia de mucho y control de nada');
                }
            } catch (error) {
                console.error(error);
            }
        },
        contac (...arg) {
            if(this.currentRolComplete.rol === 'Propietario'){
               window.open(`https://api.whatsapp.com/send?phone=${arg[0]}&text=!Hola%20Soy%20Dudas%20acerca%20de%20TROKUT`)

            } if (this.currentRolComplete.rol === 'Broker'){
                window.open(`https://api.whatsapp.com/send?phone=${arg[0].owner_info[0].phone}&text=!Hola%20Esto%20Dudas%20acerca%20de%20TROKUT`)

            } else {
                window.open(`https://api.whatsapp.com/send?phone=${arg[0].broker_info[0].phone}&text=!Hola%20Tengo%20Dudas%20acerca%20de%20TROKUT`)
                this.addTour(arg[0])
            }
            
        },
        async getRentalProcess(){
            try {
                this.propertyInfo = []
                let params = {
                    tenant_id:this.currentRolComplete.user_rol_id,
                    status_process_id: 1
                }
                const response = await axiosTrocutServer.post('/getRentalProcess',params)
                if(response.status === 200){
                    const result = response.data.response
                    const filter = result.filter( el => parseInt(el.property_id) === parseInt(this.idProperty))
                    this.processRent = filter.filter( el => parseInt(el.tenant_id) === parseInt(this.currentRolComplete.rol))
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    async mounted() {
        this.idProperty = this.$route.params.property_id
        await this.getPorperty()
        const store = this.getStoreUser.roles
        if(store !== undefined){
            this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        }
        await this.getQuestions()
        this.auth = this.Auth
        this.hour = moment().format('HH:mm')
        this.date_init = moment().format('YYYY-MM-DD')
        this.descripcion = this.property.descripcion
        if (this.Auth) {
            if(this.currentRolComplete.rol === 'Inquilino'){
                await this.getRentalProcess()
            }
            await this.getBroker(this.property.broker_id)
            await this.getDataPayment()
        }
    }
}
