import brokerClient from '@/components/broker/prospectosBroker'

export default {
  components: {
    brokerClient   
  },
  data(){
    return{
     
    }
  }
};
