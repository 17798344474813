import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/example/asset/prism.css'
import advancedFilter from '@/components/generals/searchAdvance'
import router from '@/router'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapActions, mapGetters } from "vuex"
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import {  validationMixin } from 'vuelidate' 
import { helpers,required } from "vuelidate/lib/validators"
// Don't forget to include the Bootstrap CSS/SCSS files!
import 'vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.css'

export default {
    prop:['infoData'],
    mixins: [ validationMixin ],
    components: {
        Multiselect,
        advancedFilter,
        VueSlider,
        VueBootstrapTypeahead
    },
    name: "app",
    data () {
      return {
        currentRolComplete:[],
        test:0,
        address: '',
        rooms: 0,
        windowWidth: window.innerWidth,
        currentAddresses: [],
        valueCity: '',
        value:[{address:'Queretaro'}],
        valuePets: '',
        valueRooms: '',
        searchOptions: [{
          address:'',
          city_id:0,
          neigthborhood_id:0,
          town_id:0
        }],
        petsD:[{
            value: 1,
            name:'Sí'
          },
          {
            value: 0,
            name:'No'
          }
        ],
        roomsD:[{value:1,room:'1'},{value:2,room:'2'},{value:3,room:'3'},{value:4,room:'4'},{value:5,room:'5+'}],
        optionsSuburbs: [],
        valueSuburb: '',
        valuePrice: [7500, 80000],
        format: (value) => {
          let val = (value/1).toFixed(2).replace(',', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        nameRoute: '',
        min: 7500,
        max: 80000,
        minimun: 0,
        options: {
          dotSize:18,
          width: 'auto',
          height: 6,
          contained: false,
          direction: 'ltr',
          data: null,
          dataLabel: 'label',
          dataValue: 'value',
          min: 7500,
          max: 80000,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: 'active',
          tooltipPlacement: 'top',
          tooltipFormatter: void 0,
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange: void 0,
          maxRange: void 0,
          order: true,
          marks: false,
          dotOptions: void 0,
          dotAttrs: void 0,
          process: true,
          dotStyle: void 0,
          railStyle: void 0,
          processStyle: void 0,
          tooltipStyle: void 0,
          stepStyle: void 0,
          stepActiveStyle: void 0,
          labelStyle: void 0,
          labelActiveStyle: void 0
        }
      }
  },
  validations() {
    return {
      min: { required, priceNumber: helpers.regex('priceNumber',/^\d+$/) },
      max: { priceNumber: helpers.regex('priceNumber',/[0-9]/) },
      address:{ address: helpers.regex('address',/^[^+*-{}[]?]|[a-z]|[0-9]$/) }
    }
  },
  watch: {
    min(arg) {
      this.$v.min.$touch()
      console.log(this.$v.min)
      if(this.$v.min.$invalid){
        this.min = 7500
      }else{
        const max = this.valuePrice[1]
        this.valuePrice = []
        if(arg < 7500){
          this.valuePrice.push(7500, max)
        }else {
          if((this.max < parseInt(arg)) && max > 0){
            this.min = this.max
            this.max = parseInt(arg)
            this.valuePrice.push( max,parseInt(arg))
            if(this.$refs['max'] !== undefined){
              this.$refs['max'].focus()
            }
            this.$refs['minP'].focus()
          } else {
            this.valuePrice.push( parseInt(arg),max)
          }
        }
      }
    },
  max(arg) {
    const min = this.valuePrice[0]
    this.valuePrice = []
    if(arg < 7500 ){
      this.valuePrice.push(min, 7500)
    } else if (arg > 80000){
      this.max = 80000
      this.valuePrice.push(min, 80000)
    }else {
      if(min > parseInt(arg) && parseInt(arg) > 0){
        this.valuePrice.push(parseInt(arg), min)
        this.max = min
        if(this.$refs['maxP'] !== undefined){
          this.$refs['maxP'].focus()
        }
        this.min = parseInt(arg)
      } else {
        this.valuePrice.push(min, parseInt(arg))
      }
    }
  },
  valuePrice(arg){
    if(this.max < this.min){
      const maximo = this.max
      this.min = maximo
      this.max = arg[0]
    }else if(this.min >= 7500 && this.max <= 80000){
      this.max= arg[1]
      this.min= arg[0]
    }
  },
    test(val){
      if(val !== undefined){
        this.min= val[0]
        this.max= val[1]
      }
    }
  },
  computed: {
    ...mapGetters({
      storeSearch: 'getStoreSearch',
      getCurrentRol: 'getCurrentRol',
      getRol:'getRol',
      getStoreUser:'getStoreUser'
    })
  },
  methods: {
    ...mapActions({
      setGeneralDataSearch: 'setGeneralDataSearch'
    }),
    nameWithLangCity ({ address }) {
      return `${address}`
    },
    nameWithPet ({ name }) {
      return `${name}`
    },
    nameWithLRooms ({ room }) {
      return `${room}`
    },
    addTag (newTag) {
      const tag = {
        address: newTag
      }
      this.value.push(tag)
    },
    modalAdvance(){
      this.$modal.show('modal-advanced')
    },
    search() {
      this.$v.address.$touch()
      if(this.$v.address.$invalid){
        this.address = ''
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp; favor de ingresar una direccion correcta`,
          type: 'error'
        })
      }else {
        if(this.min < 7500 ){
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; El monto minimo de renta es de $ 7,500.00 mxn.`,
            type: 'error'
          })
        } else if (this.max < 7500 ){
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; El monto minimo de renta es de $ 7,500.00 mxn.`,
            type: 'error'
          })
          if(this.max < this.min){
            const maximo = this.max
            this.min = maximo
            //this.max = arg[0]
          }
        }else{
          const option = this.searchOptions.find(el => {
            return el.address === this.address
          })
          if (option !== undefined) {
            this.valueCity = option.city_id
            this.valueSuburb = option.neighborhood_id
          }
          let pets = this.valuePets === '' ? null : this.valuePets.value
          this.valuePrice[0]=this.min
          this.valuePrice[1]=this.max
          this.setGeneralDataSearch({
            prices: this.valuePrice,
            township: this.valueCity,
            suburb: this.valueSuburb,
            direccionCompleta: this.address,
            rooms: this.valueRooms === null ? this.valueRooms : this.valueRooms.value,
            pets: pets,
            tenant_id: this.currentRolComplete.user_rol_id
          })
          if(this.$route.name !== 'resultSearch') {
            router.push({ name: 'resultSearch'}) 
          }
        }
      }
       
    },
    async getInventary (){
      try {
        
        const response = await axiosTrocutServer.post('/getInventaryAddress') // listado de inventario actual para multiSelect
        response.data.forEach(el => {
          if (el.address !== null) {
            this.searchOptions.push(el)
            this.currentAddresses.push(el.address)
          }
        })
      } catch (error) {
        console.error(error);
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      })
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    async loadParameters () {
      await this.sleep(1000)
      this.valuePrice[0] = this.storeSearch.prices[0]
      this.valuePrice[1] = this.storeSearch.prices[1]
      this.address = this.storeSearch.direccionCompleta
      this.$refs.townTypeahead.inputValue = this.storeSearch.direccionCompleta
    }
  },
  mounted() {
    this.nameRoute = this.$route.name
    const store = this.getStoreUser.roles
    if(store !== undefined){
      this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
    }
    this.getInventary()
    if (this.nameRoute === 'resultSearch') {
      this.loadParameters()
    }
    setTimeout(() => {
      this.test = this.storeSearch.prices
    }, 1000)
      this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }
}
