import Multiselect from "vue-multiselect"
import { validationMixin } from 'vuelidate'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { required,email } from "vuelidate/lib/validators"
import moment from "moment"

export default {
  mixins:[validationMixin],
    components:{
        Multiselect
    },
    data (){
        return{
            asunto:'',
            asuntoSelect:['Falla técnica en la plataforma','Reporte de propiedad','Consulta general','Otro']
            ,contacInfo:{
                email:'',
                fullName:'',
                explication:''
            },
            submitted: false
        }
    },
    validations:{ 
        contacInfo:{
            email:{ required, email },
            fullName:{ required },
            explication:{ required }
        }
    },
    computed: {
        isDisabled () {
          return this.$v.$invalid
        }
    },
    methods:{
        async contactM(){
            this.submitted = true
            try {
                this.$v.contacInfo.$touch()
                if(!this.$v.contacInfo.$invalid){
                    let payload = {
                        affair : this.asunto ,
                        email : this.contacInfo.email ,
                        fullName : this.contacInfo.fullName,
                        affairAdditional : this.contacInfo.explication,
                        createAt: moment().format('YYYY-MM-DD') 
                    }
                    const response = await axiosTrocutServer.post('/createContact',payload)
                    if(response.status === 200){
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp; Gracias por contactar al equipo de trocut, en breve nos cominicaremos contigo..`,
                            type: 'success'
                          }) 
                    }
                    this.contacInfo={
                        email:'',
                        fullName: '',
                        explication: ''
                    }
                    this.diasableY = true
                } else {
                    console.error('error');
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted () {

    }
}