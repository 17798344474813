// import 'vue-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import BestProperties from '@/components/generals/best-properties'
import axiosTrocutServer from '@/axios/axiosTrocutServer'

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        BestProperties
    },
    name: "best-Properties",
    data () {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            breakpoints: {
                // 300px and up
                300: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 400 and up
                435: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
                // 700px and up
                700: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                }
            },
            properties:[],
            windowWidth: window.innerWidth,
            propertyRandom:[]
        }
    },
    watch: {
        loadResolution(){
            this.onResize
        }
    },
    computed: {
    },
    methods: {
        async getRandomProperties () {
            try {
                const params = {
                    amount_rent: [7500, 30000]
                }
                const response = await axiosTrocutServer.post('/getProperties', params)
                let l = response.data.properties.length
                if( response.status === 200 ){   
                    const arreglo = await response.data.properties.sort(() => { return Math.random() - 0.5 })
                    let cont = 0
                    let arrTemp = []
                    for (const key in arreglo) {
                    if ( arreglo[key].property_media.length > 0) {
                            arrTemp.push({
                                property_id: arreglo[key].property_id,
                                url: arreglo[key].property_media[0].url !== undefined ? arreglo[key].property_media[0].url : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pngwing.com%2Fen%2Ffree-png-nxjzt&psig=AOvVaw3JhQdziqoq5LzTaRxBKlYG&ust=1651002827689000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCKjY2P7-r_cCFQAAAAAdAAAAABAE'
                            })
                            if (cont === 5) {
                                this.properties.push(arrTemp)
                                arrTemp = []
                            }
                            cont++
                        }   
                    }
                    if (arrTemp.length < 6) {
                        const tamano = arrTemp.length
                        const faltantes = 6 - tamano
                        for (let i = 0; i < faltantes; i++) {
                            arrTemp.push({
                                property_id: 0,
                                url: 'https://www.javer.com.mx/images/desarrollos/1637260542_fachada%20modelo%20aguila%20en%20valle%20de%20los%20encinos%20pllus%20800x600.jpg'
                            })
                        }
                        this.properties.push(arrTemp)
                       let  arrFaltante = [...this.properties[0]]
                       this.propertyRandom = arrFaltante
                    }
                }
            } catch (error) {
                console.error(error);
            }
           
        },
        goPropertyProfile (property_id) {
            if (property_id !== 0) {
                this.$router.push({
                    name:'propertyProfile',params: {property_id: property_id}}).catch(()=>{})   
            } else {
                this.$toastedPush({
                    message: ` &nbsp;&nbsp;&nbsp; Lo sentimos esta propiedad no esta disponible..`,
                    type:`info`
                })
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
    },
    mounted () {
       this.getRandomProperties()
       this.$nextTick(() => {
        window.addEventListener('resize', this.onResize)
      })
    }
}

