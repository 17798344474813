import tourAgendado from '@/components/broker/modalAgendarTour'
import seeModal from '@/components/broker/modalCondiciones'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from 'vuex'
import moment from 'moment'
import pdfGenerator from '@/components/generals/contratModule'

export default {
    components:{
        tourAgendado,
        seeModal,
        pdfGenerator
    },
    data () {
        return{
          currentRolComplete:[],
            Solicitados:[],
            Finalizados:[],
            prospectos:[],
            agendados:[],
            investigacion:[],
            cierre:[],
            seletedTour:[],
            idBroker:0,
            propertyId:0,
            tour_id:0,
            propertyInfoNew:[],
            nameOwner:'',
            nameBroker:''
        }
    },computed:{
        ...mapGetters({
            user: 'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        })
    },
    methods: {
        showModal (row) {
            this.seletedTour = row
            this.$modal.show('modal-agendar')
        },
        async cancelTourBroker (id) {
            try {
                let params = {
                    broker_id: this.currentRolComplete.user_rol_id,
                    property_id: id
                }
                const response = await axiosTrocutServer.post('/cancelTour',params)
                if (response.status === 200 ){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;Se cancelo el tour.`,
                        type: 'success'
                    })
                    this.getProspectos()
                } else {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Uups no se podido cancelar el tour favor de intentalo mas tarde.`,
                        type: 'error'
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        showModalCongetRentalProcessditions (idP,idtour) {
            this.propertyId = idP
            this.tour_id = idtour
            this.$modal.show('modal-condiciones')
        },
        async getProspectos(){
            this.Solicitados = []
                this.Finalizados =[]
                this.prospectos = []
                this.agendados= []
                this.investigacion = []
                this.cierre = []
            try {
                let params = {
                    broker_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getAddititonalBrokerInfo',params)
                if(response.status === 200){
                    response.data.solicitados.forEach(el => {
                        this.Solicitados.push(el)
                    })
                    response.data.finalizados.forEach(el => {
                        this.Finalizados.push(el)
                    }); 
                    response.data.prospectos.forEach(el => {
                        this.prospectos.push({
                            init_date: moment(el.init_date).format('YYYY-MM-DD'),
                             status_process_id:el.status_process_id ,
                              comments_id: el.comments_id,
                               property_id: el.property_id, 
                               status_id: el.status_id, 
                               tenant_id: el.tenant_id, 
                               broker_id: el.broker_id,
                                key_property:el.key_property, 
                                last_update: el.last_update, 
                                process_status_id: el.process_status_id, 
                                status: el.status, 
                                Broker: el.broker, 
                                Tenant: el.Tenant,
                                email: el.email, 
                                phone: el.phone,
                                comment:el.comment
                        })
                    });
                    response.data.cierre.forEach(el => {
                        this.cierre.push(el)
                        this.cierre[0].init_date= moment(this.cierre[0].init_date).format('YYYY-MM-DD')

                    });
                    response.data.investigacion.forEach(el => {
                        this.investigacion.push({init_date: moment(el.init_date).format('YYYY-MM-DD'),
                        status_process_id:el.status_process_id ,
                         comments_id: el.comments_id,
                          property_id: el.property_id, 
                          status_id: el.status_id, 
                          tenant_id: el.tenant_id, 
                          broker_id: el.broker_id,
                           key_property:el.key, 
                           last_update: el.last_update, 
                           process_status_id: el.process_status_id, 
                           status: el.status, 
                           Broker: el.broker, 
                           Tenant: el.Tenant,
                           email: el.email, 
                           phone: el.phone})
                    });
                    response.data.agendados.forEach(el => {
                        this.agendados.push({
                            tour_id: el.tour_id, 
                            date: moment(el.date).format('YYYY-MM-DD'),
                            time: el.time, 
                            tenant_id: el.tenant_id, 
                            broker_id: el.broker_id, 
                            property_id: el.property_id, 
                            key_property: el.key_property, 
                            status_id: el.status_id,
                           status: el.status,
                           tenant: el.tenant, 
                            email: el.email, 
                            phone: el.phone,
                            comment:el.comment
                        })
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        goToProperty (id) {
            this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})

        },
        emittedPdf(){
            console.log('emit enviado');
        },
        generatePdf(...arg){
            this.propertyInfoNew = []
            this.propertyInfoNew = arg[0]
            this.nameBroker = this.propertyInfoNew.Owner
            this.nameOwner = this.propertyInfoNew.Broker
            this.emittedPdf()  
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.getProspectos()
    }
}


