
import { validationMixin } from 'vuelidate'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import moment from 'moment'
import { required, minLength, minValue, helpers } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect"
import { mapGetters } from 'vuex'



export default {
  mixins: [validationMixin],
  components:{
    Multiselect
  },
  props:{
  },
  data () {
    return {
      currentRolComplete: [],
      submitted: false,
      optionState: [],
      optionstownship: [],
      addressError:false,
      errorInfo:false,
      optionsCity: [],
      isDisabled:true,
      optionsneighborhood: [],
      today: '',
      infoUser:{
        name: '',
        lastname: '',
        lastname2: '',
        birthday:  '',
        nationality: 'Mexico',
        generalInfoNumber: 0
      },
      infoUserAddress: {
        state: '',
        township:  '',
        city:  '',
        neighborhood: '',
        street:  '',
        postalCode : 0,
        exteriorNumber: '',
        interiorNumber: 0
      }
    }
  },
  validations (){
    return {
        infoUserAddress: {
            state:{ required } ,
            township: { required } ,
            city: { required } ,
            neighborhood:{ required} ,
            exteriorNumber: { minvalue: minValue(1),required },
            postalCode: { required, minLengthValue: minLength(5) }
        },
        infoUser:{
            name: { required },
            lastname:{ required },
            lastname2:{ required },
            birthday: { required }
        },
    }
    //isNameValid: helpers.regex('isNameValid',/^[a-zA-Z\s]*$/)
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      getStoreUser:'getStoreUser',
      getCurrentRol:'getCurrentRol'
    })
  },
  methods: {
    nameWithStateOwner({ name }) {
      return `${name}`
    },
    nameWithTownshipOwner({ name }) {
        return `${name}`
    },
    nameWithCity({ name }) {
      return ` ${name}`
    },
    nameWithNeigthborhood({ name }) {
      return `${name}`
    },
   async compareData(){
       try {
            let valid = true
            this.$v.infoUserAddress.$touch()
            this.$v.infoUser.$touch()
            this.errorInfo = false
            this.addressError = false
            if(this.$v.infoUser.$anyError){
                valid = false
                this.errorInfo = true  
            }
            if(this.$v.infoUserAddress.$anyError){
                valid = false
                this.addressError = true   
                //this.getStates()
            }
            if(valid){
                this.isDisabled = false
                const x = await this.savePersonalInfo()
                const y = await this.addAdressOwner()
                if(x && y){
                    this.isDisabled = true
                    this.$emit('getAddress')
                }else{
                    this.isDisabled = true
                }
                return valid
            }
        } catch (error) {
            console.error(error)
            this.isDisabled = true
            this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp; Favor de llenar los datos marcados con **`,
                type: 'error'
            })
       }
    },
    async getStates() {
        try {
            const response = await axiosTrocutServer.post('/getStates')
            if (response.status === 200) {
                response.data.states.forEach(el => {
                    this.optionState.push({
                        name: el.state,
                        value: el.state_id,
                        abbre:el.abbreviation
                    })
                })
            }
        } catch (error) {
            console.error('get States', error)
        }
    },
    async getTownhips(...arg) {
        try {
            console.log('entro',arg)
            this.optionstownship = []
            const params = {
                state_id: arg[0].value
            }
            const response = await axiosTrocutServer.post('/getTownship', params)
            if (response.status === 200) {
                response.data.township.forEach(el => {
                    this.optionstownship.push({
                        name: el.township,
                        value: el.township_id
                    })
                })
            }
        } catch (error) {
            console.error('error', error)
        }
    },
    async getCities(...arg) {
        try {
            this.optionsCity = []
            let params = {
                town_id: arg[0].value
            }
            const response = await axiosTrocutServer.post('/getCities', params)
            if (response.status === 200) {
                response.data.cities.forEach(el => {
                    this.optionsCity.push({
                        name: el.city,
                        value: el.city_id
                    })
                })
            }
        } catch (error) {
            console.error('error', error)
        }
    },
    async getNeighborhood(...arg) {
        try {
            this.neighborhood = []
            let params = {
                city_id: arg[0].value,
                stock: false
            }
            const response = await axiosTrocutServer.post('/getNeighborhoods', params)
            if (response.status === 200) {
                response.data.neighborhood.forEach(el => {
                    this.optionsneighborhood.push({
                        name: el.neighborhood,
                        value: el.neighborhood_id
                    })
                })
            }
        } catch (error) {
            console.error('error', error)
        }
    },
    async completeInfoBase (infoBase,addresc){
      try {
          let params = {
            complete_info_base: infoBase,
            complete_address: addresc,
            user_id:this.user.user.user_id
          }
          const response = await axiosTrocutServer.post('/updateInfoBase',params)
          if(response.status === 200){
                this.isDisabled = true
            }
      } catch (error) {
        console.error(error)
    }
  },
  async addAdressOwner() {
        try {
            this.submitted = true
            let payload = {
                    street:this.infoUserAddress.street,
                    suburb_id:this.infoUserAddress.neighborhood.value,
                    city_id: this.infoUserAddress.city.value,
                    town_id: this.infoUserAddress.township.value,
                    state_id: this.infoUserAddress.state.value,
                    interior_number: parseInt(this.infoUserAddress.interiorNumber),
                    outdoor_number:parseInt( this.infoUserAddress.exteriorNumber),
                    user_rol_id: this.currentRolComplete.user_rol_id  !== undefined ? this.currentRolComplete.user_rol_id : this.user.roles[0].user_rol_id ,
                    address_type: "User" ,
                    postal_code: this.infoUserAddress.postalCode ,
                    new: true,
                    user_id: this.currentRolComplete.user_id
                }
                this.$v.infoUserAddress.$touch()
            if (!this.$v.infoUserAddress.$invalid) {
                let response = await axiosTrocutServer.post('/addAddress', payload)
                if (response.status === 200) {
                    this.completeInfoBase(1,1)
                } 
                if(response.status === 400) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;Algo ocurrio,al momento de registrar la direccion por favor, intentelo de nuevo.`,
                        type: 'error'
                    })
                }
                return response
            }
            this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;favor de rellenar los campos marcados con * `,
                type: 'error'
            })
        } catch (error) {
          console.error('error en : ',error)
            this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;favor de rellenar los campos marcados con * `,
                type: 'error'
            })
        }
    },
    async savePersonalInfo () {
      try {
        this.submitted = true
        this.$v.infoUser.$touch()
        if(!this.$v.infoUser.$invalid) {
              let payload = {}
              var nacimientoMoment = moment (this.infoUser.birthday).format('YYYY-MM-DD')
              var actualidadMoment = moment().format('YYYY-MM-DD')
              var nacimiento = new Date(nacimientoMoment)
              var actualidad = new Date(actualidadMoment)
              var edad = Math.abs(nacimiento.getFullYear() -actualidad.getFullYear())
              if(edad >= 18){
                  payload = {
                      user_id: this.user.user.user_id ,
                      name: this.infoUser.name ,
                      lastname: this.infoUser.lastname ,
                      lastname2: this.infoUser.lastname2 ,
                      birthday: this.infoUser.birthday = moment (this.infoUser.birthday).format('YYYY-MM-DD')
                  }
                    const response = await axiosTrocutServer.post('/registerUserData',payload)
                    if(response.status === 200){
                        this.$emit('changeStep')
                    }
                    console.log(response)
                    //return response

                  } else {
                  payload = {
                      user_id: this.user.user.user_id,
                      name: this.infoUser.name ,
                      lastname: this.infoUser.lastname ,
                      lastname2: this.infoUser.lastname2 ,
                      birthday: this.infoUser.birthday = moment (this.infoUser.birthday).format('YYYY-MM-DD') ,
                      is_foreign: 0 ,
                      rfc: ' ',
                      nationality: 'Mexico'
                  }
                  this.$toastedPush({
                      message: `&nbsp;&nbsp;&nbsp;debes de tener la mayoria de edad para poder seguir con el proceso` ,
                      type: 'error'
                  })
              }
          }
      } catch (error) {
        console.error(error)
      }
    },
    async getAddresOwner() {
      try {
          let params = {
              user_id : this.currentRolComplete.user_id,
              address_type : 'User'
            }
          if(this.currentRolComplete.user_rol_id !== undefined) {
              const response = await axiosTrocutServer.post('/getAddress', params)
              if(response.status === 200){
                  response.data.address.forEach(el => {
                    this.infoUserAddress.postalCode = el.postal_code,
                    this.infoUserAddress.state = { value: el.state_id , name: el.state },
                    this.infoUserAddress.township = { value: el.town_id, name: el.township },
                    this.infoUserAddress.city = { value: el.city_id, name: el.city },
                    this.infoUserAddress.neighborhood = { value: el.suburb_id, name: el.suburb },
                    this.infoUserAddress.street = el.street,
                    this.infoUserAddress.interiorNumber = el.interior_number = 0,
                    this.infoUserAddress.exteriorNumber = el.outdoor_number
                    this.priceRenta = el.minimalPrice
                    this.dateInit = moment(el.init_contrat).format('YYYY-MM-DD') 
                    this.deposit = el.months_of_deposit 
                    this.contrat = el.contract_term
                    this.diasablePets = el.pets
                    this.idAddressOwner = el.address_id
                    this.fillMultiSelect(el)
                })
              }
          }
      } catch (error) {
          console.error(error)
      }
    },
    fillMultiSelect(data){
        this.getTownhips(data.state_id)
        this.getCities(data.town_id),
        this.getNeighborhood(data.city_id)
    } ,
    validateState(name,type){
        let data = []
      if (type === 'infoUser') {
        data = this.$v.infoUser[name]
      }if(type === 'infoUserAddress'){
        data = this.$v.infoUserAddress[name]
      }
      const { $dirty, $error } = data
      return $dirty ? !$error : null
    },
    async getUserData(){
        try {
            let payload = {
                user_id : this.user.user.user_id
            }
            const response =  await axiosTrocutServer.post('/getUserData',payload)
            if(response.status === 200){
                this.infoUser = response.data.response !== undefined ? response.data.response: []
                this.infoUser.birthday = this.infoUser.birthday !== undefined ? moment(response.data.response.birthday).format('YYYY-MM-DD') : ''
            }
        } catch (error) {
            console.error(error)
        }
    }
  },
  mounted() {
    const store = this.getStoreUser.roles
    this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    this.getUserData()
    this.today = moment().format('YYYY-MM-DD')
    this.getAddresOwner()
    this.getStates()
  }
}