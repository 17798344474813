import { render, staticRenderFns } from "./template.html?vue&type=template&id=6f2a0592&scoped=true&"
import script from "./controller.js?vue&type=script&lang=js&"
export * from "./controller.js?vue&type=script&lang=js&"
import style0 from "./styles.css?vue&type=style&index=0&id=6f2a0592&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2a0592",
  null
  
)

export default component.exports