import propertie from '@/components/owner/propertyOwner'

export default {
  components: {
    propertie   
  },
  data(){
    return{
     
    }
  }
};
