import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import BestProperties from '@/components/generals/best-properties'
import { mapGetters, mapActions , mapMutations } from "vuex"


export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
    ,BestProperties
  },
  props:{
    brokersProp:[]
  },
  data () {
    return{
      urlI: '',
      bokersArr: [],
      selectBroker: false
    }
  },
  methods: {
    ...mapActions({
        setBroker:'setBroker',
        clearBroker:'clearBroker'
    }),
    selectedBroker(slide){
      for (let i = 0; i < this.brokersProp.length; i++) {
         if(this.brokersProp[i].idBroker !== slide.user_rol_id){
          this.$refs[slide.user_rol_id][0].className = 'card'
          this.selectBroker = true
          //this.clearBroker()
        }else {
            this.selectBroker = true
            this.clearBroker()
            this.$refs[slide.user_rol_id][0].className = 'card undselectedBroker'
          }
      }
      this.setBroker({slide})
      this.$emit('getAgain')
    } 
  },
  mounted (){
    setTimeout(() => {
      this.brokersProp
    }, 3000)
  }
}
