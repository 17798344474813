import modalRent from '@/components/tenant/rentModal'
import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import moment from 'moment';
import { mapGetters } from 'vuex';
import shareM from '@/components/generals/share-modal'

export default {
    components:{
        modalRent,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        shareM
    },data () {
        return{
            currentRolComplete: [],
            finished: [],
            idTour:0,
            scoreLocal: 0,
            iniContrat: '',
            termContrat: '',
            mounts: 0,
            isActive: false,
            calificacionP: 0,
            tour_id: 0,
            teempDate: new Date,
            idP: [],
            brokerId: 0,
            dateInit: '',
            commentText: ''
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getRol:'getRol',
            getCurrentRol: 'getCurrentRol',
            getStoreUser:'getStoreUser'
        }),
    },
    methods: {
        modalTerms(id) {
            console.log(`estos son los argumentos ${JSON.stringify(id)}`);
            this.idP = id,
            this.idTour = 0
            this.$modal.show('modal-terms')
            this.selectedProperty()
        },
        closeTerms() {
            this.$modal.hide('modal-terms')
        },
        async aceptConditions (propertyKey){
            try {
                let params = {
                    notification: `Hola ${this.user.user.email}, has aceptado las condiciones de renta de la propiedad ${propertyKey}
                    Ingresa a trocut.com para continuar con el proceso de renta.
                    El equipo Trócut`,
                    importantId: 1,
                    user_rol_id: this.currentRolComplete.user_rol_id,
                    notification_type: 'Inquilino',
                    rental_process_id: 0,
                    idTemplate: 3,
                    idProperty: this.idP.property_id,
                    tour_id: this.idP.tour_id,
                    userId: this.user.user_data !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : ''
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; notificacion lanzada`,
                        type: 'success'
                      })
                      //this.sendMail(this.idP)
                }
            } catch (error) {
                console.error(error);
            }  
              
        },
        async selectedProperty (){
            try {
                let params = {
                    notification: `Hola ${this.user.user.email},¡Genial!, has seleccionado exitosamente la propiedad
                    para rentar. Una vez que completes tu información en la Solicitud de
                    arrendamiento, llevaremos a cabo el proceso de investigación correspondiente y
                    te avisaremos del resultado`,
                    importantId: 1,
                    user_rol_id: this.currentRolComplete.user_rol_id,
                    notification_type: "Inquilino",
                    rental_process_id: 0,
                    idTemplate: 47,
                    userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : this.user.user.email
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
                if(response.status === 200 ){
                    //this.sendMail(this.idP)
                }
            } catch (error) {
                console.error(error);
            }  
              
        },
        async modalRent() {
            this.aceptConditions(this.user.user_data.fullName,this.finished[0].property[0].key_property)
            this.$modal.show('modal-rent')
            this.$modal.hide("modal-terms")
        },
        async cancelTours () {
            try {
                let payload = {
                    tour_id : this.tour_id
                }
                const response = await axiosTrocutServer.post('/cancelTour',payload)
                if(response.status === 200){
                    this.$emit('loadRentalProcess')
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;se cancelo el tour de manera correcta.`,
                        type: 'success'
                    })
                }
            } catch (error) {
                console.error('error',error)
            }
        },
        tourScore (...arg) {
            if(arg[0].score > 0){
                this.$modal.show("modal-eliminacion")
                this.tour_id = arg[0]
            } else {
                this.$modal.show("modal-calificacion")
                this.tour_id = arg[0].tour_id
                this.brokerId = arg[0].broker_id
            }
        },
       async getToursFinished(){
            try {
                this.finished = []
                let payload = {
                    tenant_id: this.currentRolComplete.user_rol_id,
                    status_id: 3
                }
                let response = await axiosTrocutServer.post('/getTours',payload)
                if (response.status === 200) {
                    response.data.response.forEach(el => {
                        this.finished = []
                        this.finished.push(el)
                        this.scoreLocal = el.broker_price > 0  ? el.broker_price : el.property[0].amount_rent 
                        this.mounts = el.broker_mounts !== null  ? el.broker_mounts : el.property[0].rental_conditions.meses_de_deposito
                        this.iniContrat = el.broker_init_contrat !== null  ? moment(el.broker_init_contrat).format('YYYY-MM-DD') : moment(el.property[0].rental_conditions.disponible_desde).format('YYYY-MM-DD')
                       //console.log(el.broker_term_contrat)
                        this.teempDate = moment(el.date).format('YYYY-MM-DD')
                    })
                    this.closeModalS()
                    this.$emit('loadRentalProcessEmit')
                }
            } catch (error) {
                console.error(error);
            }
        },
        closeModalS(){
            this.$modal.hide("modal-calificacion")
            this.$modal.hide("modal-eliminacion")
            this.$modal.hide("modal-terms")
        },
        scoreProperty(calificacion){
            let cal=this.$refs[calificacion][0].id
            let stars = document.getElementsByClassName('stars')
           for(var h = 0; h < stars.length; h++)
           {
               stars[h].classList.remove('scoreProperty_seleted');
           }
            if(stars.classList !== undefined){
                stars.classList.remove('scoreProperty_seleted')
            }for(var i =1; i <= cal ;i++){
               var el = document.getElementById(i)
               el.classList.add('scoreProperty_seleted')
            
            }
            this.calificacionP = calificacion
        },
       async qualifyTour (){
            try {
                let payload = {
                    score: this.calificacionP,
                    tour_id: this.tour_id,
                    user_rol_id: this.brokerId
                }
                let response = await axiosTrocutServer.post('/qualifyTour', payload)
                if (response.status === 200) {
                   await this.cancelTours()
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; gracias por su calificacion del tour`,
                        type: 'success'
                    })
                    this.addComentBroker(this.brokerId)
                    this.finished= []
                 await   this.getToursFinished()
                }
            } catch (error) {
                console.error(error);
            }
            this.$modal.hide('modal-calificacion')

        },
        async quitarFinzalizado (){
            try {
                let payload={
                    tour_id : this.tour_id
                }
                let response = await axiosTrocutServer.post('/cancelTour',payload)
            if (response === 200){
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; se elimino la propiedad`,
                    type: 'success'
                })
                
            }
            this.$modal.hide('modal-eliminacion')
            } catch (error) {
                console.error(error);
            }
            
        },
        async addComentBroker (arg){
            try {
                let payload={
                    comment: this.commentText,
                    type: 'Broker',
                    id_relation: arg,
                    commentarist: this.user.user_data.fullName,
                    active:1
                }
                const response = await axiosTrocutServer.post('/addComments',payload)
                if (response.status === 200){
                    console.log(response);
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; se envio el comentario al broker`,
                        type: 'success'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            let payload={
                tour_id : this.tour_id
            }
            
        },
        shareProperty (){
            this.$modal.show('share-modal')
        }

    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.finished = []
        this.getToursFinished()
        this.dateInit = moment().format('YYYY-MM-DD')
    }
}