export default{
    name:'owner',
    data () {
        return {}
    },
    methods: {
        know () {
            this.$router.push({
                name: 'index.info.knowUs',
                params: {
                    type: 'Propietario'
                }
            })
        }
    }
}