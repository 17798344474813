//import timeLine from "../../generals/time-line"
import  processRent  from '@/components/tenant/processRent'
import cancelModal from '@/components/tenant/cancelModal'
import Multiselect from "vue-multiselect"
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    components:{
        processRent,
        cancelModal,
        Multiselect
    },
    props:[
        'changeRental'
    ],
    data () {
        return{
            rmotiveRetunMoney:['Seleccione un motivo ','Encontré una mejor oferta','Problemas con el broker','Problemas con el propietario','otros motivos personales'],
            propertyInfo:[],
            currentRolComplete:[],
            causaDevolucion:'',
            idProcess:0,
            idProperty:0,
            tenantId:0,
            payment:[]
        }
    },
    watch:{
        changeRental (){
            this.getData()
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getRol:'getRol',
            getCurrentRol: 'getCurrentRol',
            getStoreUser:'getStoreUser'
        })  
    },
    methods: {
        modalProcess(...arg) {
            this.idProperty = arg[0]
            this.tenantId = this.currentRolComplete.user_rol_id
            this.$modal.show('modal-process')
        },
       async modalCancel(arg) {
            this.idProcess = arg.process_id
            if(this.payment.length > 0 ){
                this.$modal.show('cancel-modal')
                this.getData()
            } else {
                this.finish()
                this.$toastedPush({
                    message:`&nbsp;&nbsp;&nbsp; la propiedad fue cancelada`,
                    type:`success`
                  })
            } 
        },
        closeModal (){
            this.$modal.hide('modal-tabla')
        },
        aceptDestiny(){
            this.$modal.show('modal-fin-cancelacion')
        },
        goToPropertyProfile (id) {
            this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})
        },
       async finish(){
            try {
                //var today = moment().add(30,'d').format('YYYY-MM-DD hh:mm:ss')
                let params = {
                    status_process_id:8,
                    process_id:this.idProcess
                }
                const response = await axiosTrocutServer.post('/updateProcessRent',params)
                if(response.status === 200){
                    this.$modal.hide('cancel-modal')
                    this.$modal.hide('modal-tabla')
                    this.$modal.hide('modal-fin-cancelacion')
                    this.getData()
                }
            } catch (error) {
                console.error(error);
            }
        },
        
        async getData(){
            this.propertyInfo = []
            try {
                let params = {
                    tenant_id:this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getRentalProcess',params)
                if(response.status === 200){
                    this.propertyInfo = []
                    response.data.response.forEach(el => {
                        this.propertyInfo.push(el)
                        this.getDataPayment(el.property_id)
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getDataPayment(...arg){
            this.payment = []
            try {
               let params = {
                    tenant_id: this.currentRolComplete.user_rol_id,
                    id_property: arg
                }
                const response = await axiosTrocutServer.post('/getPayment',params)
                if(response.status === 200){
                    response.data.metadata.forEach(el => {
                        if(el.id_property === arg[0]){
                            this.payment.push(el)
                        }
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        formatCurrency (amount) {
            return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(amount)
        }
    },
    async mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.getData()
        await this.propertyInfo != undefined ? [] : this.propertyInfo[0].property[0].media[0].url
        console.log('changeRental',this.changeRental);
    }
}