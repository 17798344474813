import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import citiesList from '@/mixins/citiesList.js'
import suburbQueretaro from '@/mixins/suburbQueretaro.js'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/example/asset/prism.css'

import  searchHeader  from "@/components/generals/search";

import router from '@/router'

export default {
    components: {
        Multiselect,
        VueSlider,
        searchHeader
    },
    prop:['price'],
    name: "app",
    data () {
        return {
            rooms: 2,
            valuePrice:this.price,
            valueCity: {name: 'Querétaro'},
            optionsCity: citiesList,
            optionsSuburbs: [],
            valueSuburb: {name: 'Juriquilla'},
            value: [7500, 35000],
            max: 40000,
            min: 7500,
            format: (value) => {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        }
    },
    watch: {
        valueCity(arg) {
            this.seearchSuburbs(arg.value)
        }
    },
    computed: {
    },
    methods: {
        nameWithLangCity ({ name }) {
            return `${name}`
        },
        nameWithLangSuburb ({ colonia }) {
            return `${colonia}`
        },
        seearchSuburbs (city_num) {
            this.optionsSuburbs = suburbQueretaro.filter(suburb => {
                return suburb.municipio_num === city_num
            })
        },
        UpdateValues(e) {
            this.barMinValue = e.minValue;
            this.barMaxValue = e.maxValue;
        },
        search () {
            alert('entro al search')
            router.push({ name: 'resultSearch', params: { valuePrice:this.price } })   
        }
    }
}
