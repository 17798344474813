import contacto from  '@/components/home/contact'
export default {
  components: {
    contacto
  },
  data () {
    return{

    }
  }

}
