import register from '@/components/home/register'

export default {
  components: {
    register
  },
  data () {
    return{
      type: ''
    }
  },
  methods: {
    register () {
      this.$modal.show('modal-register')
    },
    ownerFocus() {
     var focusOwner = document.getElementById("owner")
      console.log(focusOwner);
    },
    brokerFocus() {
      document.getElementById("broker")
    }
  },
  mounted () {
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
    this.type = this.$route.params.type
    if (this.type === undefined) {
      this.$router.push({ name: 'index.home' })
    }
    this.brokerFocus()
    this.ownerFocus()
  }
}
