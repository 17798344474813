import { mapGetters } from "vuex"
import {es} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import downBroker from '@/components/generals/downs-Type'
 
export default {
    components:{
        downBroker
    },
    data () {
        return{
          Solicitados:[],
          currentRolComplete:[],
            Finalizados:[],
            prospectos:[],
            investigacion:[],
            cierre:[],
            seletedTour:[],
            propertyInfo:[],
            comision:12450.45,
            notificationA:[],
            brokerInfo:[],
            commentsArray:[],
            pay:0,
            score:0,
            broberId:0,
              brokerOrigin:[ {Date:'Noviembre 26,2021',origin:'Póliza Jurídica',messge:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit deleniti porro ipsam sint, incidunt similique excepturi nulla laudantium perspiciatis sapiente temporibus'} ]
              ,
              es:es,
        highlighted: {
          to: new Date(), 
          from: new Date(), 
          includeDisabled: true // Highlight disabled dates
        },
        hora:0
      }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        }),
        nameFull () {
            let name = this.user.user_data === undefined ? this.user.user.email : this.user.user_data.fullName
            return name
        }
    },
    methods: {
        gotToCards () {
            this.$router.push({path:'broker/propertyes'})
        },
        gotToProspects () {
            this.$router.push({path:'broker/prospect'})
        },showModal () {
            this.$modal.show('modalAcount')
          },
          closeModalS(){
            this.$modal.hide("modal-Baja")
            this.$modal.hide('modal-Baja-temporal')
          },
        darBaja (){
            this.$modal.show('modal-Baja')
        },
      goToPropertyProfile (id) {
          this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})
      },
      async getBrokerProperty () {
          try {
              let params = {
                  broker_id : this.currentRolComplete.user_rol_id
              }
              const response = await axiosTrocutServer.post('/getProperties',params)
              if(response.status === 200 ){
                  response.data.properties.forEach(el => {
                      this.propertyInfo.push({
                          id:el.user_rol_id,
                          idBuilding:el.property_id,
                          nameOwner:el.propietario,
                          brokersName:el.Broker,
                          contactP:el.phone,
                          key:el.key_property
                      })
                  })
              } else{
                  console.error('error');
              }
          } catch (error) {
              console.error(error);
          }
      },
      confirmTemporal() {
          var fechaSalida = moment(this.highlighted.to).format('YYYY-MM-DD')
          var fechaRegreso = moment(this.highlighted.from).format('YYYY-MM-DD')
          var exitDate= new Date(fechaSalida)
          var inDate= new Date(fechaRegreso)
          var suma = Math.abs(exitDate-inDate)
          var days = suma/(1000 * 3600 * 24)
          console.log(days);
          if(days === 0){
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;debe de seleccionar un rango de 15 dias maximo`,
              type: 'error'
            })
          }
          if (days >1 && days <= 15){
                this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;se dio de baja temporal nos vemos en  ${days} dias`,
                type: 'success'
                })
                this.$modal.hide('modal-Baja-temporal')
              
            }if (days >= 16){
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;no puede darse de baja temporal por mas de  15 dias`,
              type: 'error'
            })
          }
      },
      async getClientes(){
          try {
              let params = {
                  broker_id: this.currentRolComplete.user_rol_id
              }
              const response = await axiosTrocutServer.post('/getAddititonalBrokerInfo',params)
              if(response.status === 200){
                  response.data.solicitados.forEach(el => {
                      this.Solicitados.push(el)
                  })
                  response.data.finalizados.forEach(el => {
                      this.Finalizados.push(el)
                  }); 
                  response.data.prospectos.forEach(el => {
                      this.prospectos.push(el)
                  });
                  response.data.cierre.forEach(el => {
                      this.cierre.push(el)
                  });
                  response.data.investigacion.forEach(el => {
                      this.investigacion.push(el)
                  });
              }
          } catch (error) {
              console.error(error);
          }
      },
      async getNotifations (){
        try {
          let payload = {
            user_rol_id: this.currentRolComplete.user_rol_id
          }
          const response = await axiosTrocutServer.post('/getNotifications',payload)
          if( response.status === 200 ){
            response.data.response.forEach(el => {
              if(el.notification_type !== 'Sistema' ){
                this.notificationA.push({
                  Date :moment(el.create_at).format('YYYY-MM-DD'),
                  origin:el.notification_type,
                  message:el.notification
                })
                return
              }
            })
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getComments () {
        try {
          let params = {
            id_relation: this.currentRolComplete.user_rol_id
          }
          const response = await axiosTrocutServer.post('/GetComments',params)
          if(response.status === 200 ){
            console.log('comentarios',response);
            const comments = response.data.response
            comments.forEach(el => {
              this.commentsArray.push({
                commentarist: el.commentarist,
                create_at: moment(el.create_at).format('ll'),
                comment: el.comment
              })
            })
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getPay () {
        try {
          let params = {
            broker_id: this.currentRolComplete.user_rol_id
          }
          const response = await axiosTrocutServer.post('/getPayment',params)
          if(response.status === 200 ){
            this.pay = response.data.commisions
            console.log(this.pay);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getBroker () {
        try {
          let params = {
            user_rol_id: this.currentRolComplete.user_rol_id
          }
          const response = await axiosTrocutServer.post('/getBroker',params)
          if(response.status === 200 ){
            this.brokerInfo.push(response.data.broker)
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getScore () {
        try {
          let params = {
            user_rol_id: this.currentRolComplete.user_rol_id
          }
          const response = await axiosTrocutServer.post('/getScoreBroker',params)
          if(response.status === 200){
            this.score = response.data.score
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
   async mounted() {
    const store = this.getStoreUser.roles
    this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    this.getScore()
    this.getClientes()
    this.getBrokerProperty()
    this.getNotifations()
    this.getComments()
    this.getPay()
    await this.getBroker()
    this.broberId = this.user.user.user_id
  }
}


