export default {
    components: {
    },
    props:['idmethods'],
    name: "app",
    data () {
        return {
            confirmStep: false
        }
    },
    watch: {
    },
    computed: {
        paymetType(){
            return this.idmethods
        }
    },
    methods: {
        changeStatus(){
            this.confirmStep = true
        }
    },
    mounted(){
    }
}
