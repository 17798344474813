// import * as Sentry from '@sentry/browser'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { firebase, fbauth, db } from '@/firebase'

const auth = {
  state: {
    autenticated: false,
    user: {},
    token: '',
    rol: {},
    currentRol: {},
    password:'',
    userEmail:'',
    notificationCount:{}
  },
  mutations: {
    storeAutenticated (state, autenticated) {
      state.autenticated = autenticated
    },
    storeUser (state, user) {
      state.user = user
    },
    updateStoreUser(state, user){
      state.user.user_data = user.user_data
      state.user.roles = user.user_data.roles
      state.user.roles = user.user_data.roles
      state.user.user.complete_info_base = user.user_data.complete_info_base
      state.user.user.complete_address = user.user_data.complete_address
    },
    updateStore (state, user) {
      state.user.user.image_profile = user
    },
    storeToken (state, token) {
      state.token = token
    },
    countNotification(state, notificationCount){
      state.notificationCount = notificationCount
    },
    clearAuth (state) {
      state.autenticated = false
      state.user = {}
      state.token = ''
      state.currentRol = {}
      state.rol = {}
    },
    changeRolMutation(state, params ){
      state.rol = {}
      state.rol = params
    },
    updateStepMutation(state, params ) {
      //state.rol.next_step = 0
      state.rol.next_step = params
    },
    storeRol(state, rol) {
      state.rol = rol
      state.currentRol = rol.rol
    },
    mutationStoreRol(state, params) {
      return state.redrogo = params.rol_id
    }
  },
  actions: {
    async signIn ({ commit }, params) {
      try {
        let payload = {
          email:params.email,
          key:  params.password
        }
        const response = await axiosTrocutServer.post('/Login', payload)
        let rolActiveArr = []
        let rolInActiveArr = []
        if (response.data.Authenticated) {
           response.data.roles.filter(el =>{
            if(el.rol_active !== 1){
              rolInActiveArr.push(el)
            }else{
              rolActiveArr.push(el)
            }
          })      
          const user = {
            user: {...response.data.user,...response.data.user_data},
            user_data: response.data.user_data,
            roles: rolActiveArr.length !== 0 ?  rolActiveArr :rolInActiveArr ,
            totalRecords: response.data.roles.totalRecords
          }
          commit('storeAutenticated', true)
          commit('storeUser', user)
          commit('storeToken', response.data.token)
          return {
            authenticated: true,
            forceResetPassword: parseInt(response.data.user.force_reset_password)
          }
        } else {
          return {
            authenticated: false,
            forceResetPassword: false,
            error: response.data.error,
            msg:'se prendio fuego todo'
          }
        }
      } catch (error) {
        console.log('Error Store->auth', error)
      }
    },
    async getCustomToken ({ commit, dispatch, state }, validate) {
      await dispatch('getIdToken')
      let data = {
        idToken: state.fireBaseIdToken,
        gencustom: true
      }
      let response = await dispatch('verifyIdToken', data)
      return response.customToken
    },
    getIdToken ({ commit, dispatch, state }) {
      return firebase.auth().currentUser !== null ? firebase.auth().currentUser.getIdToken(true).then((data) => {
        commit('updateFireBaseIdToken', data)
        return data
      }) : ''
    },
    setCurrentRol ({ commit }, params) {
      commit('storeRol', params)
    },
    async countNotifications ({ commit, dispatch },params) {
      try {
        let payload = {
          user_rol_id: params
        }
        const response = await axiosTrocutServer.post('/getNotifications',payload)
         if( response.status === 200 ){
          let notifications = response.data.response
          notifications = notifications.filter(el => el.read_notification !== 1 )
            commit('countNotification',notifications.length)
        }
      } catch (error) {
        console.error(error);
      }
    },
    changeRol ({ commit }, params) {
      commit('changeRolMutation', params) 
    },
    updateNextStep ({ commit }, params) {
      console.log('parametros del next step',params);
      commit('updateStepMutation',params) 
    },
    async updatePasswordOnSignIn (context, params) {
      try {
        const response = await axiosTrocutServer.post('/updatePassword', JSON.stringify({
          ...params
        }))
        return response.data
      } catch (error) {
        console.log('Store -> auth (updatePasswordOnSignIn)', error)

      }
    },
    async signOut ({ commit }) {
      commit('cleanAlert')
      commit('clearAuth')
    },
    async updateImage ({ commit }, params) {
      try {
        console.log('esto es params',params);
        const response = await axiosTrocutServer.post('/getUserData', params)
          const user = response.data.response.media[0].url
          commit('updateStore',user)
      } catch (error) {
        console.log('Error Store->auth', error)
      }
    },
    async getUserDataStore ({ commit, state }, params) {
      try {
        console.log('getUserDataStore', state)
        const params = {
          user_id: state.user.user.user_id
        }
        const response = await axiosTrocutServer.post('/getUserData', params)
        console.log('GetUserData response', response)
        const userstorage = {
          user_data: response.data.response
        }
        commit('updateStoreUser', userstorage)
      }
        catch (error) {
        console.log('Error Store->auth', error)
      }
    }
  },
  getters: {
    getUser (state) {
      return state.user
    },
    getUserNew(state)
    {
      return state.user.user_data
    },
    getCurrentRol (state) {
      return state.currentRol
    },
    getRol(state){
      return state.rol.user_rol_id
    },
    getRolesAuth(state){
      return state.user.roles
    },
    getCountNotification(state) {
      return state.notificationCount
    },
    getAuth (state) {
      return state.autenticated
    },
    getStoreUser(state){
      return state.user
    },
    getStore(state){
      return state
    }
  }
}

export default auth