import { faUsers as fasUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faUser as fasUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faTrash as falTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp'
import { faChevronCircleLeft as falChevronCircleLeft } from '@fortawesome/free-solid-svg-icons/faChevronCircleLeft'
import { faHome as falHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faBars as falBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faBuilding as falBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding'
import { faUsers as falUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faUserCircle as falUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faChartPie as falChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie'
import { faCog as falCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faHistory as falHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faChartBar as falChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar'
import { faPuzzlePiece as falPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faEnvelope as falEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faFileInvoiceDollar as falFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar'
import { faTimesCircle as falTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faUsersCog as falUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog'
import { faMinusCircle as falMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle'
import { faTimes as falTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons/faMoneyCheckAlt'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons/faSearchLocation'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons/faLongArrowAltDown'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons/faWhatsappSquare'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen'
import { faCarAlt } from '@fortawesome/free-solid-svg-icons/faCarAlt'
import { faPaw } from '@fortawesome/free-solid-svg-icons/faPaw'
import { faBath } from '@fortawesome/free-solid-svg-icons/faBath'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt'
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile'
// import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons/faArrowsAlt'
import { faWifi } from '@fortawesome/free-solid-svg-icons/faWifi'
import { faWheelchair } from '@fortawesome/free-solid-svg-icons/faWheelchair'
import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling'
import { faSnowflake } from "@fortawesome/free-regular-svg-icons/faSnowflake";
import { faSwimmer } from '@fortawesome/free-solid-svg-icons/faSwimmer'
import { faUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield'
import { faGolfBall } from '@fortawesome/free-solid-svg-icons/faGolfBall'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faJediOrder } from '@fortawesome/free-brands-svg-icons/faJediOrder'
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter' 
import { faCampground } from '@fortawesome/free-solid-svg-icons/faCampground'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'
import { faIdCard } from '@fortawesome/free-solid-svg-icons/faIdCard'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons/faCreditCard'
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle' 
import { faClone } from '@fortawesome/free-regular-svg-icons/faClone'
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faFileArchive } from '@fortawesome/free-regular-svg-icons/faFileArchive'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar'
import { faSortAmountDown  } from "@fortawesome/free-solid-svg-icons/faSortAmountDown"
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import  { faEye } from '@fortawesome/free-regular-svg-icons/faEye'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars' 
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake'
import { faHouseUser } from "@fortawesome/free-solid-svg-icons/faHouseUser";
import { faPersonBooth } from '@fortawesome/free-solid-svg-icons/faPersonBooth'
import { faMugHot } from '@fortawesome/free-solid-svg-icons/faMugHot'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons/faUserSlash'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons/faCheckDouble'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faGooglePlusSquare } from "@fortawesome/free-brands-svg-icons/faGooglePlusSquare"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle"
import { faHouseDamage } from "@fortawesome/free-solid-svg-icons/faHouseDamage"
import {faInstagramSquare } from "@fortawesome/free-brands-svg-icons/faInstagramSquare"
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize"
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faHandsWash } from '@fortawesome/free-solid-svg-icons/faHandsWash'
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils'
import {  faLeaf } from '@fortawesome/free-solid-svg-icons/faLeaf'
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse'
import {  faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import {  faHollyBerry } from '@fortawesome/free-solid-svg-icons/faHollyBerry'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'
import { faGlassCheers  } from '@fortawesome/free-solid-svg-icons/faGlassCheers'
import {  faTshirt } from '@fortawesome/free-solid-svg-icons/faTshirt'
import { faWater } from "@fortawesome/free-solid-svg-icons/faWater"
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import { faWind } from "@fortawesome/free-solid-svg-icons/faWind";
import {  faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import {  faNetworkWired } from "@fortawesome/free-solid-svg-icons/faNetworkWired"
import { faTv } from "@fortawesome/free-solid-svg-icons/faTv";
import { faDumbbell } from  "@fortawesome/free-solid-svg-icons/faDumbbell"
import { faBaseballBall } from "@fortawesome/free-solid-svg-icons/faBaseballBall"
import { faTableTennis } from "@fortawesome/free-solid-svg-icons/faTableTennis"
import { faUmbrellaBeach  } from "@fortawesome/free-solid-svg-icons/faUmbrellaBeach"
import { faDumpsterFire } from "@fortawesome/free-solid-svg-icons/faDumpsterFire"
import { faFilm } from "@fortawesome/free-solid-svg-icons/faFilm"
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons/faSyncAlt";

export default {
  fasUsers,
  falUsers,
  fasUser,
  falTrash,
  faThumbsUp,
  falChevronCircleLeft,
  falHome,
  falBars,
  falBuilding,
  falChartPie,
  falCog,
  falHistory,
  falUserCircle,
  falChartBar,
  falPuzzlePiece,
  faExclamationTriangle,
  falEnvelope,
  falFileInvoiceDollar,
  falTimesCircle,
  falUsersCog,
  falMinusCircle,
  falTimes,
  faMoneyCheckAlt,
  faSearch,
  faFacebookSquare,
  faLinkedin,
  faWhatsappSquare,
  faTwitterSquare,
  faWhatsapp,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faSearchLocation,
  faDoorOpen,
  faCarAlt,
  faPaw,
  faBath,
  faHeart,
  faShareAlt,
  faFile,
  faFilePdf,
  faArrowsAlt,
  faWifi,
  faWheelchair,
  faSeedling,
  faSnowflake,
  faSwimmer,
  faUserShield,
  faGolfBall,
  faStar,
  faGoogle,
  faFacebookF,
  faJediOrder,
  faApple,
  faTimesCircle,
  faFilter,
  faCampground,
  faCloudUploadAlt,
  faIdCard,
  faCreditCard,
  faFileContract,
  faCheckCircle,
  faPen,
  faCircle,
  faClone,
  faPlusCircle,
  faKey,
  faFileArchive,
  faSpinner,
  faCheck,
  faBell,
  faAngleDown,
  faCar,
  faSortAmountDown,
  faInfo,
  faEye,
  faBars,
  faHandshake,
  faHouseUser,
  faPersonBooth,
  faMugHot,
  faUserSlash,
  faFileSignature,
  faCheckDouble,
  faGooglePlusSquare,
  faHouseDamage,
  faInstagramSquare,
  faWindowMinimize,
  faClock,
  faHandsWash,
  faUtensils,
  faLeaf,
  faColumns,
  faWarehouse,
  faHourglassHalf,
  faHollyBerry,
  faPaperclip,
  faGlassCheers,
  faTshirt,
  faWater,
  faSort,
  faWind,
  faPhone,
  faNetworkWired,
  faTv,
  faDumbbell,
  faBaseballBall,
  faTableTennis,
  faUmbrellaBeach,
  faDumpsterFire,
  faFilm,
  faSyncAlt
}