import {  mapGetters, mapActions, mapMutations  } from 'vuex'
import addBulding from '@/components/owner/modalAddBulding'
import registerBroker from '@/components/broker/modalAltaBroker'
import altaTenant from '@/components/tenant/rentModal'
import Datepicker from 'vuejs-datepicker'
import {es} from 'vuejs-datepicker/dist/locale'
import infoGeneralModal from '@/components/generals/generalInfo-modal'
import moment from 'moment'
import  axiosTrocutServer from '@/axios/axiosTrocutServer'

import downBroker from '@/components/generals/downs-Type'
import { getAuth, signOut } from "firebase/auth";
export default {
    components: {
      addBulding,
      registerBroker,
      altaTenant,
      Datepicker,infoGeneralModal,
      downBroker
    },
    computed: {  
      ...mapGetters({
        user:'getUser',
        getCurrentRol:'getCurrentRol',
        getStoreUser:'getStoreUser',
        getAuth: 'getAuth',
        nameUser:'getUserNew',
        getUserNew:'getUserNew'
      })
    },
    data(){
      return {
        currentRolComplete:[],
        fullAddress:[],
        pictureDocumentation:[],
        es:es,
        identificator:'',
        highlighted: {
          to: new Date(), 
          from: new Date(), 
          includeDisabled: true, // Highlight disabled dates,
        },
        hora:0,
        typeAddress:'',
        idUser:0,
        windowWidth: window.innerWidth,
      }
    },
    watch:{
      user(val){
        console.log('cambio',val);
      },
      loadResolution(){
        this.onResize
      }
    },
    methods: {
      ...mapActions({
        setAlerts: 'setAlerts',
        signOut: 'signOut',
        updateImage:'updateImage'
      }),
      ...mapMutations([
        'clearAuth'
      ]),
      async uploadImage (event) {
        try {
          const images = event.target.files
          var form = new FormData()
          form.append("type","User")
            form.append("subtype","images")
            form.append("user_id",this.user.user.user_id)
          for (const key in images) {
            console.log('arreglo de imagenes',images[key]);
            form.append('Images', images[key])
          }
          const response = await axiosTrocutServer.post('/addProfileImage', form)
          if( response.status === 200){
            this.updateImage({
              user_id: this.user.user.user_id
            })
          } if (response.status === 400) {
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; Favor de subir un archivo de imagen correcto`,
              type: 'error'
            })
          }
        } catch (error) {
          console.error(error)
        }
      },
      async sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve(), ms);
        })
      },
      showModal (arg) {
        this.identificator = arg
        if(this.getCurrentRol === 'Inquilino' && this.user.user.complete_info_base !== 1){
          this.$modal.show('modal-GeneralInfo')
        } else {
          this.$modal.show('modalAcount')
        }
      },
      showModalInfo () {
        this.$modal.show('modal-GeneralInfo')
      },
      closeModalInfo () {
        this.$modal.hide('modal-Baja-General')
      },
      closeModalS(){
        this.$modal.hide("modal-Baja")
        this.$modal.hide('modal-Baja-temporal')
        this.$modal.hide('modal-Baja-General')
        this.$modal.hide('modal-Baja-Broker')
    },
    darBaja (){
      
      if(this.getCurrentRol === 'Broker'){
        this.$modal.show('modal-Baja')
      }else {
        this.$modal.show('modal-Baja-General')
      }
    },
    brokerTemporal(){
      this.$modal.show('modal-Baja-temporal')
    },
    brokerPermanente(){
      this.$modal.show('modal-Baja')
    },
    closeModalB () {
      this.$modal.hide('modal-Baja-Broker')
    },
    logOut (){
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log('gracias por visitarnos ')
        console.log('Danke, dass Sie uns besuchen')
      }).catch((error) => {
       console.error(error);
      });
      this.signOut()
      this.$router.push({ path: '/'}).catch(()=>{})
    },
   async deleteUser(text,template){
      let params = {
        notification:text,
        importantId: 1,
        user_rol_id: this.currentRolComplete.user_rol_id,
        notification_type: "User",
        rental_process_id: 0,
        idTemplate: 2,
        userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
        email: this.user.user !== undefined ? this.user.user.email : this.user.user.email
      }
      const response = await axiosTrocutServer.post('/addNotification',params)
      if(response.status === 200 ){
        this.logOut()
      }
    },
   async confirmTemporal() {
        try {
          let params = {
            user_id: this.user.user.user_id,
            user_rol_id: this.currentRolComplete.user_rol_id,
            idTemplate: 2
          }
          var text = ''
          var template = 0
          if(this.getCurrentRol === 'Owner'){
             text = `Hola ${this.user.user_data.fullName}, hemos recibido de tu parte una solicitud para darte de baja de manera permanente de nuestra plataforma. Lamentamos que tomes esta decisión. 
            Haz click en el siguiente botón para confirmar dicha solicitud.
            BOTÓN DE CONFIRMACIÓN DE BAJA PERMANENTE
            En caso de querer reactivar tu cuenta en algún momento más adelante, deberás ingresar a trocut.com. `
            template = 2
          }if(this.getCurrentRol === 'Broker'){
            text = `Hola ${this.user.user_data === undefined ? this.user.user.email : this.user.user_data.name}, hemos recibido de tu parte una solicitud para darte de baja de manera permanente de nuestra plataforma. Lamentamos que tomes esta decisión. 
            Haz click en el siguiente botón para confirmar dicha solicitud.
            BOTÓN DE CONFIRMACIÓN DE BAJA PERMANENTE
            Te recordamos que deberás coordinar con tu broker socio la entrega de la llave de las propiedades que tienes a tu cargo.
            En caso de querer reactivar tu cuenta en algún momento más adelante, deberás ingresar a trocut.com.
            El equipo Trócut
            `
            template = 2

          }else {
             text =`Hola ${this.user.user_data === undefined ? this.user.user.email : this.user.user_data.name}, hemos recibido de tu parte una solicitud para darte de baja de manera permanente de nuestra plataforma. Lamentamos que tomes esta decisión. 
            Haz click en el siguiente botón para confirmar dicha solicitud.
            BOTÓN DE CONFIRMACIÓN DE BAJA PERMANENTE
            En caso de querer reactivar tu cuenta en algún momento más adelante, deberás ingresar a trocut.com.
            El equipo Trócut
            `
            template = 2
          }
          const response = await axiosTrocutServer.post('/updadteStatusUser',params)
          if( response.status === 200 ){
            this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;se dio de baja al usario`,
                type: 'success'
              })
            this.closeModalInfo ()
            this.deleteUser(text,template)
          }
      } catch (error) {
          console.error(error);
      }
    },
    async getAddress () {
      try {
        let params = {
          user_id: this.user  !== undefined ? this.user.user.user_id : this.user.user.user_id,
          address_type: 'User'
        }
        const response = await axiosTrocutServer.post('/getAddress',params)
        if(response.status === 200 ){
          response.data.address.forEach(el => {
            this.fullAddress.push(el.fullAddress)
          })
        }
      } catch (error) {
        console.error(error);
      }
    },
    urlImages(tyepDir,subType,dirName,arrayFiles) {
      let urlArr =
      {
        arrayFilesBack: arrayFiles,
        type:tyepDir,
        subTypeFile:subType,
        dir:dirName
      }
      console.log(urlArr);
    },
    async imageProfile(e){
      if(e.target.files.length > 0){
          const file = e.target.files[0]
          let fileType = file.type
          let fileName = file.name
          let fileSize = file.size
          fileName = `user-${this.currentRolComplete.user_rol_id}-ineCardFront.${fileType.substring(6,10)}`
          document.getElementById('imageProfile').src = window.URL.createObjectURL(file)
          this.pictureDocumentation.push({
              name:fileName,
              size:fileSize
          })
        }
    },
    onResize() {
      this.windowWidth = window.innerWidth
      //console.log(`tamaño de la resolucion ${this.windowWidth}`);
    }
  },
  async mounted (){
    const store = this.getStoreUser.roles
    if(store !== undefined){
      this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
     await this.getAddress()
      this.highlighted.to = moment().format('YYYY-MM-DD')
      this.idUser = this.user.user.user_id
      if(this.getCurrentRol === 'Inquilino' && this.user.user.complete_info_base !== 1){
        this.showModalInfo()
      }else if(this.user.user.complete_info_base === 0 ){
        this.showModal()
      }
    }else{
      console.log(this.user);
      //this.$router.go(0)
    }
  }
}