import cancel from "@/components/broker/cancelBrokerModal";
import processRent from '@/components/broker/modalBrokerRenta'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters,mapActions } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'

export default {
    components:{
        cancel,
        processRent,
        VueHtml2pdf
    },
    data () {
        return{
            propertyInfo:[],
            propertyInfoNew:[],
            idBroker:0,
            sendPropertyArr:[],
            idProperty:0,
            payment:[],
            sendIdProperty:0,
            currentRolComplete:[]
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        })
    },
    methods: {
        showModal (...arg){
            this.sendIdProperty = arg[0]
            console.log('Propiedad selecconada', this.sendIdProperty.id_property)
            this.$modal.show('modal-processRent')
        },
        goToDownloadPDF () {
            this.$router.push({path:'aprendepython.es/_downloads/907b5202c1466977a8d6bd3a2641453f/aprendepython.pdf'})
        },
        showModalCancelBroker (id) {
            console.log('id de la propiedad',id);
            this.sendPropertyArr.push(id)
            this.$modal.show('modal-cancelBroker')
        },
        goToPropertyProfile(id){
            this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})
        },
        generatePDF(...arg){
            this.propertyInfoNew = arg[0]
            console.log('por amor a jebus el testamento de codigo que esta escrito creo que indica falta de vida social y novia, son 360 lineas  ');
            this.$refs.html2Pdf.generatePdf()
        },
        closeModal (){
            this.$modal.hide('modal-recibir-llave')
        },
        async getProperties(){
            this.propertyInfo = []
            try {
                let params = {
                    broker_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getProperties',params)
                if(response.status === 200 ){
                    response.data.properties.forEach(el => {
                        console.log('elemento el',el);
                        this.propertyInfo.push({
                            id_property:el.property_id,
                            nameOwner:el.propietario,
                            brokersName:el.broker_info.fullName,
                            phone:el.owner_info[0].phone,
                            key:el.key_property,
                            key_delivery:el.key_delivery,
                            rooms: el.rooms, 
                            bath: el.bath, 
                            garage: el.garage, 
                            area: el.area,
                            description: el.description,
                            amount_rent:el.amount_rent,
                            cost_maintenance: el.cost_maintenance,
                            state:el.state,
                            city:el.city,
                            ownermedia:el.owner_media,
                            owner_information:el.owner_information,
                            owner_user_rol_id: el.property_address.user_rol_id,
                            media:el.property_media,
                            status:el.status,
                            procesRent:el.procesRent
                        })
                    })
                } else{
                    console.error('error');
                }
            } catch (error) {
                console.error(error);
            }
        },
        showModalDeliveryBroker () {
            this.$modal.show('modal-delivery_broker')
        },
        showConfirm(...arg){
            this.propertyInfoNew = []
            this.$modal.show('modal-recibir-llave')
            this.propertyInfoNew = arg[0]
        },
        async recibirllave () {
            try {
                let params ={
                    property_id: this.propertyInfoNew.id_property, 
                    broker_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/deliveryKey',params)
                if(response.status === 200){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;felicides has recibido las llaves de la propiedad`,
                        type: 'success'
                      })
                    console.log('id del propietario',this.propertyInfoNew.owner_user_rol_id)
                    this.getProperties()
                    this.closeModal()
                }
            } catch (error) {
                console.error(error);
            }
        },
        goPropertyProfile (property_id) {
            const id = property_id
            this.$router.push({name:'propertyProfile',params:{property_id:id}}).catch(()=>{})
        },
        async dateInit (){
            try {
              let condition ={
                broker_id: this.currentRolComplete.user_rol_id
              }
              const response = await axiosTrocutServer.post('/getAddititonalBrokerInfo',condition)
                if(response.status === 200){
                  response.data.solicitados.forEach(el => {
                      this.payment.push({
                          el
                      })
                    })
                } 
              } catch (error) {
                this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp; porfavor espere a que nuestros administradores validen sus datos.`,
                  type: 'error'
              })
            }
          },
          contac (arg) {
            window.open(`https://api.whatsapp.com/send?phone=${arg}&text=!Hola%20Tengo%20Dudas%20acerca%20de%20TROKUT`)
          }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.dateInit()
        this.getProperties()
    }
}


