import userAcount from '@/components/generals/userProfile'
import { mapGetters } from 'vuex'

export default {
  components: {
    userAcount
  },
  data (){
    return{
      currentRolComplete: [],
      userInfo:[{Nombre:'Elon Musk',email:'exaple@gmail.com',Tel:4432364657,Direccion:'Centro Historico de morelia', tipoU:'broker'}]
    }
  },computed: {  
    ...mapGetters({
      user:'getUser',
      getStoreUser:'getStoreUser',
      getCurrentRol:'getCurrentRol'
    })
  }, 
  methods :{
    currentRolCompleteStatus(){
      const store = this.getStoreUser.roles
      if(store !== undefined ){
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        return this.currentRolComplete.status
      }
    }
  },
 async mounted () {
    this.currentRolCompleteStatus()
  }
}
