import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/example/asset/prism.css'
import { mapActions, mapGetters } from "vuex"
import axiosTrocutServer from '@/axios/axiosTrocutServer'

export default {
  props: ['price', 'valueCity', 'valueSuburb', 'rooms'],
  components: {
    VueSlider
  },
  name: "app",
  data () {
    return {
      pets: false,
      furnished: false,
      houseType: '',
      houseActive: false,
      departmentActive: false,
      room: undefined,
      valuePrice: this.price,
      bath: undefined,
      min: 7500,
      max: 80000,
      valueMetters:[50, 500],
      minMetters:50,
      nameRoute:'',
      maxMetters:500,
      detailsHome:[],
      amenidadesArr:[],
      amenidades :[],
      caracteristicas: [],
      advancedSearchArray: [],
      options: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 7500,
        max: 80000,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'active',
        tooltipPlacement: 'bottom',
        tooltipFormatter: void 0
      }
    }
  },
  computed: {
    ...mapGetters({
      storeSearch: 'getStoreSearch'
    })
  },
  methods: {
    ...mapActions({
      setGeneralDataSearch: 'setGeneralDataSearch'
    }),
    closeModal (){
      this.$modal.hide('modal-advanced')
    },
    selectedCaracteristicas (caracteristica){
      if (this.$refs[caracteristica][0].className === 'col-4 selectedItem') {
        this.$refs[caracteristica][0].className = 'col-4 '
      } else {
        this.$refs[caracteristica][0].className = 'col-4 selectedItem'
        this.caracteristicas.push(caracteristica)
      }
    },
    selectedAmenitie(amenidades){
      if (this.$refs[amenidades][0].className === 'col-4 selecAmenitie') {
        this.$refs[amenidades][0].className = 'col-4 '
      } else {
        this.$refs[amenidades][0].className = 'col-4 selecAmenitie'
        this.amenidades.push(amenidades)
      } 
    },
    addClassActiveBath(btnNumber, type) {
      this.bath = btnNumber
      const objects = document.getElementsByClassName('btn' + type + 'Active')
      for (let i = 0; i <= objects.length; i++) {
        if (objects[i] !== undefined) {
          objects[i].className = 'btn btn-outline-danger'
        }
      }
      this.$refs[type + btnNumber][0].className = 'btn btn-outline-danger ' + 'btn' + type + 'Active'
    },
    addClassActive(btnNumber, type) {
      this.room = btnNumber
      const objects = document.getElementsByClassName('btn' + type + 'Active')
      for (let i = 0; i <= objects.length; i++) {
        if (objects[i] !== undefined) {
          objects[i].className = 'btn btn-outline-danger'
        }
      }
      this.$refs[type + btnNumber][0].className = 'btn btn-outline-danger ' + 'btn' + type + 'Active'
    },
    Advanced() {
     this.amenidades = this.amenidades.concat(this.caracteristicas)
     let pet = 0
     if(this.pets === true) {
      pet = 1
     } if (this.pets === false){
       pet = 0
     }
     this.setGeneralDataSearch({
        amenities: this.amenidades,
        area: this.valueMetters,
        bathrooms: this.bath,
        pets: pet,
        prices : this.valuePrice,
        rooms: this.room,
        type: this.houseType === '' ? null : this.houseType,
        tenant_id: 1
      })
      if (this.$route.name !== 'resultSearch') {
        this.$router.push({ name: 'resultSearch'})  
        this.closeModal()
      } else {
        this.closeModal()
        //this.$router.go(0)
      }
    },
    async getCaracteristicas (){
      const caracteristica = await axiosTrocutServer.post('/getAmenidades')
      if (caracteristica.status === 200) {
        this.detailsHome = caracteristica.data.Caracteristica
        this.amenidadesArr = caracteristica.data.Amenidad
      }
    },
    changeType (type) {
      this.houseType = type
      if (type === 'Casa') {
        this.houseActive = true
        this.departmentActive = false
      } else {
        this.departmentActive = true
        this.houseActive = false
      }
    }
  },
  mounted() {
    this.nameRoute = this.$route.name
    if (this.storeSearch.rooms !== undefined) {
      this.room = this.storeSearch.rooms
      this.addClassActive(this.storeSearch.rooms, 'room')
    }
    if (this.storeSearch.type !== '') {
      this.changeType(this.storeSearch.type)
    }
    this.pets = this.storeSearch.pets
    this.getCaracteristicas()
  }
}
