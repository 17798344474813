const suburbs = [
    {
        codigo_postal: 76000,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76000,
        colonia: "Rincón de San Andrés",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76000,
        colonia: "Mariano Escobedo",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76000,
        colonia: "Vicente Guerrero",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76000,
        colonia: "Rinconada de Morelos",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76010,
        colonia: "Las Campanas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76010,
        colonia: "Niños Héroes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76017,
        colonia: "Centro Universitario (U.A.Q.)",
        tipo_colonia: "Equipamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "17",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "Bosques del Acueducto",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "Calesa",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "Diligencias",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "El Cortijo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "Jardines de Querétaro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "La Cruz",
        tipo_colonia: "Barrio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "Pathé",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76020,
        colonia: "San Javier",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "Karina",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "La Peñita",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "Calesa 2a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "El Cortijo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "El Oasis",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "Villa los Arcos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "El Cortijo II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "Seminario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76024,
        colonia: "Del Río",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76025,
        colonia: "La Pastora",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76026,
        colonia: "La Cruz",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76026,
        colonia: "Goyeneche",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76027,
        colonia: "San José Inn",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76027,
        colonia: "Arboledas del Río",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76028,
        colonia: "Universidad",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "Casa Blanca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "El Carrizal",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "Estrella",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "Las Fuentes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "Las Misiones",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "Moderna",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "San Ángel",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76030,
        colonia: "El Prado",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76036,
        colonia: "Rinconada Fray Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76037,
        colonia: "Mariano de las Casas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Alameda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Aragón",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Las Palmas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Mercurio",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Observatorio",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Panamericano",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Valle Alameda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Villas del Sur",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76040,
        colonia: "Andalucía",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76046,
        colonia: "El Laurel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76046,
        colonia: "Villas del Sol",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76046,
        colonia: "Panorámico",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76046,
        colonia: "San Joaquín",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76047,
        colonia: "El Marqués",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76047,
        colonia: "Quintas del Marqués",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76048,
        colonia: "Arquitos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76049,
        colonia: "Loma Bonita",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76050,
        colonia: "Carretas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76057,
        colonia: "Hidalgo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76058,
        colonia: "San Francisquito",
        tipo_colonia: "Barrio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76059,
        colonia: "Plaza de las Américas",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76060,
        colonia: "Loma Dorada",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76060,
        colonia: "Vista Dorada",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76060,
        colonia: "Milenio 3a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76060,
        colonia: "Cumbres del Mirador",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76063,
        colonia: "Vista Hermosa",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76063,
        colonia: "La Virgen",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76063,
        colonia: "Villas del Oriente",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76063,
        colonia: "Cuesta Bonita",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76063,
        colonia: "Pedregal de Vista Hermosa",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76067,
        colonia: "La Laguna",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "Jardines de Hércules",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "2 de Abril",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "El Bosque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "El Limonar",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "Tejas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "Hércules",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "8 de Diciembre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "Bugambilias",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "La Estación",
        tipo_colonia: "Barrio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "San José de los Perales",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "La Cuesta",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "La Estación 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76069,
        colonia: "Nuevo Morelos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76070,
        colonia: "Corregidora (Burocrata)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76070,
        colonia: "Prados del Mirador",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76070,
        colonia: "Vista",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76070,
        colonia: "El Retiro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76070,
        colonia: "Loma Linda",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76070,
        colonia: "Conjunto Terranova",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76074,
        colonia: "La Alhambra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76074,
        colonia: "Vista Alegre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76074,
        colonia: "Vista Alegre 2a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76074,
        colonia: "Vista Alegre 3a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76074,
        colonia: "Palomar del Duque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76078,
        colonia: "Villas Valle Alameda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76079,
        colonia: "Balaustradas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76079,
        colonia: "Quinta Balaustradas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76080,
        colonia: "Lomas de Casa Blanca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76080,
        colonia: "Presidentes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Azteca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Arte Mexicano",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "El Marqués Queretano",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Lomas del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Lázaro Cárdenas III",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Universo 2000",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Colinas del Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Fraternidad de Santiago",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Vistas del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Lomas del Valle",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "La Unión",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Altos del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Bosques del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76085,
        colonia: "Rincón del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76086,
        colonia: "Reforma Agraria 1a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76086,
        colonia: "Reforma Agraria 2a Secc",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76086,
        colonia: "Reforma Agraria 3a Secc",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76086,
        colonia: "Reforma Agraria 4a Secc",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76086,
        colonia: "Reforma Agraria 5a Secc",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Lázaro Cárdenas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Comerciantes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Monte Blanco I",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Vista Azul",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Villas del Rincón",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Constelación",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Bosques del Cimatario 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Zapata Vive",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Bosques de Querétaro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Unidad Roma",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Cuitlahuac",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Praderas del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Campestre Italiana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Lomas de Pasteur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Villas del Cimatario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Jardines del Cimatario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "San Andrés",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Huertas del Cimatario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Linderos del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Prados de Querétaro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Bosques de las Lomas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Pedregal del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Misión Cimatario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Bellavista",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Manantiales del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Raquel Trejo Ortiz",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Prados del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Monte Blanco II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76087,
        colonia: "Monte Blanco III",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76090,
        colonia: "Colinas del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76090,
        colonia: "Centro Sur",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros de la Corregidora 2",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros del Marques",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Residencial Ángeles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros del Sur",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros de la Corregidora I",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros de Santiago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros de la Catedral",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76093,
        colonia: "Claustros de las Misiones",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76099,
        colonia: "Plazas del Sol 1a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76099,
        colonia: "Plazas del Sol 3a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76099,
        colonia: "Plazas del Sol 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "La Castellana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Valle Garden",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "El Destino",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Dolce Terra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Vive",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Santa Rosa",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Jurica",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Bleu Casas Platina",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Hacienda Juriquilla Santa Fe",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Atenas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Rinconada Jurica",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "El Bordo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Valle de Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Jurica",
        tipo_colonia: "Ejido",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Gobernantes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Jurica Pinar",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Jurica",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Privada de los Portones",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Las Campanas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76100,
        colonia: "Murano",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Satélite",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Cosmos (Satelite)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Josefa Ortiz de Domínguez II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Josefa Ortiz de Domínguez III",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Lomas de Satélite",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Satélite Sección Andadores",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Satélite Sección Condominios",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76110,
        colonia: "Ampliación Satélite",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "El Sol",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "El Sol Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Rinconada del Sol",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Bosques del Sol",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "La Luna",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Los Framboyanes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Valle de San Pedro 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Plan Santa María",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Santa Isabel",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Alborada",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Tonatiuh 4a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Tonatiú",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Tonatiuh 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Tonatiuh 3a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "El Arcángel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Valle de San Pedro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76113,
        colonia: "Rancho San Pedro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "El Rocio",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "Los Sauces",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "El Rocio II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "Don Manuel",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "Puerta del Sol II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "La Huerta",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "Sendero",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "Los Castaños",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76114,
        colonia: "Lomas del Carmen",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76115,
        colonia: "El Garambullo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76115,
        colonia: "Parque Santiago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Benito Juárez",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Ciudad del Sol Sección Valle de Santiago",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Real de la Loma",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Puerta Navarra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Plaza Ikal",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Ex-Hacienda Santana",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Villas de Guadalupe",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Teotihuacan",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "La Loma",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Vistas del Valle",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Querena",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "La Cordillera",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Puerta del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Las Azucenas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Colinas del Poniente",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado III",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado IV",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado V",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado VI",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Cerrito Colorado VII",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Bugambilias Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Rocio Sanz",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "La Peña",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Geo Plazas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Claustros de la Loma",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Los Encinos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Sierra Colorada",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Tenochtitlán",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Patria Nueva",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Ampliación Patria Nueva",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Chula Vista I",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Che Guevara (Axayacatl)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Loma IX",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "25 de Diciembre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Geo Villas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Josefa Ortiz de Domínguez IV",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Tenochtitlán II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Víctor Iturbe",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Rinconada de las Fuentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Misión de Carrillo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Chula Vista II",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Ciudad del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Misión de Carrillo II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Loma Real",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Eucaliptos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Lomas de San Ángel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Las Camelinas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Jardines de Azucenas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Prados del Rincón 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "La Floresta",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76116,
        colonia: "Rinconada del Cerrito",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Insurgentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "San Marcos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Desarrollo Familiar",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Rubén Jaramillo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "San Pedro Mártir",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Fundadores",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Colinas de Santa Cruz 1a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Colinas de Santa Cruz 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Misión Fundadores",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Prados del Rincón",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Fernando de Tapia",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Pedregal de Buenos Aires",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Jardines del Sol",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "San Marcos I",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "La Esmeralda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Laderas de San Pedro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Comevi",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Francisco Javier Mina",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76117,
        colonia: "Centenario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Loma Bonita 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Francisco Villa I",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Hacienda Mompaní",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Monte Vesubio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Rosendo Salazar",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Morelos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Francisco Villa II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Miguel Hidalgo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Loma Bonita",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "10 de Abril",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Independencia",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Libertadores",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "15 de Mayo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Luis Donaldo Colosio",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Paseos de San Miguel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Eduardo Loarca Castillo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Jardines de Jurica",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "El Romerillal",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Villas de San Miguel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Paraíso",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Revolución",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Prados de Loma Bonita",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Leyes de Reforma",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "El Tepeyac",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "San Miguel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Real del Marques Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "5 de Febrero",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76118,
        colonia: "Lomas del Pedregal 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76120,
        colonia: "Benito Juárez",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76120,
        colonia: "Santiago",
        tipo_colonia: "Parque industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "26",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Las Américas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Las Américas San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "San Pablo IV (INFONAVIT)",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Conquistadores",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "San Pablo III (INFONAVIT)",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Movimiento Obrero",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Tlanese",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Altos de San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Felipe Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Puesta del Sol",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "La Esperanza",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76121,
        colonia: "Nueva Esperanza",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "San Pablo II (INFONAVIT)",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "San Pablo I (INFONAVIT)",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Desarrollo San Pablo II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Desarrollo San Pablo I",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Terrazas de San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Bellavista",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Los Robles",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Cipreses",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Lomas de San Pablo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Sergio Villaseñor",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Jardines de Alborada",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "La Condesa",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Balcones de San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Nuevo Horizonte",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Valle de San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "FOVISSSTE San Pablo II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Desarrollo San Pablo",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "FOVISSSTE San Pablo I",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Rivera y Tamayo",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Punta San Carlos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "San Pablo",
        tipo_colonia: "Ejido",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Misión Bucareli Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Latinoamericana",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "José María Bustamante",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Margaritas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Proyección 2000",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76125,
        colonia: "Desarrollo San Ángel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "Raquet Club",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "Arco de Piedra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "El Salitre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "Cuitláhuac",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "Parque Industrial Jurica",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "Palmares",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76127,
        colonia: "Lomas del Salitre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76130,
        colonia: "Industrial",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76130,
        colonia: "Obrera",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "El Tintero",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "El Arco",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "Solidaridad 90",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "Plutarco Elías Calles",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "Nuevo Carrillo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "El Mirador",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "Ampolletas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "El Tintero Dos (Las Margaritas)",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "San Sebastián",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "Santa Mónica",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76134,
        colonia: "Rancho Bellavista",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76135,
        colonia: "San Antonio de la Punta",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76135,
        colonia: "5 de Febrero",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76136,
        colonia: "José María Arteaga",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76136,
        colonia: "Zona Militar",
        tipo_colonia: "Equipamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "17",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Santa María Magdalena",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Santo Niño",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Las Flores",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "San Antonio del Maurel",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "La Sierrita",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Fuentes Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Jardines del Valle",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "La Aurora",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Valle Real de San Ángel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "El Chamizal",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "San José 2a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Del Valle",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76137,
        colonia: "Ferrocarriles Sección Rinconada Santa Anita",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Felipe Carrillo Puerto",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Las Teresas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "El Progreso",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Santa Mónica 1a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Santa Mónica 2a Secc",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Luis Donaldo Colosio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Rinconada Zimapán",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Valle del Mezquital",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "El Higo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "Villas de Santa Mónica",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76138,
        colonia: "El Tintero",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "Manuel Jurado",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "Los Tabachines",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "San José",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "San Diego",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "Los Laureles",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "Carrillo",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "Las Josefinas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76139,
        colonia: "La Higuera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Arboledas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Balcones Coloniales",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Colinas del Parque",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Vista 2000",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Viveros Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Villas del Parque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Arboledas del Parque",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Privada Arboledas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "San Pedrito Peñuelas",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Parque Querétaro 2000",
        tipo_colonia: "Equipamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "17",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Las Plazas",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Cedros Alcatraces",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76140,
        colonia: "Cedros Tilipanes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76144,
        colonia: "Rancho San Antonio",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76144,
        colonia: "Bolaños",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76144,
        colonia: "El Pedregal de Querétaro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76144,
        colonia: "Puerta del Cielo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76144,
        colonia: "Balcones del Acueducto",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Lomas del Marqués",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "La Vista Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "La Cima",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Paseo San Junípero",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "La Espiga",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Altos del Marqués",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "El Campanario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "La Purísima",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Bolaños",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Lomas del Campanario II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Residencial el Refugio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Villas Palmira",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Miradores del Marques",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76146,
        colonia: "Lomas del Campanario III",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Valles de San José",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Constituyentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Privalia Ambienta",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Ejido Menchaca (Los Pinos)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "La Cantera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Las Fuentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Menchaca I",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Cuauhtémoc",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Victoria Popular",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Rancho Menchaca (Ex-Hacienda Menchaca)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Menchaca II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Menchaca III",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "San José el Alto",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Colinas de Menchaca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Mujeres Independientes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Buenos Aires",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Cinco Halcones",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Independencia",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Diana Laura Rojas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Nueva Creación",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Real de España",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Santa Fe",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "San Felipe",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Libertadores de América",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Renacimiento",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Ignacio Zaragoza",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "La Tinaja",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Jardines de San José",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Pedregal de San José",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Villas de San José",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Epigmenio González (El Oasis)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Los Arroyitos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Rancho Menchaca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "La Ladera (Menchaca III)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Vistana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "El Arcángel (La Guayina)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Lic. Alfredo Estrada Romero",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Monarca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Enrique Burgos (La Rinconada)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "12 de Diciembre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "San Ángel",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Palmas de San José",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Oasis",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Jardines de San José 3a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Nuevo Menchaca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Colinas de Menchaca 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Lomas de San José",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Fray Junípero Serra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "La Ladera",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Los Huertos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Jardines de San José 2a. Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Ignacio Pérez",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Generación 2000",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Lomas de Menchaca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Rinconada las Joyas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76147,
        colonia: "Antorchista",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Puerta de Belén",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Peñuelas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Misión Bucareli Norte",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Desarrollo Centro Norte",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Misión Riviera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "El Castaño",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "El Castaño II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Mallorca",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Mirador San Xavier",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Lomas de San Pedrito",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Amalia Solórzano",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Los Sabinos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "San Pedrito Ecológico",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Ricardo Flores Magón",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "San Pedrito Peñuelas I",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "San Pedrito Peñuelas II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "San Pedrito Peñuelas III",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "San Pedrito Peñuelas IV",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Libertad",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Lomas de San Pedrito (Sección Portales)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Reforma Agraria Radical",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Villas de Santiago",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Cuesta Azul",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "San Pedrito los Arcos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "El Parque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Unidad Nacional",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Belén",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "El Vergel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Los Padilla",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Eurípides",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Villas Fontana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Los Ciruelos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Jardines de Santiago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Bosques de la Hacienda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Bosques de Buenavista",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Nueva Esperanza",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Ampliación Amalia Solórzano",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Paseos del Pedregal",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Colinas de San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Nuevo San Pedrito",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Paseos de la Cuesta",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "La Rueca",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Valle de San Pedrito Peñuelas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Anexo a la Ecólogia",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "La Ladera",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Alameda Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Orion",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Jardines de Villas de Santiago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Bosques de Iturbide",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76148,
        colonia: "Rincones del Parque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76149,
        colonia: "Misión San Jerónimo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76149,
        colonia: "Misión de Concá",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Constituyentes FOVISSSTE",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "La Estación F.F.C.C.",
        tipo_colonia: "Equipamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "17",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "La Florida",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "La Era",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "La Piedad",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Los Alcanfores",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Los Fresnos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Los Molinos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Molinos de la Era",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "La Popular",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "San Roque",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Rinconada los Pirules",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Parque Industrial la Montaña",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Felipe Ángeles",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "San Pablo Tecnológico",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Villas Tecnológico",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Los Vitrales",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Tecnológico 2000",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Géminis",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "San Roque",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "El Fenix",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Asalias",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Las Palomas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Sauces",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Bugambilias",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76150,
        colonia: "Rinconada San José",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76154,
        colonia: "El Retablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76155,
        colonia: "Viveros de Querétaro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76156,
        colonia: "Constituyentes de 1824",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76156,
        colonia: "Los Eucaliptos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76156,
        colonia: "San Gregorio",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76156,
        colonia: "Los Alcanfores Sección Norte",
        tipo_colonia: "Equipamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "17",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76157,
        colonia: "Santa Catarina",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "España",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Las Peñitas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "El Porvenir",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Tecnológico",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Parques",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Las Peñas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Departamental Parques",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Prados del Tecnológico",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Los Faroles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Real del Parque",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76158,
        colonia: "Conjunto Victoria",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "San Pablo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Los Girasoles",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Rinconada San Joaquín",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Real Jurica",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "La Cima",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Balcón Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Querétaro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Fresnos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "5 de Febrero",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76159,
        colonia: "Real de San Pablo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Álamos 1a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Álamos 2a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Álamos 3a Sección",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "El Cerrito",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "El Tepetate",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Las Brujas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Las Hadas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Residencial Parque del Álamo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Privada Bugambilias",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Romy",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Montecarlo",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76160,
        colonia: "Rinconada de los Alamos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76164,
        colonia: "Nacozari",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76164,
        colonia: "Las Rosas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76164,
        colonia: "Primavera",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76165,
        colonia: "Los Cedros",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76165,
        colonia: "Margaritas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76165,
        colonia: "San Sebastián",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76165,
        colonia: "Guadalupe Victoria (SAHOP)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76165,
        colonia: "Los Claustros",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76166,
        colonia: "La Trinidad",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Las Gemas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Lindavista",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Industria del Hierro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Jacarandas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Quinta la Laborcilla",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Claustros del Parque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Lomas de Lindavista",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Mega Plaza",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76168,
        colonia: "Residencial Bosques",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76169,
        colonia: "Plaza del Parque",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76169,
        colonia: "Plaza Boulevares",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76169,
        colonia: "Privada la Laborcilla",
        tipo_colonia: "Unidad habitacional",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76170,
        colonia: "La Capilla",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76170,
        colonia: "Colibrí",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76170,
        colonia: "La Reja",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76170,
        colonia: "Eucaliptos I",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76170,
        colonia: "Eucaliptos II",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76170,
        colonia: "Eucaliptos III",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76175,
        colonia: "Los Virreyes",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76176,
        colonia: "Prados de la Capilla",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Residencial Gema",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Galindas Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Modelo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Rinconada la Capilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "La Carambada",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Residencial Frondoso",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Carolina",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Demetrio Vallejo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Frida Kahlo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Residencial Parque La Gloria",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Villas de San Antonio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Residencial Villa Jardín",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Residencial laToscana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Provincia Santa Elena",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76177,
        colonia: "Hacienda Galindo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76178,
        colonia: "Ensueño",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76178,
        colonia: "Villa las Arboledas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76178,
        colonia: "El Rosario",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76178,
        colonia: "La Sandia",
        tipo_colonia: "Condominio",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76178,
        colonia: "Residencial los Ángeles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76179,
        colonia: "Santiago",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76179,
        colonia: "El Sillar",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76179,
        colonia: "Residencial Italia",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76179,
        colonia: "Quinta Alicia",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76179,
        colonia: "C.E.A.",
        tipo_colonia: "Zona federal",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "34",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "El Jacal",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "Jardines de la Hacienda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "La Granja",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "Las Plazas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "Orquídeas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "Plaza Eugenia",
        tipo_colonia: "Zona comercial",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "33",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "Magisterial",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "La Joya",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76180,
        colonia: "Anáhuac",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76185,
        colonia: "Mansiones del Valle",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76185,
        colonia: "Hacienda San Miguel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76185,
        colonia: "Claustros del Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76190,
        colonia: "Club Campestre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76190,
        colonia: "Del Valle",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76190,
        colonia: "Lomas de Querétaro",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76190,
        colonia: "Rincón de San Antonio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76190,
        colonia: "Los Pilares",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76197,
        colonia: "Fidel Velázquez Sánchez",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76199,
        colonia: "Prados del Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        ciudad: "Santiago de Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano",
        clave_ciudad: "01"
    },
    {
        codigo_postal: 76210,
        colonia: "Jofrito",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76210,
        colonia: "La Estacada",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76210,
        colonia: "La Gotera",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76211,
        colonia: "Ojo de Agua",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76212,
        colonia: "El Rincón de Ojo de Agua",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76212,
        colonia: "Guadalupe (La Lagrima)",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76212,
        colonia: "El Raspiño",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76212,
        colonia: "La Luz",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76212,
        colonia: "Charape de los Pelones",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76212,
        colonia: "La Cantera",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76213,
        colonia: "La Palma",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76213,
        colonia: "La Versolilla",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76213,
        colonia: "Palo Alto",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76214,
        colonia: "La Estancia de Palo Dulce",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76215,
        colonia: "Jofre",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76215,
        colonia: "San Antonio de Trojes",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76215,
        colonia: "Parque Industrial Querétaro",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76216,
        colonia: "Presa de Becerra",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76217,
        colonia: "Cañada de la Monja",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76218,
        colonia: "Loma del Chino",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76219,
        colonia: "La Monja",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Lindavista",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Montenegro",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Puerto de Aguirre",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "San Miguelito",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Santa Rosa de Jauregui",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Continental",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Las Rosas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Lomas del Pedregal",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Valle Dorado",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Los Arquitos",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76220,
        colonia: "San Francisco",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Santa Lucia",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Francisco Villa",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Colinas de Santa Rosa",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Emiliano Zapata",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Las Lajitas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "El Pedregal",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Los Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "La Cruz",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Corregidora",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "La Loma",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Arboledas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Las Flores",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Benito Juárez",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76220,
        colonia: "Parque Industrial Polígono Empresarial",
        tipo_colonia: "Zona industrial",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76221,
        colonia: "Cerro de la Cruz",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76221,
        colonia: "Charape de la Joya",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76221,
        colonia: "La Barreta",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76221,
        colonia: "La Carbonera",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76221,
        colonia: "La Joya",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Pintillo",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Pinto",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "San Isidro Buenavista",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Santa Catarina",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Corea",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Los Venados",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76223,
        colonia: "Santa Catarina (Asociación de Colonos)",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Ampliación Piano",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76223,
        colonia: "Hacienda Santa Rosa",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "La Puerta de Santiaguillo",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76224,
        colonia: "Presita de San Antonio",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76224,
        colonia: "Los Jiménez",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76224,
        colonia: "Cerro Colorado (El Colorado)",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "El Paraíso",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76224,
        colonia: "Casa Blanca",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "Estancia de la Rochera",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "Pie de Gallo",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "San Isidro Viejo",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "El Herrero",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "Llano de la Rochera",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76224,
        colonia: "El Madroño",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76225,
        colonia: "Buenavista",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Lomas de Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Acequia Blanca",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "San José Buenavista",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Villas del Mesón",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Lomas de San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Grand Preserve",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Campestre Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Real de Juriquilla Prados",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Real de Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Ecológico la Rica",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Jurica Misiones",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Juriquilla",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Cuadrilla Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Loma Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76226,
        colonia: "Rincón del Lago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76227,
        colonia: "Cerro Prieto",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76227,
        colonia: "Quintín Lozada",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76227,
        colonia: "Los Muertos",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76227,
        colonia: "El Patol",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76227,
        colonia: "El Tránsito",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76227,
        colonia: "La Purísima",
        tipo_colonia: "Ejido",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76228,
        colonia: "La Tinaja de la Estancia",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76228,
        colonia: "San Isidro el Alto",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "El Pie",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "Tlacote El Alto",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "El Nabo",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "Santa María del Zapote",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "Tlacote el Bajo",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "Santo Niño de Praga",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "El Puertecito",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76229,
        colonia: "Puertas de San Miguel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "Mesita de Mompani",
        tipo_colonia: "Ejido",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "San Francisco de la Palma",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76229,
        colonia: "Mompaní",
        tipo_colonia: "Pueblo",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Jurica la Solana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Punta Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Colinas de Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Hacienda Grande",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Rinconada Santini",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Altos Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Cumbres del Lago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Balcones de Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "La Cañada Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Jurica Tolimán",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "San Francisco Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Juriquilla Privada",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Paseo del Piropo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Altavista Juriquilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "La Solana",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Mayoral",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Nuevo Paraíso",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Paraíso Diamante",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Los Naranjos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Jurica Acueducto",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Nuevo Juriquilla",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Rancho Largo",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Azteca",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76230,
        colonia: "Independencia",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Caletto",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76230,
        colonia: "Juriquilla Santa Fe",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76233,
        colonia: "Santa Rosa de Jauregui",
        tipo_colonia: "Ejido",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76233,
        colonia: "Las Paulonias Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76233,
        colonia: "El Huizachal de las Tetillas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76233,
        colonia: "Las Mariposas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76235,
        colonia: "Santa María de los Reyes",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76235,
        colonia: "Campestre Huertas la Joya",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76235,
        colonia: "Puerta Verona",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76235,
        colonia: "Tres Cantos Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76235,
        colonia: "El Rincón",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76235,
        colonia: "Sonterra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76235,
        colonia: "Los Viñedos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76237,
        colonia: "San Pedrito el Alto (El Obraje)",
        tipo_colonia: "Ranchería",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 14,
        zona: "Rural"
    },
    {
        codigo_postal: 76237,
        colonia: "Altozano el Nuevo Querétaro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76237,
        colonia: "Nuevo Refugio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76238,
        colonia: "Prados de Miranda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76238,
        colonia: "Santa Juanita Miranda",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76238,
        colonia: "Las Águilas",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76238,
        colonia: "Nuevo Horizonte",
        tipo_colonia: "Colonia",
        municipio: "Querétaro",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 14,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "La Cañada Centro",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "El Cerrito",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "Jardín",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "Del Panteón",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "San José (Del Baño)",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "Villas la Cañada",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76240,
        colonia: "La Presa (San Antonio)",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76243,
        colonia: "Los Encinos",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76243,
        colonia: "Jesús María",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "San Juan (San Juan de Dios)",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "Cruz Alta",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "Rinconada el Capricho",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "San Felipe",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "Santa María de Guadalupe",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "El Pinito",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "Socavón",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "Huerta Grande (1er de Dolores)",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "San Francisco",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76244,
        colonia: "El Zapote",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "VYNMSA Querétaro Industrial Park",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "Agua Azul",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "El Coyme",
        tipo_colonia: "Ranchería",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76245,
        colonia: "La Loma",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "Coyotillos",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "Parque Industrial O Donnell Aeropuerto",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "Paseos del Marques",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76245,
        colonia: "El Coyme",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Parque Tecnológico Innovación Querétaro",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Colinas de la Piedad",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Real Solare",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Zen House II",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Zen House",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Bosque de los Encinos",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Villa Catania",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "La Cañada",
        tipo_colonia: "Ejido",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Ciudad Maderas",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Piamonte",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Provenza Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Adara Lifestyle",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Mallorca Residence",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Pirineos",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Fuji",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Los Alpes",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Himalaya",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Palmar",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Enramada",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Los Andes",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Los Volcanes",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Capital Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Parque Industrial FINSA Querétaro",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Segundo Barrio de Dolores",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Zen Life Residencial II",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Lucepolis",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Rimachi",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Zen Life Residencial I",
        tipo_colonia: "Condominio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Cumbres de Conín",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Magadi Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Melissani Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Mascardi Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Euro Business (Parque Industrial)",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "General Lázaro Cárdenas (El Colorado)",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Parque Industrial el Marqués",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Parque Industrial Bernardo Quintana",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "El Carmen",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "La Piedad (San Miguel Colorado)",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Del Parque Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "San Isidro Miranda",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Cumbres de Conín Tercera Sección",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "El Mirador",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Parque industrial la Noria",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Villas de San José (Ampliación la Piedad)",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Villas la Piedad",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Colorilandia",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "El Rosario",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "El Durazno",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "El Organal",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Loma de la Cruz",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "San Cristobal (El Colorado)",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Rinconada del Pedregal",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76246,
        colonia: "Rincones del Marques",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76247,
        colonia: "Palo Alto",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76247,
        colonia: "Calamanda",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76247,
        colonia: "El Monte",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76248,
        colonia: "Parque Industrial TLC",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76248,
        colonia: "El Paraíso",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76248,
        colonia: "Monte de la Calavera",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76248,
        colonia: "Ampliación del Paraíso",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "La Granja",
        tipo_colonia: "Ranchería",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76249,
        colonia: "La Griega",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "Guadalupe la Venta",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "Loma de la Griega",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "San Lorenzo",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "Casa Blanca",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "San Martín de Porres",
        tipo_colonia: "Ranchería",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76249,
        colonia: "San Pedro Zacatenco",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "Campestre San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76249,
        colonia: "Cerrito Colorado (La Curva)",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76250,
        colonia: "Chichimequillas",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76251,
        colonia: "La Laborcilla",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76252,
        colonia: "Matanzas",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76253,
        colonia: "Las Lajitas",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76254,
        colonia: "Presa de Rayas",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76255,
        colonia: "Atongo",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76256,
        colonia: "Tierra Blanca",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76257,
        colonia: "Los Pocitos",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76257,
        colonia: "Presa del Carmen",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76258,
        colonia: "San Rafael",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76258,
        colonia: "San Pedro Amazcala",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76259,
        colonia: "San Vicente Ferrer",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76260,
        colonia: "El Lobo",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76260,
        colonia: "San José Navajas",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76260,
        colonia: "El Rosario",
        tipo_colonia: "Ranchería",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76261,
        colonia: "Alfajayucan",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76262,
        colonia: "San Miguel Amazcala",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76263,
        colonia: "Santa María Begoña",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76263,
        colonia: "San Gabriel",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76264,
        colonia: "Santa Cruz",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "Amazcala",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "San Isidro",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "El Bosque",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "San José",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "La Primavera",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "Amazcala",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76265,
        colonia: "San Pedrito (La Tegua)",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76266,
        colonia: "Dolores",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Libertadores",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Saldarriaga",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Santa María Ticomán",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "El Rodeo",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Los Héroes Querétaro",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Hacienda la Cruz",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Jesús María Dos (Barrientos)",
        tipo_colonia: "Ejido",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76267,
        colonia: "Jesús María Uno",
        tipo_colonia: "Ejido",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76267,
        colonia: "Barrientos",
        tipo_colonia: "Ranchería",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76267,
        colonia: "Nueva la Campana",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "Buenavistilla",
        tipo_colonia: "Barrio",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "La Mariola (El Rosario)",
        tipo_colonia: "Colonia",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76267,
        colonia: "La Luz",
        tipo_colonia: "Ranchería",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 11,
        zona: "Rural"
    },
    {
        codigo_postal: 76267,
        colonia: "Cerro Prieto",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76268,
        colonia: "Santa María de los Baños",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "Villa Carriedo",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "Zákia",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "Villas del Refugio",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "Zizana",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "El Campanario",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "Terra Bussines Park",
        tipo_colonia: "Zona industrial",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "La Pradera",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "Zibatá",
        tipo_colonia: "Fraccionamiento",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "El Pozo",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76269,
        colonia: "La Laborcilla",
        tipo_colonia: "Pueblo",
        municipio: "El Marqués",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 11,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "Colón Centro",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "La Guanajuato",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "El Cerrito",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "El Salitre",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "Las Crucitas",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "Las Fronteras",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "Los Laureanos",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "San Antonio",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "Soriano",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "Camino Real",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "El Arroyito",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "El Puertecito",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76270,
        colonia: "El Tepetate",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76272,
        colonia: "El Fuenteño",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76272,
        colonia: "Los Trigos",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76272,
        colonia: "Tierra Adentro",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76272,
        colonia: "El Álamo Cuate",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76272,
        colonia: "El Leoncito",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76272,
        colonia: "Tanquesitos",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76272,
        colonia: "Peña Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76272,
        colonia: "El Cilguero (Pueblo Nuevo)",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76272,
        colonia: "Ailitos",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76273,
        colonia: "San Antonio (Col. Álvaro Obregón)",
        tipo_colonia: "Rancho",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "48",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76274,
        colonia: "El Coyote",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76274,
        colonia: "Patria",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76274,
        colonia: "La Joya",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76276,
        colonia: "El Carrizal",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76276,
        colonia: "El Poleo",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76276,
        colonia: "El Potrero",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76276,
        colonia: "Casas Viejas",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76276,
        colonia: "Salitrera (Presa de la Soledad)",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76276,
        colonia: "Santa María de Guadalupe (Santa Ma. del Mexicano)",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76277,
        colonia: "El Saucillo",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76277,
        colonia: "Puerto de San Antonio",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76277,
        colonia: "Nuevo Álamos",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76278,
        colonia: "Parque Industrial Aerotech",
        tipo_colonia: "Zona industrial",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76278,
        colonia: "Querétaro (Ing. Fernando Espinoza Gtz)",
        tipo_colonia: "Aeropuerto",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "01",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76278,
        colonia: "Parque Aeroespacial de Querétaro",
        tipo_colonia: "Zona industrial",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76279,
        colonia: "Zamorano",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76279,
        colonia: "La Carbonera",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76280,
        colonia: "Ajuchitlán",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76280,
        colonia: "San Martín",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76280,
        colonia: "San Martín",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76280,
        colonia: "Centro (Ajuchitlán)",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76281,
        colonia: "Puerta de Enmedio",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76282,
        colonia: "Los Quiotes",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76282,
        colonia: "Milpillas",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76282,
        colonia: "El Leoncito",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76283,
        colonia: "Peña Colorada",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76283,
        colonia: "Vista Hermosa",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76283,
        colonia: "La Pila",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76283,
        colonia: "El Mezote",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76284,
        colonia: "La Zorra",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76284,
        colonia: "Nogales",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76286,
        colonia: "Nuevo Rumbo",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76286,
        colonia: "El Lindero",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76286,
        colonia: "Nuevo Progreso",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76286,
        colonia: "Los Naranjos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76286,
        colonia: "La Palmita",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76288,
        colonia: "San José la Peñuela (La Peñuela)",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76288,
        colonia: "San Martín",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76289,
        colonia: "Esperanza",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76290,
        colonia: "El Gallo",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76290,
        colonia: "Santa Rosa de Lima (Santa Rosa Poblado)",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76290,
        colonia: "Santa Rosa Finca",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76293,
        colonia: "Urecho",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76293,
        colonia: "Palmas",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76293,
        colonia: "Tierra Dura",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76294,
        colonia: "San Vicente el Alto",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76294,
        colonia: "La Ponderosa",
        tipo_colonia: "Fraccionamiento",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76294,
        colonia: "San Francisco",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76294,
        colonia: "San Vicente (San Vicente el Bajo)",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76295,
        colonia: "Terrakia Park",
        tipo_colonia: "Zona industrial",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76295,
        colonia: "Galeras",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76295,
        colonia: "San Ildefonso",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76295,
        colonia: "Viborillas",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76295,
        colonia: "Ejido San Ildefonso",
        tipo_colonia: "Barrio",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76295,
        colonia: "Santa María Nativitas",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76296,
        colonia: "El Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76296,
        colonia: "La Esperanza",
        tipo_colonia: "Fraccionamiento",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76296,
        colonia: "Purísima de Cubos (La Purísima)",
        tipo_colonia: "Colonia",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76297,
        colonia: "Las Cenizas",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76298,
        colonia: "México Lindo",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76299,
        colonia: "Los Benitos",
        tipo_colonia: "Pueblo",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 5,
        zona: "Urbano"
    },
    {
        codigo_postal: 76299,
        colonia: "Florisol",
        tipo_colonia: "Ranchería",
        municipio: "Colón",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 5,
        zona: "Rural"
    },
    {
        codigo_postal: 76300,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76300,
        colonia: "Piedra Grande",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76300,
        colonia: "La Escondida",
        tipo_colonia: "Barrio",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76300,
        colonia: "El Bondojito",
        tipo_colonia: "Barrio",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76300,
        colonia: "Curva Colorada",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76300,
        colonia: "El Calvario",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76303,
        colonia: "Puerto de Amoles",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76304,
        colonia: "Ojo de Agua",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76309,
        colonia: "San Pedro Escanela",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76310,
        colonia: "Ahuacatlán de Guadalupe",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76313,
        colonia: "Rincón de Pitzquintla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Adjuntas de Ahuacatlán",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Quirambal",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "El Encino",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Ojo de Agua",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Puerto del Naranjo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Rancho Nuevo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Encino de Ahuacatlán",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76313,
        colonia: "Naranjo de Escanelilla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Huilotla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Alejandría de Morelos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Carrizal de Adjuntas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Coatlán de los Ángeles",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Huajáles",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Las Adjuntas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Peña Alta",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Puerto Colorado",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "Puerto de Huilotla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "San Martín",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76314,
        colonia: "La Angostura",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76315,
        colonia: "Sauz de Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76315,
        colonia: "Puerto de Alejandría",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76315,
        colonia: "La Barrosa",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76315,
        colonia: "La Joya de Ahuacatlán",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76315,
        colonia: "La Troja",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76315,
        colonia: "Puerto de Escanelilla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Derramadero de Juárez",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76316,
        colonia: "Cuesta Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Cuesta de Huazmazontla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "La Charca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "La Yerbabuena",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Las Mesitas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Loma de la Cañada",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Loma de las Minas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Río Escanela",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "Las Joyas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76316,
        colonia: "El Llano",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "San Gaspar",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "La Barranca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "El Gallo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Agua Enterrada",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Agua Fría de la Barranca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Cerro de la Pingüica",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "El Bernalito",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "El Cantón",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Llano de San Francisco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "El Rodezno",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Hornitos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "La Mesa de la Barranca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "La Muñeca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "La Peña Colorada",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "La Sierrita",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Los Lirios",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Los Pinos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Puerto Canoas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "Puerto de Tepozán",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76317,
        colonia: "El Cantoncito",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76318,
        colonia: "La Tinaja",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76318,
        colonia: "Maguey Blanco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76318,
        colonia: "Arroyo Hondo de Huilotla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76318,
        colonia: "El Apartadero",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76318,
        colonia: "El Pilón",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76318,
        colonia: "Sauz de Arroyo Hondo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "Santa Águeda",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "El Limón",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "El Naranjo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "El Arpa",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "Ciénega de San Juan",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "La Loma de la Ciénega",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "La Mesa",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "La Morita",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "Las Cruces",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "La Joya del Leal",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "Las Mesas de Santa Inés",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "Puerto de Pujunguía",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "San Isidro",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "La Joya de Santa Águeda",
        tipo_colonia: "Barrio",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "La Cañada",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76319,
        colonia: "El Puertecito",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76320,
        colonia: "Tonatico",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76321,
        colonia: "Escanelilla",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76323,
        colonia: "Agua Cazuela",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76323,
        colonia: "Agua Verde",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76323,
        colonia: "El Chuveje",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76323,
        colonia: "El Salto",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76323,
        colonia: "La Cieneguilla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76323,
        colonia: "Joyas del Real",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76323,
        colonia: "San José Cochinito",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76323,
        colonia: "El Cuervo de San Pedro",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Puerto Hondo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "La Colgada",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Agua del Maíz",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "El Limón de la Cruz",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "El Naranjo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Puerto del Sabino",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "La Cebolla",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "La Mohonera",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Las Guayabas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Las Majaditas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Maby",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Pie de la Cuesta",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Piedra Parada de Santa Rosa",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Tierras Coloradas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76324,
        colonia: "Buenavista",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "San Pedro Viejo",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76325,
        colonia: "El Carrizalito",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "Casas Viejas (Agua Enterrada San Carlos)",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "El Durazno Grande",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "Aguacate de San Pedro",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "El Mezquite",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "El Murciélago",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "Puerto de Tenamaxtle",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "San Isidro",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "Cuervo de San Rafael",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "De la Loma de San Pedro",
        tipo_colonia: "Barrio",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76325,
        colonia: "La Ciénega",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "El Ranchito",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Loma Larga (Santa Cecilia)",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Agua Amarga",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Durazno de San Francisco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "El Refugio",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Puerto Hacienda Vieja",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Mesas de San José",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Puerto Pinto",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Las Escaleras",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76326,
        colonia: "Puerto del Molino",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Huazquilíco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "El Llano de Huazquilíco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Agua Fría",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Arquitos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "La Gachupina",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Cruz Verde",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "El Cuervo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "El Tigre",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Puerto de Vigas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Puerto del Perico",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Puerto del Rodezno",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Tres Cruces",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "Cuesta Colorada de Huazquilíco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "El Pedregal",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "La Curva del Chuveje",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "El Tambor de Huazquilíco",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76327,
        colonia: "La Gallina",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76330,
        colonia: "Bucareli",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76333,
        colonia: "Barranca del Plátano",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "Cerro del Carmen",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "El Galéme",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "El Plátano",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "Epazotitos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "La Esperanza",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "Magueycitos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "Puerto del Oro",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "Temascales",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "La Dinamita",
        tipo_colonia: "Colonia",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "La Escondida",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76333,
        colonia: "Las Cabañas 5 Pinos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "Epazotes Grandes",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "El Tejocote",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "El Timbre de Guadalupe",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76334,
        colonia: "Medias Coloradas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "Mesa de Ramírez",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "Otomites",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "Puerto Hondo",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "San Antonio del Pelón",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76334,
        colonia: "Loma de Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76335,
        colonia: "Joyas de Bucareli",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76335,
        colonia: "Agua Fría de Gudiño",
        tipo_colonia: "Pueblo",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 2,
        zona: "Urbano"
    },
    {
        codigo_postal: 76335,
        colonia: "La Meca",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76335,
        colonia: "Las Vegas",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76335,
        colonia: "Palo Grande",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76336,
        colonia: "Adjunta de Gatos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76336,
        colonia: "Agua Caliente Chiquita",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76336,
        colonia: "Aguacate de Morelos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76336,
        colonia: "Mazatiapán",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Puerto del Derramadero",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Derramadero de Bucareli",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "El Madroño",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Barbechos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Cuatro Palos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Carricillo de Media Luna",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Puerto de Tejamaníl",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "El Soyatal",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Tepozán de Derramadero",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Joyas del Derramadero",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "La Cañada",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "La Joya",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "La Quebradora",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "El Mastranto",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Mesa del Soyatal",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Potrerillos",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Puerto de los Velázquez",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Puerto del Pino (Puerta del Cielo)",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Nuevo Dos",
        tipo_colonia: "Rancho",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "48",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Huerta Don Manuel (Ojo de Agua)",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Loma del Revolcadero (La Joya)",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "Mesa Chiquita (Mesa del Comalito)",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76337,
        colonia: "La Mojonera",
        tipo_colonia: "Ranchería",
        municipio: "Pinal de Amoles",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 2,
        zona: "Rural"
    },
    {
        codigo_postal: 76340,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "La Pedrera",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "San Francisco",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "El Mercado",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "La Cruz",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "Paseos de los Adobes",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "La Santa Cruz",
        tipo_colonia: "Barrio",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "Del Niño Jesús",
        tipo_colonia: "Barrio",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "Magisterial",
        tipo_colonia: "Barrio",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "El Puente",
        tipo_colonia: "Barrio",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "Los Cocos (El Coco)",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "San José",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76342,
        colonia: "Arroyo del Real",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76344,
        colonia: "Vivah",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76344,
        colonia: "Buenos Aires",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76344,
        colonia: "El Platanito",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76344,
        colonia: "Las Ortigas",
        tipo_colonia: "Barrio",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76344,
        colonia: "Del Panteón",
        tipo_colonia: "Barrio",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76344,
        colonia: "Arboledas",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Las Terrazas",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Santa Inés",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Lindavista",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Luis Donaldo Colosio",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Las Misiones",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Bosques de la Sierra",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Puerto de San Nicolás",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Las Auroras",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Solidaridad",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Vista Hermosa",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Alcantarilla",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "Santa Celia",
        tipo_colonia: "Fraccionamiento",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "San Nicolás",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76345,
        colonia: "La Cruz Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Puerto de Ánimas",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76346,
        colonia: "Capulines",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Laguna de Pitzquintla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Moctezumas",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Rincón de Pitzquintla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Loma de la Guerra",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Villas Jalpan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76346,
        colonia: "El Embocadero",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76346,
        colonia: "El Zapote",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Acatitlán del Río",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Piedras Anchas",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76346,
        colonia: "Manzanillos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Mohonera de Gudiño",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Puerto Hondo",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Río Adentro",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Cuesta de Timbal",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Puerto de Hoyos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "San Martín",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Cuesta del Sabino",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Avícola la Presa",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "La Cabaña",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Huerta las Misiones",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Loma Alta de Pitzquintla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Entronque a Piedras Anchas",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76346,
        colonia: "Entronque a Malila",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "San Vicente",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "Carrera de Tancáma",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "Tancáma",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76347,
        colonia: "La Ceiba",
        tipo_colonia: "Colonia",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "Malila",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "Puerto de Tamales (Puerto de Tancáma)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "Tancoyolillo",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "El Rincón (Ojo de Agua)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76347,
        colonia: "Puerto de San Vicente",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Madroño",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Puerto del Naranjo",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Yerbabuena",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76348,
        colonia: "El Álamo",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Carrizal de los Sánchez",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76348,
        colonia: "Loma Delgada",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Teocho",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "La Arena",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "La Cebolla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Barreales",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76348,
        colonia: "Agua Enterrada",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Los Charcos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Quirambitos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "El Cuicillo",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "El Limón",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "El Pino",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76348,
        colonia: "Jagüey (Jagüey Grande)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Mesa del Sáuz",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Tancoyol",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76350,
        colonia: "Espadañuela",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "El Tepamal",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "El Rincón",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "El Divisadero",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "La Mesa del Pino",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Jagüey Nuevo",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76350,
        colonia: "Las Nuevas Flores",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Ojo de Agua",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76350,
        colonia: "Orilla del Plan (La Laguna)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Las Flores",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "El Callejón",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "El Cañon",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Jagüey Blanco",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "Jagüey Cuate (La Cuchilla)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76350,
        colonia: "La Carrera (La Perla)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Sabino Chico",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76353,
        colonia: "Sabino Grande",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Saucillo",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76353,
        colonia: "Petzcola",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "El Limoncito",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Ojo de Agua de los Mares",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Lomas de Juárez",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Agua Amarga",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "La Alberquita",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "La Ciénega",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Guayabos Saucillo",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "El Refugio",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Limón de la Peña",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Los Naranjitos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Fin del Llano",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Arenitas",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76353,
        colonia: "Tres Cruces",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "Valle Verde",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76354,
        colonia: "La Esperanza",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "Rancho Nuevo",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "Carrizal de los Durán",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "San Juan de los Durán",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76354,
        colonia: "La Cercada",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "San Antonio Tancoyol",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76354,
        colonia: "El Tepozán",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "El Noventa y Nueve",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "El Saucito",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "Ojo de Agua (San Francisco)",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "Tanchanaquito",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "La Barranca",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "La Isla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "El Pocito",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76354,
        colonia: "San Isidro",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76355,
        colonia: "El Lindero",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76355,
        colonia: "Saldiveña",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76355,
        colonia: "El Fraile",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76355,
        colonia: "Ojo de Agua del Lindero",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76355,
        colonia: "El Rayo",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76355,
        colonia: "El Limón",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76356,
        colonia: "Soledad de Guadalupe",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76356,
        colonia: "Charco Prieto",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76356,
        colonia: "Cerritos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76356,
        colonia: "La Cuchilla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76357,
        colonia: "Zoyapilca",
        tipo_colonia: "Pueblo",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 9,
        zona: "Urbano"
    },
    {
        codigo_postal: 76358,
        colonia: "Tierra Fría",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76358,
        colonia: "Agua Fría",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76358,
        colonia: "Carrizalito",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76358,
        colonia: "Mojonera de Osorio",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76358,
        colonia: "La Cuchilla",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76358,
        colonia: "Los Jasso",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76358,
        colonia: "Soledad del Refugio",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76359,
        colonia: "Guayabos",
        tipo_colonia: "Ranchería",
        municipio: "Jalpan de Serra",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 9,
        zona: "Rural"
    },
    {
        codigo_postal: 76360,
        colonia: "San Esteban",
        tipo_colonia: "Barrio",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76360,
        colonia: "San Miguel",
        tipo_colonia: "Barrio",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76360,
        colonia: "Chacatlán",
        tipo_colonia: "Barrio",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76360,
        colonia: "Ortigas",
        tipo_colonia: "Barrio",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76360,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76360,
        colonia: "San Sebastian",
        tipo_colonia: "Barrio",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76367,
        colonia: "Rincón de Piedra Blanca (La Pechuga)",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76367,
        colonia: "Arboledas",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76367,
        colonia: "La Reforma",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76368,
        colonia: "Otates",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76368,
        colonia: "Tilaco",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76368,
        colonia: "Barrio de la Luz",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76368,
        colonia: "Buenavista",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76368,
        colonia: "Barrio de Santa Teresita",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76368,
        colonia: "Puerto de Guayabitas",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76370,
        colonia: "Tres Lagunas",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76370,
        colonia: "La Huastequita",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76370,
        colonia: "El Gavilán",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76372,
        colonia: "Valle de Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76372,
        colonia: "Lagunita de San Diego",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76372,
        colonia: "Mesa de la Cruz",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76372,
        colonia: "La Joya Chiquita de San Antonio",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76372,
        colonia: "La Margarita",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76372,
        colonia: "Los Charcos",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76373,
        colonia: "El Llano Chiquito",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76378,
        colonia: "Pinalito de la Cruz",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76378,
        colonia: "Pío X",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76379,
        colonia: "El Lobo",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76379,
        colonia: "El Madroño",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76379,
        colonia: "La Yerbabuena",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76379,
        colonia: "Puerto de Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76379,
        colonia: "La Loma",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76379,
        colonia: "Agua de la Peña",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76379,
        colonia: "El Sabino",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76380,
        colonia: "La Lagunita",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76382,
        colonia: "La Vuelta",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76382,
        colonia: "La Sierrita",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76383,
        colonia: "El Aguacate",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76383,
        colonia: "Malpaís",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76384,
        colonia: "El Charco",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76384,
        colonia: "El Banco",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76384,
        colonia: "La Florida",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76384,
        colonia: "La Yesca",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "Encino Solo",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76385,
        colonia: "La Mora",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "La Tinaja",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "El Alambre",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "Palo Verde",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "La Alberca",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "La Espuela",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76385,
        colonia: "Matzacintla",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76386,
        colonia: "Laguna de San Miguel",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76388,
        colonia: "La Reforma",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76388,
        colonia: "La Campana",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76388,
        colonia: "Jacalilla",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76388,
        colonia: "Las Ánimas",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76388,
        colonia: "La Polvareda",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76388,
        colonia: "El Carnicero",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76388,
        colonia: "Las Vallas",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76390,
        colonia: "Acatitlán de Zaragoza",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76390,
        colonia: "San José",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76392,
        colonia: "Agua Zarca",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76393,
        colonia: "Neblinas",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76393,
        colonia: "El Aguacate (Neblinas)",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76393,
        colonia: "Puerto de San Agustín",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76393,
        colonia: "Cerro de San Agustín",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76393,
        colonia: "Mesa del Jagüey (Jagüey del Muerto)",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76393,
        colonia: "Mesa del Fortín",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76393,
        colonia: "El Capulín",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76394,
        colonia: "Camarones",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76394,
        colonia: "San Onofre",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76394,
        colonia: "Mesa del Corozo",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76394,
        colonia: "Cerro de la Palma",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76394,
        colonia: "San Juanito",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76394,
        colonia: "La Joya",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76394,
        colonia: "La Ceiba",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "El Humo",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "Río Verdito",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "Potrero del Llano (La Joya)",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "El Gavilán",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "La Silleta",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "La Agüita",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "El Retén",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "Puerto del Sabino",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "La Lima",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "El Pemoche",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "Puerto Hondo",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "La Cruz",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76395,
        colonia: "El Naranjo",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76396,
        colonia: "Jagüey Colorado",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76396,
        colonia: "El Sabinito",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76396,
        colonia: "Tres Lagunas (Agua Zarca)",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76399,
        colonia: "Santa Inés",
        tipo_colonia: "Pueblo",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 10,
        zona: "Urbano"
    },
    {
        codigo_postal: 76399,
        colonia: "Las Mesitas",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76399,
        colonia: "Tangojó",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76399,
        colonia: "Piedra Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76399,
        colonia: "El Zacatal",
        tipo_colonia: "Ranchería",
        municipio: "Landa de Matamoros",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 10,
        zona: "Rural"
    },
    {
        codigo_postal: 76400,
        colonia: "Arroyo Seco",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76400,
        colonia: "La Loma",
        tipo_colonia: "Colonia",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76401,
        colonia: "Sanguijuela",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76402,
        colonia: "La Escondida de Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Concá",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "El Crucero del Sabinito",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76410,
        colonia: "Mesa de Palo Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76410,
        colonia: "El Aguacate",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "La Maroma",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "El Ceronal (La Loma)",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Mesa del Platanito",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "San Isidro",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "El Temporalito",
        tipo_colonia: "Colonia",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Los Puentes",
        tipo_colonia: "Colonia",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Los Canelos",
        tipo_colonia: "Colonia",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "El Pitahayo",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "La Huastequita",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "La Pechuga",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Los Capulines",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Las Colmenas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "San Felipe",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "Villa Riviera",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76410,
        colonia: "El Rejalgar",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76411,
        colonia: "Mesas de Agua Fría",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76411,
        colonia: "Jesús María",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76411,
        colonia: "Mesa de los Uribe",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76411,
        colonia: "Tanque Viejo",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76413,
        colonia: "El Sabinito",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76413,
        colonia: "Tierras Prietas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76413,
        colonia: "Agua Fría de los Fresnos",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "El Salitrillo",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "El Coyote",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "El Bosque",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "San José de las Flores",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "Las Trancas",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76414,
        colonia: "Casas Viejas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "Laguna de la Cruz",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76414,
        colonia: "Milpas Viejas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76417,
        colonia: "San Juan Buenaventura",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76417,
        colonia: "El Quirino",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76417,
        colonia: "El Barrito",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76417,
        colonia: "La Mohonera",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76420,
        colonia: "La Florida",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76420,
        colonia: "La Ciénega",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76420,
        colonia: "Alpujarras",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76420,
        colonia: "El Tepozán",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76420,
        colonia: "Los Guillenes",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76420,
        colonia: "La Loma",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76420,
        colonia: "Las Adjuntas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76421,
        colonia: "El Refugio",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76421,
        colonia: "Vegas Cuatas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76421,
        colonia: "Río del Carrizal",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76421,
        colonia: "La Cantera",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76421,
        colonia: "La Mora",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76421,
        colonia: "El Riachuelo",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76421,
        colonia: "La Ceja",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76421,
        colonia: "Mesa del Coco",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76424,
        colonia: "San José del Tepame",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76425,
        colonia: "El Jardín",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76425,
        colonia: "La Lagunita",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76425,
        colonia: "El Pino",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76425,
        colonia: "El Pocito",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76425,
        colonia: "La Escondida de Hidalgo",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76425,
        colonia: "Los Álamos",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76426,
        colonia: "Cerro Blanco",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76426,
        colonia: "Los Cuicillos",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76426,
        colonia: "Tuna Manza",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76426,
        colonia: "La Huastequita",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76427,
        colonia: "El Durazno",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76434,
        colonia: "Ayutla",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76434,
        colonia: "La Estancia",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76434,
        colonia: "Las Adjuntas",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76434,
        colonia: "El Nogal",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76434,
        colonia: "Panales",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76434,
        colonia: "Puerto Ayutla",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76436,
        colonia: "Santa María de los Cocos",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76440,
        colonia: "Purísima de Arista",
        tipo_colonia: "Pueblo",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 3,
        zona: "Urbano"
    },
    {
        codigo_postal: 76440,
        colonia: "El Trapiche",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76440,
        colonia: "El Rejalgar",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76440,
        colonia: "El Sabino",
        tipo_colonia: "Colonia",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76440,
        colonia: "La Laguna de Caballos (El Sabino)",
        tipo_colonia: "Ranchería",
        municipio: "Arroyo Seco",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 3,
        zona: "Rural"
    },
    {
        codigo_postal: 76450,
        colonia: "Peñamiller Centro",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76453,
        colonia: "San Francisco",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76453,
        colonia: "Presidentes de México",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76454,
        colonia: "La Ermita",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76454,
        colonia: "Villas de Guadalupe",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76454,
        colonia: "San Marcos",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76454,
        colonia: "Deportiva",
        tipo_colonia: "Colonia",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76460,
        colonia: "Río Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76460,
        colonia: "El Zapote II",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76460,
        colonia: "Los Pleitos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76463,
        colonia: "La Liga",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76464,
        colonia: "La Concentradora (La Milpa Larga)",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76464,
        colonia: "La Curva",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76464,
        colonia: "El Plan",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76465,
        colonia: "El Cantoncito",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76465,
        colonia: "Canoas",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76470,
        colonia: "La Yerbabuena",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76471,
        colonia: "Molinitos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "El Saucito I",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "El Nogalito",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "El Comedero",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "Los Orozco",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "Los Ocotes",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "San Isidro (San Isidro Boquillas)",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "Cerro Colorado",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "Los Álamos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "El Tepozán",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76474,
        colonia: "Piedra Gorda",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76476,
        colonia: "Agua Caliente",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76476,
        colonia: "La Ordeña",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76476,
        colonia: "El Higuerón",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76477,
        colonia: "Puerto del Aire",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76477,
        colonia: "Puerto de Ramos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "San Miguel Palmas (Misión de Palmas)",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76478,
        colonia: "Cruz del Milagro",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Ciénega",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "Mesa del Troje",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "Las Camelinas",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Salado",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Carrizal",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Tequezquite",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "Adjuntas de los Guillén",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "Miranda",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Arte",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Tinaja",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Pitahayo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Guamúchil",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "Los Cuartos (El Salado)",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Piloncillo (El Puente)",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Pajarito",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Era",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Alcaparosa",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Resendeña",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "Piedra Grande",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Escondida",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "La Estacada (Cruz del Milagro)",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76478,
        colonia: "El Ojo de Agua",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76483,
        colonia: "Agua Fría",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76483,
        colonia: "Pueblo Nuevo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76483,
        colonia: "Las Mesitas",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76484,
        colonia: "Los Encinos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "El Portugués",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76485,
        colonia: "Los Llanos de Buenavista",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "La Zancona",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Las Mesas",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Alto Bonito",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76485,
        colonia: "La Ladera",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Cuesta de los Ibarra",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Puerto del Cobre",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Agua de Pedro",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "El Encino",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "El Magüeyal (Cuesta de los Ibarra)",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Puerto del Ojo de Agua",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Loma Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "Corral Viejo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "El Saucito II",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76485,
        colonia: "La Tecozautla",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "El Carrizalillo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "Los Cerritos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "El Torbellino",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "El Lindero",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "Frontoncillo",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76486,
        colonia: "El Alamito",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "Aposentos",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76486,
        colonia: "La Colonia III",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "Boquillas",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76486,
        colonia: "El Cajón",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "Panales",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "El Carricillo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "El Aguacatito",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "Puerto de Santa María",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76486,
        colonia: "El Saucillo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76489,
        colonia: "San Juanico",
        tipo_colonia: "Barrio",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76489,
        colonia: "Sebastianes",
        tipo_colonia: "Barrio",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76489,
        colonia: "Puerto del Ahorcado",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76489,
        colonia: "Vista Alegre",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76489,
        colonia: "Puerto Rico",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76489,
        colonia: "Puerto Amarillo",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76490,
        colonia: "Camargo",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76490,
        colonia: "Cuesta Colorada",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76490,
        colonia: "Maguey Verde",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76490,
        colonia: "La Nopalera",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76492,
        colonia: "El Pilón",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76492,
        colonia: "Milpillas",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76492,
        colonia: "Mentiras",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76492,
        colonia: "El Motoshí",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76492,
        colonia: "El Alamito II",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76492,
        colonia: "La Paz",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76493,
        colonia: "La Higuera",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76493,
        colonia: "El Paraíso",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76493,
        colonia: "La Laja",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76494,
        colonia: "La Plazuela",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76494,
        colonia: "El Atorón",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76494,
        colonia: "Los Morales",
        tipo_colonia: "Barrio",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76494,
        colonia: "El Moral",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76494,
        colonia: "Loma Bonita",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76494,
        colonia: "Puerto Blanco",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76495,
        colonia: "El Zapote II",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "San Lorenzo",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "La Vega",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "Las Enramadas",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "Morenos",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76495,
        colonia: "Villa Emiliano Zapata (Extoraz)",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "El Sauz",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "El Garambullal",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76495,
        colonia: "El Álamo",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76495,
        colonia: "La Cuisha",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76496,
        colonia: "Peña Blanca",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76496,
        colonia: "La Estación",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76496,
        colonia: "El Ardillero",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76496,
        colonia: "La Colonia I",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76496,
        colonia: "El Puerto de la Guitarra",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76496,
        colonia: "El Campamento",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76499,
        colonia: "Adjuntas de Higueras",
        tipo_colonia: "Pueblo",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 13,
        zona: "Urbano"
    },
    {
        codigo_postal: 76499,
        colonia: "Agua de Ángel",
        tipo_colonia: "Ranchería",
        municipio: "Peñamiller",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 13,
        zona: "Rural"
    },
    {
        codigo_postal: 76500,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76503,
        colonia: "Los Llanitos",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76503,
        colonia: "El Paraíso",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76504,
        colonia: "Las Fuentes",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76504,
        colonia: "Fuentes y Pueblo Nuevo",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76504,
        colonia: "La Meseta",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76505,
        colonia: "La Magdalena",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76505,
        colonia: "El Refugio",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76505,
        colonia: "San Diego",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76505,
        colonia: "Magisterial",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76505,
        colonia: "San Gaspar",
        tipo_colonia: "Fraccionamiento",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76506,
        colonia: "San Gaspar",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76506,
        colonia: "La Fuente",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76506,
        colonia: "El Demiño",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76510,
        colonia: "El Rincón",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76510,
        colonia: "Santa Bárbara",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76510,
        colonia: "Guadalupe",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76510,
        colonia: "Yonthé",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76510,
        colonia: "La Mesa Providencia",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76510,
        colonia: "Santo Domingo",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76513,
        colonia: "Villa Guerrero",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76513,
        colonia: "Llano Blanco",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76513,
        colonia: "Los Maqueda",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76513,
        colonia: "El Ranchito",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76513,
        colonia: "Los Espinos",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76513,
        colonia: "Mintehé",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76513,
        colonia: "El Organal",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76513,
        colonia: "La Luz",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76513,
        colonia: "El Magueyal",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76514,
        colonia: "Villa Nueva",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76515,
        colonia: "Boxasní",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76515,
        colonia: "Puerto de la Concepción",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76515,
        colonia: "CTM",
        tipo_colonia: "Unidad habitacional",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76515,
        colonia: "Los Silvestres (La Concepción)",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76515,
        colonia: "Los Sánchez (La Concepción)",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76515,
        colonia: "Alto",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "Los Vázquez",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76516,
        colonia: "Zituní",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76516,
        colonia: "Puerto del Chiquihuite",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76516,
        colonia: "Arroyo de Zituní",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76516,
        colonia: "Los Ríos",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76516,
        colonia: "Los MartÍnez",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "Las Maravillas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "Las Fuentes (La Peña)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "Bordo de Santo Niño",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "San Antonio",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "Hacienda San Antonio",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76516,
        colonia: "Los Barrones (La Concepción)",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76517,
        colonia: "San Martín Florida",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76517,
        colonia: "Llanitos de Santa Bárbara",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76517,
        colonia: "El Sauz",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76517,
        colonia: "La Puerta de San Pedro",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76517,
        colonia: "Las Cenizas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76520,
        colonia: "Higuerillas",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76520,
        colonia: "El Cuervo (Las Peñitas)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "El Banco",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76523,
        colonia: "La Tinaja",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76523,
        colonia: "La Culata",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76523,
        colonia: "Tierra Colorada",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "Mesa del Castillo (La Fajilla)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "El Tepozán",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "Quemado",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "La Pastilla",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76523,
        colonia: "Tuna Manza",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "Corral de Encino",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "El Agua Salada",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76523,
        colonia: "Adjuntas de Rancho Quemado",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76525,
        colonia: "San Juan de la Rosa",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76525,
        colonia: "Los Remedios",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76525,
        colonia: "El Carricillo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76525,
        colonia: "La Rinconada",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76525,
        colonia: "Atarjeas las Ánimas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76525,
        colonia: "Los Mateos",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76526,
        colonia: "Jabalí",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76526,
        colonia: "La Florida",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76526,
        colonia: "Corral Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76526,
        colonia: "Vizarrón de Montes",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76526,
        colonia: "Arcia",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "El Venado",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "La Haciendita",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "El Banco (Cara de Palo)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "El Púlpito",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "La Loma (Corral Blanco)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "Las Norias Derramadero",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "Los González",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76526,
        colonia: "El Cercado (Palmarcito)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76527,
        colonia: "Los Juárez",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76527,
        colonia: "La Adarga",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76527,
        colonia: "Las Viguitas",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76527,
        colonia: "Puerto del Zenthé",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Loma de Guadalupe",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76528,
        colonia: "Santa María de Gracia",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Mesa del Niño",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "La Carbonera",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Agua Fría",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "El Limón",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "San José del Catiteo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Puerto Verde",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Puerto de Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Curva de la Doctorcilla",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "De Canohitas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76528,
        colonia: "Sierra Alta (El Fiscal)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "El Doctor",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76529,
        colonia: "El Membrillo",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76529,
        colonia: "El Suspiro",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "Los Hernández",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "La Lagunita",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76529,
        colonia: "Apartaderito",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "Cerro Blanco",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "La Venta",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "El Socavón",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "El Sarro",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76529,
        colonia: "Cerro Blanco Sección Dos",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76530,
        colonia: "San Javier",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76530,
        colonia: "Los Amolitos",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76530,
        colonia: "Agua de Chivo (Los Luna)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Solares",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "La Esperanza",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "Maconí",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "Chavarrías",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "La Laja",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "Tierras Coloradas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Altamira",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "La Luz (Carrizalito)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Hortelano",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Divino Pastor",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Cerro Colorado",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Los Arteaga",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "La Calera (Los Guerreros)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Huizache",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Banthí",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "La Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Pinalito",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "La Honda (San Nicolás)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Rancho Viejo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Los Piñones",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76534,
        colonia: "Las Joyas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Torno",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Puerto el Atajo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Pie del Cerro",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Espolón (La Cabeza del Viborón)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Puerto Torres",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "El Zothí (La Calera)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "Guadalupe",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76534,
        colonia: "La Mesa",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Puerto de la Luz",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Ocotitlán",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Camarones",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "El Aguacate",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76535,
        colonia: "El Devisadero",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "El Naranjo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Vega de Ramírez",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Las Joyas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Loma de San Pedro",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Loma Ancha (La Maroma)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Loma Larga (La Maroma)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Escalerillas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76535,
        colonia: "Casas Viejas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76536,
        colonia: "Sombrerete",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76536,
        colonia: "El Soyatal",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76536,
        colonia: "Puerto de Charco Frío",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76536,
        colonia: "De Santiago (Charco Frío)",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76536,
        colonia: "De Guadalupe (Charco Frío)",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76536,
        colonia: "Las Aguas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76536,
        colonia: "Mesa de San Ramón",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76537,
        colonia: "Puerto del Salitre",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76537,
        colonia: "Carricillo",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76537,
        colonia: "Nuevo Sombrerete",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76537,
        colonia: "Santa Mónica",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76537,
        colonia: "Los Tepetates",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76537,
        colonia: "El Puerto",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76537,
        colonia: "Santa Inés",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "La Veracruz",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76538,
        colonia: "Los Martínez",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "Los Lirios",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "La Mora",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "El Timbre",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "Santo Tomás",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "La Sábila (El Durazno)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "Las Huertas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "Pie de la Loma",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76538,
        colonia: "Misión de Maconí",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76539,
        colonia: "Rancho Nuevo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76539,
        colonia: "Cerro Boludo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76539,
        colonia: "San José Tepozán",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76539,
        colonia: "San Juan de Enramadas",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76539,
        colonia: "Adjuntitas Dos",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76539,
        colonia: "La Mojonera",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76539,
        colonia: "Adjuntitas Uno",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76540,
        colonia: "El Palmar (Santa María del Palmar)",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76540,
        colonia: "De las Cruces",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76540,
        colonia: "De los Sánchez",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76540,
        colonia: "Los Martínez",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76543,
        colonia: "Boyé",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76543,
        colonia: "San Rafael Dethiga",
        tipo_colonia: "Barrio",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76544,
        colonia: "Cerro Prieto",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76544,
        colonia: "San Antonio de la Cañada",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76544,
        colonia: "El Ranchito",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76544,
        colonia: "Barranca del Sordo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76544,
        colonia: "La Presa Vieja",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76544,
        colonia: "Valle el Solís",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76544,
        colonia: "Los Badillo",
        tipo_colonia: "Colonia",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76545,
        colonia: "Bella Vista del Río",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76545,
        colonia: "El Yeso",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76545,
        colonia: "Las Cuevas",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76545,
        colonia: "El Chivo",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76546,
        colonia: "Mesa de León",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76546,
        colonia: "El Arbolito",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76546,
        colonia: "El Plan",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76546,
        colonia: "La Lajita",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76547,
        colonia: "Tziquia",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76547,
        colonia: "El Charco",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76547,
        colonia: "Taxhidó",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76547,
        colonia: "Tzibantzá",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76547,
        colonia: "Xodhé",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76547,
        colonia: "El Terrero",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76547,
        colonia: "La Mina",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76547,
        colonia: "La Hacienda (El Organal)",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76547,
        colonia: "La Loma Bonita",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76547,
        colonia: "Xidhí",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76547,
        colonia: "La Presa (La Nueva Presita)",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "Pathé",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "Portezuelo",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "La Nopalera",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "Los Llanitos de Pathé",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "El Chilar",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "Río Grande",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76548,
        colonia: "Boyecito",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "La Puerta",
        tipo_colonia: "Pueblo",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 4,
        zona: "Urbano"
    },
    {
        codigo_postal: 76548,
        colonia: "Yexthó Chico",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76548,
        colonia: "El Charcón",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76548,
        colonia: "Domuhí",
        tipo_colonia: "Ranchería",
        municipio: "Cadereyta de Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 4,
        zona: "Rural"
    },
    {
        codigo_postal: 76550,
        colonia: "La Laderita",
        tipo_colonia: "Colonia",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76550,
        colonia: "Campo Alegre (Las Cabañas)",
        tipo_colonia: "Colonia",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76550,
        colonia: "La Laguna",
        tipo_colonia: "Colonia",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76550,
        colonia: "La Loma",
        tipo_colonia: "Colonia",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76550,
        colonia: "Puerto las Pilas",
        tipo_colonia: "Colonia",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76550,
        colonia: "San Joaquín",
        tipo_colonia: "Pueblo",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76553,
        colonia: "La Mina Prieta",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76553,
        colonia: "Los Azóguez",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76553,
        colonia: "La Guadalupana",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76554,
        colonia: "Canoas (Nuevo San Joaquín)",
        tipo_colonia: "Pueblo",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76554,
        colonia: "El Deconí",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76555,
        colonia: "Los Pozos",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76555,
        colonia: "Las Ovejas",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76555,
        colonia: "Los Herrera",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76556,
        colonia: "Santa Ana",
        tipo_colonia: "Pueblo",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76557,
        colonia: "Los Hernández",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76558,
        colonia: "Culebras",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76558,
        colonia: "San Antonio",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76558,
        colonia: "Agua del Venado",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76558,
        colonia: "San Cristóbal",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76560,
        colonia: "Maravillas",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76563,
        colonia: "Santa Teresa",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76563,
        colonia: "El Plátano",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76564,
        colonia: "San Juan Tetla",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76564,
        colonia: "Apartadero",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76565,
        colonia: "Puerto de Rosarito",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76565,
        colonia: "La Soledad",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76566,
        colonia: "El Durazno",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76566,
        colonia: "San Rafael",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76566,
        colonia: "San José Quitasueño",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76566,
        colonia: "Los Planes",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76567,
        colonia: "San Francisco Gatos",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76568,
        colonia: "Tierras Coloradas",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76568,
        colonia: "San Pascual",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76568,
        colonia: "Puerto Hondo",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76570,
        colonia: "San José Carrizal",
        tipo_colonia: "Pueblo",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76570,
        colonia: "Llanos de Santa Clara",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76573,
        colonia: "San Agustín",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76573,
        colonia: "Santa María Álamos",
        tipo_colonia: "Pueblo",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 15,
        zona: "Urbano"
    },
    {
        codigo_postal: 76574,
        colonia: "San Bartolo",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76574,
        colonia: "Puerto del Durazno",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76574,
        colonia: "Ocotitlán",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76574,
        colonia: "Santa Mónica las Tinajas",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76574,
        colonia: "Zarza y Somerial",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76575,
        colonia: "Puerto de la Garita",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76575,
        colonia: "El Bordo",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76575,
        colonia: "Santa Elena",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76575,
        colonia: "San Sebastián",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76575,
        colonia: "Los Naranjos",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76575,
        colonia: "Medias Coloradas",
        tipo_colonia: "Ranchería",
        municipio: "San Joaquín",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 15,
        zona: "Rural"
    },
    {
        codigo_postal: 76600,
        colonia: "Tolimán",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76600,
        colonia: "La Loma",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76605,
        colonia: "San Pedro de los Eucaliptos",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76605,
        colonia: "Casas Viejas",
        tipo_colonia: "Barrio",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76610,
        colonia: "La Presita",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76610,
        colonia: "Ciprés",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76610,
        colonia: "El Tule",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76613,
        colonia: "La Vibora",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76613,
        colonia: "El Shaminal",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76613,
        colonia: "Los González",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76613,
        colonia: "Casa Blanca",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76614,
        colonia: "Los Rincones (San Pedro de los Eucaliptos)",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76615,
        colonia: "Guadalupe",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76615,
        colonia: "La Cañada",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76615,
        colonia: "Los Rincones (Casas Viejas)",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76615,
        colonia: "Rancho Viejo",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76616,
        colonia: "Corralitos",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76616,
        colonia: "Derramadero",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76617,
        colonia: "Maguey Manso",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76617,
        colonia: "El Saucito",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76617,
        colonia: "El Madroño",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76617,
        colonia: "Mesa de Ramírez",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76618,
        colonia: "Bomintzá",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76618,
        colonia: "Loma de Casa Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76618,
        colonia: "El Puertecito (Zapote de los Uribe)",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76618,
        colonia: "Laguna de Álvarez",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76618,
        colonia: "Mesa de Chagoya",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76618,
        colonia: "Puerto Blanco",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76620,
        colonia: "Carrizalillo",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76623,
        colonia: "Adjuntillas",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76623,
        colonia: "Tuna Manza",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76623,
        colonia: "La Vereda",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76623,
        colonia: "Manantial",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76624,
        colonia: "El Chilar",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76624,
        colonia: "Las Crucitas",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76624,
        colonia: "El Puerto del Álamo",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76624,
        colonia: "Las Cuatas",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76626,
        colonia: "La Matamba Uno",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76626,
        colonia: "El Jabalí",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76626,
        colonia: "La Matamba Dos",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76626,
        colonia: "Ronquillo",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76626,
        colonia: "Las Moras",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76627,
        colonia: "García",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76627,
        colonia: "Bermejo",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76627,
        colonia: "Zapote de los Uribe (El Zapote)",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76628,
        colonia: "La Redonda",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76630,
        colonia: "Don Lucas",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76630,
        colonia: "La Peña",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76630,
        colonia: "La Cebolleta",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76630,
        colonia: "Diezmero",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76630,
        colonia: "San Miguel",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76631,
        colonia: "El Tequesquite",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76632,
        colonia: "Granjeno",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76632,
        colonia: "Nueva el Granjeno",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76632,
        colonia: "Longo",
        tipo_colonia: "Barrio",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76632,
        colonia: "Peña Blanca",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76632,
        colonia: "Linda Vista El Granjeno",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76632,
        colonia: "El Molino",
        tipo_colonia: "Barrio",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76632,
        colonia: "Tierra Volteada",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76633,
        colonia: "El Cerrito Parado",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76633,
        colonia: "El Mezquite",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76633,
        colonia: "La Cuchara",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76633,
        colonia: "Sabino de San Ambrosio",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76634,
        colonia: "La Estancia",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76634,
        colonia: "La Puerta",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76634,
        colonia: "Nogales",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76634,
        colonia: "La Concepción",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76634,
        colonia: "Gudinos",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76636,
        colonia: "La Loma",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76636,
        colonia: "Horno de Cal",
        tipo_colonia: "Barrio",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76637,
        colonia: "Lindero",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76637,
        colonia: "Panales",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76637,
        colonia: "De García",
        tipo_colonia: "Barrio",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76640,
        colonia: "El Pueblito",
        tipo_colonia: "Barrio",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76640,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76640,
        colonia: "El Rincón",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76643,
        colonia: "La Villita",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76643,
        colonia: "San Pablo Tolimán",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76643,
        colonia: "Lomas Bonitas",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76643,
        colonia: "Campesina",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76644,
        colonia: "El Cerrito",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76644,
        colonia: "Los Roque",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76644,
        colonia: "Manantial Chalmita",
        tipo_colonia: "Colonia",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76646,
        colonia: "Rancho Nuevo",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76646,
        colonia: "El Terrero",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76646,
        colonia: "El Patol",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76646,
        colonia: "El Zapote",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76647,
        colonia: "El Cedazo",
        tipo_colonia: "Ranchería",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 18,
        zona: "Rural"
    },
    {
        codigo_postal: 76649,
        colonia: "San Antonio de la Cal",
        tipo_colonia: "Pueblo",
        municipio: "Tolimán",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 18,
        zona: "Urbano"
    },
    {
        codigo_postal: 76650,
        colonia: "San Félix",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76650,
        colonia: "Ezequiel Montes Centro",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76650,
        colonia: "Los Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76650,
        colonia: "La Presa",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76650,
        colonia: "Magisterial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76651,
        colonia: "La Laguna",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76654,
        colonia: "Conin",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76654,
        colonia: "La Queretana",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76654,
        colonia: "El Milagro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76654,
        colonia: "Santa Elena",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "Mansión del Ciervo",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "Chino",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "El Toril",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "Pedro Vega",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "La Soledad",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "Mansión del Bosque",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "La Providencia",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "El Lindero",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76655,
        colonia: "La Bola",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76656,
        colonia: "Los Velásquez",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76656,
        colonia: "El Toro Pinto",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76656,
        colonia: "Las Fuentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76657,
        colonia: "La Uca",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76663,
        colonia: "La Luna",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76663,
        colonia: "La Nueva Unidad",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "Ejido Arroyo Colorado",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "La Providencia (Las Norbertas)",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "La Ermita",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "Ejido Arroyo Colorado (Familia Glz. Reséndiz)",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76664,
        colonia: "Las Flores",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "Valle Colorado",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "La Nueva Unidad Cardenista",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76664,
        colonia: "Villa Nueva",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76665,
        colonia: "La Sala",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76665,
        colonia: "El Cerrito",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76665,
        colonia: "La Purísima",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76666,
        colonia: "La Soledad",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76666,
        colonia: "Montequis las Coloradas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76666,
        colonia: "Los Arcos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76667,
        colonia: "Piedras Negras",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76667,
        colonia: "El Ordaz (Familia Arteaga Ávila)",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76667,
        colonia: "Los Sánchez",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76667,
        colonia: "El Cardonal (La Tijera)",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76667,
        colonia: "San Antonio",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "El Tablado",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "Las Canteras",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "La Boveda",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "Santa María",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "Villa Progreso",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "San José",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76670,
        colonia: "San Miguel",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76674,
        colonia: "Cerritos Barrio San Miguel",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76674,
        colonia: "Los Ramírez",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76675,
        colonia: "El Ciervo",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76676,
        colonia: "El Bondotal",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76676,
        colonia: "Loberas",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76676,
        colonia: "El Coyote",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76677,
        colonia: "Barreras",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76677,
        colonia: "Palo Seco",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76677,
        colonia: "Guanajuatito",
        tipo_colonia: "Barrio",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76680,
        colonia: "Bernal",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76681,
        colonia: "El Jagüey Grande",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76683,
        colonia: "San José del Jagüey",
        tipo_colonia: "Colonia",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76685,
        colonia: "Tunas Blancas",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76686,
        colonia: "Alfredo V. Bonfil (Los Pérez)",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76687,
        colonia: "La Lomita",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76688,
        colonia: "La Propiedad (Campamento Palabra de Vida)",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76690,
        colonia: "La Higuera",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76693,
        colonia: "San José de los Trejo (Las Adelitas)",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76694,
        colonia: "Hacienda el Charcón",
        tipo_colonia: "Ranchería",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 7,
        zona: "Rural"
    },
    {
        codigo_postal: 76694,
        colonia: "Las Rosas",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76697,
        colonia: "Sombrerete",
        tipo_colonia: "Pueblo",
        municipio: "Ezequiel Montes",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 7,
        zona: "Urbano"
    },
    {
        codigo_postal: 76700,
        colonia: "Pedro Escobedo Centro",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76700,
        colonia: "Estrella",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76700,
        colonia: "Independencia",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76700,
        colonia: "Los Pinos",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76700,
        colonia: "El Chamizal",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76700,
        colonia: "Los Girasoles",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76703,
        colonia: "San Fandila",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76703,
        colonia: "Agua Caliente",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76703,
        colonia: "La Palma",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76704,
        colonia: "El Toreo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76704,
        colonia: "Toreo",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76704,
        colonia: "Toreo 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76704,
        colonia: "Unidad Social",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76704,
        colonia: "Villa Escobedo 2000",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76704,
        colonia: "Emiliano Zapata",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "Presidentes",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "20 de Enero 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "20 de Enero 1a Sección",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "Arboledas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "Del Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "Las Canteras",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76705,
        colonia: "El Encato II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76707,
        colonia: "La Venta de Ajuchitlancito",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76707,
        colonia: "El Gavillero",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76707,
        colonia: "El Venado",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76708,
        colonia: "La Lira",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76709,
        colonia: "Ajuchitlancito",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76710,
        colonia: "Villas de Escobedo",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76710,
        colonia: "Francisco Villa",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76710,
        colonia: "Desarrollo Ciudadano",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76713,
        colonia: "La Lira",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76713,
        colonia: "Real de San Pedro",
        tipo_colonia: "Fraccionamiento",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76714,
        colonia: "Las Torres",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76714,
        colonia: "Vicente Guerrero",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76715,
        colonia: "La Loma",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76715,
        colonia: "Moctezuma",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76720,
        colonia: "Estación el Ahorcado",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76720,
        colonia: "Ignacio Pérez (El Muerto)",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76720,
        colonia: "San Clemente",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76720,
        colonia: "Chintepec",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76720,
        colonia: "Negro",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76720,
        colonia: "Tepetate",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76720,
        colonia: "Las Coronelas",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76720,
        colonia: "La Asturiana",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76721,
        colonia: "Noria Nueva",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76722,
        colonia: "Los Álvarez",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76722,
        colonia: "Santiago Atepetlac",
        tipo_colonia: "Ejido",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76722,
        colonia: "El Tesoro del Campo",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76723,
        colonia: "Epigmenio González (El Ahorcado)",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76724,
        colonia: "Quintanares",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76725,
        colonia: "Guadalupe Septién",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76726,
        colonia: "La Palma",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76726,
        colonia: "Las Gladiolas",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76726,
        colonia: "La Teresita",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76726,
        colonia: "Santa María la Cotera",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76729,
        colonia: "El Sáuz Alto",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76729,
        colonia: "El Sáuz Bajo",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76729,
        colonia: "Loma Bonita",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76740,
        colonia: "Escolástica",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76740,
        colonia: "La D",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76740,
        colonia: "La presa",
        tipo_colonia: "Colonia",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76741,
        colonia: "Dolores de Ajuchitlancito",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76743,
        colonia: "San Antonio la D",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76743,
        colonia: "La D Santa Barbara",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76743,
        colonia: "La Mora (La Joya)",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76743,
        colonia: "Las Postas",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76746,
        colonia: "San Cirilo",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76746,
        colonia: "La Purísima",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76746,
        colonia: "La Ceja",
        tipo_colonia: "Pueblo",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 12,
        zona: "Urbano"
    },
    {
        codigo_postal: 76746,
        colonia: "Parada los Terraza",
        tipo_colonia: "Ranchería",
        municipio: "Pedro Escobedo",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 12,
        zona: "Rural"
    },
    {
        codigo_postal: 76750,
        colonia: "Tequisquiapan Centro",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "El Vergel",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Vista Hermosa",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Adolfo López Mateos",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "La Magdalena",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Los Cactus",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Rincón del Marques",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Rivera de los Sabinos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Los Jades",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Manantiales del Prado",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Nautha",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Vergel del Acueducto",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Villas del Centenario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Los Sabinos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Quintas de la Viña",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Centenario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Villas del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "El Portalito",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Los Claustros",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Serena",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76750,
        colonia: "Villas Campestres",
        tipo_colonia: "Unidad habitacional",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76753,
        colonia: "Adolfo López Mateos 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76753,
        colonia: "Los Laureles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76753,
        colonia: "Bordo Blanco",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76754,
        colonia: "Tierra Blanca",
        tipo_colonia: "Barrio",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Santa Fe",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "San Juan",
        tipo_colonia: "Barrio",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Balcones de Tequisquiapan",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Colonial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Residencial Tequisquiapan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Villas del Puente Viejo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Ibiza",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Los Girasoles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Nahui Ollin",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76755,
        colonia: "Sección Sureste de Tequisquiapan",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Rural"
    },
    {
        codigo_postal: 76755,
        colonia: "Ramas Blancas",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76756,
        colonia: "El Pedregal",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76756,
        colonia: "Pedregal de Hacienda Grande",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76757,
        colonia: "San Nicolás",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76773,
        colonia: "El Cerrito",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76774,
        colonia: "La Trinidad",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76775,
        colonia: "Bordo Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76775,
        colonia: "Santa María del Camino",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76775,
        colonia: "Las Corraletas",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76775,
        colonia: "La Ermita",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76775,
        colonia: "Cerrito San José",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76776,
        colonia: "Estación Bernal",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Rural"
    },
    {
        codigo_postal: 76780,
        colonia: "Fuentezuelas",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76781,
        colonia: "El Tejocote",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76781,
        colonia: "Quinta Rojas (Las Quintas)",
        tipo_colonia: "Ranchería",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 17,
        zona: "Rural"
    },
    {
        codigo_postal: 76783,
        colonia: "La Laja",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76785,
        colonia: "Los Cerritos",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76786,
        colonia: "La Fuente",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76786,
        colonia: "Vista Hermosa",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76786,
        colonia: "La Cañadita",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76790,
        colonia: "Santillán",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76790,
        colonia: "Granjas Residencial de Tequisquiapan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76790,
        colonia: "Los Arquitos",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76791,
        colonia: "San José de la Laja",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76794,
        colonia: "Real del Ciervo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76795,
        colonia: "La Tortuga",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76795,
        colonia: "Residencial los Viñedos de Tequisquiapan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76795,
        colonia: "La Lagunita",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76795,
        colonia: "Fátima (Ejido de Fuentezuelas)",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76795,
        colonia: "Residencial Haciendas de Tequisquiapan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76796,
        colonia: "El Sáuz",
        tipo_colonia: "Pueblo",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76799,
        colonia: "Hacienda Grande",
        tipo_colonia: "Colonia",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76799,
        colonia: "Club de Golf Tequisquiapan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Tequisquiapan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 17,
        zona: "Urbano"
    },
    {
        codigo_postal: 76800,
        colonia: "Centro",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Olivos 2a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Santa Fe",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "La Paz",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Valle de Oro",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Naranjos",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "La Rioja de San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "El Carrizo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Real de San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Campestre las Palmas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Calli Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "San Isidro",
        tipo_colonia: "Barrio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "INFONAVIT San Isidro",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Valle de Oro",
        tipo_colonia: "Zona industrial",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villas Jardín",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villas de San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villas los Pirules",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "El Carrizo",
        tipo_colonia: "Barrio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Real de los Nogales",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Manantiales de San Juan",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Nuevo San Isidro",
        tipo_colonia: "Barrio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "La Herradura",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villa los Cipreses",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Olivos 1a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Espíritu Santo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Nuevo Espíritu Santo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "La Concepción",
        tipo_colonia: "Barrio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Las Rosas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "San Pedro",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "San Pedro 3a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Residencial Claustros del Río",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villas de San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Vivero de Fresnos",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "La Guitarrilla",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Compadres",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Prados",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Paseos del Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Club Residencial San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Campestre San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "La Rueda",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Paseos de San Isidro",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Ana",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "CFE",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Residencial las Flores",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Cuauhtémoc",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Cipreces",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Fluminense",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Hacienda las Nueces",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Jardines de San Juan",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Residencial la Isla",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Privada del Parque",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Residencial del  Ángel",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Rinconada del Río",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Vegas del Río",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villa los Olivos",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Villas la Herradura",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Las Margaritas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "José López Portillo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Alejandrina",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Viveros de Cedros",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Bosques de San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Country",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Siglo XXI",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Los Nogales",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76803,
        colonia: "Rinconada los Nogales",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Nuevo Banthí (Ampliación Banthí)",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Esmeralda",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Las Estrellas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Banthí",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Santa Cruz Nieto",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "INFONAVIT la Paz",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Villa de las Haciendas",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Aquiles Serdán",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Bosques de Banthí",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Peña 3a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Floresta",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Paz",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Jardines Banthí",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "El Colibri de Banthi",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "El Rocío",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Independencia",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Jardines del Valle",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Aquiles Serdán Sección Libertad",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Loma (Ampliación Santa Cruz)",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Las Torres",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Rincón de los Azhares",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "INFONAVIT los Fresnos",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Misión de Santa Cruz",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Praderas del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Paseo de las Palmas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Rinconada Banthí",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Villas del Puente",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Granjas Banthí Sección Solares",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Huizache",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Peña 1a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "La Peña 2a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "Rinconada Santa Cruz Nieto",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76804,
        colonia: "De Banthí",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Granjas Banthi",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "De Enmedio",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Los Girasoles",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Granjas Banthí 3ra Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Colinas de Oriente",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Francisco Villa",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Las Águilas II",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "El Capricho",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Prados de Oriente",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Las Palomas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "El Dorado",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Sagrado Corazón",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Bugambilias",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "El Mirador",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Heraclio Bernal",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Jacarandas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "La Misión",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Las Águilas III",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Paseos de Xhosda",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Insurgentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Praderas de Oriente",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Las Águilas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76805,
        colonia: "Las Águilas I",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Lomas de San Juan 2da. Sección",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Pedregal de San Juan",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "San Cayetano",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "San Rafael",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "INDECO",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "INFONAVIT Pedregoso",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Lomas de San Juan",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "México",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Nuevo Parque Industrial",
        tipo_colonia: "Zona industrial",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Nuevo San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Deportiva",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Comevi Banthi",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Lomas de San Juan Sección Campestre",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Villas del Parque",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Villa las Flores",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Magisterial",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Las Haciendas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Haciendas del Pedregal",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Villas del Pedregal",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Villas del Pedregal 1a. Sección",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Lomas de San Juan Sección Jardines",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "El Marfil",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Lomas del Pedregal",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Quintas de Guadalupe",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Parque Industrial Nuevo San Juan",
        tipo_colonia: "Zona industrial",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "37",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Centro Urbano Nuevo San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Colibrí del Pedregal",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Tomasita",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Conca",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "El Pedregal",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Jardines del Pedregal",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76806,
        colonia: "Ferrocarrileros",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "La Cruz",
        tipo_colonia: "Barrio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Betania",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "San Juan Bosco",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Benito Juárez",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Fátima",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Ramos Millán",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Lomas de Guadalupe",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Arrayanes",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "El Riel",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "INFONAVIT San Cayetano",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Bugambilias",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Las Alamedas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "El Espárrago",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Arboledas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Villas Corregidora",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Villas las Fuentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Villas Tabachines",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Las Huertas",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Quintas Esmeralda",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Residencial Ahuehuetes",
        tipo_colonia: "Condominio",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Guadalupe de las Peñas",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "La Arboleda",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "El Bosque",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "El Campanario",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "El Molino",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Huerta Grande",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "INFONAVIT Fatima",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Juárez",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "La Viña",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Las Fuentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Residencial San Fernando",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Rinconada de San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "Villas del Centro",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76807,
        colonia: "La Venta",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        ciudad: "San Juan del Rio",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano",
        clave_ciudad: "02"
    },
    {
        codigo_postal: 76810,
        colonia: "Ampliación Visthá Norte",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "San José",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "CERESO San Juan del Río",
        tipo_colonia: "Equipamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "17",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "La Llave",
        tipo_colonia: "Ejido",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76810,
        colonia: "Valle Real Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "Jardines de Visthá",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "San Pedro Ahuacatlán",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "Santa Matilde",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "Visthá",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76810,
        colonia: "Pueblo Quieto",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76812,
        colonia: "Laguna de Lourdes",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76813,
        colonia: "El Organal",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76814,
        colonia: "Loma de la Valla (Buenavista de la Valla)",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76814,
        colonia: "Militar",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76814,
        colonia: "Santa Elena",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76814,
        colonia: "La Valla",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76814,
        colonia: "La Llave",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76814,
        colonia: "El Paraíso",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76815,
        colonia: "San Germán",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76815,
        colonia: "San Javier",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76815,
        colonia: "San Gil",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76815,
        colonia: "El Porvenir",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76815,
        colonia: "Nuevo San Germán",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "El Carmen",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "Loma Linda",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "Arcila",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "Dolores Cuadrilla de Enmedio",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "El Coto",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "El Rosario",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "San José Galindo",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "San Miguel Galindo",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "Casa Blanca",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "Santa Isabel el Coto",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76820,
        colonia: "La Ladera",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76820,
        colonia: "Los Cerritos San Miguel",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76821,
        colonia: "Senegal de las Palomas",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76823,
        colonia: "Ojo de Agua de Vaquerías",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76823,
        colonia: "Laguna de Vaquerías",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76823,
        colonia: "Vaquerías",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76824,
        colonia: "Nueva Esperanza",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76824,
        colonia: "Lindavista",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76824,
        colonia: "La Nueva Esperanza",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76824,
        colonia: "La Estancia",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76824,
        colonia: "Lomo de Toro",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76824,
        colonia: "Milagrito",
        tipo_colonia: "Unidad habitacional",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76826,
        colonia: "Sabino Chico",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76826,
        colonia: "El Jazmín",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76826,
        colonia: "Ojo de Agua",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76826,
        colonia: "Vista Hermosa",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76826,
        colonia: "La Mina",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76827,
        colonia: "Puerta de Alegrías (Puerto de Alegrías)",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76827,
        colonia: "Salto de Vaquerías",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "San Antonio la Labor",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "Buenavista",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "Estancia de Bordos",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "Santa Lucía",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "Rosa de Castilla",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "San Pablo Potrerillos",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76828,
        colonia: "San Pedro Potrerillos",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76829,
        colonia: "El Granjeno",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76829,
        colonia: "La Mesa de San Antonio",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76829,
        colonia: "Perales",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76829,
        colonia: "Barranca de Cocheros",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Paso de Mata zona IV",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Paso de Mata zona VII",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Margaritas (Loma Bonita)",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Cerro Gordo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "El Sitio",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Paso de Mata",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Villas Fundadores",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Prados de Cerro Gordo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Arcos del Sol",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Haciendas San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Valle Dorado",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76830,
        colonia: "Loma Alta",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76833,
        colonia: "San José",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76833,
        colonia: "Pedregal del Río",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76833,
        colonia: "El Mirador",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76833,
        colonia: "Santa Rosa Xajay",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76833,
        colonia: "Santa Cruz Escandón",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76833,
        colonia: "Villas de San José",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76835,
        colonia: "Banthí (Familia Navarrete)",
        tipo_colonia: "Rancho",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "48",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76837,
        colonia: "La Caseta (Ampliación Santa Bárbara)",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76837,
        colonia: "Palmillas",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76837,
        colonia: "Puerta de Palmillas",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76838,
        colonia: "Cristo Rey",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76838,
        colonia: "San Isidro Labrador",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76838,
        colonia: "San Juan de Dios",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76838,
        colonia: "Santa Anita",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76838,
        colonia: "El Cazadero",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76838,
        colonia: "San Miguel Arcángel",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76840,
        colonia: "Revolución",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76840,
        colonia: "Palma de Romero",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76840,
        colonia: "Cuarto Centenario",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76842,
        colonia: "Neria de Dolores Godoy",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76842,
        colonia: "Los Llanitos",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76842,
        colonia: "Dolores Godoy",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76842,
        colonia: "San Francisco",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76842,
        colonia: "Buenavista Palma de Romero",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "San Martín",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "La Guadalupana",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "Santa Isabel",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "El Rodeo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "Lázaro Cárdenas 1a. Sección",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "Tercera Sección del Rodeo",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "Josefa Ortiz de Domínguez 4a. Sección",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76843,
        colonia: "Villas del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76844,
        colonia: "La Magdalena",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76844,
        colonia: "Santa Rita",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76844,
        colonia: "Soledad del Río",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76844,
        colonia: "La Estancita",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76845,
        colonia: "Prados de San Juan Bautista",
        tipo_colonia: "Fraccionamiento",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76845,
        colonia: "Los Frailes",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76846,
        colonia: "Estancia de Santa Lucía",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76846,
        colonia: "San Antonio Zatlauco",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76846,
        colonia: "La Corregidora",
        tipo_colonia: "Colonia",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76847,
        colonia: "San Sebastián de las Barrancas Sur",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76847,
        colonia: "Pueblo Nuevo",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76847,
        colonia: "Cofradía Grande",
        tipo_colonia: "Ranchería",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76847,
        colonia: "Santa Barbara de la Cueva",
        tipo_colonia: "Ejido",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 16,
        zona: "Rural"
    },
    {
        codigo_postal: 76847,
        colonia: "San Sebastian Loma Linda",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76847,
        colonia: "Santa Bárbara de la Cueva",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76847,
        colonia: "San Sebastián de las Barrancas Norte",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76848,
        colonia: "El Chaparro",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76848,
        colonia: "Tuna Mansa",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76849,
        colonia: "La Laborcilla",
        tipo_colonia: "Pueblo",
        municipio: "San Juan del Río",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 16,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Rinconada la Perita",
        tipo_colonia: "Fraccionamiento",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Rinconada de Bonfil",
        tipo_colonia: "Fraccionamiento",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Amealco de Bonfil Centro",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "La Cruz",
        tipo_colonia: "Fraccionamiento",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "El Vicario",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Los Miradores",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Jardines del Bosque",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Primavera",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Lomas de San Higinio",
        tipo_colonia: "Fraccionamiento",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Cerro de los Gallos",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "San Martín",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "La Mora",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "San Higinio",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "El Pinar",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "La Perita",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Vista Real",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Balcones de Amealco",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "El Fresno",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Las Delicias",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Bosdha",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Las Américas",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Las Ánimas",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Los Duraznos",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76850,
        colonia: "Las Américas (La Haciendita)",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76860,
        colonia: "Galindillo",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76860,
        colonia: "Hacienda Blanca",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76861,
        colonia: "Palos Altos",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76862,
        colonia: "Quiotillos",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76862,
        colonia: "El Pino",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76862,
        colonia: "La Mesa",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76862,
        colonia: "El Granjeno",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76863,
        colonia: "Los Reyes",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76870,
        colonia: "El Rincón",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76870,
        colonia: "San Miguel Dehetí",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76870,
        colonia: "La Cofradía",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76874,
        colonia: "El Baño",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76875,
        colonia: "Laguna de Servín",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76875,
        colonia: "La Alameda del Rincón",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76876,
        colonia: "Bordos Cuates",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76876,
        colonia: "Mal Paso (La Laguna)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76876,
        colonia: "El Juvilete",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76877,
        colonia: "San Martín",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76877,
        colonia: "El Rincón (La Botija)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76877,
        colonia: "Mesa de San Martín",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76878,
        colonia: "Parques de San Miguel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76878,
        colonia: "Llano Largo",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76878,
        colonia: "Arroyo Hondo",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76878,
        colonia: "La Isla",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76879,
        colonia: "El Batán",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76879,
        colonia: "El Aserrín (Ojo de Agua del Hornito)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76879,
        colonia: "El Rayo",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76879,
        colonia: "La Beata",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76879,
        colonia: "El Salvador",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76880,
        colonia: "Tenasdá (Barrio de San Ildefonso)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76883,
        colonia: "El Tepozán (Barrio de San Ildefonso)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76883,
        colonia: "La Muralla",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76883,
        colonia: "El Bothé",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76883,
        colonia: "La Cruz del Apartadero (La Garita)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76883,
        colonia: "Veinte de Noviembre",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76884,
        colonia: "El Atorón",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76884,
        colonia: "San Bartolomé del Pino (San Bartolo)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76884,
        colonia: "La Paloma (San Bartolo)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76884,
        colonia: "La Esperanza (San Bartolo)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76884,
        colonia: "La Cruz (San Bartolo)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76884,
        colonia: "San Antonio (San Bartolo)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76885,
        colonia: "Nuevo Amanecer",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76885,
        colonia: "Agua Blanca",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76886,
        colonia: "Mesillas",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76887,
        colonia: "San Ildefonso Tultepec (Centro)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76887,
        colonia: "El Rincón de San Ildefonso",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76887,
        colonia: "Yosphí",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76887,
        colonia: "Xajay",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76887,
        colonia: "Cuicillo (Barrio de San Ildefonso)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76887,
        colonia: "Loma de los Blases",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76887,
        colonia: "Tesquedó (Puerto del Chivato)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76888,
        colonia: "El Saucito",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76889,
        colonia: "San Pablo",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76889,
        colonia: "La Piní",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76890,
        colonia: "Chitejé de la Cruz",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "Donicá",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "San Felipe (Santiago Mexquititlán Barrio 6o.)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "Santiago Mexquititlán Barrio 1o.",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "Santiago Mexquititlán Barrio 2o.",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "Santiago Mexquititlán Barrio 3o.",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "Santiago Mexquititlán Barrio 4o.",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76890,
        colonia: "Santiago Mexquititlán Barrio 5o. (El Pastoreo)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "La Purísima",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Los Árboles",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "El Cacahuate (Stgo. Mexquititlán Barrio 6o.)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "El Carmen (Santiago Mexquititlán Barrio 6o.)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "La Concepción (La Concha)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "San Nicolás de la Torre",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "Loma las Liebres (Los Árboles)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Las Grullas",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Presa del Tecolote (El Lindero)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "La Venta (Santiago Mexquititlán Barrio 6o.)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "La Isla (Stgo. Mexquititlán B. 6o.)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Amárcigo (San Luis)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "El Jaral (Santiago Mexquititlán Barrio 6o.)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Tierras Negras (Santiago Mexquititlán Barrio 6to.)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "De Sta. Teresa (S. Mexquititlan B. 6o.)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Loma de las Víboras (S. Mexquititlán B. 6o.)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "Los Arcos",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76893,
        colonia: "El Río (Santiago Mexquititlán Barrio 6o.)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76893,
        colonia: "Tierra Negra",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76894,
        colonia: "San Juan Dehedó",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76894,
        colonia: "El Capulín",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76894,
        colonia: "San José Ithó",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76894,
        colonia: "Rincón de la Florida",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76894,
        colonia: "La Soledad",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76894,
        colonia: "Los Argueta",
        tipo_colonia: "Colonia",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76894,
        colonia: "La Joya",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76894,
        colonia: "San José de los Encinos (Las Cabañas)",
        tipo_colonia: "Fraccionamiento",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76895,
        colonia: "Guadalupe el Terrero",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76895,
        colonia: "San Miguel Tlaxcaltepec (Barrio Centro)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76895,
        colonia: "El Lindero",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76895,
        colonia: "El Cerrito",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "El Picacho",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76895,
        colonia: "El Rincón de Agua Buena (San M. Tlaxcaltepec)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76895,
        colonia: "Bosques del Renacimiento",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "Del Coyote (San Bartolo)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "Del Barco (San Miguel Tlaxcaltepec)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "Ojo de Agua (San Miguel Tlaxcaltepec)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "De la Ladera (San Miguel Tlaxcaltepec)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "De la Cruz (San Miguel Tlaxcaltepec)",
        tipo_colonia: "Barrio",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "El Pueblito (San Miguel Tlaxcaltepec)",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76895,
        colonia: "San Pedro Tenango",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76896,
        colonia: "Chitejé de Garabato",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76896,
        colonia: "San Juan Dehedó",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76896,
        colonia: "Los Arenales (San Juan Dehedó)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76896,
        colonia: "El Varal",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76896,
        colonia: "La Cañada del Varal",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76896,
        colonia: "Las Salvas",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76897,
        colonia: "El Capulín",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "San Antonio",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "El Apartadero",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "La Ladera",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76897,
        colonia: "La Manzana",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76897,
        colonia: "Loma Linda",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "Jacal de la Piedad",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76897,
        colonia: "San Carlos",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "La Loma del Rosario",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "La Loma del Apartadero",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "El Coyote",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "El Colorín",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "Buenos Aires (El Apartadero)",
        tipo_colonia: "Pueblo",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 1,
        zona: "Urbano"
    },
    {
        codigo_postal: 76897,
        colonia: "La Atarjea",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76897,
        colonia: "Santa Clara",
        tipo_colonia: "Ranchería",
        municipio: "Amealco de Bonfil",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 1,
        zona: "Rural"
    },
    {
        codigo_postal: 76900,
        colonia: "Misiones de Corregidora",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Pueblo Nuevo",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "El Pueblito Centro",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Emiliano Zapata",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Residencial Bellavista Diamante",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Las Palomas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Los Nogales",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Las Flores",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Santuarios del Cerrito",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Rincón Colonial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "San Miguelito",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Talavera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "La Antigua",
        tipo_colonia: "Condominio",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "El Rocio Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "El Zorzal",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "El Sorgo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Portal del Ángel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Rinconada de la Virgen",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Residencial Bellavista Mezquites",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Los Frailes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Villa Antigua",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Huertas de la Virgen",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Haciendas del Pueblito",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Privada de las Capillas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Privada Bellavista",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Gallegos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Don Bosco",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Catalina",
        tipo_colonia: "Condominio",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "La Ciénega",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Villas de la Corregidora",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "La Pirámide",
        tipo_colonia: "Zona federal",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "34",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Misión la Esperanza",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Ampliación el Pueblito",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Orquideas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Las Flores",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76900,
        colonia: "Quintas del Bosque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "San José de los Olvera",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Las Delicias",
        tipo_colonia: "Unidad habitacional",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "El Pocito",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Villas Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Valle de los Olivos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "El Pórtico",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Rincón Campestre",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "El Batan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Tabachines",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Ámsterdam",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Misión de Santa Sofía",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Los Pinos",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "El Fortín",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Villas de Tejeda",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Misión de Santiago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "El Roble",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Santa Lucía",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Privada Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Acueducto Candiles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Amstel V",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Amstel IV",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Amstel II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Amstel I",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76902,
        colonia: "Villas Campestre 2a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "José María Truchuelo",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Vistas del Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Los Candiles",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Los Olivos",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Camino Real",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Misión de San Carlos",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Villas Fontana",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Cumbres del Roble",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Misión Mariana",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Tierra y Libertad",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Rinconada Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Lomas del Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Colinas del Sol",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Valle Diamante",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Colinas del Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Valle Real Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Claustros del Campestre",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "El Risco Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Misión Candiles",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Residencial Andrea",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Bernardo Quintana Arrioja",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Boulevares del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Venceremos",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Francisco Villa",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Lomas del Mirador",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76903,
        colonia: "Camelinas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Colinas del Santuario",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Misión San Joaquín",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Pedregal de Schoenstatt",
        tipo_colonia: "Unidad habitacional",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "31",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Pedregal de Schoenstatt",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Hacienda San Gabriel",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Misión Marbella",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Misión Antigua",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Azahar Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Los Olvera",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Huertas el Carmen",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Tejeda",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Colinas del Bosque 1a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Filosofal",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Las Fuentes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Panorama",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Los Pájaros",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Maravillas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "El Pueblito",
        tipo_colonia: "Parque industrial",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "26",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Hacienda Real Tejeda",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "La Gavia",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Ampliación Huertas del Carmen",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Luz María",
        tipo_colonia: "Condominio",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "10",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Residencial los Vitrales",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "El Prado Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Residencial la Vista",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Colinas del Remanso",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Privada las Mariposas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Los Mendoza",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "Pirules",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "La Hacienda",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76904,
        colonia: "De la Saca",
        tipo_colonia: "Barrio",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "02",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Praderas del Bosque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Lomas del Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Colinas del Bosque 2a Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Vista Real y Country Club",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "El Paraíso",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Vista Hermosa",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Bosques de Viena",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Popular Ecológica Valle de Oro)",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Los Cipreses",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "San Agustín",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Balcones de Vista Real",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76905,
        colonia: "Bahamas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76906,
        colonia: "Punta Esmeralda",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76906,
        colonia: "Santa Bárbara 1a Sección",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76906,
        colonia: "Santa Bárbara 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Misión Regina",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Altos del Pueblito",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "La Negreta",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Lomas de la Cruz",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Rincón de Guadalupe",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "21 de Marzo",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Jardines de la Negreta",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Luis Donaldo Colosio",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Los Reyes",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "1° de Mayo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Balcones de la Negreta",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Valle de Aragón 1a Sección",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "El Milagrito",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "20 de Enero",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "20 de Enero 2a Sección",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Prados de Balvanera",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Valle de los Pinos",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Villas de las Flores",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Misión San José",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Espíritu Santo",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Valle de Santiago",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Colinas del Sur",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Valle Arboledas",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76907,
        colonia: "Misión San Juan",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Rinconada Mediterráneo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Las Cabañas",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76908,
        colonia: "Niños Héroes de Chapultepec",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Puerta de Piedra",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Colinas de los Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Moralta Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Los Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Amanecer Balvanera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Lomas de Balvanera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Balvanera",
        tipo_colonia: "Parque industrial",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "26",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Pirámides",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Valle Dorado 2000",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Pueblito Colonial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Praderas de los Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Ampliación los Ángeles",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Mediterráneo I",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Colinas de Balvanera",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Federal del Río",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Mediterráneo II",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Mediterráneo III",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Santa Virginia",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Pirámides 3a. Sección",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "La Palma",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Trojitas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Los Arrayanes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76908,
        colonia: "Residencial las Trojes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        ciudad: "El Pueblito",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano",
        clave_ciudad: "04"
    },
    {
        codigo_postal: 76910,
        colonia: "San Jerónimo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Terranova",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Casa Magna",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Carlota Hacienda Vanegas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Puerta Real",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Desarrollo Hidalgo (Desarrollo Zapata)",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Jardines de la Corregidora",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76910,
        colonia: "Paseos del Bosque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76912,
        colonia: "Magisterial",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76912,
        colonia: "San Mateo",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76912,
        colonia: "Los Mezquites",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76912,
        colonia: "Cruz de Fuego",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76915,
        colonia: "El Romeral",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76915,
        colonia: "Balvanera Polo y Country Club",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76920,
        colonia: "Lomas de Zaragoza",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76920,
        colonia: "Hacienda el Batán",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76922,
        colonia: "Cañadas del Lago",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "El Manantial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "El Condado",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "Arroyo Hondo",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "El Progreso (Las Trojas)",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "San Francisco",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "Real del Bosque",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76922,
        colonia: "El Sabino",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76922,
        colonia: "Condes (Residencial)",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76923,
        colonia: "La Poza",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76923,
        colonia: "El Ranchito",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76923,
        colonia: "La Tinaja",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76923,
        colonia: "Purísima de San Rafael",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76924,
        colonia: "Loma de San Isidro",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76924,
        colonia: "San Miguelito",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76924,
        colonia: "San Rafael",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76924,
        colonia: "Puerta de San Rafael",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76924,
        colonia: "Presa de Bravo",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76925,
        colonia: "Fracción San Luis",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76925,
        colonia: "Las Taponas",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76925,
        colonia: "Bravo",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76926,
        colonia: "Loma Bonita",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76926,
        colonia: "Lomas de Charco Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76926,
        colonia: "Pita",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76926,
        colonia: "La Cantera",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76926,
        colonia: "La Cueva",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76926,
        colonia: "La Purísima de la Cueva",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76926,
        colonia: "Charco Blanco",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76926,
        colonia: "El Jaral",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Bosques de Lourdes",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Doctores",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Praderas de Lourdes",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Loretto Residencial",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Paisajes de Lourdes",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Lourdes",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76927,
        colonia: "Vistas del Sol",
        tipo_colonia: "Colonia",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76928,
        colonia: "San Felipe Calichar",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76928,
        colonia: "El Calichar",
        tipo_colonia: "Pueblo",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76930,
        colonia: "Santa María Magdalena",
        tipo_colonia: "Ejido",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "15",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76930,
        colonia: "Veredas",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76930,
        colonia: "Residencial Santa Fe",
        tipo_colonia: "Fraccionamiento",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 6,
        zona: "Urbano"
    },
    {
        codigo_postal: 76930,
        colonia: "San Juanico",
        tipo_colonia: "Ranchería",
        municipio: "Corregidora",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 6,
        zona: "Rural"
    },
    {
        codigo_postal: 76950,
        colonia: "La Florida",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76950,
        colonia: "Huimilpan Centro",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76950,
        colonia: "Las Taponas",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76950,
        colonia: "Alto",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76951,
        colonia: "El Vegil",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76952,
        colonia: "La Mora",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76952,
        colonia: "El Salto de la Cantera",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76952,
        colonia: "Guadalupe Segunda Fracc. 2 (La Peña Colorada)",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76952,
        colonia: "Las Monjas",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76952,
        colonia: "El Salto",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76952,
        colonia: "El Granjeno",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76952,
        colonia: "La Cuesta",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76953,
        colonia: "Piedras Lisas",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76955,
        colonia: "La Ceja",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76956,
        colonia: "Ceja de Bravo",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76957,
        colonia: "La Peña",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76957,
        colonia: "Buenavista",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76957,
        colonia: "Huitrón",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76958,
        colonia: "La Nueva Joya",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76958,
        colonia: "El Peral",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76958,
        colonia: "San José Tepuzas",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76958,
        colonia: "El Salitrillo",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76963,
        colonia: "Los Bordos",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76964,
        colonia: "La Presita (Palo Blanco)",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76964,
        colonia: "Puerta del Tepozán",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76965,
        colonia: "Guadalupe Segundo Fracción Primera",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76965,
        colonia: "El Mirador",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76965,
        colonia: "Guadalupe Segundo Fracción Tres",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76965,
        colonia: "La Haciendita",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76970,
        colonia: "Los Cues",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76970,
        colonia: "El Bimbalete",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76973,
        colonia: "La Noria",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76973,
        colonia: "Cumbres del Cimatario",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76974,
        colonia: "San Antonio la Galera",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76975,
        colonia: "Las Barrancas",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76975,
        colonia: "Los Timoteo",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76976,
        colonia: "Guadalupe Primero",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76976,
        colonia: "Santa Teresa",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76980,
        colonia: "Lagunillas",
        tipo_colonia: "Colonia",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "09",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76981,
        colonia: "El Milagro",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76983,
        colonia: "San Judas",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76983,
        colonia: "La Bomba",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76983,
        colonia: "San Antonio del Puente",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76983,
        colonia: "Nuevo Apapátaro",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76983,
        colonia: "La Soledad",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76983,
        colonia: "Los Betos",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76983,
        colonia: "Apapátaro",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76984,
        colonia: "El Garruñal (San Felipe de Jesús)",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76984,
        colonia: "El Zorrillo (Santa Cruz)",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76984,
        colonia: "Los Corrales",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76984,
        colonia: "Carranza (San Antonio)",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76985,
        colonia: "La Mesita Lagunillas",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76985,
        colonia: "Paniagua",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76985,
        colonia: "El Fresno",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76990,
        colonia: "San Pedrito",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76992,
        colonia: "Pío XII",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76992,
        colonia: "El Rincón",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76992,
        colonia: "La Joya",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76992,
        colonia: "Nevería Sector Norte (Neverías los Martínez)",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76992,
        colonia: "Campestre Bosques del Sur",
        tipo_colonia: "Fraccionamiento",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "21",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76992,
        colonia: "San Francisco (San Francisco Nevería)",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76992,
        colonia: "Neverías II",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76993,
        colonia: "San Pedro Sector Norte",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76994,
        colonia: "Capula",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76995,
        colonia: "San Pedro",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76997,
        colonia: "San Ignacio",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76998,
        colonia: "El Sauz",
        tipo_colonia: "Pueblo",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "28",
        municipio_num: 8,
        zona: "Urbano"
    },
    {
        codigo_postal: 76998,
        colonia: "Los Cabrera",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    },
    {
        codigo_postal: 76998,
        colonia: "El Pajarito",
        tipo_colonia: "Ranchería",
        municipio: "Huimilpan",
        estado: "Querétaro",
        estado_num: 22,
        c_tipo_asenta: "29",
        municipio_num: 8,
        zona: "Rural"
    }
]

export default suburbs