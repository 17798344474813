// import 'vue-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation 
    },
    name: "Blog",
    data () {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                320: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                  },
                // 700px and up
                700: {
                  itemsToShow: 3.5,
                  snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                  itemsToShow: 3.4,
                  snapAlign: 'start',
                }
            },
            windowWidth:window.innerWidth,
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
          goToBlog  (arg){
            this.$router.push({name:'index.info.blog',params:{type:arg}})
        }
    },
    mounted () {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
          })
    }
}
