import {  mapGetters ,mapActions } from 'vuex'
import { FormWizard,TabContent } from 'vue-form-wizard'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import closeRent from '@/components/owner/modalCloseProceso'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required, maxLength,email,minLength,helpers } from 'vuelidate/lib/validators'
import axiosTrocutServer from '@/axios/axiosTrocutServer'


export default {
  mixins:[validationMixin],
  components: {
    FormWizard,
    TabContent,
    closeRent,
    Datepicker,
    Multiselect
  },
  props:{
    flag: ''
  },
  data () {
    return {
      optionsCity: [],
      optionsNeighborhood: [],
      optionsTownship: [],
      optionsStates: [],
      optionsBanks: [],
      municipios: [],
      typeImage: [3,13,4],
      currentRolComplete:[],
      pictureDocumentation:[],
      storeStep:[],
      es: es,
      today:'',
      collapse1: false,
      collapse2: false,
      collapse3: false,
      agenda: false,
      submitted: false,
      keyG: false,
      imageSpinner: true,
      state: {
        date: new Date
      },
      hora: 0,
      spinnBrokerInfo: false,
      infoUser:{
        name: '',
        lastname: '',
        lastname2: '',
        birthday: '',
        nationality: 'Mexico',
        generalInfoNumber: 0
      },
      brokerInfo: {
        completeName: '',
        email: '',
        city: '',
        town: '',
        state: '',
        street:'',
        phoneNumber: '',
        postalCode: '',
        suburb: '',
        interior_number: 0,
        outdoor_number: 0
      },
      parthnerBroker: {
        socioBroker:'',
      },
      bankInfoBroker: {
        bank: '',
        clabe: '',
        rfc: ''
      },
      errorExistInfo: false,
      errorExistAddress: false,
      errorExistPartnerBroker: false,
      addressPapers:true
    }
  },
  validations () {
    return {
      infoUser:{
        name: { isNameValid: helpers.regex('isNameValid',/^([a-zA-Z]|[à-ú]|[À-Ú])+$$/),required },
        lastname:{ isNameValid: helpers.regex('isNameValid',/^([a-zA-Z]|[à-ú]|[À-Ú])+$$/), required},
        lastname2:{ isNameValid: helpers.regex('isNameValid',/^([a-zA-Z]|[à-ú]|[À-Ú])+$$/), required},
        birthday: {required}
      },
      brokerInfo: {
        town: { required },
        postalCode: {  required },
        //postalCode: {  required,minLength: minLength(4) },
        city: { required },
        street: { required },
        suburb: { required },
        outdoor_number: { required }
      },
      parthnerBroker: {
        socioBroker: { required, email }
      },
      bankInfoBroker: {
        bank: { required },
        clabe: { required, minLength: minLength(2), maxLength: maxLength(18) },
        rfc: { required, minLength: minLength(2), maxLength: maxLength(13) }
      }
    }
  },
  computed: {  
    ...mapGetters({
      user: 'getUser',
      getCurrentRol: 'getCurrentRol',
      getRol:'getRol',
      getStoreUser:'getStoreUser'
    }),
    full_name () {
      return this.user.user_data.fullName !== undefined ? this.user.user_data.fullName : ' '
    }
  },
  watch: {
  },
  methods: {
    ...mapActions({
      updateNextStep:'updateNextStep',
      updateImage:'updateImage',
      getUserDataStore:'getUserDataStore',
      countNotifications: 'countNotifications'
    })
    ,
    nameWithLangCity ({ name }) {
      return `${name}`
    },
    recibirllave () {
      this.keyG = true
    },
    gotToCards () {
      this.$router.push({path:'broker/propertyes'})
    },
    secondModal () {
      this.$modal.show('modal-closeProcess')
    },
    closeModal () {
      this.noCompleteNotification()
      this.$modal.hide('modalAcount')
    },
    async updateStatususer(additionalInfo,user_rol_id,step,status){
      try {
        let params = {
          complete_additional_info:additionalInfo,
          next_step:step,
          user_rol_id:user_rol_id,
          status_id:status
        }
        const response = await axiosTrocutServer.post('/updateStepUserDataTenant',params)
        if(response.status === 200){
          this.updateNextStep(step)
        }
      } catch (error) {
        console.error(error);
      }
    },
    async compareData(){
      try {
        let valid = true
        this.$v.infoUser.$touch()
        this.$v.brokerInfo.$touch()
        this.$v.parthnerBroker.$touch()
        this.errorExistInfo = false
        this.errorExistAddress = false
        this.errorExistPartnerBroker = false
        if (this.$v.infoUser.$anyError) {
          this.errorExistInfo = true
          valid = false
        }if(this.$v.brokerInfo.$anyError){
          this.errorExistAddress = true
          valid = false
          this.getTownship()
        }
        if(this.$v.parthnerBroker.$anyError){
          this.errorExistPartnerBroker = true
          valid = false
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; favor de llenar los campos faltantes`,
            type: 'error'
          })
        }
        if(valid){
          const x  = await this.savePersonalInfo()
          const y = await this.savePersonalInfoBroker()
          if(x && y){
           await this.updateStatususer(1,this.currentRolComplete.user_rol_id,1,2)
           await this.savePartherBroker(this.parthnerBroker.socioBroker,this.currentRolComplete.user_rol_id)
          }else{
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; favor de llenar los campos faltantes`,
              type: 'error'
            })
          }
          return valid
        }
      } catch (error) {
        console.error(error);
      }
    },
    validateState (name, type) {
      let data = []
      if (type === 'brokerInfo') {
        data = this.$v.brokerInfo[name]
      }
      if (type === 'infoUser') {
        data = this.$v.infoUser[name]
      }if(type === 'parthnerBroker'){
        data = this.$v.parthnerBroker[name]
      }
      const { $dirty, $error } = data
      return $dirty ? !$error : null
    },
  async createNotification (){
      try {
          let params = {
            notification: `Hola ${this.user.user.email}, has culminado satisfactoriamente tu proceso de alta de
            información y documentación en nuestra plataforma`,
            importantId: 1,
            user_rol_id: this.currentRolComplete.user_rol_id,
            notification_type: "Broker",
            rental_process_id: 0,
            idTemplate: 30,
            userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
            email: this.user.user !== undefined ? this.user.user.email : this.user.user.email
          }
          const response = await axiosTrocutServer.post('/addNotification',params)
      } catch (error) {
          console.error(error);
      }  
  },
  async noCompleteNotification(){
    let payload = {
      notification: `Hola ${this.user.user.email}, Ups!, hemos detectado que aún tienes información por
      completar.`,
      importantId: 1,
      user_rol_id: this.currentRolComplete.user_rol_id,
      notification_type: "Broker",
      rental_process_id: 0,
      idTemplate: 31,
      userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
      email: this.user.user !== undefined ? this.user.user.email : this.user.user.email,
      roleId: 4
    }
    const response = await axiosTrocutServer.post('/addNotification',payload)
    
  },
  async savePersonalInfoBroker (){
    try {
      this.submitted = true
      if(this.$v.brokerInfo.$anyError === false){
        let payload = {}
        var nacimientoMoment = moment (this.infoUser.birthday).format('YYYY-MM-DD')
        var actualidadMoment = moment().format('YYYY-MM-DD')
        var nacimiento = new Date(nacimientoMoment)
        var actualidad = new Date(actualidadMoment)
        var edad = Math.abs(nacimiento.getFullYear() -actualidad.getFullYear())
        if(edad >= 18){
          payload = {
            user_id: this.user.user.user_id,
            name: this.infoUser.name ,
            lastname:this.infoUser.lastname,
            lastname2:this.infoUser.lastname2,
            birthday: moment(this.infoUser.birthday).format('YYYY-MM-DD'),
            is_foreign: 0,
            rfc:' ',
            nationality:'Mexico'
          }
            const response = await axiosTrocutServer.post('/registerUserData',payload)
            if(response.status === 200){
              this.mountedInfo()
              //this.savePartherBroker()
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;informacion registrada con exito`,
                type: 'success'
              })
              return response
            }
        } else {
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; debes de tener la mayoria de edad para poder seguir con el proceso`,
            type: 'error'
          })
        }
      }else {
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp;&nbsp; Favor de llenar los los campos con * `,
          type: 'error'
        })
      }
    } catch (error) {
      console.error(error);
    }
  },
  async savePersonalInfo () {
    try {
      this.submitted = true 
        if(!this.$v.brokerInfo.$anyError){
          const params = {
            street: this.brokerInfo.street,
            suburb_id: this.brokerInfo.suburb.value,
            city_id: this.brokerInfo.city.value,
            state_id: 1,
            interior_number: parseInt(this.brokerInfo.interior_number),
            outdoor_number: parseInt(this.brokerInfo.outdoor_number),
            user_rol_id: this.getRol !== undefined ? this.getRol : this.user.roles[0].user_rol_id,
            address_type: 'User',
            town_id: this.brokerInfo.town.value,
            postal_code: this.brokerInfo.postalCode,
            user_id: this.currentRolComplete.user_id
          }
          const response = await axiosTrocutServer.post('/addAddress', params)
          if (response.status === 200) {
            this.$emit('getAddress')
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp; Información guardada correctamente.`,
              type: 'success'
            })
            return response
          }
        }else {
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp;&nbsp;Favor  de llenar los campos marcados con *`,
            type: 'error'
          })
        }
      } catch (error) {
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp;&nbsp;Favor  de llenar los campos marcados con *`,
          type: 'error'
        })
        console.error('Error', error);
      }
  },
  async saveBancaryInfo (){
    try {
      this.submitted = true
      if(!this.$v.bankInfoBroker.$invalid){
        let payload = {}
        payload = {
          user_id: this.user.user.user_id,
          name: this.user.user_data.name ,
          lastname:this.user.user_data.lastname,
          lastname2:this.user.user_data.lastname2,
          birthday:this.infoUser.birthday = moment (this.infoUser.birthday).format('YYYY-MM-DD'),
          is_foreign: 0,
          rfc: this.bankInfoBroker.rfc,
          id_bank: this.bankInfoBroker.bank.value,
          interbank_code:this.bankInfoBroker.clabe,
          nationality:'Mexico'
        }
        const response = await axiosTrocutServer.post('/registerUserData',payload)
        if(response.status === 200){
          this.$modal.show('modal-finalizarAlta-Broker')
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp;&nbsp;Información bancaria guardada correctamente.`,
            type: 'success'
          })
          this.updateInfoBase()  
          this.updateStatususer(1,this.currentRolComplete.user_rol_id,4,2)
        }
      }else {
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp;&nbsp; Favor  de llenar los campos marcados con *`,
          type: 'error'
        })
      }
    } catch (error) {
      console.error(error);
    }
  },
  async savePartherBroker(email,id_broker){
    this.spinnBrokerInfo = true
    try {
      this.submitted = true
      const params = {
        email: email,
        broker_id: id_broker
      }
      const response = await axiosTrocutServer.post('/addPartnerBroker', params)
      if(response.status === 200){
        this.spinnBrokerInfo = false
        this.$refs.wizzardBroker.nextTab()
      }if(response.status === 500){
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp; `,
          type: 'error'
        })
      }
    } catch (error) {
      console.error(error);
    }
  },
  async updateInfoBase (){
    try {
      let params = {
        complete_info_base: 1,
        complete_address: 1,
        user_id:this.user.user.user_id
      }
      const response = await axiosTrocutServer.post('/updateInfoBase',params)
      if( response.status ===  200){
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp;felicides has completado la informacion base`,
          type: 'success'
        })
      }
    } catch (error) {
      console.error(error);
    }
  },
    async imageProfile (e){
    try {
      const images = e.target.files
      var form = new FormData()
      form.append("type","User")
        form.append("subtype","images")
        form.append("user_id",this.user.user.user_id)
      for (const key in images) {
        form.append('Images', images[key])
      }
      this.imageSpinner = false
      const response = await axiosTrocutServer.post('/addProfileImage', form)
      if( response.status === 200){
        this.imageSpinner = true
        this.updateStatususer(1,this.currentRolComplete.user_rol_id,2,2)
        this.updateImage({
          user_id: this.user.user.user_id
        })
        this.getUserDataStore({
          user_id: this.user.user.user_id
        })
          this.$emit('loadImage')
          this.$refs.wizzardBroker.nextTab()
      }
    } catch (error) {
      this.imageSpinner = true
      this.$toastedPush({
        message: `&nbsp;&nbsp;&nbsp; favor de subir una foto para seguir avanzando en el proceso de alta de su informacion`,
        type: 'error'
      })
    }
  }, 
  async ineFront(e){
    if( e !== undefined){
      if(e.target !== undefined){
        const file = e.target.files[0]
        let fileType = file.type
        let fileName = file.name
        fileName = `INE_FRONT.${fileType[1]}`
        document.getElementById('idFront').src = window.URL.createObjectURL(file)
        this.pictureDocumentation.push({
          name:fileName,
          file
        })
      }else{
        const file = e
        if(document.getElementById("idFront") !== null){
          document.getElementById("idFront").src = `${file.url}`
        }
      }
    }
  },
  async ineBack(e){
    if( e !== undefined){
      if(e.target !== undefined){
        const file = e.target.files[0]
        let fileType = file.type
        let fileName = file.name
        fileName = `INE_BACK.${fileType[1]}`
        document.getElementById('idCardB').src = window.URL.createObjectURL(file)
        this.pictureDocumentation.push({
          name:fileName,
          file
        })
      }else {
        const file = e
        if(document.getElementById("idCardB") !== null){
          document.getElementById("idCardB").src = `${file.url}`
        }
      }
    }
  },
  async comproabnte(e){
    if( e !== undefined){
      if(e.target !== undefined){
        const file = e.target.files[0]
        let fileType = file.type
        let fileName = file.name
        fileName = `CompronteDomicilio.${fileType[1]}`
        this.addressPapers = false
        this.pictureDocumentation.push({
          name:fileName,
          file
        })
      }else {
        this.addressPapers = false
      }
    }
  },
  async nextTab (prop) {
    let activeIndex = prop.activeTabIndex
    switch (activeIndex) {
      case 0:
        this.compareData()
        break;
        case 1:
          this.imageProfile()
          break;
        case 2:
            try {
            const tempo = []
            for (let i = 0; i < this.pictureDocumentation.length; i++) {
              const valuesArary = Object.values(this.pictureDocumentation[i]).map(el => {
                tempo.push(el)
              })
            }
            if( tempo.length >=6) {
              const ineF = document.getElementById("idFront").src
              const ineB = document.getElementById("idCardB").src
              var form = new FormData()
              form.append("type","User")
              form.append("subtype","documents")
              form.append("user_id",this.user.user.user_id)
              form.append("user_rol_id",this.currentRolComplete.user_rol_id)
              for (const key in tempo) {
                form.append('id_media_type',this.typeImage[key])
                form.append('Documentation', tempo[key])
              } 
              this.imageSpinner = false
                const response = await axiosTrocutServer.post('/addDocumentation',form)
                console.log(response)
                if(response.status === 200) {
                  this.imageSpinner = true
                  this.updateStatususer(1,this.currentRolComplete.user_rol_id,3,5)
                  this.countNotifications(this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : undefined)
                  this.getUserDataStore({
                    user_id: this.user.user.user_id
                  })
                  this.getMediaDocument()
                  this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; se han cargado los documentos de manera satisfactoria`,
                    type:`success`
                  })
                  this.$refs.wizzardBroker.nextTab()
                }else if(ineB !== null  && ineF !== null){
                  this.$refs.wizzardAltaPropiedad.nextTab()
                  return
              }
              return
            }
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; favor de subir las imagenes/documentos que se requieren `,
              type:`error`
            })
          } catch (error) {
            this.imageSpinner = true
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; favor de subir las imagenes/documentos que se requieren`,
              type:`error`
            })
          } 
        break
        case 3:
          this.saveBancaryInfo()
          break;
      default:
      break;
    }
  },
  async getCities (...arg) {
    try {
      this.optionsCity = []
      const params = {
        town_id: arg[0].value
      }
      const response = await axiosTrocutServer.post('/getCities', params)
      if (response.status === 200) {
          response.data.cities.forEach(el => {
              this.optionsCity.push({
                  name: el.city,
                  value: el.city_id
              })
          })
        }
      } catch (error) {
      console.error('getCities Error', error)
    }
  },
  async getTownship () {
    try {
      this.optionsTownship = []
      const response = await axiosTrocutServer.post('/getTownship')
      if (response.status === 200) {
          response.data.township.forEach(el => {
              this.optionsTownship.push({
                  name: el.township,
                  value: el.township_id
                })
              })
            }
          } catch (error) {
      console.error('getTownship Error', error)
    }
  },
  async getStates () {
      try {
        const response = await axiosTrocutServer.post('/getStates')
        if (response.status === 200) {
            response.data.states.forEach(el => {
                this.optionsStates.push({
                    name: el.states,
                    value: el.state_id
                })
            });
        }
      } catch (error) {
        console.error('getStates Error', error)
      }
  },
  async getBanks () {
    try {
      const response = await axiosTrocutServer.post('/getBanks')
      if (response.status === 200) {
          response.data.banks.forEach(el => {
              this.optionsBanks.push({
                  name: el.bank,
                  value: el.bank_id
              })
          });
      }
    } catch (error) {
      console.error('getStates Error', error)
    }
  },
  async getNeighborhoods (...arg) {
      try {
        this.optionsNeighborhood = []
        const params = {
          city_id: arg[0].value,
          stock: false
        }
        const response = await axiosTrocutServer.post('/getNeighborhoods', params)
        if (response.status === 200) {
            response.data.neighborhood.forEach(el => {
                this.optionsNeighborhood.push({
                    name: el.neighborhood,
                    value: el.neighborhood_id
                })
            });
        }
      } catch (error) {
        console.error('getNeighborhoods Error', error)
      }
  },
  async loadAddres () {
    try {
      let params = {
        user_id : this.currentRolComplete.user_id,
        address_type : 'User'
      }
      if(this.currentRolComplete.user_rol_id !== undefined ){
        const response  = await axiosTrocutServer.post('/getAddress',params)
        if ( response.status === 200 ){
          response.data.address.forEach(el => {
            this.brokerInfo.city = {value:el.city_id,name:el.city},
            this.brokerInfo.town = {value:el.town_id,name:el.township},
            this.brokerInfo.state = el.state,
            this.brokerInfo.street = el.street,
            this.brokerInfo.socioBroker= el.parther,
            this.brokerInfo.postalCode= el.postal_code,
            this.brokerInfo.suburb= {value:el.suburb_id, name:el.suburb},
            this.brokerInfo.interior_number= el.interior_number,
            this.brokerInfo. outdoor_number= el.outdoor_number,
            this.parthnerBroker.socioBroker = el.parther,
            this.setDataAddress(el)
          })
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  setDataAddress(arg){
    this.getCities(arg.city_id)
    this.getNeighborhoods(arg.suburb_id)
  },
  sendInformation(){
    this.$modal.hide('modal-finalizarAlta-Broker')
    this.$modal.hide('modalAcount')
    this.createNotification()
    this.getUserDataStore({
      user_id: this.user.user.user_id
    })

  },
  mountedInfo(){
    this.brokerInfo.email = this.user.user.email
    this.brokerInfo.phoneNumber = this.user.user.phone
    this.brokerInfo.state = 'Querétaro'
    this.brokerInfo.email = this.user.user.email
    this.infoUser = this.user.user_data !== undefined ? this.user.user_data : []
    this.bankInfoBroker.clabe = this.user !== undefined ? this.user.user_data.interbank_code :''
    this.bankInfoBroker.rfc = this.user.user_data.rfc 
    this.bankInfoBroker.bank = {value:this.user.user_data.id_bank,name:this.user.user_data.bank}
    this.infoUser.birthday !== undefined ?  this.user.user_data.birthday = moment(this.user.user_data.birthday).format('YYYY-MM-DD') : '' 
    this.infoUser.generalinfoNumber = this.user.user.phone
    this.infoUser.name = this.user.user_data.name
    this.storeStep[0].next_step !== undefined ? this.storeStep[0].next_step :0
    if(this.$refs.wizzardBroker !== undefined){
     /*  this.$refs.wizzardBroker.changeTab(0,this.storeStep[0].next_step) */
      this.$refs.wizzardBroker.changeTab(0,3)
    }else{
      if(this.flag === 'pencil'){
        this.currentRolComplete.next_step = 0
      }
    }
    this.ineFront(this.pictureDocumentation[0])
    this.ineBack(this.pictureDocumentation[1])
    this.comproabnte(this.pictureDocumerntation[2])
  },
  async getImageProfile() {
    try {
      let params = {
        user_id:this.currentRolComplete.user_id,
        type_media_id:5
      }
      const response = await axiosTrocutServer.post('/getMedia',params)
      if(response.status === 200){
        response.data.resolve.forEach(el => {
          if(el.url !== null || el.url !== undefined){
            if(this.$refs.wizzardBroker !== undefined){
              this.$refs.wizzardBroker.changeTab(0,2)
              document.getElementById('profilePicture').src = el.url
            }
            
          } 
        })
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getMediaDocument() {
    try {
      let params = {
        user_id : this.currentRolComplete.user_id,
        user_rol_id : this.currentRolComplete.user_rol_id
    }
      const response = await axiosTrocutServer.post('/getMedia',params)
      if(response.status === 200){
        let arraTemp = []
        response.data.resolve.forEach(element => {
          if(element.name  === 'INE_FRONT' || element.name  === 'INE_BACK' || element.name  === 'CompronteDomicilio' ) {
            this.pictureDocumentation.push(element)
            arraTemp.push(element)
          }
        })
        this.ineFront(arraTemp[0])
        this.ineBack(arraTemp[1])
        this.comproabnte(arraTemp[2])
      }
    } catch (error) {
      console.error(error);
    }
  }
},
 async  mounted () {
    this.today = moment().format('YYYY-MM-DD')
    const tempArr = []
    document.getElementById("profilePicture").src = 'https://hennesseyonline.com/wp-content/uploads/2017/01/xgeneric-profile-avatar_352864.jpg.pagespeed.ic.v7SgxPyPI2.jpg'
    this.user.roles.forEach(el => {
      tempArr.push(el)
      this.storeStep = tempArr.filter(t => {
        return t.rol === 'Broker'
      })
    })
    const store = this.getStoreUser.roles
    this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
   
    
    this.mountedInfo()
    this.loadAddres()
    /* this.getTownship()
    this.getStates()
    
    await this.getBanks()
    this.getImageProfile()
    this.getMediaDocument() */
  }
}