import VueHtml2pdf from 'vue-html2pdf'

export default {
  components:{
    VueHtml2pdf
  },
  props:{
    brokerName:String,
    ownerName:String
  },
  data () {
    return{
    }
  },
  computed: {
  },
  methods: {
    async generatePdfTemplete(){
      console.log('se lanzo el pdf')
      //this.$refs.html2Pdf.generatePdf()
    },
    dataEmit(){
      this.$emit('buildPdf')
      console.log('llego el emit');
    }
  },
  mounted () {
  }
}