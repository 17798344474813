const alerts = {
    alertas:{
      alertsArr:[]
    },
    mutations: {
      storeAlerts (alertas, params) {
        alertas.alertsArr = params
      },
      cleanAlert (alertas) {
        alertas.alertsArr = []
      },
    },
    actions: {
      setAlerts ({ commit }, params) {
        commit('storeAlerts', params)
      },
      clearAlert ({commit}){
        commit('cleanAlert')
      }
    },
    getters: {
      getStoreAlerts (alertas) {
        return alertas.alertsArr
      }
    }
  }
export default alerts
  