import { FormWizard,TabContent } from 'vue-form-wizard'
import steProgress from '@/components/generals/step-progress'
import closeRent from '@/components/owner/modalCloseProceso'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
export default {
    
    components:{
        steProgress,
        FormWizard,
        TabContent,
        closeRent,
        VueHtml2pdf
    },
    props:{
        idProperty:0
    }
    ,
    data () {
        return{
            currentRolComplete: [],
            Tours: [],
            Finalizados: [],
            prospectos: [],
            investigacion: [],
            investigacionStatus:  [],
            cierre: [],
            firma: [],
            propertyInfo: [],
            idp: 0,
            detailsProperty: []
        }
    },
    computed:{
        ...mapGetters({
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser',
            user: 'getUser'
        })
    },
    methods: {
        gotToCards () {
            this.$router.push({path:'owner/propertyes'})
        },
        secondModal(idP){
            this.idp = idP
            this.$modal.show('modal-closeProcess')
        },closeModal (){
            this.$modal.hide('modal-Owner')
        },
        nextSave (prop) {
            let indexAddPoliza = prop.activeTabIndex
            switch (indexAddPoliza) {
                case 0:
                    this.$refs.wizzardProcesoCierre.nextTab()
                    break;
                case 1:
                    this.$refs.wizzardProcesoCierre.nextTab()
                    break;
                case 2:
                    this.$refs.wizzardProcesoCierre.nextTab()
                break;
                case 3:
                    this.$refs.wizzardProcesoCierre.nextTab()
                break;
                case 4:
                    this.$refs.wizzardProcesoCierre.nextTab()
                break;
                case 5:
                    this.$modal.hide('modal-Owner')
                break;
                default:
                    break;
            }
        },
        async getDetails(){
            try {
                let params = {
                    property_id: this.idProperty
                }
                const response = await axiosTrocutServer.post('/getPolicy',params)
                if(response.status === 200){
                    response.data.poliza.forEach(el => {
                     this.detailsProperty.push(el)
                    })
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getProspectos(){
            try {
                let params = {
                    user_rol_id: this.currentRolComplete.user_rol_id
                } 
                const response = await axiosTrocutServer.post('/getAdditionalOwnerInfo',params)
                if(response.status === 200){
                    response.data.message.toursfinalizados.forEach(el => {
                        if(el.property_id === this.idProperty){
                            this.Finalizados.push({
                                tenant:el.tenant,
                                date :moment(el.date).format('YYYY-MM-DD')
                            })
                            this.Tours.push({ 
                                tenant:el.tenant,
                                date :moment(el.date).format('YYYY-MM-DD')
                            })
                        }
                    })
                    response.data.message.prospectos.forEach(el => {
                        if(el.property_id === this.idProperty){
                            this.prospectos.push({ 
                                Tenant: el.Tenant,
                                init_date: moment(el.init_date).format('YYYY-MM-DD')
                            }) 
                        }
                    })
                    response.data.message.investigacion.forEach(el => {
                        if(el.property_id === this.idProperty){
                            this.investigacion.push({
                                Tenant: el.Tenant,
                                status: el.status,
                                init_date: moment(el.init_date).format('YYYY-MM-DD')
                            })
                        }
                    })
                    response.data.message.resultados.forEach(el => {
                        if(el.property_id === this.idProperty){
                            this.investigacionStatus.push({
                                Tenant: el.Tenant,
                                status: el.status,
                                init_date: moment(el.init_date).format('YYYY-MM-DD')
                            })
                        }
                    })
                    response.data.message.cierre.forEach(el => {
                        console.log('firma',this.firma);
                        if(el.property_id === this.idProperty){
                            this.cierre.push({ 
                                Broker: el.Broker,
                                Tenant: el.Tenant,
                                init_date: moment(el.init_date).format('YYYY-MM-DD'),
                                last_update: moment(el.last_update).format('YYYY-MM-DD')
                            })
                            this.firma.push({
                                Broker: el.Broker,
                                DaySig:moment(el.init_date).locale('es').format('dddd'),
                                DateSig:moment(el.init_date).format('YYYY-MM-DD'),
                                TimeSig:moment(el.init_date).format('LT')
                            })
                        }
                    })
                    this.getDetails()
                }
            } catch (error) {
                console.error(error);
            }
        },
        generatePDF(){
            console.log('por amor a jebus el testamento de codigo que esta escrito creo que indica falta de vida social y novia, son 360 lineas');
            this.$refs.html2Pdf.generatePdf()
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
        this.getProspectos()
    }
}