const toastedPlugin = {
  install (Vue, options) {
    Vue.prototype.$toastedPush = (value) => {
      var iconfa = ''
      switch (value.type) {
        case 'success':
          iconfa = Vue.icon(Vue.fa.faCheck).html
          break
        case 'info':
          iconfa = Vue.icon(Vue.fa.faExclamationTriangle).html
          break
        case 'error':
          iconfa = Vue.icon(Vue.fa.faExclamationTriangle).html
          break
        default:
          iconfa = Vue.icon(Vue.fa.falCheck).html
          break
      }
      Vue.toasted.show(`${iconfa} ${value.message} `, {
        position: 'bottom-right',
        duration: 5000,
        type: value.type,
        action: value.action,
        ...value.options
      })
    }
  }
}

export default toastedPlugin
