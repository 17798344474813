import { mapGetters, mapActions , mapMutations } from "vuex"
import login from '@/components/home/login'
import register from '@/components/home/register'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { getAuth, signOut } from "firebase/auth"
import {  validationMixin } from 'vuelidate' 
import { email } from "vuelidate/lib/validators"

export default {
  mixins: [ validationMixin ],
  components: {
    login,
    register
  },
  name: "app",
  data () {
    return {
      subs:false,
      windowWidth: window.innerWidth,
      rolesN: [],
      rolName: [],
      newRol: [],
      emailNews: '',
      currentRolComplete: [],
      rolUser: [],
      sigInAray: [],
      notificationsInfo: [],
      submited: false
    }
  },
  validations: {
    emailNews: {email}
  },
  watch:{
    loadWidht(){
      this.onResize()
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      getCurrentRol: 'getCurrentRol',
      getAuth: 'getAuth',
      nameUser:'getUserNew',
      alerts:'getStoreAlerts',
      getRol:'getRol',
      getStoreUser:'getStoreUser',
      getRolesAuth:'getRolesAuth',
      getCountNotification: 'getCountNotification'
    }),
    currentRolCompleteStatus() {
      const store = this.getStoreUser.roles
      if(store !== undefined ){
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
        if (this.currentRolComplete != undefined){
          return this.currentRolComplete.status
        }
      }
    }
  },
  methods: {
    ...mapActions({
      signOut: 'signOut',
      signIn: 'signIn',
      setCurrentRol: 'setCurrentRol',
      resfreshRol: 'resfreshRol',
      changeRol: 'changeRol',
      setAlerts: 'setAlerts',
      getUserDataStore:'getUserDataStore',
      countNotifications: 'countNotifications',
      clearAlert: 'clearAlert'
    }),
    ...mapMutations([
      'storeRol'
    ]),
    modalLogin() {
      this.$modal.show('modal-login')
    },
    closeModal  ()  {
      this.$modal.hide('modal-other')
      this.$modal.hide('modal-change')
    },
    modalRegister() {
      this.$modal.show('modal-register')
    },
    myAccount (rol){
      if(rol === 'Propietario') {
        this.$router.replace({path:'/'})
        return this.$router.replace({ path:'owner/profile' })
      }
      if(rol === 'Broker') {
        this.$router.replace({path:'/'})
        return this.$router.replace({ path:'broker/account' })
      }
      if(rol === 'Inquilino') {
        this.$router.replace({path:'/'})
        return this.$router.replace({ path:'tenant/profile' })
      }
    },
    brokerProperties () {
      this.$router.replace({ path: '/'})
      return this.$router.replace({path:'/broker/propertyes'})
    },
    myclients (typeA) {
      switch(typeA){
        case 'Broker':
          this.$router.replace({ path: '/'})
          this.$router.replace({path:`/${typeA}/prospect`})
        break;
        case 'Propietario':
          this.$router.push({path:'/owner/clients'})
        break;
      }

    },
    propiedadesRenta () {
      this.$router.replace({ path: '/'})
      return this.$router.replace({path:'/owner/propertyes'})
    },
    async completeInfoBase (){
      try {
          let params = {
            complete_info_base: 0,
            complete_address: 0,
            user_id:this.user.user.user_id
          }
          const response = await axiosTrocutServer.post('/updateInfoBase',params)
          if(response.status === 200){
            console.log('success');
          }
      } catch (error) {
        console.error(error);
      }
    },
    logOut (){
      const auth = getAuth();
      signOut(auth).then(() => {
        this.signOut()
        this.$router.push({ path: '/'}).catch(()=>{})
        console.log('gracias por visitarnos ')
        console.log('Danke, dass Sie uns besuchen')
      }).catch((error) => {
        console.error(error);
      })
    },
    async getRolesRegister (){
      const response  = await axiosTrocutServer.post('/getRoles')
      if(response.status === 200){
        this.rolesN = response.data.roles
      }
    },
    async getUserRoles(){
      this.user !== undefined ? [] : this.user.user.user_id 
      try {
        let payload = {
          user_id:  this.user.user.user_id 
        }
        const response = await axiosTrocutServer.post('/getUsersRol',payload)
        if( response.status === 200 ){
          this.changeRol(response.data.roles)
        }
      } catch (error) {
      console.error(error);
      }
    },
    async mailSubscription (){
      try {
        let payload = {
          idTemplate:10,
          userId:0,
          email: this.emailNews
        }
        let response = await axiosTrocutServer.post('/email/actions', payload)
        if (response.status === 200){
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp;La suscripción fue exitosa `,
            type: 'success'
          })
        }
      } catch (error) {
        console.error(error);
      } 
    },
    async createnotificationNewsletter (usuario){
      try {
          let params = {
            notification: `Hola ${usuario},¡Genial!, ahora podrás recibir mensualmente nuestros boletines
            informativos, así como notas relacionadas al mundo inmobiliario.
            El equipo Trócut`,
            importantId: 1,
            user_rol_id: 0,
            notification_type: "Subscripcion",
            rental_process_id: 0,
            idTemplate: 10,
            userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
            email: this.emailNews
          }
          const response = await axiosTrocutServer.post('/addNotification',params)
          if(response.status === 200 ){
            this.mailSubscription()
          }
      } catch (error) {
          console.error(error);
      }
    },
    async subscribe (){
      try {
        this.submited = true
        this.$v.emailNews.$touch()
        if(this.$v.emailNews.$invalid){
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; Favor de ingresar un formato valido de corro electronico`,
            type: 'error'
          })
        } else {
          let payload = {
            email: this.emailNews
          }
          let response = await axiosTrocutServer.post('/registernewsletter', payload)
          if (response.status === 200) {
            this.createnotificationNewsletter(this.emailNews)
            this.subs = true
          } if (response.status === 205) {
            console.log(`response ${JSON.stringify( response)}`);
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; Lo sentimos pero este correo ya se encuentra registrado.`,
              type: 'error'
            })
          }
        }
      } catch (error) {
        this.$toastedPush({
        message: `&nbsp;&nbsp;&nbsp; Lo sentimos pero este correo ya se encuentra registrado`,
        type: 'error'
      })
        console.error(error);
      } 
    },
    onResize() {
      this.windowWidth = window.innerWidth
      console.log('esto es el ancho de la pagina', this.windowWidth);
    },
    async goToProfile( arg ) {
      switch (arg.rol) {
        case 'Inquilino':
          //Tenant
          this.setCurrentRol(arg) 
          this.signIn({email:this.user.user.email,password:this.user.password})
          this.getUserDataStore({
            user_id: this.user.user.user_id
          })
          this.$router.push({name:'index.tenant.profile'}).catch(()=>{})
        break;
        case 'Broker':
          this.setCurrentRol(arg) 
          this.signIn({email:this.user.user.email,password:this.user.password})
          this.getUserDataStore({
            user_id: this.user.user.user_id
          })
          this.$router.push({name:'index.broker.account'}).catch(()=>{})
        break;
        case 'Propietario':
          //Owner
          this.setCurrentRol(arg) 
          this.signIn({email:this.user.user.email,password:this.user.password})
          this.getUserDataStore({
            user_id: this.user.user.user_id
          })
          this.$router.push({name:'index.owner.profile'}).catch(()=>{})
        break;
      }
      this.getRolesRegister()
      this.closeModal()
    },
    DashboardUser( arg ) {
      switch (arg) {
        case 'Inquilino':
          this.$router.push({name:'index.tenant'}).catch(()=>{}) 
        break;
        case 'Broker':
          this.$router.push({name:'index.broker'}).catch(()=>{}) 
        break;
        case 'Propietario':
          this.$router.push({name:'index.owner'}).catch(()=>{}) 
        break; 
      }
    },
    notificationsBellClick(arg){
      let rutaNotificaciones = arg.toLowerCase()
      this.$router.push({path:`/${rutaNotificaciones}/notifications`})
    },
    async registerRol (arg) {
      try {
          let payload = {
            user_id: this.user.user.user_id,
            role_id: arg.rol_id ,
            os: 'web'
          }
          let response = await axiosTrocutServer.post('/registerRoleForUser', payload)
            if (response.status === 200) {
              this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp;Registro Completo`,
                  type: 'success'
                })
                this.goToProfile(arg)
                this.completeInfoBase()

              }
            } catch (error) {
              this.$toastedPush({
                message: ` &nbsp;&nbsp;&nbsp; Error registerTrocutServer ,${error}`,
              type:`error`
            })
          } 
    },
  async  reigstOtherCount  () {
      await this.getRolesRegister()
      this.newRol = []
      this.rolesN.forEach(element => {
        const x = this.user.roles.filter(el => el.rol === element.rol)
        if (x.length === 0) {
          this.newRol.push(element)
        }
      })
      this.$modal.show('modal-other')
    },
    async getCountNotifations (){
      this.notificationsInfo = []
    },
    changeSesion  () {
      this.rolName = []
      if (this.user.roles.length > 1){
        this.$modal.show('modal-change')
        for( var i=0;i < this.user.roles.length;i++){
            if(this.getCurrentRol !== this.user.roles[i].rol){
              this.rolName.push(this.user.roles[i]) 
            }
          }
      }
    },
    /* removeAlert(arg) {
      delete this.alerts[arg] 
      const tempArr = []
      this.alerts.forEach(el => {
        tempArr.push(el)
      });
      this.setAlerts(tempArr)
    },
    seasonSales(){
      this.clearAlert()
      const a = [{
        type:'alert alert-warning alert-dismissible fade show',
        msg: 'estimados usuarios de duel links es nuestro deber anunciar el que los cupones de descuento estan a la venta ahora',
        icon: 'check',
        title: 'Temporada de rebajas por Samantha Maxis'
      }
    ]
      this.setAlerts(a)
    } */
    async getAxios (){
      try {
        let payload = {
          params:{
            a:1,
            b:2,
            c:3
          }
        }
        const response = await axiosTrocutServer.get('/getNumbers',payload)
        console.log('respuesta',response)

      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted () {
      const store = this.getStoreUser.roles
        if(store !== undefined){
            this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
            this.getRolesRegister()
            this.getCountNotification
            this.countNotifications(this.currentRolComplete.user_rol_id)
        }
    // https://bandai.com.mx/blog/wp-content/uploads/2019/03/son-goku-smiling-1.jpg
    //this.getNotifations()
    this.newRol = []
    this.rolName = []
   await this.getAxios()
    //this.seasonSales()
    //this.getUserRoles()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }
}