import config from '@/config'
import firebase from 'firebase/compat/app'
import'firebase/compat/auth'
import 'firebase/compat/database'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
  OAuthCredential,
  signInWithPhoneNumber,
  EmailAuthProvider,
  linkWithCredential,
  RecaptchaVerifier,
  createUserWithEmailAndPassword
} from 'firebase/auth' 

const fire = firebase.initializeApp(config.firebaseAuth)
const db = fire.database()

const fbauth = function (token) {   
  return firebase.auth().signInWithCustomToken(token)
}

firebase.auth().useDeviceLanguage()

export {
  firebase,
  fbauth,
  db,
  fire,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
  OAuthCredential,
  signInWithPhoneNumber,
  EmailAuthProvider,
  linkWithCredential,
  RecaptchaVerifier,
  createUserWithEmailAndPassword
}
