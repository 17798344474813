import Datepicker from 'vuejs-datepicker'
import {es} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from 'vuex'

export default {
    props:{
        tour: []
    },
    components:{
        Datepicker
    },
    data () {
        return{
          currentRolComplete:[],
        es:es,
        state:{
            date: new Date
        },
        hora:0
        }
    },
    computed:{
        ...mapGetters({
            user: 'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
        })
    },
    methods: {
        closeModal (){
            this.$modal.hide('modal-agendar')
        },
        async updateSheduledTour (){
            var fecha=moment(this.state.date).format('YYYY-MM-DD')
            try {
                let params = {
                    tour_id:this.tour.tour_id ,
                    date: fecha,
                    time: this.hora,
                    status_id:2
                }
                const response = await axiosTrocutServer.post('/tourSchedule',params)
                if(response.status === 200){
                    if(!response.data.error){
                        this.$emit('updateTour')
                        this.closeModal()
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;Tour Agendado correctamente`,
                            type: 'success'
                        })
                    }
                }
            } catch (error) {
                console.error(error);
            }
            
        }
      
    },
    mounted(){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
    }
}


