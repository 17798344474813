import { FormWizard, TabContent } from 'vue-form-wizard'
import steProgress from '@/components/generals/step-progress'
import individualD from '@/components/owner/individualDrag'
import BC from '@/components/owner/carucelBrokers'
import { validationMixin } from 'vuelidate'
import { required, minLength, between, minValue, helpers } from "vuelidate/lib/validators";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import Multiselect from "vue-multiselect"
import { mapGetters,mapActions } from "vuex"
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import infOwner from '@/components/owner/generalInfo'


export default {
    mixins: [validationMixin],
    components: {
        steProgress,
        FormWizard,
        TabContent,
        individualD,
        Multiselect,
        BC,
        VueHtml2pdf,
        infOwner
    },
    props:{
        continue: 0,
        userReferences: []
    }, 
    data() {
        return {
            loadingStatus: true,
            pdfSrc: '',
            paymentPaper: true,
            currentRolComplete: [],
            noStore: false,
            temporalProperty: [],
            finishOpacity: false,
            idProperty: 0,
            petActive:  false,
            mediumPet:  false,
            largePet:  false,
            homeActive:  false,
            apartamentActive:  false,
            diasableY: false,
            diasableX: false,
            diasableZ: false,
            diasableM: false,
            diasableN: false,
            diasableO: false,
            diasbleCost: false,
            imageType: [3,3,4],
            diasablePets: false,
            priceRenta: 0,
            dateInit:null,
            garage1: false,
            garage2: false,
            garage3: false,
            othersC: '',
            continueAdd: this.continue,
            deposit: 0,
            descriptionOther: '',
            contrat: 0,
            expanded:  false,
            closeButtonRef: null,
            idAddressOwner: 0,
            maxMetters: 40000,
            selectedItem: false,
            selecAmenitie: false,
            valueMetters: [10000, 12000],
            minMetters: 4000,
            arraObjectPicture: [],
            amenitiesTemp: [],
            propertyArray: [],
            caracteristicasTemp: [],
            detailsHome: [],
            amenidadesArr: [],
            submitted:  false,
            count: 0,
            room: 0,
            garageArg: 0,
            brokersArr:[],
            submittedA: false,
            completeAddress:  false,
            bath: 0,
            ownwerBroker:  '',
            ownbroker:  false,
            validateNextBtn:  false,
            optionState: [],
            optionstownship: [],
            optionsCity: [],
            optionsneighborhood: [],
            loadDocumentsProperty:false,
            idAddress: 0,
            today: '',
            temporal: [],
            keyProperty: '',
            petN: '',
            numero: 0,
            inventaryPicture: [],
            pictureDocumentation: [],
            documentationArray: [],
            brokerArrRent: [],
            stepModal: 0,
            sterStepIndex: 0,
            loadGeneralPropertyInfo: false,
            loadCaracteristics: false,
            buttonFlag: true,
            upBuilding: {
                arrendamiento: 0,
                mantenimiento: 0,
                codigoPostal: '',
                colonia: '',
                street: '',
                numInterior: 0,
                numExterior: 0,
                description: '',
                condiciones: [],
                typeBuilding: '',
                state: '',
                township: '',
                city: '',
                neighborhood: ''
            },
            upProperty: {
                rooms: 0,
                bath: 0,
                garage: 0,
                area: 0,
                amount_rent: 0,
                cost_maintenance: 0,
                title: '',
                description: '',
                complete: 0,
                next_step: 0
            },
            otherConditionsArray: []
        }
        
    },
    validations() {
        return {
            upBuilding: {
                state: { required },
                township: { required },
                city: { required },
                neighborhood:{ required },
                codigoPostal: { required, minLength: minLength(5) },
                street: { isNameValid: helpers.regex('isNameValid',/^[A-Za-zÑñ]+$/i), required },
                numExterior: { required, minvalue: minValue(1)},
                description: { required }
            },
            upProperty: {
                rooms:  { required },
                bath:  { required },
                garage:  { required },
                area:  { required },
                amount_rent:  { 
                    between: between(7500,80000) 
                },
                cost_maintenance:  { required },
                title:{ required },
                description:{ required, },
                complete: { required }
            }
        }
    }, 
    computed: {
        ...mapGetters({
            user: 'getUser',
            getfilesArray:'getfilesArray',
            getCurrentRol:'getCurrentRol',
            getBrokerSelected:'getBrokerSelected',
            getStoreUser:'getStoreUser',
            getStore:'getStore',
            getIdAddress:'getIdAddress'
        }),
        start(item){
            return item.stepModal
        }
    },
    watch: {
        expanded (status) {
          this.$nextTick(() => {
            if (status) {
              this.cloned = this.$el.cloneNode(true)
              this.closeButtonRef = this.cloned.querySelector('.close-button')
              this.closeButtonRef.addEventListener('click', this.closeImage)
              document.body.appendChild(this.cloned)
              document.body.style.overflow = 'hidden'
              this.cloned.addEventListener('touchmove', this.freezeVp, false)
              setTimeout(() => {
                this.cloned.style.opacity = 1
              }, 0)
            } else {
              this.cloned.style.opacity = 0
              this.cloned.removeEventListener('touchmove', this.freezeVp, false)
              setTimeout(() => {
                this.closeButtonRef.removeEventListener('click', this.closeImage)
                this.cloned.remove()
                this.cloned = null
                this.closeButtonRef = null
                document.body.style.overflow = 'auto'
              }, 250)
            }
          })
        },
        diasablePets (item){
            if(!item){
                this.petActive= false
                this.mediumPet= false
                this.largePet = false
            }
        },
        ownbroker(item){
            if(item === true){
                this.showOwnBrokerModal()
            }
        }
      },
    methods: {
        ...mapActions({
            clearArrayFiles:'clear',
            clearBroker:'clearBroker',
            getUserDataStore:'getUserDataStore',
            getUserDataStoreStatus: 'getUserDataStoreStatus',
            countNotifications: 'countNotifications',
            setIdAddress:'setIdAddress',
            clearAddress:'clearAddress',
            getPropertieInfo:'getPropertieInfo'
        }),
        async updateRentProcess(additionalInfo,user_rol_id,step,status){
            try {
              let params = {
                complete_additional_info:additionalInfo,
                next_step: step,
                user_rol_id: user_rol_id,
                status_id:status
              }
              const response = await axiosTrocutServer.post('/updateStepUserDataTenant',params)
              if(response.status === 200){
                  if(step === 6){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; ya puedes navegar por el sitio `,
                        type: 'success'
                    })
                  }
              }
            } catch (error) {
              console.error(error)
            }
        },
        nameWithStateOwner({ name }) {
            return `${name}`
        },
        nameWithTownshipOwner({ name }) {
            return `${name}`
        },
        mantenimentCost(arg) {
            if(arg === true){
              this.diasbleCost = false
            } else {
              this.diasbleCost = true
            }
        },
        nameWithState({ name }) {
            return `${name}`
        },
        nameWithCity({ name }) {
            return ` ${name}`
        },
        nameWithTownship({ name }) {
            return `${name}`
        },
        nameWithNeigthborhood({ name }) {
            return `${name}`
        },
        closeModal() {
            this.createNotification()
            this.$modal.hide('modal-addBulding')
            this.$modal.hide('modalAcount')
        },
        closeModalFinish(){
            //this.$modal.hide('modal-addBulding')
            this.$modal.hide('modalAcount')
        },
        closeModalOwnBroker(){
            this.$modal.hide('ownbroker')
        },
        closeModalDocument() {
            this.$modal.hide('document-Modal')
        },
        async createNotification(){
            try {
              let params = {
                notification: `Hola ${this.user.user.email},¡Ups!, hemos detectado que aún tienes información por completar.
                Ingresa a trocut.com y concluye el proceso pendiente.
                El equipo Trócut`,
                importantId: 1,
                user_rol_id: this.currentRolComplete.user_rol_id,
                notification_type: 'Proceso Incompleto',
                rental_process_id: 0,
                idTemplate: 9,
                userId: this.user.user_data !== undefined ? this.user.user.user_id  : 0,
                email: this.user.user !== undefined ? this.user.user.email : '',
                roleId: 9
              }
              const response = await axiosTrocutServer.post('/addNotification',params)
              if(response.status === 200 ){
                console.log('se lanzo la notificacion')
              }
            } catch (error) {
              console.error(error);
            }
            
        },
        addClassActive(btnNumber, type) {
            this.room = btnNumber
            const objects = document.getElementsByClassName('btn' + type + 'Active')
            for (let i = 0; i <= objects.length; i++) {
                if (objects[i] !== undefined) {
                    objects[i].className = 'btn btn-outline-danger'
                }
            }
            this.$refs[type + btnNumber][0].className = 'btn btn-outline-danger ' + 'btn' + type + 'Active'
        },
        selectedBulding(type) {
            if (type === 'Casa') {
                this.homeActive = true
                this.apartamentActive = false
                this.upBuilding.typeBuilding = 'Casa'
            } else {
                this.homeActive = false
                this.apartamentActive = true
                this.upBuilding.typeBuilding = 'Apartamento'
            }
        },
        selectedPet (type) {
            if(this.diasablePets){
                if( type === 'little') {
                    this.petActive= true
                    this.mediumPet= false
                    this.largePet = false
                    this.petN = 'little'
                }
                if( type === 'medium') {
                    this.petActive= false
                    this.mediumPet= true
                    this.largePet = false
                    this.petN = 'medium'
                } if( type  === 'large') {
                    this.petActive= false
                    this.mediumPet= false
                    this.largePet = true
                    this.petN = 'large'
                }
            }
        },
        async getAddressproperty(address_id) {
            try {
                let params = {
                    address_id:  address_id
                }
                console.log('esto es la direccion',params)
                const response = await axiosTrocutServer.post('/getAddress', params)
                if(response.status === 200){
                    //this.temporal.push(response.data.address)
                    response.data.address.forEach(el => {
                        this.upBuilding.codigoPostal = el.postal_code,
                        this.upBuilding.neighborhood = { value: el.suburb_id, name: el.suburb },
                        this.upBuilding.street = el.street,
                        this.upBuilding.numInterior = el.interior_number,
                        this.upBuilding.numExterior = el.outdoor_number,
                        this.upBuilding.state = { value: el.state_id, name: el.state },
                        this.upBuilding.township = { value: el.town_id, name: el.township },
                        this.upBuilding.city = { value: el.city_id, name: el.city },                            
                        this.priceRenta = el.minimalPrice
                        this.dateInit = moment(el.init_contrat).format('YYYY-MM-DD') 
                        this.deposit = el.months_of_deposit 
                        this.contrat = el.contract_term
                        this.diasablePets = el.pets
                        this.idAddress = el.address_id
                    }) 
                }
            } catch (error) {
                console.error(error)
            }
        },
        async continueProcess (arg) {
            console.log('entro a continuar proceso',arg.property_id)
            this.stepModal = 0
             this.upProperty = arg
             this.idAddress = arg.address_id
             this.idProperty = arg.property_id
             this.stepModal = arg.next_step
             this.keyProperty = arg.key_property === null ?  '' : arg.key_property 
             this.setIdAddress({
                address_id:this.idAddress,
                property_id:arg.property_id,
                key_property: this.keyProperty
            })
             this.getBroker(this.idAddress)
             this.getAddressproperty(this.idAddress)
             this.continueAdd = 0
             this.moreInventario(arg.inventary)
             this.getConditions(this.getIdAddress.property_id )
             const imagesTempArray = []
            arg.property_media.forEach(element => {
                if(element.type_media_id === 1 && element.property_id === this.idProperty) {
                    imagesTempArray.push(element)
                }
                if(element.name  === 'INE_FRONT' || element.name  === 'INE_BACK' || element.name  === 'Predial' ) {
                    this.documentationArray.push(element)
                }
            })
            this.inventaryPicture.push({
                key_property:this.keyProperty,
                property_id:this.idProperty
            },imagesTempArray)
            this.ineFront(this.documentationArray[0])
            this.ineBack(this.documentationArray[1])
            this.addressPaper(this.documentationArray[2])
             this.paymentPaper = true
             setTimeout(() => {
                 arg.amenities.forEach(el => {
                     if(el.type === 'Caracteristica' ){
                         this.selectedCaracteristicas(el.amenity_id)
                     }
                     if(el.type === 'Amenidad'){
                         this.selectedAmenitie(el.amenity_id)
                     }
                 })
                 this.$refs.wizzardAltaPropiedad.changeTab(0,this.stepModal)
             }, 3000)
        },
        async getIncompleteProperty (){
            try {
                let params = {
                    user_rol_id: this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/verifyProperty',params)
                console.log('verificacion vehicular',this.currentRolComplete)
                if(response.status === 200){
                    if(response.data.metadata.length > 0){
                        response.data.metadata.forEach(el => {
                            this.idProperty = el.property_id
                            this.idAddress = el.address_id
                            this.upProperty = el
                            this.upBuilding.description = el.description
                            this.temporal= el
                            this.temporalProperty.push(el)
                            this.stepModal = el.next_step
                            this.selectedBulding(el.type)
                            this.getGarage(el.garage)
                        })
                        this.loadingStatus = false
                        this.getAddressproperty(this.idAddress)
                        setTimeout(() => {
                            if(this.$refs.wizzardAltaPropiedad !== undefined){
                                this.$refs.wizzardAltaPropiedad.changeTab(0,this.stepModal)
                            }
                        }, 3000)
                        return
                    }
                    this.loadingStatus = false
                }
            } catch (error) {
                console.error(error)
            }
        },
        async getConditions(arg){
            try {
                let params = {
                    property_id : arg
                }
                const response = await axiosTrocutServer.post('/getConditionalRent',params)
                if(response.status === 200){
                    response.data.consdicionesRendicion.forEach(el => {
                        this.priceRenta = el.minimal_price ,
                        this.diasableY = el.edit_minimal_price ,
                        this.dateInit = moment(el.init_contrat).format('YYYY-MM-DD'),
                        this.diasableX = el.edit_contrat,
                        this.deposit = el.months_of_deposit,
                        this.diasableZ = el.edit_month,
                        this.contrat = el.contract_term ,
                        this.diasableM = el.contract_term,
                        this.petN = el.pets,
                        this.diasablePets = el.edit_pets,
                        this.othersC = el.others ,
                        this.diasableO = el.edit_others
                    })
                    this.selectedPet(this.petN)
                }
            } catch (error) {
                console.error(error)
            }
        },
        newProperty() {
            this.garage1 = false
            this.garage2 = false
            this.garage3 = false
            this.homeActive = false
            this.apartamentActive = false
            this.inventaryPicture = []
            this.upBuilding = {
                arrendamiento: 0,
                mantenimiento: 0,
                codigoPostal: '',
                colonia: '',
                street: '',
                numInterior: 0,
                numExterior: 0,
                description: '',
                condiciones: [],
                typeBuilding: '',
                state: '',
                township: '',
                city: '',
                neighborhood: ''
            },
            this.upProperty= {
                rooms: 0,
                bath: 0,
                garage: 0,
                area: 0,
                amount_rent: 0,
                cost_maintenance: 0,
                title: '',
                description: '',
                complete:0
            }
            this.idProperty = 0
            this.stepModal = 0
            this.idAddress = 0
            this.continueAdd = 0
            this.keyProperty= '' 
            this.paymentPaper = true
            this.getCaracteristicas()
        },
        selectedCaracteristicas(caracteristica) {
            if (this.$refs[caracteristica][0].className === 'col-4 selectedItem') {
                this.$refs[caracteristica][0].className = 'col-4 '
            } else {
                this.$refs[caracteristica][0].className = 'col-4 selectedItem'
                this.caracteristicasTemp.push(caracteristica)
            }
        },
        selectedAmenitie(aminitie) {
            if (this.$refs[aminitie][0].className === 'col-4 ') {
                this.$refs[aminitie][0].className = 'col-4 selecAmenitie'
            } else {
                this.$refs[aminitie][0].className = 'col-4 selecAmenitie'
                this.amenitiesTemp.push(aminitie)
            }
        },
        ownwerBrokerAwnser(awnser) {
            if (awnser === 'yes') {
                this.updateRentProcess(1,this.currentRolComplete.user_rol_id,6,5)
                this.getUserDataStoreStatus({
                    user_id: this.user.user.user_id
                })
                this.$modal.show('soon-modal')
                this.closeModalOwnBroker()
                this.ownwerBroker = true
                this.ownbroker = true
                this.finishOpacity= true
            } if (awnser === 'no') {
                this.ownbroker = false
                this.closeModalOwnBroker()
            }
        },
        async ineFront(e){
            if(e !== undefined){
                if(e.target !== undefined){
                    const file = e.target.files[0]
                    let fileType = file.type
                    let fileName = file.name
                    fileType = fileType.split('/')
                    fileName = `INE_FRONT.${fileType[1]}`
                    document.getElementById('idCardF').src = window.URL.createObjectURL(file)
                    this.pictureDocumentation.push({
                        name:fileName,
                        file:file
                    })
                }
                else {
                    const file = e
                    if(file !== undefined){
                        if(document.getElementById("idCardF") !== null){
                            document.getElementById("idCardF").src = `${file.url}`
                        }
                    }
                }
            }
        },
        async ineBack(e){
            if( e !== undefined){
                if(e.target !== undefined){
                    const file = e.target.files[0]
                    let fileType = file.type
                    let fileName = file.name
                    fileType = fileType.split('/')
                    fileName = `INE_BACK.${fileType[1]}`
                    document.getElementById('idCardB').src = window.URL.createObjectURL(file)
                    this.pictureDocumentation.push({
                        name:fileName,
                        file:file
                    })
                }
                else {
                    const file = e
                    if(file !== undefined){
                        if(document.getElementById("idCardB") !== null){
                            document.getElementById("idCardB").src = `${file.url}`
                        }
                    }
                }
            }
        },
        showPdfModal(){
            this.$modal.show('document-Modal')
        },
        async addressPaper(e){
            if( e !== undefined){
                if(e.target !== undefined){
                    const file = e.target.files[0]
                    let fileType = file.type
                    let fileName = file.name
                    fileType = fileType.split('/')
                    fileName = `Predial.${fileType[1]}`
                    this.pictureDocumentation.push({
                        name:fileName,
                        file:file
                    })
                    this.paymentPaper = false
                }
                else {
                    setTimeout(() => {
                        this.paymentPaper = false
                    }, 3000)
                }
                //this.getMediaDocument()
            }
        },
        async getStates() {
            try {
                const response = await axiosTrocutServer.post('/getStates')
                if (response.status === 200) {
                    response.data.states.forEach(el => {
                        this.optionState.push({
                            name: el.state,
                            value: el.state_id,
                            abbre:el.abbreviation
                        })
                    })
                }
            } catch (error) {
                console.error('get States', error)
            }
        },
        async getTownhips(...arg) {
            try {
                this.optionstownship = []
                const params = {
                    state_id: arg[0].value
                }
                const response = await axiosTrocutServer.post('/getTownship', params)
                if (response.status === 200) {
                    response.data.township.forEach(el => {
                        this.optionstownship.push({
                            name: el.township,
                            value: el.township_id
                        })
                    })
                }
            } catch (error) {
                console.error('error', error)
            }
        },
        async getCities(...arg) {
            try {
                this.optionsCity = []
                let params = {
                    town_id: arg[0].value
                }
                const response = await axiosTrocutServer.post('/getCities', params)
                if (response.status === 200) {
                    response.data.cities.forEach(el => {
                        this.optionsCity.push({
                            name: el.city,
                            value: el.city_id
                        })
                    })
                }
            } catch (error) {
                console.error('error', error)
            }
        },
        async getNeighborhood(...arg) {
            try {
                this.neighborhood = []
                let params = {
                    city_id: arg[0].value,
                    stock: false
                }
                const response = await axiosTrocutServer.post('/getNeighborhoods', params)
                if (response.status === 200) {
                    response.data.neighborhood.forEach(el => {
                        this.optionsneighborhood.push({
                            name: el.neighborhood,
                            value: el.neighborhood_id
                        })
                    })
                }
            } catch (error) {
                console.error('error', error)
            }
        },
        async addAdressProperty() {
            try {
                this.submittedA = true
                this.$v.upBuilding.$touch()
                if (!this.$v.upBuilding.$invalid) {
                    this.submittedA = false
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;Favor  de llenar los campos marcados con *`,
                        type: 'error'
                    })
                } else {
                    let payload = {}
                    this.loadGeneralPropertyInfo = true
                    this.buttonFlag = false 
                    if(this.idAddress !== 0){
                        payload = {
                            address_id: this.idAddress,
                            street:this.upBuilding.street,
                            suburb_id:this.upBuilding.neighborhood.value,
                            city_id: this.upBuilding.city.value,
                            town_id: this.upBuilding.township.value,
                            state_id: this.upBuilding.state.value,
                            interior_number: this.upBuilding.numInterior,
                            outdoor_number:parseInt( this.upBuilding.numExterior),
                            user_rol_id: this.currentRolComplete.user_rol_id  !== undefined ? this.currentRolComplete.user_rol_id : this.currentRolComplete.user_rol_id,
                            address_type: "Propiedad" ,
                            postal_code: this.upBuilding.codigoPostal,
                            user_id: this.currentRolComplete.user_id
                        }
                    } else {
                        payload = {
                            street: this.upBuilding.street,
                            suburb_id:this.upBuilding.neighborhood.value,
                            city_id: this.upBuilding.city.value,
                            town_id: this.upBuilding.township.value,
                            state_id: this.upBuilding.state.value,
                            interior_number: this.upBuilding.numInterior,
                            outdoor_number:parseInt( this.upBuilding.numExterior),
                            user_rol_id: this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : this.currentRolComplete.user_rol_id,
                            address_type: "Propiedad" ,
                            postal_code: this.upBuilding.codigoPostal,
                            new:true,
                            user_id: this.currentRolComplete.user_id
                        }
                       // ,user_id: this.currentRolComplete.user_id
                    }
                    const response = await axiosTrocutServer.post('/addAddress', payload)
                    if (response.status === 200) {
                        this.updateStep(1)
                        this.updateRentProcess(1,this.currentRolComplete.user_rol_id,1,5)
                        this.idAddress = response.data.metadata.insertId
                        this.addProperty(payload.address_id !== undefined ? payload.address_id :this.idAddress)
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;Registro de la direccion  Completo, por favor continue agregando su información.`,
                            type: 'success'
                        })
                        this.loadGeneralPropertyInfo = false
                    }if  (response.status === 500){
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;Favor  de llenar los campos marcados con * `,
                            type: 'error'
                        })
                    }
                }
            } catch (error) {
                this.loadGeneralPropertyInfo = false
                this.buttonFlag = true
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp;Favor  de llenar los campos marcados con * `,
                    type: 'error'
                })
            }
        },
        getGarage(arg){
            if(arg === 1){
                this.garage1 = true
                this.garage2 = false
                this.garage3 = false
                this.garageArg = arg
            }
            if(arg === 2){
                this.garage1 = false
                this.garage2 = true
                this.garage3 = false
                this.garageArg = arg
            }
            if(arg === 3){
                this.garage1 = false
                this.garage2 = false
                this.garage3 = true
                this.garageArg = arg
            }
            
        },
        async addProperty(arg) {
            try {
                let params = {
                    address_id: arg,
                    type: this.upBuilding.typeBuilding,
                    rooms: 0,
                    bath: 0,
                    garage: 0,
                    user_rol_id: this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : this.user.roles[0].user_rol_id,                        
                    amount_rent: this.upProperty.amount_rent,
                    cost_maintenance: this.upProperty.cost_maintenance,
                    title: this.upProperty.title,
                    description: this.upBuilding.description,
                    userId:this.user.user.user_id
                }
                const response = await axiosTrocutServer.post("/addProperties", params)
                if (response.status === 200) {
                    this.idProperty = response.data.metadata.insertId
                    this.addConditions()
                    this.updateStep(2)
                    this.getPropertieInfo(this.idProperty)
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; Registro de la Propiedad Completo, por favor continue agregando su información.`,
                        type: 'success'
                    })
                    this.buttonFlag = true
                } if (response.status === 500) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; fFavor  de llenar los campos marcados con * `,
                        type: 'error'
                    })
                } 
            } catch (error) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; fFavor  de llenar los campos marcados con * `,
                    type: 'error'
                })
            }
        },
        async updatePropety() {
            try {
                let params = {
                    property_id: this.getIdAddress.property_id ,
                    rooms: parseInt(this.upProperty.rooms),
                    bath: parseInt(this.upProperty.bath),
                    garage: this.garageArg,
                    surface: this.upProperty.area,
                    area: this.upProperty.area,
                    status:1,
                }
                const response = await axiosTrocutServer.post('/updateProperty', params)
                if (response.status === 200) {
                    this.updateStep(2)
                    this.inventaryPicture.push({ key_property:this.getIdAddress.keyProperty, property_id:this.idProperty })
                    await this.addPropertyAmenities()
                    if(this.otherConditionsArray.length > 0){
                        await this.addOtherConditions(this.otherConditionsArray.textCondition,this.otherConditionsArray.value)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
        async addConditions(){
            try {
                let params = {
                    minimal_price: this.priceRenta !== undefined ?  this.priceRenta : 0 ,
                    edit_minimal_price: this.diasableY !== false ? 1 : 0 ,
                    init_contrat: this.dateInit,
                    edit_contrat: this.diasableX !== false ? 1 : 0,
                    months_of_deposit: this.deposit !== undefined ?  this.deposit : 0,
                    property_id: this.idProperty,
                    edit_month: this.diasableZ !== false ? 1 : 0,
                    contract_term: this.contrat !== undefined ? this.contrat : 0 ,
                    edit_contrat_term: this.diasableM !== false ? 1 : 0,
                    pets: this.petN ,
                    edit_pets: this.diasablePets !== false ? 1 : 0,
                    edit_others: this.diasableO !== false ? 1 : 0
                }
                const response = await axiosTrocutServer.post("/addPropertiesConditions",params)
                if( response.status === 200){
                    this.$refs.wizzardAltaPropiedad.nextTab()
                }
            } catch (error) {
                console.error(error)
            }
        },
        async addOtherConditions(tille,description){
            try {
                let payload ={
                    textCondition:tille,
                    value:description,
                    type:'Owner',
                    property_id:this.idProperty
                }
                const response = await axiosTrocutServer.post('/addOtherConditions', payload)
                if(response.status === 200){
                    console.log('se registro la otra condicion')
                }
            } catch (error) {
                console.error('error en ',error)
            }
        },
        async addPropertyAmenities() {
            this.buttonFlag = false
            this.propertyArray = [...this.caracteristicasTemp, ...this.amenitiesTemp]
            if(this.propertyArray.length > 0){
                try {
                    this.loadCaracteristics = true
                    let params = {
                        amenity_id: this.propertyArray,
                        property_id: this.getIdAddress.property_id 
                    }
                    const response = await axiosTrocutServer.post('/addPropertiesAmeinties', params)
                    if (response.status === 200) {
                        this.idProperty = this.idProperty !== 0 ? this.idProperty : response.data.metadata.insertId
                        this.updateStep(3)
                        this.updateRentProcess(1,this.currentRolComplete.user_rol_id,3,5)
                        this.$refs.wizzardAltaPropiedad.nextTab()
                        this.loadCaracteristics = false
                        this.buttonFlag = true
                        await this.getBroker(this.idAddress !== 0 ? this.idAddress :this.getIdAddress.address_id)
                    } else {
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;Algo ocurrio,con las amenidades,por favor, intentelo de nuevo.`,
                            type: 'error'
                        })
                    }
                } catch (error) {
                    console.error(error)
                }
            }else{
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; Favor de ingresar almenos una amenidad o caracteristica`,
                    type: 'error'
                })
            }
            
        },
        async getCaracteristicas() {
            const caracteristica = await axiosTrocutServer.post('/getAmenidades')
            if (caracteristica.status === 200) {
                this.detailsHome = caracteristica.data.Caracteristica
                this.amenidadesArr = caracteristica.data.Amenidad
            }
        },
        async moreInventario(e) {
            if( e.target !== undefined) {
                if (e.target.files.length > 0) {
                    const files = e.target.files
                    for (const key in files) {
                        if (key !== 'length' && key !== 'item') {
                            let originalName = files[key].name.split('.')
                            let fileName = files[key].name
                            let fileType = files[key].type
                            fileType = fileType.split('/')
                            let filePath = URL.createObjectURL(files[key])
                            files[key].fileName = fileName = `${this.inventaryPicture[0].key_property.substring(0,4)}-${this.arraObjectPicture.length}.${fileType[1]}`
                            files[key].filePath = filePath
    
                            this.arraObjectPicture.push({File:files[key],nameObject:originalName[0]})
                        }
                    }
                }
            } else {
                const file = e
                file.forEach(el => {
                    this.arraObjectPicture.push(
                        {File:{
                            filePath: el.url
                        },
                        nameObject: el.description
                    })
                })
            }
        },
        async changeStepEmit(){
            this.$emit('getAddress')
            await this.$refs.wizzardAltaPropiedad.nextTab()
        },
        closeImage (event) {
            this.expanded = false
            event.stopPropagation()
        },
        freezeVp (e) {
            e.preventDefault()
        },
        async addNotification(notification,type,id_template){
            try {
                let params = {
                    notification: notification ,
                    important:1,
                    user_rol_id: this.currentRolComplete.user_rol_id ,
                    notification_type: type,
                    rental_process_id: 0,
                    idTemplate: id_template,
                    user_id: this.user.user_data !== undefined ? this.user.user.user_id  : 0,
                    email: this.user.user !== undefined ? this.user.user.email : '',
                    roleId: 9
                }
                const response = await axiosTrocutServer.post('/addNotification',params)
            } catch (error) {
                console.error(error)
            }
        },
        setKey (){
            this.$modal.hide('soon-modal')
            if(this.ownbroker === false){
                this.$modal.show('autorizada-modal')
            }
            this.finishOpacity = false
        },
        setKeyOwnBroker (){
            this.$modal.show('soon-modal')
            this.ownerBroker()
            this.finishOpacity = true 
        },
        getKey (){
            this.finishOpacity = true
            this.$modal.hide('autorizada-modal')
            setTimeout(() => {
                this.closeModalFinish()
            }, 10000)
        },
        cancelKey (){
            this.finishOpacity = false
        },
        async getBroker (arg){
            this.brokersArr =[]
            this.brokerArrRent=[]
            try {
                let  params = {
                    address_id: arg
                }
                const response = await axiosTrocutServer.post('/searchBroker',params)
                if (response.status === 200){
                    response.data.brokers.forEach(el => {
                        this.brokersArr.push(el)
                    })
                }
            } catch (error) {
                console.error(error)
            }
        },
        getSelectedBroker(){
            this.brokerArrRent.push(this.getBrokerSelected.brokerArray)
        },
        showOwnBrokerModal(){
            this.$modal.show('ownbroker')
        },
        async assignmentBroker(){
            try {
                let params = {
                    broker_id: this.brokerArrRent[0].slide.user_rol_id,
                    property_id: this.idProperty,
                    typeBroker:'Broker'
                }
                const response = await axiosTrocutServer.post('/brokerAssigned',params)
                 if(response.status === 200){
                    this.updatePropertyStatus()
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp;El broker Fue asignado correctamente`,
                        type: 'success'
                    })
                    //this.addNotification(this.currentRolComplete.user_rol_id,text,'Broker Asignado',1)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async updatePropertyStatus(){
            try {
                let payload = {
                    status:3, 
                    property_id:this.idProperty,
                    user_rol_id:this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/updateStatusProperty',payload)
                if(response.status ===200) {
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; alta de propiedad lista`,
                        type: 'success'
                    })
                }
            } catch (error) {
                console.error(error)
            }
        },
        deleteDocument(position) {
            delete this.pictureDocumentation[position]
            this.paymentPaper = true
        },
        async ownerBroker(){
            try {
                let params = {
                    broker_id: this.currentRolComplete.user_rol_id,
                    property_id: this.idProperty,
                    typeBroker:'Owner'

                }
                const response = await axiosTrocutServer.post('/brokerAssigned',params)
                if(response.status === 200){
                    this.$modal.hide('soon-modal')
                    this.closeModal()
                    this.$emit('updateProperties')
                    this.updatePropertyStatus()
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; felicidades ahora eres e broker de la propiedad con la clave ${this.keyProperty}`,
                        type: 'success'
                    })
                }
            } catch (error) {
                console.error(error)
            }
        },
        async completeInfoBase (infoBase,addresc){
            try {
                let params = {
                  complete_info_base: infoBase,
                  complete_address: addresc,
                  user_id:this.user.user.user_id
                }
                const response = await axiosTrocutServer.post('/updateInfoBase',params)
                if(response.status === 200){
                    this.$toastedPush({
                        message: `&nbsp;&nbsp;&nbsp; success`,
                        type: 'success'
                    })
                }
            } catch (error) {
              console.error(error)
          }
        },
       async updateStep(arg) {
            try {
                let params  = {
                    next_step : arg,
                    property_id : this.idProperty
                }
                const response = await axiosTrocutServer.post('/updateSteptProperty',params)
                if(response.status === 200) {
                    if(arg === 5){
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;Registro de propiedad completo ya puede navegar por el sitio`,
                            type: 'success'
                        })
                        return
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
        generatePDF(){
            console.log('por amor a jebus el testamento de codigo que esta escrito creo que indica falta de vida social y novia.')
            this.$refs.html2Pdf.generatePdf()
        },
        moreCondition(){
            this.otherConditionsArray.push({textCondition:'',value:''})
        },
        async getMediaDocument(){
            try {
                let tempArr = []
                let params = {
                    user_id : this.currentRolComplete.user_id,
                    user_rol_id : this.currentRolComplete.user_rol_id
                }
                const response = await axiosTrocutServer.post('/getMedia',params)
                if(response.status === 200){
                    response.data.resolve.forEach(el => {
                        if(el.type_media_id === 4){
                            this.pdfSrc = el.url
                            this.paymentPaper = false
                        }
                        if(el.name  === 'INE_FRONT' || el.name  === 'INE_BACK' || el.name  === 'Predial' ) {
                            tempArr.push(el)
                        }
                    })
                    this.ineFront(tempArr[0])
                    this.ineBack(tempArr[1])
                    this.addressPaper(tempArr[2])
                }
            } catch (error) {
                console.error(error)
            }
        },
        veificationCaracteristics() {
            if(this.upProperty.area === 0 || this.upProperty.rooms === 0 || this.upProperty.bath === 0 ){
                    this.$toastedPush({
                        message:`favor de llenar los campos faltantes`,
                        type:`error`
                    })
                return false
            }else {
                return true
            }
        } ,
        async nextSave(prop) {
            let indexAddRegister = prop.activeTabIndex
            switch (indexAddRegister) {
                case 1:
                    this.addAdressProperty()
                break;
                case 2:
                    try {
                        const result = await this.veificationCaracteristics()
                        if(result === true){
                           await this.updatePropety()
                        }
                    } catch (error) {
                        this.$toastedPush({
                            message:`Favor  de llenar los campos marcados con *`,
                            type:`error`
                        })
                    }
                break;
                case 3:
                    if(this.getfilesArray.picturesArr.length >= 5 && this.getfilesArray.picturesArr.length <= 17 ){
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;Registro de imagenes de propiedad completo, por favor continue agregando su información.`,
                            type: 'success'
                        })
                        this.clearAddress()
                        this.updateStep(4)
                        this.updateRentProcess(1,this.currentRolComplete.user_rol_id,4,5)
                    }else{
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp;No puede seguir agregando su información hasta que suba minimo 6 fotografias de la propiedad.`,
                            type: 'error'
                        })
                    }
                    break;
                case 4:
                    if(this.noStore){
                        if(this.arraObjectPicture.length >= 2 && this.arraObjectPicture.length <= 10){
                           try {
                                const images = this.arraObjectPicture
                                var form = new FormData()
                                form.append("type","Properties")
                                form.append("key_property",this.getIdAddress.keyProperty)
                                form.append("subtype","inventary")
                                form.append("property_id",this.getIdAddress.property_id)
                                for (const key in this.arraObjectPicture) {
                                    form.append("description",images[key].nameObject)
                                    form.append("Images",images[key].File)
                                }
                                const response = await axiosTrocutServer.post('/addImageInventaryProperty', form)
                                if(response.status === 200){
                                    this.updateRentProcess(1,this.currentRolComplete.user_rol_id,5,5)
                                    this.updateStep(5)
                                    this.$refs.wizzardAltaPropiedad.nextTab()
                                }
                           } catch (error) {
                               console.error('exploto',error)
                           }
                           this.$toastedPush({
                                message: `&nbsp;&nbsp;&nbsp; Inventario Completo, por favor continue agregando su información.`,
                                type: 'success'
                            })
                        } else {
                            this.$toastedPush({
                                message: `&nbsp;&nbsp;&nbsp; No puede seguir agregando su información hasta que suba almenos dos fotografias del inventario`,
                                type: 'error'
                            })
                        }
                    } else {
                        this.$refs.wizzardAltaPropiedad.nextTab()
                    }
                break;
                case 5:
                    try {
                        this.buttonFlag = true
                        this.loadDocumentsProperty = true
                        const tempo = []
                        for (let i = 0; i < this.pictureDocumentation.length; i++) {
                          const valuesArary = Object.values(this.pictureDocumentation[i]).map(el => {
                            tempo.push(el)
                          })
                        }
                        const ineF = document.getElementById("idCardF").src
                        const ineB = document.getElementById("idCardB").src
                        var formData = new FormData()
                        formData.append("type","Properties")
                        formData.append("subtype","documents")
                        formData.append("key_property",this.getIdAddress.keyProperty )
                        formData.append("property_id",this.idProperty)
                        formData.append("user_id",this.user.user.user_id)
                        for (const key in tempo) {
                            formData.append("ImagesType_id",this.imageType[key])
                            formData.append('Documentation', tempo[key])
                        }
                          if( tempo.length >= 6){
                            const response = await axiosTrocutServer.post('/addDocumentationProperty',formData)
                            if(response.status === 200){
                                this.updateStep(5)
                                this.$toastedPush({
                                  message: `&nbsp;&nbsp;&nbsp;Documentos personales agregados con exito`,
                                  type: 'success'
                                })
                                this.updateRentProcess(1,this.currentRolComplete.user_rol_id,6,5)
                                this.loadDocumentsProperty = false
                                this.$refs.wizzardAltaPropiedad.nextTab()
                                this.getMediaDocument()
                                this.buttonFlag = false
                                return
                            }
                        } else if(ineB !== null  && ineF !== null){
                            this.$refs.wizzardAltaPropiedad.nextTab()
                            return
                        }
                            this.$toastedPush({
                                message: `&nbsp;&nbsp;&nbsp; Favor  de llenar los campos marcados con *`,
                                type: 'error'
                            })
                        } catch (error) {
                        this.$toastedPush({
                          message: `&nbsp;&nbsp;&nbsp; favor de subir las imagenes que se requieren`,
                          type:`error`
                        })
                      }
                break;
                case 6:
                    if(this.brokerArrRent.length === 0 || this.ownbroker !== false ){
                        this.$toastedPush({
                            message: `&nbsp;&nbsp;&nbsp; favor de seleccionar a un broker o seleccionar la opcion de ser su propio broker`,
                            type:`error`
                        }) 
                      return
                    }
                    this.completeInfoBase(1,1)
                    this.countNotifications(this.currentRolComplete.user_rol_id !== undefined ? this.currentRolComplete.user_rol_id : undefined)
                      if(this.finishOpacity !== true ){
                          this.$modal.show('soon-modal')
                          this.$emit('updateProperties')
                      } else {
                         this.assignmentBroker()
                         this.$emit('updateProperties')
                        }
                    this.updateStep(indexAddRegister)
                    this.updateRentProcess(1,this.currentRolComplete.user_rol_id,6,5)
                    this.getUserDataStoreStatus({
                       user_id: this.user.user.user_id
                    })
                    //this.closeModalFinish()
                break
            }
        }
    },
    mounted() {
        setTimeout(() => {
            const store = this.getStoreUser.roles
            this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
            console.log('continuar',this.continueAdd)
            if(this.continueAdd !==  0){
                this.getIncompleteProperty()
                this.clearBroker()
            }
            this.clearAddress()
            this.getStates()
            this.getCaracteristicas()
            const viewportMeta = document.createElement('meta')
            viewportMeta.name = 'viewport'
            viewportMeta.content = 'width=device-width, initial-scale=1'
            document.head.appendChild(viewportMeta)
            this.dateInit = moment().format('YYYY-MM-DD')
            this.today = moment().format('YYYY-MM-DD')
            this.getMediaDocument()
        }, 4000)
    }
}