import Vue from "vue";
import VueRouter from 'vue-router'
import store from "@/store"

// Public
import Home from "@/views/public/home/index.vue";
import resultSearch from "@/components/home/result-search";
import propertyProfile from '@/components/home/property-profile'
import knowUs from '@/views/public/knowUs'
import  resetPassword from "@/views/public/resetPassword";

// Private

// Broker
import broker from '@/views/private/broker/dashboard/index.vue'
import notificationsB from '@/views/private/broker/notification/index.vue'
import brokerProspect from '@/views/private/broker/brokerClient/index.vue'
import propertyBroker from '@/views/private/broker/propiedadesBroker/index.vue'
import brokerAccount from '@/views/private/broker/brokerAcount/index.vue'
import brokerProperty from '@/views/private/broker/brokerPropertyProfile/index.vue'

// Tenant
import tenant from '@/views/private/tenant/dashboard/index.vue'
import tenantAcount from '@/views/private/tenant/userAcount/index.vue'
import teantNotifications from '@/views/private/tenant/notifications/index.vue'

// Owner
import owner from '@/views/private/owner/dashboard/index.vue'
import ownerP from '@/views/private/owner/propertyies/index.vue'
import ownerCardP from '@/views/private/owner/propertyCard/index.vue'
import notificationsO from '@/views/private/owner/notifications/index.vue'
import ownerAccont from '@/views/private/owner/ownerProfile/index.vue'
import ownerClient from '@/views/private/owner/ownerClient/index.vue'

//info
import trocutInfo from '@/views/public/sectionTrocut/index.vue'
import funciona from '@/views/public/howDoesItWork/index.vue'
import preguntas from '@/views/public/FAQ/index.vue'
import contacto from '@/views/public/contact/index.vue'
import Ayuda from '@/views/public/help/index.vue'
import terminos from '@/views/public/termsAndConditions/index.vue'
import AvisoPrivacidad from '@/views/public/noticeOfPrivacy/index.vue'
import blog from "@/views/public/blog/index.vue"

Vue.use(VueRouter)
function authRequired (to, from, next) {
  if (store.state.auth.autenticated) {
    next()
  } else {
    next('/')
  }
}
const routes = [
    {
      path: "/",
      name: "index.home",
      component: Home,
      meta: { title: "Trocut", description: "Trocut cool" }
    },
    {
      path: "/result",
      name: "resultSearch",
      component: resultSearch,
      meta: { title: "Trocut Busqueda", description: "Busqueda de propiedades" },
    },
    {
      path: "/propertyProfile/:property_id",
      name: "propertyProfile",
      component: propertyProfile,
      meta: { title: "Trocut Resultado", description: "Resultados de la busqueda de propiedades" }
    },
    {
      path: "/resetPassword/:emailHash",
      name: "resetPassword",
      component: resetPassword,
      meta: { title: "Trocut Resultado", description: "Resultados de la busqueda de propiedades" }
    },
    //broker
    {
      path: "/broker",
      name: "index.broker",
      component: broker,
      meta: { title: "Trocut Resultado", description: "Resultados de la busqueda de propiedades" },
      beforeEnter: authRequired
    },
    {
      path: "/broker/account",
      name: "index.broker.account",
      component: brokerAccount,
      meta: { title: "Trocut Resultado", description: "Resultados de la busqueda de propiedades" },
    },
    {
      path: "/broker/notifications",
      name: "index.broker.notificacions",
      component: notificationsB,
      meta: { title: "Trocut", description: "Trocut Broker notificaciones"}
    },
    {
      path: "/broker/prospect",
      name: "index.broker.prospect",
      component: brokerProspect,
      meta: { title: "Trocut", description: "Trocut broker prospectos" }
    },
    {
      path: "/broker/propertyes",
      name: "index.broker.propertyes",
      component: propertyBroker,
      meta: { title: "Trocut", description: "Trocut broker propediedades a su mando" }
    },
    {
      path: "/broker/propertyProfile",
      name: "index.broker.profileProperty",
      component: brokerProperty,
      meta: { title: "Trocut", description: "Trocut perfli de una casa del broker" }
    },
    //owner
    {
      path: "/owner",
      name: "index.owner",
      component: owner,
      meta: { title: "Trocut", description: "Trocut owner cool" },
      beforeEnter: authRequired 
    },
    {
      path: "/owner/propertyes",
      name: "ownerCards",
      component: ownerP,
      meta: { title: "Trocut", description: "Trocut owner propiedades" }
    },
    
    {
      path: "/owner/cardProperty",
      name: "ownerCardProperty",
      component: ownerCardP,
      meta: { title: "Trocut", description: "Trocut owner propiedad de la casa" }
    },
    
    {
      path: "/Propietario/notifications",
      name: "index.owner.notificacions",
      component: notificationsO,
      meta: { title: "Trocut", description: "Trocut owner notificaciones" }
    },
    {
      path: "/owner/profile",
      name: "index.owner.profile",
      component: ownerAccont,
      meta: { title: "Trocut", description: "Trocut owner proferfil" }
    },
    {
      path: "/owner/clients",
      name: "index.owner.client",
      component: ownerClient,
      meta: { title: "Trocut", description: "Trocut owner clientes" }
    },
    //tenant
    {
      path: "/tenant",
      name: "index.tenant",
      component: tenant,
      meta: { title: "Trocut", description: "Trocut tenant cool" },
      beforeEnter: authRequired 
    },
    {
      path: "/tenant/profile",
      name: "index.tenant.profile",
      component: tenantAcount,
      meta: { title: "Trocut", description: "Trocut tenant perfil" }
    },
    {
      path: "/inquilino/notifications",
      name: "index.tenant.notificacions",
      component: teantNotifications,
      meta: { title: "Trocut", description: "Trocut tenant notificaciones" }
    },
    //textosInformativos
    {
      path: "/what_is_trocut",
      name: "index.info.que_es_Trocut",
      component:trocutInfo ,
      meta: { title: "Trocut", description: "Trocut que es trocut" }
    },
    {
      path: "/how_does_it_work",
      name: "index.info.como_funciona",
      component:funciona ,
      meta: { title: "Trocut", description: "Trocut como funciona" }
    },
    {
      path: "/FAQS",
      name: "index.info.faqs",
      component:preguntas ,
      meta: { title: "Trocut", description: "Trocut preguntas frecuentes" }
    },
    {
      path: "/contact",
      name: "index.info.contacto",
      component:contacto ,
      meta: { title: "Trocut", description: "Trocut contacto" }
    },
    {
      path: "/help",
      name: "index.info.Ayuda",
      component:Ayuda ,
      meta: { title: "Trocut", description: "Trocut como Ayuda" }
    },
    {
      path: "/notice_of_Privacy",
      name: "index.info.aviso_de_Privacidad",
      component:AvisoPrivacidad ,
      meta: { title: "Trocut", description: "Trocut preguntas Aviso de Privacidad" }
    },
    {
      path: "/terms_and_conditions",
      name: "index.info.terminos_y_condiciones",
      component:terminos ,
      meta: { title: "Trocut", description: "Trocut terminos y condiciones" }
    },
    {
      path: "/knowUs",
      name: "index.info.knowUs",
      component: knowUs,
      meta: { title: "Trocut", description: "Conoce más" }
    },
    {
      path: "/blog/:type",
      name: "index.info.blog",
      component: blog,
      meta: { title: "Trocut", description: "Blog " }
    }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;