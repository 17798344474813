// import router from '@/router'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {  validationMixin } from 'vuelidate' 
import { required,email, url } from "vuelidate/lib/validators";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import register from '@/components/home/register'

import {
    firebase,
    GoogleAuthProvider,
    signInWithPopup,
    FacebookAuthProvider,
    OAuthProvider
} from '@/firebase' 
import { getAuth } from "firebase/auth";

export default {
  name:'login',
  mixins: [ validationMixin ],
  components: {
    register
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      currentRolComplete:[],
      credentials: {
        email: '',
        password: ''
      },
      credentialsF: {
        email: ''
      },
      rolName: [],
      user_id: 0,
      rolesN:[],
      iconsArray:['users','handshake','home'],
      submitted: false,
      submittedF:false
    }
  },
  validations: {
    credentials: {
      password: {
        required
      },
      email: {
        required,email
      }
    },
    credentialsF: {
      email: {
        email
      }
    }
  },
  watch:{
    loadWidth() {
      this.onResize()
    }
  },
  computed: {
    ...mapGetters({
      user:'getUser',
      getCurrentRol: 'getCurrentRol',
      getRol:'getRol',
      getStoreUser:'getStoreUser'
    })
  },
  methods: {
    ...mapActions({
      signIn: 'signIn',
      setCurrentRol: 'setCurrentRol',
      countNotifications: 'countNotifications'
    }),
    ...mapMutations([
        'storeRol'
    ]),
    closeModal () {
      this.$modal.hide('modal-login')
      this.$modal.hide('modal-register')
      this.$modal.hide('reactivy-rol')
    },
    modalRegister() {
      this.$modal.show('modal-register')
    },
    async deleteAccountFirebase (){
      var userD = firebase.auth().currentUser
        userD.delete().then(async()=>{
          console.log('usuario eliminado')
        }).catch((error)=>{
          console.error(error)
        })
    },
    async getRolesRegister (){
      let response  = await axiosTrocutServer.post('/getRoles')
      this.rolesN = response.data.roles
   },
    async loginEmail () {
      this.submitted = true
      try {
        this.$v.credentials.$touch()
        if(this.$v.credentials.$invalid){
          this.submitStatus = 'ERROR'
        } else {
          const login = await this.signIn(this.credentials)
          console.log(login);
          if (login.authenticated) {
            this.countNotifications(this.getRol)
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp; ¡Bienvenido(a) a Trócut!`,
              type: 'success'
            })
            if (this.user.roles.length > 1) {
              for (let i = 0; i < this.user.roles.length; i++) {
                if(this.user.roles[i].rol_active !== 0){
                  if (this.user.roles.length > 1) {
                    this.rolName = this.user.roles
                    this.$modal.show('type-login')
                    } else if(this.user.roles.length === 0){
                      this.$modal.show('newRol-modal')
                    } else{
                      this.goToProfile(this.user.roles[0])
                    } 
                  }else {
                    if (this.user.roles.length > 1) {
                      this.rolName = this.user.roles
                      this.$modal.show('reactivy-rol')
                      } else if(this.user.roles.length === 0){
                        this.$modal.show('newRol-modal')
                      } else{
                        this.goToProfile(this.user.roles[0])
                      } 
                    }
              } 
            }else if(this.user.roles.length === 0){
              this.$modal.show('newRol-modal')
            } else{
              this.goToProfile(this.user.roles[0])
            }
          } else {
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp;${login.error}`,
              type: 'error'
            })
          }
          this.submitStatus = 'PENDING' 
          setTimeout(() => {
            this.submitStatus = 'OK'
          }, 500)
        }
      } catch (error) {
        console.error('Error', error)   
      }
    },
   async loginApple () {
      const auth = getAuth()
      const provider = new OAuthProvider('apple.com')
      signInWithPopup(auth,provider)
        .then(async(result) => {
            const credential =  OAuthProvider.credentialFromResult(result)
            const user = result.user
            this.credentials.email  = result.user.email   
            this.credentials.password = user.uid
            const login = await this.signIn(this.credentials)
            if(result._tokenResponse.isNewUser !== undefined){
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;&nbsp;Esta cuenta no esta registrada aún..`,
                type: 'error'
              })
              this.deleteAccountFirebase()
              return
            }
            if (login.authenticated) {
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;&nbsp;¡Bienvenido(a) a Trócut!`,
                type: 'success'
              })
              if (this.user.roles.length > 1) {
                for (let i = 0; i < this.user.roles.length; i++) {
                  console.log('else del ciclo');
                  if(this.user.roles[i].rol_active !== 0){
                    if (this.user.roles.length > 1) {
                      this.rolName = this.user.roles
                      this.$modal.show('type-login')
                    } else if(this.user.roles.length === 0){
                      this.$modal.show('newRol-modal')
                    } else{
                      this.goToProfile(this.user.roles[0])
                    } 
                  }else {
                  if (this.user.roles.length > 1) {
                    this.rolName = this.user.roles
                    this.$modal.show('reactivy-rol')
                    } else if(this.user.roles.length === 0){
                      this.$modal.show('newRol-modal')
                    } else{
                      this.goToProfile(this.user.roles[0])
                    } 
                  }
                } 
              }else if(this.user.roles.length === 0){
                this.$modal.show('newRol-modal')
              } else{
                this.goToProfile(this.user.roles[0])
              } 
            }else{
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;&nbsp;${login.error}`,
                type: 'error'
              })
            }
        }).catch((error)=>{
        console.error('LoginApple', error)
        })
    },
 async loginFacebook () {
      const provider = new firebase.auth.FacebookAuthProvider
      const auth = getAuth()
      signInWithPopup(auth, provider)
        .then(async(result)=>{
          const credential = FacebookAuthProvider.credentialFromResult(result)
          const user = result.user
          this.credentials.email  = user.email   
          this.credentials.password = user.uid
          const login = await this.signIn(this.credentials)
          if(result._tokenResponse.isNewUser !== undefined){
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp;Esta cuenta no esta registrada aún..`,
              type: 'error'
            })
            this.deleteAccountFirebase()
            return
          }
            if (login.authenticated) {
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;&nbsp;¡Bienvenido(a) a Trócut!`,
                type: 'success'
              })
              if (login.authenticated) {
                this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp;&nbsp;¡Bienvenido(a) a Trócut!`,
                  type: 'success'
                })
                if (this.user.roles.length > 1) {
                  for (let i = 0; i < this.user.roles.length; i++) {
                    console.log('else del ciclo');
                    if(this.user.roles[i].rol_active !== 0){
                      if (this.user.roles.length > 1) {
                        this.rolName = this.user.roles
                        this.$modal.show('type-login')
                      } else if(this.user.roles.length === 0){
                        this.$modal.show('newRol-modal')
                      } else{
                        this.goToProfile(this.user.roles[0])
                      } 
                    }else {
                      if (this.user.roles.length > 1) {
                        this.rolName = this.user.roles
                        this.$modal.show('reactivy-rol')
                        } else if(this.user.roles.length === 0){
                          this.$modal.show('newRol-modal')
                        } else{
                          this.goToProfile(this.user.roles[0])
                        } 
                    }
                  } 
                }else if(this.user.roles.length === 0){
                  this.$modal.show('newRol-modal')
                } else{
                  this.goToProfile(this.user.roles[0])
                } 
              }else{
                this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp;&nbsp;${login.error}`,
                  type: 'error'
                })
              }
            }
        }).catch((error) => {
          console.error('error en ',error);
        })
    },
  async loginGoogle () {
      const provider = new firebase.auth.GoogleAuthProvider
      provider.addScope('https://www.googleapis.com/auth/user.emails.read');
      provider.addScope('https://www.googleapis.com/auth/user.gender.read');
      provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      provider.addScope('https://www.googleapis.com/auth/analytics')
      provider.addScope('https://www.googleapis.com/auth/analytics.readonly')
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then(async(result) => {
          if (result._tokenResponse.isNewUser !== undefined) {
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp;Esta cuenta no esta registrada aún..`,
              type: 'error'
            })
            this.deleteAccountFirebase()
            return
          }
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
          const user = result.user
          this.credentials.email  = result.user.email   
          this.credentials.password = user.uid
          const login = await this.signIn(this.credentials)
          if (login.authenticated) {
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp;¡Bienvenido(a) a Trócut!`,
              type: 'success'
            })
            if (this.user.roles.length > 1) {
              for (let i = 0; i < this.user.roles.length; i++) {
                console.log('else del ciclo');
                if(this.user.roles[i].rol_active !== 0){
                  if (this.user.roles.length > 1) {
                    this.rolName = this.user.roles
                    this.$modal.show('type-login')
                  } else if(this.user.roles.length === 0){
                    this.$modal.show('newRol-modal')
                  } else{
                    this.goToProfile(this.user.roles[0])
                  } 
                }else {
                  if (this.user.roles.length > 1) {
                    this.rolName = this.user.roles
                    this.$modal.show('reactivy-rol')
                    } else if(this.user.roles.length === 0){
                      this.$modal.show('newRol-modal')
                    } else{
                      this.goToProfile(this.user.roles[0])
                    } 
                }
              } 
            }else if(this.user.roles.length === 0){
              this.$modal.show('newRol-modal')
            } else{
              this.goToProfile(this.user.roles[0])
            } 
          }else{
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp;&nbsp;${login.error}`,
              type: 'error'
            })
          }
        }).catch((error)=>{
          console.error(error);
        })
    },
    async registerRol (arg) {
      try {
          let payload = {
              user_id: this.user.user.user_id,
              role_id: arg ,
              os: 'web'
          }
          const response = await axiosTrocutServer.post('/registerRoleForUser', payload)
          if (response.status === 200) {
              this.$toastedPush({
                  message: `&nbsp;&nbsp;&nbsp;Registro Completo, por favor continue agregando su información.`,
                  type: 'success'
              })
          }
      } catch (error) {
          this.$toastedPush({
              message: ` &nbsp;&nbsp;&nbsp; Error registerTrocutServer ,${error}`,
              type:`error`
          })

      }
  },
  showPassword () {
    this.$modal.show('forgot-password-modal') 
  },
 async forgotPassword (){
   try {
     this.submittedF = true
    this.$v.credentialsF.$touch()
    if(this.$v.credentialsF.$invalid){
      this.$toastedPush({
        message: `&nbsp;&nbsp;&nbsp;Favor de Ingresar un correo valido`,
        type: 'error'
      })
    } else {
      this.credentialsF.email =  this.credentials.email != '' ?  this.credentialsF.email : this.credentials.email 
      let payload ={
        email: this.credentials.email
      }
        const response = await axiosTrocutServer.post('/forgotPassword',payload)
        if(response.status === 200 ){
          this.user_id = response.data.trainer_card
          this.$toastedPush({
            message: `&nbsp;&nbsp;&nbsp; ${response.data.message}`,
            type: `${response.data.success !== false ? 'success' : 'error'}`
          })
          if(response.data.success === true){
            /* if(response.data.typeSigIn !== 'Email'){
              if(response.data.typeSigIn === 'Google'){
                window.open("https://accounts.google.com/signin/v2/usernamerecovery?continue=https%3A%2F%2Fwww.google.com%2Fsearch%3Fq%3Dfg%26oq%3Dfg%26aqs%3Dchrome..69i57.109j0j1%26sourceid%3Dchrome%26ie%3DUTF-8&dsh=S-1627251415%3A1665601641211579&flowEntry=ServiceLogin&flowName=GlifWebSignIn&hl=es-419&ifkv=AQDHYWrxw8NJQ9JKJvhUBQYKcOFMsB_JPW_JBVN7HigCWwTC7qFyEcHXjUmMuYYz4glZILyfWrbo")
              }
              if(response.data.typeSigIn === 'Facebook'){               
               window.open("https://www.facebook.com/login/identify/?ctx=recover&ars=facebook_login&from_login_screen=0")      
              }
              if(response.data.typeSigIn === 'Apple'){
                window.open("https://iforgot.apple.com/password/verify/appleid")
              }
              return
            } */
            this.goToReset(response.data.response)
            return
          }
        } 
      } 
    }
    catch (error) {
      console.error(error);
    }
  },
  goToReset(){
    this.closeModal()
  },
  async goToProfile( arg ) {
      switch (arg.rol) {
        case 'Inquilino':
          await this.registerRol(1)
          this.setCurrentRol(arg)  
         this.$router.push({name:'index.tenant.profile'}) 
          break;
        case 'Broker':
          await this.registerRol(2)
          this.setCurrentRol(arg)  
          this.$router.push({name:'index.broker.account'}) 
          break;
        case 'Propietario':
          await this.registerRol(3)
          this.setCurrentRol(arg)  
          this.$router.push({name:'index.owner.profile'}) 
          break;
        }
        this.$modal.hide('type-login')
        this.$modal.hide('modal-login')
    },
    async reactiveProfile(arg){
      let params = {
        user_rol_id: arg.user_rol_id
      }
      const response = await axiosTrocutServer.post('/reactiveProfile',params)
      if(response.status === 200){
        this.goToProfile(arg)
      }
    },
    validateState (name) {
      const { $dirty, $error } =this.$v.credentials[name]
      return $dirty ? !$error : null
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    async getAxios (){
      try {
        const response = await axiosTrocutServer.get('/getNumbers',{
          params:{
            a:1,
            b:2,
            c:3
          }
        })
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async amounted  (){
   await this.getAxios()
    this.getRolesRegister()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }
}
