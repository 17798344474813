import  axiosTrocutServer from '@/axios/axiosTrocutServer'
import { faBullseye } from "@fortawesome/free-solid-svg-icons"
import { mapActions } from "vuex";
export default {
  props:{
    inventaryPicture:[]
  },
  data () {
      return {
      filesF: [],
      complete: false,
      isDragging:false,
      wrongFile:false,
      totalImages:[],
      loadImages:false,
      filesArrayCopy:[],
      arraDataPicture: {
        0:{name:'',size:'',imageSource:null}
      },
      filesArray: [],
      count: 0,
      file:'',
      fileName:'',
      fileSize :'',
      filePath :'',
      filesDrop:[]
    }
  },
  computed: {
    getClasses(){
      for (let i = 0; i < this.filesArray.length; i++) {
        console.log(this.filesArray[i])
      }
    }
  },
  methods: {
    ...mapActions({
      setFiles: 'setFiles'
  }),
    onChange() {
      this.filesDrop = [...this.$refs.file.files];
      const files =  this.filesDrop      
      for (const key in files) {
        if (key !== 'length' && key !== 'item') {
          console.log('esta es la key',key);
          let fileName = files[key].name
          let fileType = files[key].type
          fileType = fileType.split('/')
          let filePath = URL.createObjectURL(files[key])
          files[key].fileName = fileName = `${this.inventaryPicture[0].key_property.substring(0,4)}-${this.filesArrayCopy.length}.${fileType[1]}`
          files[key].filePath = filePath
          this.filesArray.push(files[key])
        }
      }
    },
    dragOver(e){
      e.preventDefault();
      this.isDragging = true
    },
    dragLeave(){
      this.isDragging = false
    },
    drop(e, type){
      let files = e.dataTransfer.files
      this.arraDataPicture[type].name = files[0].name
      this.arraDataPicture[type].size = files[0].size
      this.arraDataPicture[type].url = files[0].webkitRelativePath
      this.wrongFile = faBullseye
      if (files.length === 1) {
        let file = files[0]
        if (file.type.indexOf('image/') >= 0) {
          var reader = new FileReader()
          reader.onload = f => {
            this.arraDataPicture[type].imageSource=f.target.result
            this.isDragging = false
          }
          reader.readAsDataURL(file)
          this.arraDataPicture[type].fileType='image'
        }else{
          if(file.type === 'application/pdf' ){
              this.arraDataPicture[type].fileType = 'pdf'
          }
        }
      }
    },
    uploadFile() {
      this.filesArray = [...this.$refs.file.files]
    },
    dragFile(e) {
      this.filesArray = e.dataTransfer.files;
    },
    dropZ(e){
      e.preventDefault();
      let files =  e.dataTransfer.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let type = files[i].type
          type = type.split('/')
          if(type[1] === 'jpeg' || type[1] === 'png' ){
          console.log('elementos',files[i])
            this.$refs.file.files = e.dataTransfer.files;
            //this.$refs.file.files = files[i]
          }else {
            console.log('nel carnal');
          }
        }
      }
      this.onChange()
      this.isDragging = false
    },
    oneMore(e){
      const files = e.target.files          
      for (const key in files) {
        if (key !== 'length' && key !== 'item') {
          let fileName = files[key].name
          let fileType = files[key].type
          fileType = fileType.split('/')
          let filePath = URL.createObjectURL(files[key])
          files[key].fileName = fileName = `${this.inventaryPicture[0].key_property.substring(0,4)}-${this.filesArrayCopy.length}.${fileType[1]}`
          files[key].filePath = filePath
          this.filesArray.push(files[key])
        }
      }
    },
    lessPictures(position){
      delete this.filesArray[position]
      let arrTemp = []
      this.filesArray.forEach(el => {
        arrTemp.push(el)
      })
      this.filesArray = arrTemp
    },
    async uploadImage () {
      try {
        //console.log('inventary Pictures',this.inventaryPicture)
        this.loadImages = true
        const tempArr = []
        const images = tempArr
        var form = new FormData()
        this.totalImages.forEach(el => {
          tempArr.push(el.files)
        })
        //console.log('imprimir',this.inventaryPicture[0].key_property)
        form.append("type","Properties")
          form.append("key_property",this.inventaryPicture[0].key_property)
          form.append("subtype","images")
          form.append("property_id",this.inventaryPicture[0].property_id)
        for (const key in this.filesArray) {
          form.append("Images",this.filesArray[key])
        }
        if(this.filesArray.length >= 6){
          const response = await axiosTrocutServer.post('/addImageProperty', form)
          if(response.status === 200){
            this.setFiles({
              picturesArr:this.filesArray
            })
            this.$toastedPush({
              message: `&nbsp;&nbsp;&nbsp; felicidades fueron cargadas las imagenes de la propiedad`,
              type:`success`
            })
            this.loadImages = false
            this.$emit('changeStep')
          }
          return
        }
        this.loadImages = true
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp; favor de subir al menos 6 fotografias de la propiedad`,
          type:`error`
        })
      } catch (error) {
        this.loadImages = false
        this.$toastedPush({
          message: `&nbsp;&nbsp;&nbsp; favor de subir archivos con formato de imagen`,
          type:`error`
        })
      }
    },
    verifyLength (){
      if( this.filesArray.length >=6 ){
        this.setFiles({
          picturesArr:this.filesArray
        })
      }
    }
  },
  mounted() {
    if(this.inventaryPicture[1] !== undefined){
      this.inventaryPicture[1].forEach(el => {
        if(el.property_id === this.inventaryPicture[0].property_id){
          this.filesArray.push({filePath:el.url})
        }
      })
    }
  }
}