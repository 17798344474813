import cardCarousel from '@/components/generals/cards-properties'
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import { mapGetters } from 'vuex';
import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    name: "app",
    data () {
        return {
            currentRolComplete:[],
            card:0,
            favorites: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                700: {
                  itemsToShow: 3.5,
                  snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                  itemsToShow: 1,
                  snapAlign: 'start',
                }
            },
            shareProperty (){
                this.$modal.show('share-modal')
            }
        }
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            user:'getUser',
            getCurrentRol:'getCurrentRol',
            getRol:'getRol',
            getStoreUser:'getStoreUser'
          })
    },
    methods: {
        async removeFavorites (row){
            this.$refs['heart' + row][0].classList.remove('favoriteImageColor')
            let payload = {
                tenant_id:  this.currentRolComplete.user_rol_id,
                property_id: parseInt(row)
            } 
            const response = await axiosTrocutServer.post('/removeFavoriteProperty', payload)
            if (response.status === 200) {
                this.$toastedPush({
                    message: `&nbsp;&nbsp;&nbsp; Se elimino la propiedad de tus favoritos.`,
                    type: 'success'
                })
                this.getFavorites()
            }
        },
        async getFavorites (){
            this.favorites =[]
            try {
                let params  = {
                    tenant_id: this.currentRolComplete.user_rol_id
                }
                const response = await  axiosTrocutServer.post('/getFavoriteProperty',params)
                if( response.status === 200){
                    response.data.response.forEach(el => {
                        this.favorites.push(el) 
                    })
                } 
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted (){
        const store = this.getStoreUser.roles
        this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol)
        this.getFavorites()
    }
}
