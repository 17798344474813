import brokerPropiedades from '@/components/broker/propertyBroker'

export default {
  components: {
    brokerPropiedades   
  },
  data(){
    return{
     
    }
  }
};
