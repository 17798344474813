//import timeLine from "../../generals/time-line"
import ownerProcess from "@/components/owner/modalRentOwner";
import axiosTrocutServer from '@/axios/axiosTrocutServer'
import Datepicker from 'vuejs-datepicker'
import {es} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import downBroker from '@/components/generals/downs-Type'
import { mapActions, mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";


export default {
    components:{
        Datepicker,
        ownerProcess,
        downBroker
    },
    props:{
        idBroker:0
    },
    data () {
      return{
        currentRolComplete:[],
        highlighted: {
          to: new Date(), 
          from: new Date(), 
          includeDisabled: true // Highlight disabled dates
        },
        hora: new Date(),
        es:es
      }
    },
    computed: {  
      ...mapGetters({
        user:'getUser',
        getAuth: 'getAuth',
        getRol:'getRol',
        getStoreUser:'getStoreUser',
        getCurrentRol: 'getCurrentRol'
      })
    },
    methods: {
        ...mapActions({
            signOut: 'signOut'
        }),
        logOut (){
            const auth = getAuth();
            signOut(auth).then(() => {
              console.log('gracias por visitarnos ')
              console.log('Danke, dass Sie uns besuchen')
            }).catch((error) => {
             console.error(error);
            });
            this.signOut()
            this.$router.push({ path: '/'}).catch(()=>{})
          },
        closeModalB () {
            this.$modal.hide('modal-Baja-Broker')
            this.$modal.hide("modal-Baja")
            this.$modal.hide('modal-Baja-temporal')
            this.$modal.hide('modal-Baja-temporal')
            this.$modal.hide('modal-Baja-General')
            this.$modal.hide('modal-Baja-Broker')
        },
        closeModalS(){
            this.$modal.hide("modal-Baja")
            this.$modal.hide('modal-Baja-temporal')
            this.$modal.hide('modal-Baja-temporal')
            this.$modal.hide('modal-Baja-General')
            this.$modal.hide('modal-Baja-Broker')
        },
        brokerTemporal(){
            this.$modal.show('modal-Baja-temporal')
        },
          brokerPermanente(){
            this.$modal.show('modal-Baja-Broker')
        },
        async deleteUser(arg){
            let params = {
              notification:`Hola ${this.user.user_data.fullName},hemos recibido de tu parte una solicitud para darte de baja de manera temporal de nuestra plataforma. 
              A continuación te dejamos los datos del periodo de inactividad que has solicitado:
              por ${arg} dias
              Te recordamos que deberás coordinar con tu broker socio la entrega de la llave de las propiedades que tienes a tu cargo, y de recibirlas nuevamente una vez que estés de regreso. 
              De igual forma, deberás notificarle respecto a los procesos de renta que pudieras tener en marcha, y a partir de los cuales, tu broker socio deberá gestionar a nombre tuyo.
              `,
              importantId: 1,
              user_rol_id: this.currentRolComplete.user_rol_id,
              notification_type: "Broker",
              rental_process_id: 0,
              idTemplate: 35,
              userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
              email: this.user.user !== undefined ? this.user.user.email : this.user.user.email,
              roleId: 4
            }
            const response = await axiosTrocutServer.post('/addNotification',params)
            if(response.status === 200 ){
              this.logOut()
            }
        },
        async deleteUser1(){
            let params = {
              notification:`Hola ${this.user.user_data === undefined ? this.user.user.email : this.user.user_data.name}, hemos recibido de tu parte una solicitud para darte de baja de manera permanente de nuestra plataforma. Lamentamos que tomes esta decisión. 
              Haz click en el siguiente botón para confirmar dicha solicitud.
              Te recordamos que deberás coordinar con tu broker socio la entrega de la llave de las propiedades que tienes a tu cargo.
              En caso de querer reactivar tu cuenta en algún momento más adelante, deberás ingresar a trocut.com.
              El equipo Trócut
              `,
              importantId: 1,
              user_rol_id: this.currentRolComplete.user_rol_id,
              notification_type: "User",
              rental_process_id: 0,
              idTemplate: 2,
              userId: this.user.user !== undefined ? this.user.user.user_id  : 0,
              email: this.user.user !== undefined ? this.user.user.email : this.user.user.email
            }
            const response = await axiosTrocutServer.post('/addNotification',params)
            if(response.status === 200 ){
              this.logOut()
            }
        },
        showModalFinale(){
          this.$modal.show('modal-Baja-Broker-definitive')
        },
        async confirmTemporal() {
            var fechaSalida = moment(this.highlighted.to).format('YYYY-MM-DD')
            var fechaRegreso = moment(this.highlighted.from).format('YYYY-MM-DD')
            var exitDate= new Date(fechaSalida)
            var inDate= new Date(fechaRegreso)
            var suma = Math.abs(exitDate-inDate)
            var days = suma/(1000 * 3600 * 24)
            if(days === 0){
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;debe de seleccionar un rango de 15 dias maximo`,
                type: 'error'
              })
            }
            if (days >1 && days <= 15){
                try {
                    let params = {
                        user_id: this.idBroker,
                        down_init: fechaRegreso,
                        down_end:fechaSalida,
                        user_rol_id: this.currentRolComplete.user_rol_id,
                        idTemplate: 35
                    }
                    const response = await axiosTrocutServer.post('/updadteStatusUser',params)
                    if( response.status === 200 ){
                        this.$toastedPush({
                          message: `&nbsp;&nbsp;&nbsp;se dio de baja temporal nos vemos en  ${days} dias`,
                          type: 'success'
                          })
                        this.closeModalB ()
                        this.closeModalS()
                        this.deleteUser(days)
                        
                    }
                } catch (error) {
                    console.error(error);
                }
            }if (days >= 16){
              this.$toastedPush({
                message: `&nbsp;&nbsp;&nbsp;no puede darse de baja temporal por mas de  15 dias`,
                type: 'error'
              })
            }
            console.log('me voy el:',fechaRegreso,',tal vez regrese el :', fechaSalida);
        },
        async definitiveUser() {
            try {
              let params = {
                user_id: this.user.user.user_id,
                user_rol_id: this.currentRolComplete.user_rol_id,
                idTemplate: 2
              }
              this.deleteUser1()
              const response = await axiosTrocutServer.post('/updadteStatusUser',params)
              if( response.status === 200 ){
                  this.$toastedPush({
                      message: `&nbsp;&nbsp;&nbsp;se dio de baja al usario`,
                      type: 'success'
                    })
                  
                  this.closeModalInfo ()
              }
          } catch (error) {
              console.error(error);
          }
        }
    },
    mounted () {
      const store = this.getStoreUser.roles
      this.currentRolComplete = store.find(el =>  el.rol === this.getCurrentRol )
      this.hora = moment().format('LT')
    }
}